import React from "react";
import { Link } from "@reach/router";

import { timeSince } from "../../../utils/helpers";
import { BookingState } from "../../../types/booking";
import { eventDescription, onBehalfOf } from "../../bookings/BookingHelpers";
import { BookingStateEventT } from "../../../graphql/queries/dashboard/supportCoordinatorDashboard";
import { IUserContext } from "../../../types/profile";

export const RecentClientActivity = ({
  activity,
  user,
}: {
  activity: BookingStateEventT[];
  user: Pick<IUserContext, "id">;
}) => {
  return (
    <div className="pl-6 lg:w-80 hidden lg:block">
      <div className="pt-6 pb-2">
        <h2 className="text-sm leading-5 font-semibold">Recent Activity</h2>
      </div>
      <div>
        <ul className="divide-y divide-gray-200">
          {activity.map((stateEvent: BookingStateEventT) => {
            const actionedBy = onBehalfOf(stateEvent);

            const color = [
              BookingState.CANCELLED_BY_SUPPORT_WORKER,
              BookingState.CANCELLED_BY_END_USER_WITH_NOTICE,
              BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE,
              BookingState.CANCELLED_BY_ADMIN,
              BookingState.TIMED_OUT_REQUEST,
            ].includes(stateEvent.newState)
              ? "red"
              : "teal";

            return (
              <li className="py-4" key={stateEvent.id}>
                <div className="flex space-x-3">
                  <div
                    className={`h-4 w-4 rounded-full bg-${
                      stateEvent.onBehalfOf === "SYSTEM"
                        ? "gray-300"
                        : `${color}-500`
                    } mt-1`}
                  />
                  <div className="flex-1 space-y-1">
                    <div className="flex items-center justify-between">
                      <h3 className="text-sm font-medium leading-5 mb-0">
                        {actionedBy &&
                          (actionedBy.id === user.id ? "You" : actionedBy.name)}
                      </h3>
                      <p className="text-sm leading-5 text-gray-500">
                        {timeSince(stateEvent.insertedAt)}
                      </p>
                    </div>
                    <p className="text-sm leading-5 text-gray-500">
                      {stateEvent.onBehalfOf === "SYSTEM" && "automatically "}
                      {eventDescription(
                        stateEvent.newState,
                        stateEvent.booking,
                        stateEvent.onBehalfOf === "SYSTEM"
                      )}
                      {actionedBy &&
                        actionedBy.id === user.id &&
                        ` for ${stateEvent.booking.endUser.name}`}
                      <Link
                        to={`/bookings/${stateEvent.booking.id}`}
                        className="text-sm ml-2 font-bold"
                      >
                        view
                      </Link>
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {/* <div className="py-4 text-sm leading-5 border-t border-gray-200">
    <p className="text-teal-600 font-semibold hover:text-teal-900">
      View all activity &rarr;
    </p>
  </div> */}
      </div>
    </div>
  );
};
