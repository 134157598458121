import React, { ChangeEvent } from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import { RECOMMENDED_SUPPORT_PROFESSIONALS } from "../../../../graphql/queries/recommendedSupportProfessionals/recommendedSupportProfessionals";
import { ToggleField as Toggle } from "../../../layout/forms";
import { EndUser } from "../../../../types/endUser";
import { useLogger } from "../../../../hooks/useLogger";
import {
  UPDATE_END_USER,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateEndUser";

export const UpdateVirtualPreferenceForm = ({
  endUser,
  add,
}: {
  endUser: Pick<EndUser, "id" | "virtualPreference">;
  add: AddToast;
}) => {
  const { log } = useLogger();
  const [updateEndUser] = useMutation<NewData, Variables>(UPDATE_END_USER);
  const handleSubmit = (values: any, formikApi: any) => {
    const { virtualPreference } = values;

    const variables = {
      id: endUser.id,
      virtualPreference,
    };

    updateEndUser({
      variables,
      refetchQueries: [
        {
          query: RECOMMENDED_SUPPORT_PROFESSIONALS,
          variables: { endUserId: endUser.id },
        },
      ],
    })
      .then((res) => res)
      .catch((err) => {
        log("error", err);
        formikApi.resetForm();

        add(`There was an error updating your service offering`, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ virtualPreference: endUser.virtualPreference }}
    >
      {(props) => {
        const { submitForm } = props;
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
          submitForm();
        };
        return (
          <Form>
            <Toggle
              name="virtualPreference"
              label="I am open to booking virtual services"
              ariaLabel="I am open to booking virtual services"
              onChange={handleChange}
              className="mb-4"
            />
            <p className="bg-gray-100 border border-gray-300 p-3 text-gray-700">
              <span className="font-bold mr-2">Note:</span> Not all services can
              be delivered virtually
            </p>
          </Form>
        );
      }}
    </Formik>
  );
};
