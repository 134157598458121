import gql from "graphql-tag";

export interface NewData {
  sendSupportRequest: string;
}

export interface Variables {
  message: string;
  serviceRoleId: string;
}

export const SEND_SUPPORT_REQUEST = gql`
  mutation sendSupportRequest($message: String!, $serviceRoleId: ID!) {
    sendSupportRequestEmail(message: $message, serviceRoleId: $serviceRoleId)
  }
`;
