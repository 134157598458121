import React from "react";
import moment from "moment";
import { Link } from "@reach/router";

import { Icon } from "../../layout/Icons"
import { Label } from "../../layout/Label"
import { BookingState, Booking } from "../../../types/booking";
import { IUserContext, ServiceRole } from "../../../types/profile";

export const ConfirmedLabel = ({
  booking,
  name,
  user,
  bookingStateLabel,
  link,
  penalty,
}: {
  booking: Pick<Booking, "stateEvents">;
  name: string;
  user: Pick<IUserContext, "__typename">;
  bookingStateLabel: string;
  link?: string;
  penalty?: boolean;
}) => {
  if (link) {
    return (
      <Link to={link}>
        <Label>{bookingStateLabel}</Label>
      </Link>
    );
  }
  const acceptedAtEvent =
    booking.stateEvents &&
    booking.stateEvents.find((event) => {
      return (
        event.oldState &&
        event.oldState.toUpperCase() === BookingState.REQUESTED_BY_END_USER &&
        event.newState.toUpperCase() === BookingState.ACCEPTED_BY_SUPPORT_WORKER
      );
    });

  const currentUserIsEU = user.__typename === ServiceRole.END_USER;
  const currentUserIsSP = user.__typename === ServiceRole.SUPPORT_WORKER;

  const bookingAcceptedNote = acceptedAtEvent && (
    <p className="italic mb-4">
      <Icon name="check" className="w-4 text-green-400 inline-block mr-2" />
      Booking confirmed by {currentUserIsSP ? "you" : name} on{" "}
      {moment(acceptedAtEvent.insertedAt).format("dddd Do MMMM")}
    </p>
  );

  return (
    <>
      {bookingAcceptedNote}
      {penalty && currentUserIsEU && (
        <div className="rounded-md bg-gray-50 px-6 py-4 border-gray-300 border-2 text-gray-900">
          This booking is within 24 hours - cancellation will incur a fee equal
          to the full cost of the booking
        </div>
      )}
    </>
  );
};
