import gql from "graphql-tag";

import { Booking } from "../../types/booking";
import { Viewer } from "../../types/viewer";
import { BOOKING_FIELDS } from "../fragments";

export interface Data {
  viewer: Viewer;
  booking: Booking;
}

export interface Variables {
  bookingId: string;
}

export const BOOKING_AND_VIEWER = gql`
  ${BOOKING_FIELDS}
  query BookingQuery($bookingId: ID!) {
    viewer {
      id
      role
      endUsers {
        id
        name
        permissions {
          booking
          message
          invoice
        }
        profileImage {
          id
          small
          med
        }
      }
      supportProfessionals {
        id
        name
      }
    }

    booking(id: $bookingId) {
      stateEvents {
        account {
          id
        }
        oldState
        newState
        insertedAt
      }
      ...BookingFields
    }
  }
`;
