import moment from "moment";
import { FormikErrors } from "formik";

import { humanizeDuration } from "../../../utils/helpers";
import { FormValues } from "./BookingFormData";
import { PaymentFlow } from "../../../types/booking";
import {
  Availability,
  ServiceOffering,
  SupportProfessional,
} from "../../../types/supportProfessional";
import { EndUser, Permissions } from "../../../types/endUser";

export const createValidateFn = (
  serviceOffering: ServiceOffering | undefined,
  billable: boolean,
  supportProfessional: Pick<
    SupportProfessional,
    "name" | "availabilities" | "wwcc"
  >,
  currentEndUserPermissions?: Permissions,
  clients?: Pick<EndUser, "id" | "dateOfBirth" | "permissions" | "name">[]
) => {
  const { availabilities } = supportProfessional;

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    const startTimeValue = moment(values.date)
      .hour(parseInt(values.startHours, 10))
      .minute(parseInt(values.startMinutes, 10))
      .millisecond(0);

    const availability = availabilities.find(
      (a: Availability) => a.dayNumber === moment(values.date).day()
    );

    if (availability && !availability.available)
      errors.date = `${supportProfessional.name} isn't available on this day`;

    if (!values.date) {
      errors.date = "You must choose a date";
    }

    if (!values.service) {
      errors.service = "You must choose a service";
    }

    if (
      startTimeValue.isBefore(
        moment()
          .add(1, "hour")
          .toDate()
          .getTime()
      )
    ) {
      errors.startHours = "1 hour's notice is required";
    }
    if (
      startTimeValue.isBefore(
        moment()
          .toDate()
          .getTime()
      )
    ) {
      errors.startMinutes = "1 hour's notice is required";
    }

    if (!values.terms) {
      errors.terms = "You must accept the booking terms and conditions";
    }

    const selectedClient =
      clients && clients.find((c) => c.id === values.clientId);

    const clientIsOver18 =
      selectedClient &&
      moment().diff(moment(selectedClient.dateOfBirth), "years") >= 18;

    const validWwcc = supportProfessional.wwcc.find(
      (w) =>
        w.verificationDate && moment(w.expiryDate).isAfter(moment(Date.now()))
    );

    if (values.clientId && !clientIsOver18 && !validWwcc) {
      errors.clientId =
        "This client requires a valid Working with Children Check";
    }

    if (values.clientId && selectedClient && selectedClient.permissions) {
      if (
        !selectedClient.permissions.invoice &&
        values.payment === PaymentFlow.INVOICE
      ) {
        errors.clientId = `Invoicing feature hasn't been added to ${
          selectedClient.name.split(" ")[0]
        }'s account yet. Please email support@tappon.co to request this.`;
      }

      if (!selectedClient.permissions.booking) {
        errors.clientId =
          "This client is not yet able to make bookings. Complete their profile details first.";
      }
    }

    if (values.recurring && serviceOffering?.weekdayRate.amount === 0) {
      errors.service = "This service cannot be recurring";
    }

    return errors;
  };

  const minDuration = serviceOffering
    ? humanizeDuration(serviceOffering.minDurationMins)
    : 60;

  const validateBillable = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = validate(values);

    if (!values.payment) {
      errors.payment = "Payment type is required";
    }

    if (
      serviceOffering &&
      parseInt(values.durationHours, 10) +
        parseInt(values.durationMinutes, 10) <
        serviceOffering.minDurationMins
    ) {
      errors.durationHours = `Booking must be at least ${minDuration} long`;
      errors.durationMinutes = `Booking must be at least ${minDuration} long`;
    }

    if (
      values.payment === "INVOICE" &&
      currentEndUserPermissions &&
      !currentEndUserPermissions.invoice
    ) {
      errors.payment =
        "Invoicing feature hasn't been added to your account yet. Please email support@tappon.co to request this.";
    }

    return errors;
  };

  return billable ? validateBillable : validate;
};
