import gql from "graphql-tag";

import { Booking } from "../../types/booking";
import { ServiceRole } from "../../types/profile";
import { BOOKING_FIELDS } from "../fragments";

export interface ExtendedBooking extends Booking {
  endUser: {
    id: string;
    name: string;
    addressCity: string;
    profileImage: { id: string; small: string };

    // Extended Fields
    supportCoordinator?: {
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
}

export interface Data {
  bookingHistory: {
    total: number;
    bookings: Pick<
      ExtendedBooking,
      | "id"
      | "endTime"
      | "endUser"
      | "insertedAt"
      | "location"
      | "schedule"
      | "serviceName"
      | "startTime"
      | "state"
      | "supportProfessional"
      | "totalCost"
      | "scheduleId"
      | "paymentFailed"
    >[];
  };
}

export interface Variables {
  id: string;
  limit?: number;
  offset?: number;
}

const END_USER_BOOKING_HISTORY = gql`
  ${BOOKING_FIELDS}

  query EndUserBookingHistory($id: ID!, $offset: Int, $limit: Int) {
    bookingHistory: bookingHistoryForEndUser(
      id: $id
      offset: $offset
      limit: $limit
    ) {
      bookings {
        ...BookingFields
        endUser {
          supportCoordinator {
            id
            firstName
            lastName
          }
        }
      }
      total
    }
  }
`;

const SUPPORT_COORDINATOR_BOOKING_HISTORY = gql`
  ${BOOKING_FIELDS}

  query SupportCoordinatorBookingHistory($id: ID!, $offset: Int, $limit: Int) {
    bookingHistory: bookingHistoryForSupportCoordinator(
      id: $id
      offset: $offset
      limit: $limit
    ) {
      bookings {
        ...BookingFields
      }
      total
    }
  }
`;

const SUPPORT_PROFESSIONAL_BOOKING_HISTORY = gql`
  ${BOOKING_FIELDS}

  query SupportProfessionalBookingHistory($id: ID!, $offset: Int, $limit: Int) {
    bookingHistory: bookingHistoryForSupportProfessional(
      id: $id
      offset: $offset
      limit: $limit
    ) {
      bookings {
        ...BookingFields
      }
      total
    }
  }
`;

export const bookingHistoryQueryForUserType = {
  [ServiceRole.END_USER]: END_USER_BOOKING_HISTORY,
  [ServiceRole.SUPPORT_WORKER]: SUPPORT_PROFESSIONAL_BOOKING_HISTORY,
  [ServiceRole.SUPPORT_COORDINATOR]: SUPPORT_COORDINATOR_BOOKING_HISTORY,
};
