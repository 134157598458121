import React, { useState, ChangeEvent } from "react";
import { Formik, Form, setNestedObjectValues, FormikTouched } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { ToastConsumer, AddToast } from "react-toast-notifications";
import moment from "moment";

import {
  Checkbox,
  CheckboxButtonGroup,
  Radio,
  DatePicker,
  Dropdown,
  DropdownGroup,
  TextArea,
  Label,
} from "../../layout/forms";
import {
  SupportProfessional,
  RatePenalty,
  ServiceOffering,
} from "../../../types/supportProfessional";
import { ServiceRole } from "../../../types/profile";
import { unCamelize } from "../../../utils/helpers";
import {
  durationHoursOptions,
  durationMinutesOptions,
  hoursOptions,
  initialValues,
  minutesOptions,
  createServiceOptions,
  createPaymentOptions,
  createDeliveryTypeOptions,
  dayOptions,
  FormValues,
} from "./BookingFormData";
import { createValidateFn } from "./validations";
import {
  CREATE_BOOKING_MUTATION,
  NewData,
  Variables as MutationVariables,
} from "../../../graphql/mutations/createBooking";
import { handleBookingFormSubmit } from "./BookingFormSubmit";
import { Loading } from "../../layout/Loading";
import { useLogger } from "../../../hooks/useLogger";
import { Button } from "../../layout/Button";
import { ErrorPage } from "../../layout/ErrorPage";
import { DeliveryType } from "../../../types/booking";
import { SelectClientModal } from "./SelectClientModal";
import { Avatar } from "../../layout/Avatar";
import { setEveningRate, setDaysDisabled } from "./bookingFormHelpers";
import {
  bookingFormQueryForUserType,
  Data,
  Variables,
} from "../../../graphql/queries/bookingForm";
import { BookingConfirmationModal } from "./BookingConfirmationModal";

export interface BookingFormProps {
  user: {
    id: string;
    __typename: ServiceRole.END_USER | ServiceRole.SUPPORT_COORDINATOR;
  };
  supportProfessional: Pick<
    SupportProfessional,
    | "id"
    | "stripeConnectAccountId"
    | "stripeConnectAccount"
    | "serviceOfferings"
    | "acceptsFastBookings"
    | "acceptsTransferBookings"
    | "availabilities"
    | "name"
    | "wwcc"
    | "profileImage"
    | "shortDescription"
    | "title"
    | "location"
  >;
  disabled?: boolean;
}

export const BookingForm = ({
  user,
  supportProfessional,
  disabled,
}: BookingFormProps) => {
  const {
    stripeConnectAccount,
    stripeConnectAccountId,
    availabilities,
    profileImage,
  } = supportProfessional;

  const { log } = useLogger();

  const [rate, setRate] = useState(RatePenalty.WEEKDAY);
  const [createBookingRequest] = useMutation<NewData, MutationVariables>(
    CREATE_BOOKING_MUTATION
  );
  const [billable, setBillable] = useState(true);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );
  const [service, setService] = useState<ServiceOffering | undefined>(
    undefined
  );

  const displayConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState<
    { text: string; value: DeliveryType }[] | []
  >([]);

  const { data, error, loading } = useQuery<Data, Variables>(
    bookingFormQueryForUserType[user.__typename],
    {
      variables:
        user.__typename === ServiceRole.END_USER
          ? { endUserId: user.id }
          : { supportCoordinatorId: user.id },
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading data-testid="loading" />;
  }

  const { publicHolidays, endUser, clients } = data;

  const usingHigherRate =
    rate !== RatePenalty.WEEKDAY && service && service[rate];

  const serviceOfferingMap: {
    [key: string]: ServiceOffering;
  } = supportProfessional.serviceOfferings.reduce(
    (map: { [key: string]: ServiceOffering }, service: ServiceOffering) => {
      map[service.id] = service;
      return map;
    },
    {}
  );

  const serviceOptions = createServiceOptions({
    supportProfessional,
    rate,
  }).filter((so) => {
    return (
      so.enabled &&
      ((endUser && !endUser.virtualOnly) ||
        so.virtual ||
        user.__typename === ServiceRole.SUPPORT_COORDINATOR)
    );
  });

  //Add payment options only if Support Professional accepts them
  const paymentOptions = createPaymentOptions({ supportProfessional });

  const nonBillableServices = supportProfessional.serviceOfferings
    .filter((s) => s.weekdayRate.amount === 0)
    .map((s) => s.id);

  if (
    // Has no service offerings available
    serviceOptions.length < 1 ||
    // Has no enabled Stripe Connect Account
    (!stripeConnectAccountId && !stripeConnectAccount) ||
    (stripeConnectAccount && !stripeConnectAccount.payoutsEnabled) ||
    // Has disabled both types of payment options
    paymentOptions.length === 0 ||
    // Has no profile image
    !profileImage ||
    // Has set every day to unavailable
    availabilities.filter((a) => !a.available).length === 7
  ) {
    return (
      <p>This support professional is currently unavailable for bookings</p>
    );
  }

  const onServiceChange = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    return (e: ChangeEvent<HTMLSelectElement>) => {
      setBillable(!nonBillableServices.includes(e.target.value));
      const service = supportProfessional.serviceOfferings.find(
        (s) => s.id === e.target.value
      );
      if (service) {
        const options = createDeliveryTypeOptions({ serviceOffering: service });
        setDeliveryTypeOptions(options);
        setFieldValue("deliveryType", options[0].value);
        setService(service);
      }
    };
  };

  const unavailableDaysOfWeek = availabilities
    .filter((a) => !a.available)
    .map((a) => (a.dayNumber === 7 ? 0 : a.dayNumber));

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        const handleSubmit = handleBookingFormSubmit({
          serviceOfferingMap,
          endUser,
          supportProfessional,
          billable,
          createBookingRequest,
          add,
          log,
          user,
        });
        return (
          <Formik
            initialValues={initialValues({
              deliveryType:
                deliveryTypeOptions[0] && deliveryTypeOptions[0].value,
            })}
            onSubmit={handleSubmit}
            validate={createValidateFn(
              service,
              billable,
              supportProfessional,
              endUser && endUser.permissions,
              clients
            )}
          >
            {({
              errors,
              touched,
              isSubmitting,
              validateForm,
              values,
              setFieldValue,
              setTouched,
              submitForm,
              setValues,
            }) => {
              const {
                durationHours,
                durationMinutes,
                startHours,
                startMinutes,
                date,
                clientId,
              } = values;

              const client = clients && clients.find((c) => c.id === clientId);

              return (
                <Form
                  id="booking-form"
                  className={`${
                    disabled ? "opacity-50" : "opacity-100"
                  } space-y-4`}
                >
                  {confirmationModalVisible && (
                    <BookingConfirmationModal
                      isSubmitting={isSubmitting}
                      hideConfirmationModal={hideConfirmationModal}
                      submitForm={submitForm}
                      supportProfessional={supportProfessional}
                      values={values}
                      service={service}
                      endUser={endUser || client}
                      publicHolidays={publicHolidays}
                    />
                  )}
                  {client && (
                    <div>
                      <Label className="mb-1" htmlFor="clientId">
                        Client
                      </Label>
                      <div className="flex-shrink-0 group block focus:outline-none">
                        <div className="flex items-center">
                          <div>
                            <Avatar user={client} size="xs" />
                          </div>
                          <div className="ml-3">
                            <p className="text-base leading-5 font-medium text-gray-700 group-hover:text-gray-900 mb-1">
                              {client.name}
                            </p>
                            <p
                              className="text-sm leading-4 font-bold cursor-pointer text-teal-600 group-hover:text-teal-700 group-focus:underline transition ease-in-out duration-150"
                              onClick={() => setFieldValue("clientId", "")}
                            >
                              change
                            </p>
                          </div>
                        </div>
                        {errors.clientId && (
                          <p className="mt-2 text-sm text-red-600 max-w-xs">
                            {errors.clientId}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  <DatePicker
                    name="date"
                    label={values.recurring ? "Start Date" : "Booking Date"}
                    availabilities={supportProfessional.availabilities}
                    disabled={disabled}
                    onDateChange={(date) => {
                      setEveningRate(
                        parseInt(startHours, 10),
                        parseInt(startMinutes, 10),
                        parseInt(durationHours, 10),
                        parseInt(durationMinutes, 10),
                        service,
                        rate,
                        setRate,
                        date.toISOString(true),
                        true
                      );

                      // Reset which recurring days are selected
                      const {
                        monday,
                        tuesday,
                        wednesday,
                        thursday,
                        friday,
                        saturday,
                        sunday,
                      } = initialValues({
                        deliveryType:
                          deliveryTypeOptions[0] &&
                          deliveryTypeOptions[0].value,
                      });

                      setValues({
                        ...values,
                        monday,
                        tuesday,
                        wednesday,
                        thursday,
                        friday,
                        saturday,
                        sunday,
                      });

                      // Set the required day of the week if recurring
                      setFieldValue(
                        moment(date)
                          .format("dddd")
                          .toLowerCase(),
                        true
                      );

                      if (
                        publicHolidays
                          .map((p) => p.date)
                          .includes(moment(date).format("YYYYMMDD"))
                      ) {
                        setRate(RatePenalty.PUBLIC_HOLIDAY);
                      }
                    }}
                    unavailableDaysOfWeek={unavailableDaysOfWeek}
                  />

                  <Checkbox name="recurring" label="Repeat this booking?" />

                  {values.recurring && (
                    <div className={`space-y-4 border border-gray-300 p-3`}>
                      <Radio
                        name="interval"
                        layout="horizontal"
                        options={[
                          { text: "Weekly", value: "1" },
                          { text: "Every two weeks", value: "2" },
                        ]}
                      />

                      <div className="flex flex-col items-center space-y-3">
                        {/* Weekdays */}
                        <CheckboxButtonGroup
                          options={setDaysDisabled(
                            dayOptions.slice(0, 5),
                            values,
                            unavailableDaysOfWeek
                          )}
                        />
                        {/* Weekend */}
                        <CheckboxButtonGroup
                          options={setDaysDisabled(
                            dayOptions.slice(5, 7),
                            values,
                            unavailableDaysOfWeek
                          )}
                        />
                      </div>

                      <DatePicker
                        name="until"
                        label="Until (optional)"
                        availabilities={[]}
                        rangeStart={(values.date
                          ? moment(values.date)
                          : moment()
                        ).add(parseInt(values.interval, 10), "weeks")}
                        rangeEnd={moment().add(6, "months")}
                        showClearDate
                        onDateChange={(date) => {}}
                      />
                    </div>
                  )}

                  {user.__typename === ServiceRole.SUPPORT_COORDINATOR &&
                    !values.clientId &&
                    clients && (
                      <SelectClientModal
                        clients={clients}
                        setClient={(clientId: string) =>
                          setFieldValue("clientId", clientId)
                        }
                        supportProfessional={supportProfessional}
                      />
                    )}

                  <Dropdown
                    name="service"
                    customHandleChange={onServiceChange(setFieldValue)}
                    options={serviceOptions}
                    blankDefault
                    disabled={disabled}
                  />
                  {usingHigherRate && (
                    <div className="rate-message my-2">
                      A {unCamelize(rate)} applies to this booking
                    </div>
                  )}
                  {(clients || (endUser && !endUser.virtualOnly)) && (
                    <Dropdown
                      name="deliveryType"
                      options={deliveryTypeOptions}
                    />
                  )}
                  <DropdownGroup
                    label="Start Time"
                    values={[
                      {
                        name: "startHours",
                        options: hoursOptions,
                        onChange: (e) => {
                          setEveningRate(
                            parseInt(e.target.value),
                            parseInt(startMinutes, 10),
                            parseInt(durationHours, 10),
                            parseInt(durationMinutes, 10),
                            service,
                            rate,
                            setRate,
                            date
                          );
                        },
                      },
                      {
                        name: "startMinutes",
                        options: minutesOptions,
                        onChange: (e) =>
                          setEveningRate(
                            parseInt(startHours, 10),
                            parseInt(e.target.value),
                            parseInt(durationHours, 10),
                            parseInt(durationMinutes, 10),
                            service,
                            rate,
                            setRate,
                            date
                          ),
                      },
                    ]}
                    errors={errors}
                    touched={touched}
                    disabled={disabled}
                  />
                  {billable && (
                    <DropdownGroup
                      label="Duration"
                      values={[
                        {
                          name: "durationHours",
                          options: durationHoursOptions,
                          onChange: (e) =>
                            setEveningRate(
                              parseInt(startHours, 10),
                              parseInt(startMinutes, 10),
                              parseInt(e.target.value),
                              parseInt(durationMinutes, 10),
                              service,
                              rate,
                              setRate,
                              date
                            ),
                        },
                        {
                          name: "durationMinutes",
                          options: durationMinutesOptions,
                          onChange: (e) =>
                            setEveningRate(
                              parseInt(startHours, 10),
                              parseInt(startMinutes, 10),
                              parseInt(durationHours, 10),
                              parseInt(e.target.value),
                              service,
                              rate,
                              setRate,
                              date
                            ),
                        },
                      ]}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                    />
                  )}
                  <TextArea
                    ariaLabel="Special Instructions for the Booking"
                    label="Special Instructions"
                    name="specialInstructions"
                    disabled={disabled}
                  />
                  {billable && (
                    <Dropdown
                      name="payment"
                      options={paymentOptions}
                      blankDefault
                      disabled={disabled}
                    />
                  )}
                  <Checkbox
                    name="terms"
                    ariaLabel="I agree to the Terms and Conditions"
                    disabled={disabled}
                    label={
                      <label>
                        I agree to the{" "}
                        <a
                          href="https://about.tappon.co/terms-and-conditions/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    }
                  />
                  <Button
                    onClick={() => {
                      validateForm()
                        .then((errors) => {
                          if (Object.keys(errors).length === 0) {
                            displayConfirmationModal();
                          } else {
                            setTouched(
                              setNestedObjectValues<FormikTouched<FormValues>>(
                                errors,
                                true
                              )
                            );
                          }
                        })
                        .catch((err) => {
                          log("error", err);
                        });
                    }}
                    disabled={disabled}
                  >
                    Submit
                  </Button>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </ToastConsumer>
  );
};
