import gql from "graphql-tag";

export const SERVICE_OFFERING_FIELDS = gql`
  fragment ServiceOfferingFields on ServiceOffering {
    id
    enabled
    eveningRate {
      amount
      currency
    }
    inPerson
    nightRate {
      amount
      currency
    }
    minDurationMins
    publicHolidayRate {
      amount
      currency
    }
    ratePeriod
    saturdayRate {
      amount
      currency
    }
    service {
      id
      name
      virtual
      slug
      category
    }
    sundayRate {
      amount
      currency
    }

    virtual
    weekdayRate {
      amount
      currency
    }
  }
`;
