import React, { useContext, useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useQuery } from "@apollo/client";
import { Header, Search } from "semantic-ui-react";
import styled from "styled-components";

import { breakpoints } from "../../styles/Styled";
import { Loading } from "../layout/Loading";
import {
  ALL_AVAILABLE_PROFILES,
  Data,
} from "../../graphql/queries/allAvailableProfiles";
import { AuthContext } from "../../auth/Auth";
import { DropdownListItem } from "../layout/nav/DropdownListItem";
import { Avatar } from "../layout/Avatar";
import { escape, concatAvailableUsers } from "../../utils/helpers";
import { ErrorPage } from "../layout/ErrorPage";
import { enableAdminOnly } from "../../utils/featureFlags";
import { NotFoundPage } from "../pages/NotFoundPage";
import { IUserContext, ServiceRole } from "../../types/profile";

export interface SwitchUserPageProps extends RouteComponentProps {
  user: Pick<IUserContext, "__typename">;
}

export interface User {
  id: string;
  name: string;
  image: {
    avatar: boolean;
    src: string;
  };
  role: ServiceRole;
}

export const SwitchUserPage = ({ user }: SwitchUserPageProps) => {
  const { data, error, loading } = useQuery<Data>(ALL_AVAILABLE_PROFILES);
  const [searchTerm, setSearchTerm] = useState("");
  const { updateUser } = useContext(AuthContext);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (data) {
    const availableUsers = concatAvailableUsers(data);

    if (
      user.__typename === ServiceRole.SUPPORT_COORDINATOR &&
      !enableAdminOnly(data.viewer)
    ) {
      return <NotFoundPage />;
    }

    const switchUser = async (id: string) => {
      const newUser = availableUsers.find((user: any) => user.id === id);

      if (newUser) await updateUser(newUser);

      navigate("/");
    };

    const filteredUsers = availableUsers.filter((user: IUserContext) => {
      if (searchTerm === "") return true;

      const regex = new RegExp(escape(searchTerm.toLowerCase()));
      return regex.test(user.name.toLowerCase());
    });

    const updateSearchTerm = (event: React.SyntheticEvent, data: any) => {
      setSearchTerm(data.value);
    };

    return (
      <Content>
        <PageHeader size="large">Switch User</PageHeader>
        <SearchUsers showNoResults={false} onSearchChange={updateSearchTerm} />
        <ScrollBox id="user-list">
          <ul className="pl-10">
            {filteredUsers.map((user: IUserContext) => (
              <DropdownListItem
                userChoice
                onListItemClick={switchUser}
                key={user.id}
                userId={user.id}
                hideDropdown={() => null}
                hoverable
              >
                <Avatar float="left" size="sm" user={user} />
                <Name>
                  {user.name}
                  <Role>({user.__typename})</Role>
                </Name>
              </DropdownListItem>
            ))}
          </ul>
        </ScrollBox>
      </Content>
    );
  }

  return <></>;
};

const { small } = breakpoints;

const PageHeader = styled(Header)`
  &&& {
    margin-left: 65px;
  }
`;

const SearchUsers = styled(Search)`
  &&& {
    margin-left: 60px;
  }
`;

const Name = styled.p`
  margin-left: 20px;
  width: 100%;
`;

const ScrollBox = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

const Content = styled.div`
  margin: 35px auto 0;
  width: 95%;\

  @media (min-width: ${small}) {
    width: 70%;
  }
`;

const Role = styled.span`
  margin: 0 5px;
  color: rgb(100, 100, 100);
  display: inline;
`;
