import gql from "graphql-tag";

import { SupportProfessional } from "../../../types/supportProfessional";
import { SUPPORT_PROFESSIONAL_FIELDS } from "../../fragments";

export interface Data {
  supportProfessional: SupportProfessional;
  stripeCustomAccountBankDetails?: {
    accountName?: string;
    bsb?: string;
    lastFour?: string;
  }
}

export interface Variables {
  profileId: string;
}

export const SUPPORT_PROFESSIONAL_SETTINGS = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}
  query SupportProfessionalSettingsQuery($profileId: ID!) {
    supportProfessional(id: $profileId) {
      ...SupportProfessionalFields
    }
    stripeCustomAccountBankDetails(supportProfessionalId: $profileId) {
      accountName
      bsb
      lastFour
    }
  }
`;
