import gql from "graphql-tag";

import { ProfileImage, ServiceRole } from "../../types/profile";

export interface NewData {
  uploadProfileImage: {
    __typename: ServiceRole;
    id: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    profileImage: ProfileImage;
    account: {
      id: string;
      email: string;
    };
  };
}

export interface Variables {
  id: string;
  fileData: File;
  serviceRole: string;
}

export const UPLOAD_PROFILE_IMAGE = gql`
  mutation uploadProfileImage(
    $fileData: Upload!
    $id: ID!
    $serviceRole: ServiceRole!
  ) {
    uploadProfileImage(
      fileData: $fileData
      serviceRoleId: $id
      serviceRoleType: $serviceRole
    ) {
      id
      ... on EndUser {
        name
      }
      ... on SupportProfessional {
        name
      }
      ... on SupportCoordinator {
        firstName
        lastName
      }
      profileImage {
        id
        small
        med
        large
      }
      account {
        id
        email
      }
    }
  }
`;
