import React from "react";
import { Field } from "formik";

export interface CheckboxProps {
  name: string;
  label: string | React.ReactNode;
  ariaLabel?: string;
  className?: string;
  customHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  note?: string;
  errorClassName?: string;
}

export const Checkbox = ({
  name,
  ariaLabel,
  label,
  className,
  customHandleChange,
  disabled,
  note,
  errorClassName,
}: CheckboxProps) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: any }) => {
        const { onChange } = field;
        const { errors, touched, setTouched } = form;
        const error = errors[name] ? "text-red-700" : "";
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setTouched({ [name]: true });
          onChange(event);

          if (customHandleChange) {
            customHandleChange(event);
          }
        };

        return (
          <div data-testid={name} className={`${className}`}>
            <div className={`flex ${note ? "items-start" : "items-center"}`}>
              <input
                id={name}
                aria-label={ariaLabel || label}
                className={`mr-2 leading-tight form-checkbox h-5 w-5 text-teal-600 transition duration-150 ease-in-out ${
                  disabled ? "cursor-not-allowed" : "cursor-pointer"
                } ${error}`}
                name={name}
                checked={field.value}
                value={field.value}
                type="checkbox"
                {...field}
                onChange={handleChange}
                disabled={disabled}
              />

              <div className="ml-3 text-base leading-5">
                <label
                  htmlFor={name}
                  className={`font-medium text-gray-700 ${
                    disabled ? "cursor-not-allowed" : "cursor-pointer"
                  } ${error}`}
                >
                  {label}
                </label>

                {note && <p className="text-gray-500">{note}</p>}
              </div>
            </div>
            {errors[name] && touched[name] && (
              <p className={`sui-error-message mt-1 ${errorClassName || ""}`}>
                {errors[name]}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
};
