import React, { useRef, useState, useEffect } from "react";

import { SupportProfessionalSummaryCard } from "./SupportProfessionalSummaryCard";
import { SupportProfessional } from "../../../types/supportProfessional";
import { EndUser } from "../../../types/endUser";
import { Icon } from "../../layout/Icons";
import { useWindowSize } from "../../../hooks";

export interface SupportProfessionalSummaryCardGroupProps {
  supportWorkers: Pick<
    SupportProfessional,
    | "id"
    | "name"
    | "shortDescription"
    | "location"
    | "title"
    | "serviceOfferings"
    | "profileImage"
  >[];
  endUser?: EndUser;
  compact?: boolean;
  recommended?: boolean;
}

export const SupportProfessionalSummaryCardGroup = ({
  supportWorkers,
  compact,
  recommended,
  endUser,
}: SupportProfessionalSummaryCardGroupProps) => {
  const windowWidth = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    setContainerWidth(
      containerRef.current
        ? containerRef.current.getBoundingClientRect().width
        : 0
    );
  }, [containerRef, windowWidth]);

  const itemsInRow = Math.floor(containerWidth / 280);

  if (supportWorkers.length > 0) {
    return (
      <div
        ref={containerRef}
        id="support-professional-list"
        className={`grid grid-cols-${itemsInRow} gap-3`}
      >
        {supportWorkers.map((supportProfessional) => (
          <SupportProfessionalSummaryCard
            supportProfessional={supportProfessional}
            key={supportProfessional.id}
            compact={compact}
            recommended={recommended}
            endUser={endUser}
          />
        ))}
      </div>
    );
  } else {
    return compact ? (
      <div className="message info">
        <Icon name="userGroup" />
        <p>
          Once you have completed a booking with a support professional, they
          will appear here
        </p>
      </div>
    ) : (
      <p>No results</p>
    );
  }
};
