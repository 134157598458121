import React from "react";
import { Flash } from "../layout/Flash"

export interface PaymentMessageProps {
  success: boolean;
}

export const PaymentMessage = ({ success }: PaymentMessageProps) => {
  const icon = success ? "checkCircle" : "warning"
  const message = success ?
    "Your pre-authorization is being processed, thank you for using TappON" :
    "We were unable to process your payment"

  return (
    <Flash success={success} icon={icon} heading="Request booking" text={message} />
  );
};
