import gql from "graphql-tag";

export const SERVICE_FIELDS = gql`
  fragment ServiceFields on Service {
    id
    name
    slug
    category
    virtual
    serviceOfferings {
      id
    }
  }
`;
