import gql from "graphql-tag";

export const SUPPORT_COORDINATOR_FIELDS = gql`
  fragment SupportCoordinatorFields on SupportCoordinator {
    id
    firstName
    lastName
    phoneNumber
    about
    company
    profileImage {
      id
      small
      med
      large
    }
  }
`;
