import React, { useState, useContext } from "react";
import { v4 as uuid } from "uuid";

import tapponLogo from "../../assets/images/tappON_partwhite.png";
import { Button } from "./Button";
import { Icon } from "./Icons";
import { AuthContext } from "../../auth/Auth";

export const PublicHeader = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { login } = useContext(AuthContext);

  const navLinks = [
    { text: "About", url: "https://about.tappon.co/about-us/" },
    { text: "Contact", url: "https://about.tappon.co/contact/" },
  ];

  return (
    <header>
      <nav className="bg-tappon-bg py-2">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="h-12" src={tapponLogo} alt="tappON logo" />
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline">
                  {navLinks.map((link, index) => (
                    <a
                      href={link.url}
                      key={uuid()}
                      className={`${
                        index > 0 ? "ml-4" : ""
                      } px-3 py-2 rounded-md text-base font-medium text-teal-200 hover:text-white hover:bg-teal-600 focus:outline-none focus:text-white focus:bg-teal-600`}
                    >
                      {link.text}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="hidden md:flex">
              <Button
                className="bg-tappon-bg hover:bg-tappon-bg hover:text-teal-200 text-light"
                onClick={login}
              >
                Sign In
              </Button>
              <Button to="/signup">Sign Up</Button>
            </div>
            <div className="-mr-2 flex md:hidden">
              {/* <!-- Mobile menu button --> */}
              <button
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-teal-300 hover:text-white hover:bg-teal-600 focus:outline-none focus:bg-teal-600 focus:text-white"
              >
                <Icon name="menu" className="stroke-current h-8 w-8" />
              </button>
            </div>
          </div>
        </div>
        {/* <!-- Off-canvas menu for mobile --> */}
        <div
          className={`md:hidden ${mobileNavOpen ? "visible" : "hide-wait-300"}`}
        >
          <div className="fixed inset-0 flex z-40">
            <div
              className={`fixed inset-0 transition-opacity ease-linear delay-0 duration-300 ${
                mobileNavOpen ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className={`absolute inset-0 bg-gray-600`}></div>
            </div>

            <div
              className={`relative flex-1 flex flex-col max-w-xs w-full bg-teal-700 transition ease-in-out duration-300 delay-0 transform ${
                mobileNavOpen ? "translate-x-0" : "-translate-x-full"
              }`}
            >
              <div className="absolute top-0 right-0 -mr-14 p-1">
                <button
                  className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                  aria-label="Close sidebar"
                  onClick={() => setMobileNavOpen(false)}
                >
                  <svg
                    className="h-6 w-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-10 w-auto"
                    src={tapponLogo}
                    alt="tappON logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  <div className="px-2 pt-2 pb-3 sm:px-3">
                    <a
                      href="https://about.tappon.co/about-us/"
                      className="block px-5 py-2 rounded-md text-base font-medium text-teal-200 hover:text-white hover:bg-teal-600 focus:outline-none focus:text-white focus:bg-teal-600"
                    >
                      About
                    </a>
                    <a
                      href="https://about.tappon.co/contact/"
                      className="mt-1 block px-5 py-2 rounded-md text-base font-medium text-teal-200 hover:text-white hover:bg-teal-600 focus:outline-none focus:text-white focus:bg-teal-600"
                    >
                      Contact
                    </a>
                  </div>
                  <div className="divider mx-4 border-teal-600" />
                  <div className="pt-4 pb-3">
                    <div className="mt-1 px-2">
                      <button
                        className="block w-full text-left px-5 py-2 rounded-md text-base font-medium text-teal-200 hover:text-white hover:bg-teal-600 focus:outline-none focus:text-white focus:bg-teal-600"
                        onClick={login}
                      >
                        Sign In
                      </button>
                      <Button to="/signup" secondary className="mt-2 w-full">
                        Sign Up
                      </Button>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className="flex-shrink-0 w-14">
              {/* <!-- Force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
