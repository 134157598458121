import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";

import { AuthContext } from "../../../auth/Auth";
import { Loading } from "../../layout/Loading";

export interface SignInPageProps extends RouteComponentProps {}

export const SignInPage = (props: SignInPageProps) => {
  const { login } = useContext(AuthContext);

  useEffect(() => {
    login();
  });

  return <Loading />;
};
