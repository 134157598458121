import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";

import { useQuery } from "@apollo/client";
import {
  SUPPORT_COORDINATOR_DASHBOARD_QUERY,
  Data,
  Variables,
} from "../../../graphql/queries/dashboard/supportCoordinatorDashboard";
import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import { SupportCoordinatorDashboardLeftSidebar as LeftSidebar } from "./SupportCoordinatorDashboardLeftSidebar";
import { SupportCoordinatorDashboardRightSidebar as RightSidebar } from "./SupportCoordinatorDashboardRightSidebar";
import { AddClientModal } from "./AddClientModal";
import { SupportCoordinatorBookingList } from "./SupportCoordinatorBookingList";
import { IUserContext } from "../../../types/profile";

export interface SupportCoordinatorDashboardPageProps
  extends RouteComponentProps {
  user: Pick<IUserContext, "id" | "profileImage" | "__typename">;
}

export const SupportCoordinatorDashboardPage = ({
  user,
}: SupportCoordinatorDashboardPageProps) => {
  const [addClientModalVisible, setAddClientModalVisible] = useState(false);

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    SUPPORT_COORDINATOR_DASHBOARD_QUERY,
    { variables: { id: user.id } }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const {
    supportCoordinator,
    upcomingClientBookings,
    actionableClientBookings: actionableBookings,
    endUsersForSupportCoordinator,
  } = data;

  const showAddClientModal = () => {
    setAddClientModalVisible(true);
  };

  const hideAddClientModal = () => {
    setAddClientModalVisible(false);
  };

  return (
    <>
      {addClientModalVisible && (
        <AddClientModal
          hideModal={hideAddClientModal}
          user={user}
          refetch={refetch}
        />
      )}
      <div className="coordinator-dashboard">
        <div className="three-column-wrapper">
          {/* <!-- Left sidebar & main wrapper --> */}
          <div className="sidebar-and-main">
            <LeftSidebar
              user={user}
              supportCoordinator={supportCoordinator}
              showAddClientModal={showAddClientModal}
              clients={endUsersForSupportCoordinator}
              upcomingClientBookings={upcomingClientBookings}
            />

            {/* <!-- Main --> */}
            <div className="dashboard-main">
              <SupportCoordinatorBookingList
                bookings={upcomingClientBookings}
                title="Upcoming Bookings"
                className="border-t"
                viewAllLink
                showHeaderRow
              />

              {actionableBookings.length > 0 && (
                <SupportCoordinatorBookingList
                  bookings={actionableBookings}
                  title="Bookings awaiting approval"
                  viewAllLink
                  showHeaderRow
                />
              )}
            </div>
          </div>
          <RightSidebar
            user={user}
            conversations={data.conversations}
            recentClientActivity={data.recentClientActivity}
          />
        </div>
      </div>
    </>
  );
};
