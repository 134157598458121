import gql from "graphql-tag";

export interface NewData {
  createAvailability: {
    id: string;
    available: boolean;
    dayNumber: number;
  };
}

export interface Variables {
  supportProfessionalId: string;
  available: boolean;
  dayNumber: number;
}

export const CREATE_AVAILABILITY = gql`
  mutation CreateAvailability(
    $supportProfessionalId: ID!
    $available: Boolean!
    $dayNumber: Int!
  ) {
    createAvailability(
      input: {
        supportProfessionalId: $supportProfessionalId
        available: $available
        dayNumber: $dayNumber
      }
    ) {
      id
      available
      dayNumber
    }
  }
`;
