import { BookingStateEventRole } from "./bookingStateEvent";

export interface Booking {
  id: string;
  cancellationReason: string;
  deliveryType: DeliveryType;
  durationHours: number;
  endTime: string;
  endUser: {
    id: string;
    name: string;
    addressCity: string | null;
    profileImage?: {
      id: string;
      small: string;
    };
    // This is not always queried for
    supportCoordinator?: {
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  insertedAt: string;
  location: string | null;
  paymentFailed: boolean;
  paymentFlow: PaymentFlow;
  projected?: boolean;
  schedule: Pick<RecurringBookingSchedule, "id" | "rrule" | "active"> | null;
  scheduleId: string | null;
  serviceName: string;
  serviceRate: Money;
  serviceRatePeriod: string;
  spAvailable?: boolean;
  specialInstructions: string;
  spPaid?: Date;
  startTime: string;
  state: BookingState;
  stripeCheckoutId: string;
  supportProfessional: {
    id: string;
    shortDescription: string;
    location: string;
    name: string;
    title: string;
    profileImage?: {
      id: string;
      small: string;
    };
  };
  // This is not always queried for
  stateEvents?: {
    id: string;
    account: {
      id: string;
    };
    insertedAt: string;
    newState: BookingState;
    oldState: BookingState;
    onBehalfOf: BookingStateEventRole;
    endUser: {
      id: string;
      name: string;
    };
    supportCoordinator: {
      id: string;
      firstName: string;
      lastName: string;
    };
    supportProfessional: {
      id: string;
      name: string;
      shortDescription: string;
    };
  }[];
  totalCost: Money;
}

export enum DeliveryType {
  IN_PERSON = "IN_PERSON",
  VIRTUAL = "VIRTUAL",
}

export enum PaymentFlow {
  CREDIT_CARD = "CREDIT_CARD",
  INVOICE = "INVOICE",
}

export interface Money {
  amount: number;
  currency: "AUD";
}

export enum BookingState {
  REQUESTED_NO_AUTH = "REQUESTED_NO_AUTH",
  REQUESTED_BY_END_USER = "REQUESTED_BY_END_USER",
  ACCEPTED_BY_SUPPORT_WORKER = "ACCEPTED_BY_SUPPORT_WORKER",
  ACCEPTED_IN_PENALTY_PERIOD = "ACCEPTED_IN_PENALTY_PERIOD",
  COMPLETED = "COMPLETED",
  APPROVED = "APPROVED",
  TIMED_OUT_REQUEST = "TIMED_OUT_REQUEST",
  CANCELLED_BY_SUPPORT_WORKER = "CANCELLED_BY_SUPPORT_WORKER",
  CANCELLED_BY_END_USER_WITH_NOTICE = "CANCELLED_BY_END_USER_WITH_NOTICE",
  CANCELLED_BY_END_USER_WITHOUT_NOTICE = "CANCELLED_BY_END_USER_WITHOUT_NOTICE",
  CANCELLED_BY_ADMIN = "CANCELLED_BY_ADMIN",
  CREATED_BY_ADMIN = "CREATED_BY_ADMIN",
}

export interface Parties {
  party: {
    id: string;
    name: string;
    profileImage?: {
      id: string;
      small: string;
    };
  };
  otherParty: {
    id: string;
    name: string;
    profileImage?: {
      id: string;
      small: string;
    };
  };
  actions: BookingAction[];
}

export interface PartyIds {
  party: { id: string; email?: string };
  otherParty: { id: string; email?: string };
}

export interface BookingAction {
  label: string;
  color: BookingActionColors;
  message: string;
  variables: {
    bookingId: string | null;
    scheduleId: string | null;
    date: string;
    personId: string;
    oldState: BookingState;
    newState: BookingState;
  };
}

export type BookingActionColors = "red" | "green";

export type BookingWithParties = Booking & Parties;

export type PartyIdsWithOptionalBooking = { id?: string } & PartyIds;

export interface RecurringBookingSchedule {
  id: string;
  rrule: string;
  projectedBookings: Booking[];
  bookings?: Booking[];
  active?: boolean;
}

export interface PublicHoliday {
  id: string;
  name: string;
  date: string;
}

export enum DayName {
  MON = "monday",
  TUE = "tuesday",
  WED = "wednesday",
  THU = "thursday",
  FRI = "friday",
  SAT = "saturday",
  SUN = "sunday",
}
