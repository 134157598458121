import gql from "graphql-tag";

export const BOOKING_FIELDS = gql`
  fragment BookingFields on Booking {
    id
    cancellationReason
    deliveryType
    durationHours
    endTime
    endUser {
      id
      name
      addressCity
      profileImage {
        id
        small
      }
    }
    insertedAt
    location
    paymentFailed
    paymentFlow
    projected
    schedule {
      id
      rrule
    }
    scheduleId
    serviceName
    serviceRate {
      amount
      currency
    }
    serviceRatePeriod
    spAvailable
    specialInstructions
    spPaid
    startTime
    state
    stripeCheckoutId
    supportProfessional {
      id
      shortDescription
      location
      name
      title
      profileImage {
        id
        small
      }
    }
    totalCost {
      amount
      currency
    }
  }
`;

export const BOOKING_STATE_EVENTS_FIELDS = gql`
  fragment BookingStateEventsFields on Booking {
    stateEvents {
      id
      account {
        id
      }
      insertedAt
      newState
      oldState
      onBehalfOf
      endUser {
        id
        name
      }
      supportCoordinator {
        id
        firstName
        lastName
      }
      supportProfessional {
        id
        name
      }
    }
    supportProfessional {
      id
      location
      name
      title
      profileImage {
        id
        small
      }
    }
    totalCost {
      amount
      currency
    }
  }
`;

export const RECURRING_BOOKING_SCHEDULE_FIELDS = gql`
  ${BOOKING_FIELDS}
  fragment RecurringBookingScheduleFields on RecurringBookingSchedule {
    id
    rrule
    projectedBookings(limit: 5) {
      ...BookingFields
    }
  }
`;
