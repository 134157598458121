import React from "react";
import { Field, FieldProps } from "formik";

export interface Option {
  label: string;
  name: string;
  disabled?: boolean;
  dayNumber: number;
}

export const CheckboxButtonGroup = ({ options }: { options: Option[] }) => {
  return (
    <div className="checkbox-button-group">
      {options.map((option, index) => {
        return (
          <Field name={option.name} key={index}>
            {({ field }: FieldProps) => {
              return (
                <>
                  <label
                    htmlFor={option.name}
                    className={`checkbox-button ${
                      index === 0 ? "rounded-l-md" : ""
                    } ${index === options.length - 1 ? "rounded-r-md" : ""} ${
                      field.value ? "checked" : ""
                    } ${option.disabled ? "disabled" : ""}`}
                  >
                    {option.label}
                  </label>
                  <input
                    id={option.name}
                    aria-label={option.label}
                    checked={field.value}
                    type="checkbox"
                    className={`sr-only`}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={option.disabled}
                  />
                </>
              );
            }}
          </Field>
        );
      })}
    </div>
  );
};
