import React from "react";
import { LocalParticipant, RemoteParticipant } from "twilio-video";

import { EndUser } from "../../../types/endUser";
import { SupportProfessional } from "../../../types/supportProfessional";
import { usePublications } from "./hooks/usePublications";
import { Publication } from "./Publication";

export const Participant = ({
  participant,
  isLocal,
  className,
  children,
  serviceRole,
}: {
  participant: RemoteParticipant | LocalParticipant;
  isLocal?: boolean;
  className?: string;
  children?: React.ReactNode;
  serviceRole: Pick<SupportProfessional | EndUser, "profileImage" | "name">;
}) => {
  const publications = usePublications(participant);

  return (
    <div
      className={`participant ${isLocal ? "local" : "remote"} ${className ||
        ""}`}
    >
      {publications.map((publication) => (
        <Publication
          key={publication.trackSid}
          publication={publication}
          isLocal={isLocal}
          participant={participant}
          serviceRole={serviceRole}
        />
      ))}

      {children}
    </div>
  );
};
