import React from "react";
import { useQuery } from "@apollo/client";
import { Link, RouteComponentProps } from "@reach/router";
import moment from "moment";

import { EndUserSummaryCardGroup } from "../endUsers/EndUserSummaryCardGroup";
import { ConversationList } from "../../messages/ConversationList";
import { BookingList } from "../../bookings/BookingList";
import {
  SUPPORT_PROFESSIONAL_DASHBOARD_QUERY,
  Data,
  Variables,
} from "../../../graphql/queries/dashboard/supportProfessionalDashboard";
import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import { Icon } from "../../layout/Icons";
import { VirtualBookingAlert } from "../../bookings/VirtualBookingAlert";
import { Booking, BookingState } from "../../../types/booking";
import { IUserContext } from "../../../types/profile";
import { concatProjectedBookingsForProfile } from "../../bookings/BookingHelpers";

export interface SupportProfessionalDashboardPageProps
  extends RouteComponentProps {
  user: Pick<IUserContext, "id" | "__typename">;
}

export const SupportProfessionalDashboardPage = ({
  user,
}: SupportProfessionalDashboardPageProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    SUPPORT_PROFESSIONAL_DASHBOARD_QUERY,
    {
      variables: {
        supportProfessionalId: user.id,
      },
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const {
    activeBookings,
    inactiveBookings,
    supportProfessional,
    conversations,
    clients,
  } = data;

  const projectedBookings = concatProjectedBookingsForProfile(
    supportProfessional
  );

  const upcomingBookings = [...activeBookings, ...projectedBookings].sort(
    (a, b) => {
      return moment(a.startTime).isAfter(moment(b.startTime)) ? 1 : -1;
    }
  );

  const confirmedBookings = upcomingBookings.filter(
    (b: Booking) =>
      b.state === BookingState.ACCEPTED_BY_SUPPORT_WORKER ||
      b.state === BookingState.ACCEPTED_IN_PENALTY_PERIOD
  );

  const completedBookings = upcomingBookings.filter(
    (b: Booking) => b.state === BookingState.COMPLETED
  );

  const pendingBookings = upcomingBookings.filter(
    (b: Booking) => b.state === BookingState.REQUESTED_BY_END_USER
  );

  const {
    stripeConnectAccountId,
    stripeConnectAccount,
    acceptsFastBookings,
    acceptsTransferBookings,
    profileImage,
  } = supportProfessional;

  const paymentEnabled = acceptsFastBookings || acceptsTransferBookings;

  // In order to accept bookings, a Support Professional needs to:
  // - have a Stripe Connect Account ID, and
  // - have at least one form of payment enabled
  const acceptingBookings =
    ((stripeConnectAccountId && !stripeConnectAccount) ||
      (stripeConnectAccount && stripeConnectAccount.payoutsEnabled)) &&
    paymentEnabled;

  const noBookings =
    upcomingBookings.length === 0 && inactiveBookings.length === 0;

  const settingsRequired =
    !acceptingBookings ||
    (stripeConnectAccount && stripeConnectAccount.requirementsOutstanding);

  const profileRequired = !profileImage;

  return (
    <div className="page-wrapper">
      <VirtualBookingAlert user={user} />
      <div className="dashboard-extra-content">
        {(noBookings || !acceptingBookings) && (
          <div className="mx-4 md:mx-0 mt-4">
            {noBookings && <h1>Welcome to your dashboard</h1>}
          </div>
        )}

        {settingsRequired && (
          <>
            <div
              className="bg-tangerine-200 border border-tangerine-400 text-tangerine-700 p-4 mb-3 flex items-center"
              role="alert"
            >
              <Icon className="h-8 w-8 mr-6" name="alert" />
              <UpdateSettingsReminder
                newUser={
                  noBookings && !supportProfessional.stripeConnectAccountId
                }
                paymentEnabled={paymentEnabled}
              />
            </div>
          </>
        )}
        {!settingsRequired && profileRequired && (
          <>
            <div
              className="bg-tangerine-200 border border-tangerine-400 text-tangerine-700 p-4 mb-3 flex items-center"
              role="alert"
            >
              <Icon className="h-8 w-8 mr-6" name="alert" />
              <UpdateProfileReminder />
            </div>
          </>
        )}
      </div>

      <div data-testid="client-list" className="flex flex-wrap">
        <div className="w-full xm:w-3/12 p-3 order-2 xm:order-1">
          <h2>Your Clients</h2>
          <EndUserSummaryCardGroup endUsers={clients} />
        </div>

        <div
          data-testid="sp-bookings-list"
          className="w-full xm:w-5/12 p-3 order-1 xm:order-2"
        >
          <h2>Bookings</h2>
          {pendingBookings.length > 0 && (
            <BookingList
              user={user}
              title="Pending Booking Requests"
              bookings={pendingBookings}
              showState={true}
              emptyStateMessage="No pending booking requests"
            />
          )}
          {completedBookings.length > 0 && (
            <BookingList
              user={user}
              title="Bookings Awaiting Approval"
              bookings={completedBookings}
              showState={true}
              emptyStateMessage="No bookings awaiting approval"
            />
          )}
          {confirmedBookings.length > 0 && (
            <BookingList
              user={user}
              title="Upcoming Confirmed Bookings"
              bookings={confirmedBookings}
              showState={true}
              emptyStateMessage="No upcoming bookings"
            />
          )}
          {upcomingBookings.length === 0 && (
            <div className="message info">
              <Icon name="calendar" />
              <p>Any upcoming or actionable bookings will appear here</p>
            </div>
          )}
        </div>
        <div
          data-testid="sp-conversations"
          className="w-full xm:w-4/12 p-3 order-last"
        >
          <h2>Inbox</h2>
          {conversations.length > 0 ? (
            <ConversationList conversations={conversations} user={user} />
          ) : (
            <div className="message info">
              <Icon name="envelope" />
              <p>Any new messages from users will appear here</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const UpdateSettingsReminder = ({
  newUser,
  paymentEnabled,
}: {
  newUser: boolean;
  paymentEnabled: boolean;
}) => {
  return newUser ? (
    <div>
      <p className="font-bold">
        Let's get your profile active so tappON members can find you.
      </p>
      <p>
        We need you to complete your{" "}
        <Link className="font-bold" to="/settings/payment">
          payment settings
        </Link>{" "}
        so that you can be paid for the work you do through tappON.
      </p>
    </div>
  ) : (
    <div>
      <p className="font-bold">
        {paymentEnabled
          ? "We've updated our requirements"
          : "Your profile is currently hidden"}
      </p>
      <p>
        {paymentEnabled
          ? "We need you to confirm some items in your"
          : "Enable at least one form of payment in your"}{" "}
        <Link className="font-bold" to="/settings/payment">
          settings
        </Link>{" "}
        to keep your profile active on tappON.
      </p>
    </div>
  );
};

const UpdateProfileReminder = () => {
  return (
    <div>
      <p className="font-bold">
        Let's get your profile active so tappON members can find you.
      </p>
      <p>
        We need you to upload a{" "}
        <Link className="font-bold" to="/settings/profile">
          profile image
        </Link>{" "}
        in order to be searchable in the platform.
      </p>
    </div>
  );
};
