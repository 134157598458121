import React, { useState } from "react";

import { IUserContext } from "../../../types/profile";
import { HelpModal } from "./HelpModal";
import tapponTeamMember from "../../../assets/images/daniel.jpg";

export const SupportPrompt = ({
  text,
  className,
  user,
}: {
  text: string;
  className?: string;
  user: Pick<IUserContext, "id" | "name">;
}) => {
  const [displayModal, setDisplayModal] = useState(false);

  const hideModal = () => {
    setDisplayModal(false);
  };

  return (
    <>
      {displayModal && <HelpModal hideModal={hideModal} user={user} />}

      <div className={`w-full bg-white pointer-events-auto ${className}`}>
        <div className="flex rounded-lg p-3">
          <div className="w-0 flex-1">
            <div className="flex items-start items-center">
              <div className="flex-shrink-0 pt-0.5">
                <div
                  className="h-24 w-24 rounded-full bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${tapponTeamMember})`,
                  }}
                />
              </div>
              <div className="ml-3 w-0 flex-1 sm:flex items-center">
                <p className="text-lg font-medium text-gray-700 ml-4">{text}</p>
                <p
                  onClick={() => setDisplayModal(true)}
                  className="text-lg ml-4 text-teal-700 hover:text-teal-800 font-bold transition ease-in-out duration-150 cursor-pointer"
                >
                  Ask Dan
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
