import React from "react";

import { unCamelize } from "../../utils/helpers";
import { ConversationList } from "./ConversationList";
import { Avatar } from "../layout/Avatar";
import { Icon } from "../layout/Icons";
import { UserT } from "./InboxLayout";
import { Conversation } from "../../types/message";
import { AdminMessageButton } from "./AdminMessageButton";

export type ConversationT = Pick<
  Conversation,
  "sender" | "messages" | "lastUpdated" | "slug"
>;

export const InboxSidebar = ({
  user,
  conversations,
  focusConversation,
}: {
  user: UserT;
  conversations: ConversationT[];
  focusConversation: boolean;
}) => {
  return (
    <div
      className={`${
        focusConversation
          ? "hidden md:flex md:flex-shrink-0"
          : "flex flex-shrink-0 w-full md:w-auto"
      } `}
    >
      <div
        className={`flex flex-col ${
          focusConversation ? "" : "w-full"
        } md:w-104 border-r border-gray-200 pb-4 bg-gray-100`}
      >
        {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
        <div className="h-0 flex-1 flex flex-col overflow-y-auto">
          <h3 className="text-sm leading-5 font-semibold mt-8 mx-5 text-gray-600">
            Your Inbox
          </h3>
          <div className="px-3">
            {conversations.length > 0 ? (
              <ConversationList
                conversations={conversations}
                className=""
                search
                user={user}
              />
            ) : (
              <p className="text-gray-500 flex items-center text-sm ml-3">
                <Icon name="envelope" className="w-3 h-3 text-gray-400 mr-2" />
                You have no messages yet
              </p>
            )}
          </div>

          <div className="flex items-center">
            <AdminMessageButton user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ClientDropdown = ({ user }: { user: UserT }) => {
  return (
    <>
      <h3 className="text-sm leading-5 font-semibold mt-8 mx-5 text-gray-600 border-t pt-8">
        Client conversations
      </h3>
      <div className="px-3 mt-2 relative inline-block text-left">
        {/* <!-- Dropdown menu toggle, controlling the show/hide state of dropdown menu. --> */}
        <div>
          <button
            type="button"
            className="group w-full rounded-md px-3.5 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:bg-gray-200 focus:border-blue-300 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <div className="flex w-full justify-between items-center">
              <div className="flex items-center justify-between space-x-3">
                <Avatar size="xs" user={user} />
                <div className="flex-1 truncate text-left">
                  <h2 className="text-gray-900 text-sm leading-5 font-medium mb-0 truncate mr-1">
                    {user.name || `${user.firstName} ${user.lastName}`}
                  </h2>
                  <p className="text-gray-500 text-sm leading-5 truncate">
                    {unCamelize(user.__typename)}
                  </p>
                </div>
              </div>
              <svg
                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};
