import React from "react";
import { v4 as uuid } from "uuid";

import { checkAvailability } from "../../profileHelpers";
import { capitalize } from "../../../../utils/helpers";
import { SupportProfessional } from "../../../../types/supportProfessional";
import { Icon } from "../../../layout/Icons";

export const Availability = ({
  supportProfessional,
  className,
}: {
  supportProfessional: SupportProfessional;
  className?: string;
}) => {
  const days = {
    monday: checkAvailability(1, supportProfessional),
    tuesday: checkAvailability(2, supportProfessional),
    wednesday: checkAvailability(3, supportProfessional),
    thursday: checkAvailability(4, supportProfessional),
    friday: checkAvailability(5, supportProfessional),
    saturday: checkAvailability(6, supportProfessional),
    sunday: checkAvailability(7, supportProfessional),
  };
  return (
    <div className={className}>
      <h4 className="font-bold">Availability</h4>
      <div className="divider" />
      {Object.values(days).find((day) => !!day) ? (
        <ul className="mt-2 flex max-w-full flex-wrap font-bold">
          {Object.entries(days).map((day) => {
            const [dayName, available] = day;
            return (
              <li
                key={uuid()}
                className={`mr-4 ${
                  available ? "text-gray-700" : "text-gray-400"
                } `}
              >
                {capitalize(dayName).slice(0, 3)}
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-gray-600 inline-flex items-center bg-gray-100 px-3 py-1 border border-gray-300">
          <Icon name="info" className="w-3 h-3 mr-2" />
          This Support Professional is currently unavailable for new bookings
        </p>
      )}
    </div>
  );
};
