/**
 * Inspired by Tailwind UI badges: https://tailwindui.com/components/application-ui/elements/badges
 * and meant to be a drop-in replacement for Semantic-UI Labels.
 */

import React, { FunctionComponent } from "react";

export interface LabelProps {
}

export const Label: FunctionComponent<LabelProps> = ({ children }) => {
  return (
    <span className="inline-flex items-center px-2 py-1.5 rounded text-sm leading-4 bg-gray-200 text-gray-600 font-bold">
      {children}
    </span>
  )
}
