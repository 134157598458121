import { useState, useEffect } from "react";

import { IUserContext } from "../types/profile";

export type UserT = Pick<
  IUserContext,
  "id" | "name" | "__typename" | "profileImage" | "role"
>;

export const useCurrentUser = (): {
  user: UserT | null;
  updateUser: (newUser: UserT) => void;
} => {
  const [user, setUser] = useState<UserT | null>(null);

  useEffect(() => {
    const prevUser = localStorage.getItem("user");

    if (prevUser) {
      setUser(JSON.parse(prevUser));
    }
  }, []);

  const updateUser = (newUser: UserT) => {
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
  };

  return { user, updateUser };
};
