import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { SupportProfessional } from "../../../../types/supportProfessional";
import { InputField as Input } from "../../../layout/forms";
import { Button } from "../../../layout/Button";
import {
  UPDATE_SP_BANK_DETAILS,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateSupportProfessionalBankDetails";
import { SUPPORT_PROFESSIONAL_SETTINGS } from "../../../../graphql/queries/settings/supportProfessionalSettings";

import { Icon } from "../../../layout/Icons";
import { BankDetails } from "./PaymentSettings";
import { useLogger } from "../../../../hooks/useLogger";

export const BankDetailsForm = ({
  bankDetails = { accountName: "", bsb: "", lastFour: "" },
  supportProfessional,
  refetch,
}: {
  bankDetails?: BankDetails;
  supportProfessional: SupportProfessional;
  refetch: () => void;
}) => {
  const { log } = useLogger();
  const [editing, setEditing] = useState(false);

  const [updateBankDetails] = useMutation<NewData, Variables>(
    UPDATE_SP_BANK_DETAILS
  );
  const { id } = supportProfessional;

  const { accountName, bsb, lastFour } = bankDetails;

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        const handleSubmit = (values: any, formikApi: any) => {
          const variables = {
            id,
            ...values,
          };

          updateBankDetails({
            variables,
            refetchQueries: [
              {
                query: SUPPORT_PROFESSIONAL_SETTINGS,
                variables: { profileId: id },
              },
            ],
          })
            .then((res) => {
              refetch();
              setEditing(false);
            })
            .catch((err) => {
              const graphql = "GraphQL error: ";
              const message = err.message.includes(graphql)
                ? err.message.replace(graphql, "")
                : err.message;
              add(message, {
                appearance: "error",
                autoDismiss: true,
              });
              log("info", err);
            });
        };

        return (
          <Formik
            initialValues={{
              accountName: "",
              bsb: "",
              accountNumber: "",
            }}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors: {
                accountName?: string;
                bsb?: string;
                accountNumber?: string;
              } = {};
              if (!values.accountName) {
                errors.accountName = "Required";
              }
              if (!values.bsb) {
                errors.bsb = "Required";
              }
              if (!values.accountNumber) {
                errors.accountNumber = "Required";
              }

              return errors;
            }}
          >
            {(props) => {
              return (
                <Form>
                  <div className="bg-white">
                    <div className="px-2 py-5 sm:p-6">
                      <div className="mt-5">
                        <div className="rounded-md md:px-6 py-5 sm:flex sm:items-start sm:justify-between">
                          <div className="sm:flex sm:items-start w-full">
                            <div className="inline-block bg-indigo-600 rounded p-2">
                              <Icon
                                name="bank"
                                className="w-8 h-8 text-white"
                              />
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-4 w-full">
                              {editing ? (
                                <div
                                  data-testid="bank-details-edit"
                                  className="sm:ml-4"
                                >
                                  <Input
                                    name="accountName"
                                    label="Name"
                                    placeholder={accountName || "Account Name"}
                                    className="mb-4"
                                  />
                                  <Input
                                    name="bsb"
                                    label="BSB"
                                    placeholder={bsb || "BSB"}
                                    className="mb-4"
                                  />
                                  <Input
                                    name="accountNumber"
                                    label="Account Number"
                                    placeholder={
                                      lastFour
                                        ? `*******${lastFour}`
                                        : "Account Number"
                                    }
                                    className="mb-4"
                                  />
                                  <div className="flex justify-between">
                                    <Button
                                      onClick={() => setEditing(false)}
                                      type="button"
                                      color="gray"
                                    >
                                      Cancel
                                    </Button>
                                    <Button type="submit">Save</Button>
                                  </div>
                                </div>
                              ) : (
                                <AccountDetails
                                  accountName={accountName}
                                  bsb={bsb}
                                  lastFour={lastFour}
                                />
                              )}
                            </div>
                          </div>
                          <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                            <span className="inline-flex rounded-md shadow-sm">
                              {!editing && (
                                <Button
                                  type="button"
                                  onClick={() => setEditing(true)}
                                  outline
                                  color="gray"
                                >
                                  {lastFour ? "Edit" : "Add"}
                                </Button>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </ToastConsumer>
  );
};

const AccountDetails = ({
  lastFour,
  bsb,
  accountName,
}: {
  lastFour?: string;
  bsb?: string;
  accountName?: string;
}) => {
  return lastFour && bsb && accountName ? (
    <>
      <div className="text-sm leading-5 font-medium text-gray-900">
        Account Number ending with {lastFour}
      </div>
      <div className="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
        <div>BSB: {bsb}</div>
        <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
          &middot;
        </span>
        <div className="mt-1 sm:mt-0">{accountName}</div>
      </div>
    </>
  ) : (
    <p className="text-gray-700 p-3">No Bank account entered yet</p>
  );
};
