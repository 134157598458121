import React from "react";

import { Button } from "../../../layout/Button";

export const ContactButton = ({ name, id }: { name: string; id: string }) => (
  <Button
    data-testid="contact-button"
    secondary
    color="gray"
    icon="textBubble"
    to={`/messages/${id}`}
    className="contact-button"
  >
    Contact {name}
  </Button>
);
