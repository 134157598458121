import gql from "graphql-tag";

import { EndUser } from "../../types/endUser";
import { SupportProfessional } from "../../types/supportProfessional";
import { SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";

export interface Data {
  supportProfessional: SupportProfessional
  endUser: Pick<EndUser, "id" | "permissions">;
}

export interface Variables {
  supportProfessionalId: string;
  endUserId: string;
}

export const NEW_BOOKING_PAGE = gql`
${SUPPORT_PROFESSIONAL_FIELDS}

query NewBookingPageQuery($supportProfessionalId: ID!, $endUserId: ID!) {
  supportProfessional(id: $supportProfessionalId) {
    ...SupportProfessionalFields
  }

  endUser(id: $endUserId) {
    id
    permissions {
      booking
      message
      invoice
    }
  }
}
`;