import React from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";

import { CURRENT_USER, Data } from "../../graphql/queries/currentUser";
import { enableAdminOnly } from "../../utils/featureFlags";
import { breakpoints } from "../../styles/Styled";

export const EnvironmentLabel = () => {
  const { data, loading } = useQuery<Data>(CURRENT_USER, {});

  if (loading || !data) {
    return <></>;
  }

  const { viewer } = data;
  const url = window.location.origin;

  if (!enableAdminOnly(viewer)) {
    return <></>;
  }

  if (url.includes("localhost")) {
    return (
      <Banner id="environment-banner" color="#5eff9c">
        Dev
      </Banner>
    );
  }

  if (url.includes("staging")) {
    return (
      <Banner id="environment-banner" color="#ffea74">
        Staging
      </Banner>
    );
  }

  return (
    <Banner id="environment-banner" color="#ff7300">
      Production
    </Banner>
  );
};

const { small } = breakpoints;

const Banner = styled.div`
  background-color: ${(props) => props.color};
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  margin: 0;
  padding: 4px;
  display: none;
  color: ${(props) => (props.color === "#ff7300" ? "#fff" : "#000")};

  @media (min-width: ${small}) {
    display: block;
  }
`;
