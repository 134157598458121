import React, { useEffect } from "react";
import { Formik, Form, FormikHelpers, FormikErrors, FormikProps } from "formik";
import { useMutation } from "@apollo/client";

import { ServiceRole, IUserContext } from "../../../../types/profile";
import { Button } from "../../../layout/Button";
import { useLogger } from "../../../../hooks";
import { TextArea, UpdateSuccess } from "../../../layout/forms";
import {
  UPDATE_END_USER,
  NewData as EUNewData,
  Variables as EUVariables,
} from "../../../../graphql/mutations/updateEndUser";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData as SPNewData,
  Variables as SPVariables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import {
  UPDATE_SUPPORT_COORDINATOR,
  NewData as SCNewData,
  Variables as SCVariables,
} from "../../../../graphql/mutations/updateSupportCoordinator";

interface FormValues {
  about: string;
}

interface Profile {
  id: string;
  __typename: ServiceRole;
  about: string | null;
  name?: string;
  firstName?: string;
  lastName?: string;
}

export const DescriptionForm = ({
  profile,
  user,
}: {
  profile: Profile;
  user: Pick<IUserContext, "id">;
}) => {
  const { log } = useLogger();
  const mutations = {
    [ServiceRole.END_USER]: UPDATE_END_USER,
    [ServiceRole.SUPPORT_WORKER]: UPDATE_SUPPORT_PROFESSIONAL,
    [ServiceRole.SUPPORT_COORDINATOR]: UPDATE_SUPPORT_COORDINATOR,
  };

  const mutation = mutations[profile.__typename];

  const [updateProfile] = useMutation<
    EUNewData | SPNewData | SCNewData,
    EUVariables | SPVariables | SCVariables
  >(mutation);

  const initialValues: FormValues = {
    about: profile.about || "",
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const { about } = values;

    const variables = {
      id: profile.id,
      about,
    };

    updateProfile({
      variables,
    })
      .then((res) => {
        formikHelpers.setSubmitting(false);
        formikHelpers.setTouched({});
      })
      .catch((err) => log("error", err));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};

          if (!values.about) {
            errors.about = "Required";
          }

          if (values.about && values.about.length < 10) {
            errors.about = "Must be at least 10 characters";
          }

          if (values.about && values.about.length > 1000) {
            errors.about = "Maximum 1000 characters";
          }

          return errors;
        }}
      >
        {(props) => (
          <DescriptionFormComponent
            formikProps={props}
            profile={profile}
            user={user}
          />
        )}
      </Formik>
    </>
  );
};

const DescriptionFormComponent = ({
  formikProps,
  profile,
  user,
}: {
  formikProps: FormikProps<FormValues>;
  profile: Profile;
  user: Pick<IUserContext, "id">;
}) => {
  const { validateForm, values, submitCount } = formikProps;
  // Show missing fields on page load
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const displaySaveButton = values.about !== profile.about;

  return (
    <Form>
      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="about"
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        >
          About
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex rounded-md shadow-sm">
            <TextArea
              className="w-full text-gray-700"
              inputClassName="h-full"
              name="about"
              noLabel
              rows={5}
            />
          </div>
          <p className="my-2 text-sm text-gray-500 text-right">
            Write a few sentences about{" "}
            {user.id === profile.id ? "yourself" : profile.name}.
          </p>
        </div>
      </div>
      {displaySaveButton && (
        <div className="flex justify-end">
          <Button type="submit">Save</Button>
        </div>
      )}
      {!displaySaveButton && submitCount > 0 && <UpdateSuccess />}
    </Form>
  );
};
