import moment from "moment";

import {
  BookingState,
  BookingActionColors,
  BookingAction,
  Booking,
  PaymentFlow,
} from "../../types/booking";
import { BookingStateEvent } from "../../types/bookingStateEvent";
import { EndUser } from "../../types/endUser";
import { ServiceRole, IUserContext } from "../../types/profile";
import { SupportProfessional } from "../../types/supportProfessional";

export const stateLabelMap: {
  [key: string]: string;
} = {
  [BookingState.REQUESTED_NO_AUTH]: "Requested Without Completion",
  [BookingState.REQUESTED_BY_END_USER]: "Awaiting confirmation from ",
  [BookingState.ACCEPTED_BY_SUPPORT_WORKER]: "Accepted",
  [BookingState.ACCEPTED_IN_PENALTY_PERIOD]:
    "Accepted (cancellation fees apply)",
  [BookingState.COMPLETED]: "Awaiting approval from ",
  [BookingState.TIMED_OUT_REQUEST]: "Timed Out Request",
  [BookingState.APPROVED]: "Approved",
  [BookingState.CANCELLED_BY_SUPPORT_WORKER]:
    "Cancelled by Support Professional",
  [BookingState.CANCELLED_BY_END_USER_WITH_NOTICE]: "Cancelled by Client",
  [BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE]:
    "Cancellation Period Applied",
  [BookingState.CANCELLED_BY_ADMIN]: "Cancelled by tappON admin",
  [BookingState.CREATED_BY_ADMIN]: "Approved",
};

export enum BookingStateTransition {
  CANCEL = "Cancel",
  CANCEL_WITHOUT_NOTICE = "Cancel with Fee",
  REJECT = "Reject",
  ACCEPT = "Accept",
  APPROVE = "Approve", // SW provided service
}

export const actionsForBooking = (
  booking: Pick<
    Booking,
    "id" | "endUser" | "state" | "projected" | "scheduleId" | "startTime"
  >,
  user: Pick<IUserContext, "id" | "__typename">
): BookingAction[] => {
  const transitionMap = bookingActionMap[user.__typename];
  const transitions = transitionMap[booking.state] || [];

  const personId =
    user.__typename === ServiceRole.SUPPORT_COORDINATOR
      ? booking.endUser.id
      : user.id;

  return transitions.map((t) => {
    return {
      label: t.transition,
      color: bookingStateTransitionMap[t.transition],
      message: t.message,
      variables: {
        bookingId: booking.projected ? null : booking.id,
        scheduleId: booking.scheduleId,
        date: moment(booking.startTime).format("YYYY-MM-DD"),
        personId,
        supportCoordinatorId:
          user.__typename === ServiceRole.SUPPORT_COORDINATOR ? user.id : null,
        oldState: booking.state,
        newState: t.newState,
      },
    };
  });
};

const bookingStateTransitionMap: {
  [key: string]: BookingActionColors;
} = {
  [BookingStateTransition.ACCEPT]: "green",
  [BookingStateTransition.APPROVE]: "green",
  [BookingStateTransition.REJECT]: "red",
  [BookingStateTransition.CANCEL]: "red",
  [BookingStateTransition.CANCEL_WITHOUT_NOTICE]: "red",
};

const bookingActionMap: {
  [key: string]: {
    [key: string]: {
      transition: BookingStateTransition;
      message: string;
      newState: BookingState;
    }[];
  };
} = {
  [ServiceRole.END_USER]: {
    [BookingState.ACCEPTED_BY_SUPPORT_WORKER]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_END_USER_WITH_NOTICE,
      },
    ],
    [BookingState.ACCEPTED_IN_PENALTY_PERIOD]: [
      {
        transition: BookingStateTransition.CANCEL_WITHOUT_NOTICE,
        message: "Cancelled without sufficient notice",
        newState: BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE,
      },
    ],
    [BookingState.COMPLETED]: [
      {
        transition: BookingStateTransition.APPROVE,
        message: "Approved",
        newState: BookingState.APPROVED,
      },
    ],
    [BookingState.REQUESTED_BY_END_USER]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_END_USER_WITH_NOTICE,
      },
    ],
    [BookingState.APPROVED]: [],
    [BookingState.CREATED_BY_ADMIN]: [],
    [BookingState.CANCELLED_BY_END_USER_WITH_NOTICE]: [],
    [BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE]: [],
    [BookingState.CANCELLED_BY_SUPPORT_WORKER]: [],
    [BookingState.TIMED_OUT_REQUEST]: [],
  },
  [ServiceRole.SUPPORT_COORDINATOR]: {
    [BookingState.ACCEPTED_BY_SUPPORT_WORKER]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_END_USER_WITH_NOTICE,
      },
    ],
    [BookingState.ACCEPTED_IN_PENALTY_PERIOD]: [
      {
        transition: BookingStateTransition.CANCEL_WITHOUT_NOTICE,
        message: "Cancelled without sufficient notice",
        newState: BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE,
      },
    ],
    [BookingState.COMPLETED]: [
      {
        transition: BookingStateTransition.APPROVE,
        message: "Approved",
        newState: BookingState.APPROVED,
      },
    ],
    [BookingState.REQUESTED_BY_END_USER]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_END_USER_WITH_NOTICE,
      },
    ],
    [BookingState.APPROVED]: [],
    [BookingState.CREATED_BY_ADMIN]: [],
    [BookingState.CANCELLED_BY_END_USER_WITH_NOTICE]: [],
    [BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE]: [],
    [BookingState.CANCELLED_BY_SUPPORT_WORKER]: [],
    [BookingState.TIMED_OUT_REQUEST]: [],
  },
  [ServiceRole.SUPPORT_WORKER]: {
    [BookingState.ACCEPTED_BY_SUPPORT_WORKER]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_SUPPORT_WORKER,
      },
    ],
    [BookingState.ACCEPTED_IN_PENALTY_PERIOD]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_SUPPORT_WORKER,
      },
    ],
    [BookingState.REQUESTED_BY_END_USER]: [
      {
        transition: BookingStateTransition.ACCEPT,
        message: "Accepted",
        newState: BookingState.ACCEPTED_BY_SUPPORT_WORKER,
      },
    ],
    [BookingState.APPROVED]: [],
    [BookingState.CREATED_BY_ADMIN]: [],
    [BookingState.CANCELLED_BY_END_USER_WITH_NOTICE]: [],
    [BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE]: [],
    [BookingState.CANCELLED_BY_SUPPORT_WORKER]: [],
    [BookingState.TIMED_OUT_REQUEST]: [],
  },
  [ServiceRole.SUPPORT_WORKER]: {
    [BookingState.ACCEPTED_BY_SUPPORT_WORKER]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_SUPPORT_WORKER,
      },
    ],
    [BookingState.ACCEPTED_IN_PENALTY_PERIOD]: [
      {
        transition: BookingStateTransition.CANCEL,
        message: "Cancelled",
        newState: BookingState.CANCELLED_BY_SUPPORT_WORKER,
      },
    ],
    [BookingState.REQUESTED_BY_END_USER]: [
      {
        transition: BookingStateTransition.ACCEPT,
        message: "Accepted",
        newState: BookingState.ACCEPTED_BY_SUPPORT_WORKER,
      },
      {
        transition: BookingStateTransition.REJECT,
        message: "Rejected",
        newState: BookingState.CANCELLED_BY_SUPPORT_WORKER,
      },
    ],
    [BookingState.COMPLETED]: [],
    [BookingState.APPROVED]: [],
    [BookingState.CREATED_BY_ADMIN]: [],
    [BookingState.CANCELLED_BY_END_USER_WITH_NOTICE]: [],
    [BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE]: [],
    [BookingState.CANCELLED_BY_SUPPORT_WORKER]: [],
    [BookingState.TIMED_OUT_REQUEST]: [],
  },
};

export const paymentTypeMessages: {
  [paymentFlow: string]: {
    [serviceRole: string]: string;
  };
} = {
  [PaymentFlow.INVOICE]: {
    [ServiceRole.END_USER]:
      "Upon approval of your booking an invoice will be generated for the session to be paid by your chosen payment manager",
    [ServiceRole.SUPPORT_COORDINATOR]:
      "Upon approval of this booking an invoice will be generated for the session to be paid by your chosen payment manager",
    [ServiceRole.SUPPORT_WORKER]:
      "An invoice will be generated on your behalf after approval to be paid by members payment manager. please allow 7 - 14 days from invoice date.",
  },
  [PaymentFlow.CREDIT_CARD]: {
    [ServiceRole.END_USER]:
      "Upon approval of booking your funds will be released from your credit card used for this booking.",
    [ServiceRole.SUPPORT_COORDINATOR]:
      "Upon approval of booking, funds will be released from the credit card used for this booking.",
    [ServiceRole.SUPPORT_WORKER]:
      "Funds will be released 1 business day after approval.",
  },
};

export const shortStateLabelMap: {
  [key: string]: { text: string; color: "red" | "orange" | "green" | "blue" };
} = {
  [BookingState.REQUESTED_NO_AUTH]: {
    text: "incomplete",
    color: "red",
  },
  [BookingState.REQUESTED_BY_END_USER]: { text: "requested", color: "orange" },
  [BookingState.ACCEPTED_BY_SUPPORT_WORKER]: {
    text: "accepted",
    color: "green",
  },
  [BookingState.ACCEPTED_IN_PENALTY_PERIOD]: {
    text: "accepted",
    color: "green",
  },
  [BookingState.COMPLETED]: { text: "completed", color: "green" },
  [BookingState.TIMED_OUT_REQUEST]: { text: "timed out", color: "red" },
  [BookingState.APPROVED]: { text: "approved", color: "blue" },
  [BookingState.CANCELLED_BY_SUPPORT_WORKER]: {
    text: "cancelled",
    color: "red",
  },
  [BookingState.CANCELLED_BY_END_USER_WITH_NOTICE]: {
    text: "cancelled",
    color: "red",
  },
  [BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE]: {
    text: "cancelled",
    color: "red",
  },
  [BookingState.CANCELLED_BY_ADMIN]: { text: "cancelled", color: "red" },
  [BookingState.CREATED_BY_ADMIN]: { text: "approved", color: "blue" },
};

export const eventDescription = (
  newState: BookingState,
  booking: {
    endUser: { name: string };
    supportProfessional: { name: string };
    serviceName: string;
  },
  system: boolean
) => {
  switch (newState) {
    case BookingState.REQUESTED_NO_AUTH:
      return `requested a booking with ${booking.supportProfessional.name}`;
    case BookingState.ACCEPTED_BY_SUPPORT_WORKER:
      return `accepted a booking for ${booking.serviceName} with ${booking.endUser.name}`;
    case BookingState.ACCEPTED_IN_PENALTY_PERIOD:
      return `moved booking for ${booking.endUser.name} into the 24 hour penalty period`;
    case BookingState.COMPLETED:
      return `marked a booking${
        system ? ` for ${booking.endUser.name}` : ""
      } as completed`;
    case BookingState.TIMED_OUT_REQUEST:
      return `timed out a booking request for ${booking.endUser.name}`;
    case BookingState.APPROVED:
      return `approved a booking with ${booking.supportProfessional.name}${
        system ? ` for ${booking.endUser.name}` : ""
      }`;
    case BookingState.CANCELLED_BY_SUPPORT_WORKER:
      return `cancelled a booking with ${booking.endUser.name}`;
    case BookingState.CANCELLED_BY_END_USER_WITH_NOTICE:
      return `cancelled a booking with ${booking.supportProfessional.name}`;
    case BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE:
      return `cancelled a booking with ${booking.supportProfessional.name}. Late cancellation penalty has been applied.`;
    case BookingState.CANCELLED_BY_ADMIN:
      return `cancelled a booking between ${booking.endUser.name} and ${booking.supportProfessional.name}`;
    case BookingState.CREATED_BY_ADMIN:
      return `created an amended booking between ${booking.endUser.name} and ${booking.supportProfessional.name}`;
  }
};

export const onBehalfOf = (
  stateEvent: Pick<
    BookingStateEvent,
    | "id"
    | "onBehalfOf"
    | "endUser"
    | "supportCoordinator"
    | "supportProfessional"
  >
): { id: string; name: string } | undefined => {
  const {
    onBehalfOf,
    endUser,
    supportCoordinator,
    supportProfessional,
  } = stateEvent;

  switch (onBehalfOf) {
    case "END_USER":
      return (
        endUser && {
          id: endUser.id,
          name: endUser.name,
        }
      );
    case "SUPPORT_COORDINATOR":
      return (
        supportCoordinator && {
          id: supportCoordinator.id,
          name: `${supportCoordinator.firstName} ${supportCoordinator.lastName}`,
        }
      );
    case "SUPPORT_PROFESSIONAL":
      return (
        supportProfessional && {
          name: supportProfessional.name,
          id: supportProfessional.id,
        }
      );
    case "ADMIN":
      return { name: "tappON Admin", id: "" };

    default:
      return { name: "tappON System", id: "" };
  }
};

// Returns a list of projected bookings from a profile's Booking Schedules
export const concatProjectedBookingsForProfile = (
  profile: Pick<Required<EndUser | SupportProfessional>, "bookingSchedules">
) => {
  return (
    (profile && profile.bookingSchedules.map((s) => s.projectedBookings)) ||
    []
  ).reduce(
    (previousValue, currentValue) => previousValue.concat(currentValue),
    []
  );
};
