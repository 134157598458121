import React from "react";

import { Icon } from "../Icons";

export const UpdateSuccess = () => {
  return (
    <div className="flex justify-end items-center text-green-500">
      <Icon name="check" className="w-3 h-3 mr-2" />
      <p className="text-sm ">Sucessfully updated</p>
    </div>
  );
};
