import gql from "graphql-tag";

import { Booking } from "../../types/booking";
import { BOOKING_FIELDS } from "../fragments";

export interface NewData {
  booking: Booking;
}

export interface Variables {
  id: string;
}

export const UPDATE_PAYMENT_METHOD = gql`
  ${BOOKING_FIELDS}
  mutation updatePaymentMethodForBooking($id: ID!) {
    booking: updatePaymentMethodForBooking(id: $id) {
      ...BookingFields
    }
  }
`;
