export const zendeskSettings = {
  color: {
    theme: "#66c7c9",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
};
