import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { Link } from "@reach/router";

import { breakpoints } from "../../styles/Styled";

export interface MobileViewProps {
  mobileview: number;
}

const { small } = breakpoints;

export const InboxHeader = styled.div`
  background-color: ${(props) =>
    props.color === "light" ? "#31aaad" : "#31aaad"};

  padding: 18px 0 15px 15px;

  @media (min-width: ${small}) {
    display: none;
  }
`;

export const PageTitle = styled.h1`
  color: #fff;
  padding: 0 0 4px 35px;
  display: ${(props: MobileViewProps) => (props.mobileview ? "none" : "block")};
  margin: 0;

  @media (min-width: ${small}) {
    display: block;
    color: #000;
    font-weight: 700;
  }
`;

export const GridLayout = styled(Grid)`
  margin: 0 !important;
`;

export const Conversations = styled(Grid.Column)`
  display: ${(props: MobileViewProps) =>
    props.mobileview ? "none" : "block"} !important;
  padding: 0 !important;

  @media (min-width: ${small}) {
    display: block !important;
    padding: 10px !important;
  }
`;

export const CurrentConversation = styled(Grid.Column)`
  display: ${(props: MobileViewProps) =>
    props.mobileview ? "none" : "block"} !important;

  padding: 0 !important;

  @media (min-width: ${small}) {
    display: block !important;
  }
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;

  a {
    color: #fff;
    font-size: 20pt;
  }
`;

export const UserCard = styled(Link)`
  &&& {
    display: flex;
    align-items: center;

    h3 {
      margin: 0 0 0 20px;
      color: #fff;
    }
  }
`;
