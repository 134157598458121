import React, { useState } from "react";

import { CompleteProfileModal } from "./CompleteProfileModal";
import { Alert } from "./Alert";
import { Viewer } from "../../../../types/viewer";
import { EndUser } from "../../../../types/endUser";
import { IUserContext } from "../../../../types/profile";

export type AccountT = Pick<Viewer, "email">;

export const CompleteProfilePrompt = ({
  endUser,
  account,
  firstVisit,
  setVisited,
  user,
}: {
  endUser: EndUser;
  account: AccountT;
  firstVisit: boolean;
  setVisited: () => void;
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  const {
    addressStreet,
    addressCity,
    addressPostcode,
    addressState,
    profileImage,
    dateOfBirth,
    about,
    fundingType,
  } = endUser;

  const requiredInfoComplete =
    !!addressStreet &&
    !!addressCity &&
    !!addressState &&
    !!addressPostcode &&
    !!dateOfBirth;

  const profileComplete = !!profileImage && !!about;

  const fundingDetailsComplete = !!fundingType;

  const [displayModal, setDisplayModal] = useState(
    !requiredInfoComplete && firstVisit
  );

  const hideModal = () => {
    setDisplayModal(false);
    setVisited();
  };

  return (
    <>
      {displayModal && (
        <CompleteProfileModal
          hideModal={hideModal}
          endUser={endUser}
          account={account}
          user={user}
        />
      )}

      {!requiredInfoComplete && (
        <Alert
          text="Your account is limited until you complete some profile information"
          url="/settings/personal"
        />
      )}

      {requiredInfoComplete && !fundingDetailsComplete && (
        <Alert
          text="We need a few details from you before you can request invoice bookings"
          url="/settings/funding"
        />
      )}
      {requiredInfoComplete && fundingDetailsComplete && !profileComplete && (
        <Alert
          text="Your profile is nearly complete. Help us finish it."
          url="/settings/profile"
        />
      )}
    </>
  );
};
