import React from "react";
import { Link } from "@reach/router";

import { ServiceGroup } from "./ServiceGroup";
import { RatePerPeriod } from "./RatePerPeriod";
import { Avatar } from "../../layout/Avatar";
import { LocationMarker } from "./profilePage/LocationMarker";
import { SupportProfessional } from "../../../types/supportProfessional";
import { EndUser } from "../../../types/endUser";
import { Button } from "../../layout/Button";
import { Icon } from "../../layout/Icons";

export interface SupportProfessionalSummaryCardProps {
  supportProfessional: Pick<
    SupportProfessional,
    | "id"
    | "serviceOfferings"
    | "location"
    | "name"
    | "title"
    | "shortDescription"
    | "profileImage"
  >;
  endUser?: Pick<EndUser, "servicePreferences">;
  compact?: boolean;
  recommended?: boolean;
}

export const SupportProfessionalSummaryCard = ({
  supportProfessional,
  compact,
  recommended,
  endUser,
}: SupportProfessionalSummaryCardProps) => {
  const lowestRateService =
    !compact &&
    supportProfessional.serviceOfferings.length > 0 &&
    supportProfessional.serviceOfferings.find((s) => s.weekdayRate.amount > 0);

  const {
    id,
    location,
    name,
    title,
    shortDescription,
    serviceOfferings,
  } = supportProfessional;

  const servicePreferences = endUser
    ? endUser.servicePreferences.map((sp) => sp.service.id)
    : [];

  const preferredService =
    serviceOfferings &&
    serviceOfferings.find((s) => servicePreferences.includes(s.service.id));

  const hasInPersonOfferings = !!(
    serviceOfferings && serviceOfferings.find((so) => so.enabled && so.inPerson)
  );
  const hasVirtualOfferings = !!(
    serviceOfferings && serviceOfferings.find((so) => so.enabled && so.virtual)
  );

  return compact ? (
    <>
      <div className="support-professional-summary-card compact mb-2">
        <div className="info-container space-x-6">
          <Link to={`/worker/profile/${id}`}>
            <Avatar user={supportProfessional} size="md" />
          </Link>
          <div className="flex-1 truncate">
            <Link to={`/worker/profile/${id}`}>
              <h3 className="name mb-2">{name}</h3>
            </Link>
            <p className="title mt-1 mb-3">{title}</p>
            <span className="location">
              <Icon name="pin" className="w-3 h-3 mr-2" />
              {location}
            </span>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <div className="-mt-px flex">
            <div className="w-0 flex-1 flex">
              <Link to={`/worker/profile/${id}`} className="profile-link">
                <span className="text-base">View Profile</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      className="support-professional-summary-card extended center"
      data-testid="sp-summary"
    >
      <div>
        <Link to={`/worker/profile/${id}`}>
          <div className="flex p-4 items-center">
            <Avatar
              float="none"
              className="mr-4 flex-none"
              size="md"
              user={supportProfessional}
            />
            <div className="truncate">
              <h3 className="text-black truncate mb-2">{name}</h3>
              <span className="text-gray-500 truncate">{title}</span>
            </div>
          </div>
        </Link>
        <div className="p-4 border-b border-gray-300">
          <p className="text-gray-700">{shortDescription}</p>
        </div>
        <div className="flex justify-between items-center px-4 py-2">
          {lowestRateService ? (
            <RatePerPeriod service={lowestRateService} />
          ) : (
            <div>No Services</div>
          )}
          <LocationMarker location={location} className="justify-center" />
        </div>
        <div className="p-2">
          <div>
            <ServiceGroup
              serviceOfferings={serviceOfferings.filter((so) => so.enabled)}
              recommended={recommended}
              preferredService={preferredService}
            />
          </div>
          <p
            className={`text-gray-600 pt-4 text-sm ${
              recommended ? "" : "px-2"
            }`}
          >
            {hasInPersonOfferings && (
              <span className="font-bold text-blue-700">In Person </span>
            )}
            {hasInPersonOfferings && hasVirtualOfferings && "and "}
            {hasVirtualOfferings && (
              <span className="font-bold text-blue-700">Virtual</span>
            )}{" "}
            bookings available
          </p>
        </div>
      </div>

      <div className="p-3 border-t border-gray-200">
        <div className="ui two buttons">
          <Button to={`/worker/profile/${id}`}>Learn More</Button>
        </div>
      </div>
    </div>
  );
};
