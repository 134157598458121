import gql from "graphql-tag";

import { PublicHoliday } from "../../types/booking";
import { EndUser } from "../../types/endUser";
import { ServiceRole } from "../../types/profile";
import { Viewer } from "../../types/viewer";
import { END_USER_FIELDS } from "../fragments";

type EndUserT = Required<
  Pick<
    EndUser,
    | "id"
    | "name"
    | "permissions"
    | "dateOfBirth"
    | "addressStreet"
    | "addressCity"
    | "addressState"
    | "addressPostcode"
    | "virtualOnly"
  >
> &
  Pick<EndUser, "profileImage">;

interface EndUserVariables {
  endUserId: string;
}

const END_USER_BOOKING_FORM_QUERY = gql`
  ${END_USER_FIELDS}

  query BookingFormQuery($endUserId: ID!) {
    endUser(id: $endUserId) {
      ...EndUserFields
      permissions {
        booking
        message
        invoice
      }
    }
    publicHolidays {
      id
      name
      date
    }
  }
`;

interface SupportCoordinatorVariables {
  supportCoordinatorId: string;
}

const SUPPORT_COORDINATOR_BOOKING_FORM_QUERY = gql`
  ${END_USER_FIELDS}

  query BookingFormQuery($supportCoordinatorId: ID!) {
    clients: endUsersForSupportCoordinator(
      supportCoordinatorId: $supportCoordinatorId
    ) {
      ...EndUserFields
      permissions {
        booking
        message
        invoice
      }
    }

    publicHolidays {
      id
      name
      date
    }
  }
`;

export interface Data {
  endUser?: EndUserT;
  publicHolidays: PublicHoliday[];
  clients?: EndUserT[];
  viewer: Pick<Viewer, "id" | "role">;
}

export type Variables = EndUserVariables | SupportCoordinatorVariables;

export const bookingFormQueryForUserType = {
  [ServiceRole.END_USER]: END_USER_BOOKING_FORM_QUERY,
  [ServiceRole.SUPPORT_COORDINATOR]: SUPPORT_COORDINATOR_BOOKING_FORM_QUERY,
};
