import React from "react";
import { v4 as uuid } from "uuid";

import { ServiceOffering } from "../../../types/supportProfessional";
import { Link } from "@reach/router";

export const ServiceGroup = ({
  serviceOfferings,
  recommended,
  preferredService,
}: {
  serviceOfferings: ServiceOffering[];
  recommended?: boolean;
  preferredService?: ServiceOffering;
}) =>
  recommended && preferredService ? (
    <>
      <p>
        Offers
        <Link to={`/services/${preferredService.service.slug}`}>
          <span
            className="font-bold text-gray-800 text-base mx-1 inline-block hover:text-gray-900"
            key={uuid()}
          >
            {preferredService.service.name}
          </span>
        </Link>
        {serviceOfferings.length - 1 > 0 && (
          <span className="text-gray-700">and more</span>
        )}
      </p>
    </>
  ) : (
    <>
      {serviceOfferings.map(({ id, service }) => (
        <Link to={`/services/${service.slug}`} key={id}>
          <span className="bg-gray-300 rounded px-2 py-1 font-bold text-gray-800 text-sm m-1 inline-block hover:bg-gray-400 text-gray-900">
            {service.name}
          </span>
        </Link>
      ))}
    </>
  );
