import React, { ChangeEvent, useState } from "react";
import { Field, FieldProps, FormikErrors, FormikTouched } from "formik";
import { v4 as uuid } from "uuid";

import { Label } from ".";
import { unCamelize, humanize } from "../../../utils/helpers";
import { Icon } from "../../layout/Icons";

export interface Option {
  text: string;
  value: string | number;
  key?: string;
  group?: string;
}

export interface DropdownProps {
  name: string;
  label?: string;
  customHandleChange?: (
    event: ChangeEvent<HTMLSelectElement>,
    data?: any
  ) => void;
  inline?: boolean;
  noLabel?: boolean;
  options: Option[];
  blankDefault?: boolean;
  className?: string;
  noErrorMessage?: boolean;
  disabled?: boolean;
  blankDefaultPlaceholder?: string;
}

export const Dropdown = (props: DropdownProps) => {
  // This component must exist inside a Form component

  const { name } = props;

  return (
    <Field name={name}>
      {({ form, field }: FieldProps) => {
        const { onChange } = field;
        const { values, errors, touched, submitCount } = form;

        const error =
          errors[name] && touched[name]
            ? "text-red-700 bg-red-100 border-red-200"
            : "";

        return (
          <DropdownComponent
            onChange={onChange}
            values={values}
            errors={errors}
            touched={touched}
            submitCount={submitCount}
            error={error}
            {...props}
          />
        );
      }}
    </Field>
  );
};

interface DropdownComponentProps extends DropdownProps {
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  values: { [key: string]: string };
  errors: FormikErrors<{ [key: string]: any }>;
  touched: FormikTouched<{ [key: string]: any }>;
  submitCount: number;
  error?: string;
  disabled?: boolean;
}

const DropdownComponent = ({
  name,
  label,
  customHandleChange,
  inline,
  noLabel,
  options,
  blankDefault,
  className,
  noErrorMessage,
  onChange,
  values,
  errors,
  error,
  touched,
  disabled,
  blankDefaultPlaceholder,
}: DropdownComponentProps) => {
  const [placeholder, setPlaceholder] = useState(!values[name]);
  const display = inline ? "inline-block" : "block";
  const width = "w-full";

  let groupedOptions;

  if (options.every((o) => o.group)) {
    groupedOptions = options.reduce(
      (accum: { [key: string]: Option[] }, option: Option) => {
        if (option.group) {
          (accum[option.group] = accum[option.group] || []).push(option);
        }
        return accum;
      },
      {}
    );
  }

  return (
    <div id={name} className={`flex-1 ${className ? className : ""}`}>
      <Label htmlFor={name} className={`${noLabel ? "sr-only" : "mb-1"}`}>
        {label || unCamelize(name)}
      </Label>
      <div className="inline-block relative w-full">
        <select
          data-testid={name}
          className={`
              ${display} appearance-none ${width} 
              bg-white border border-gray-300 px-4 py-2 pr-8 rounded leading-tight focus:outline-none 
              ${error} ${!values[name] && placeholder ? "text-gray-500" : ""} `}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            if (customHandleChange) {
              customHandleChange(e);
            }
            onChange(e);
            setPlaceholder(e.target.value === "");
          }}
          value={values[name]}
          name={name}
          disabled={disabled}
        >
          {blankDefault && (
            <option key={uuid()} value={""}>
              {blankDefaultPlaceholder || "-- Choose One --"}
            </option>
          )}
          {groupedOptions
            ? Object.entries(groupedOptions).map((g) => {
                const [category, options] = g;
                return (
                  <optgroup
                    key={uuid()}
                    label={humanize(category, { capitalized: true })}
                  >
                    {options.map((o) => (
                      <option key={uuid()} value={o.value}>
                        {o.text}
                      </option>
                    ))}
                  </optgroup>
                );
              })
            : options.map((o) => (
                <option key={o.key || uuid()} value={o.value}>
                  {o.text}
                </option>
              ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <Icon name="chevron" className="h-4 w-4" />
        </div>
      </div>
      {!inline && error && touched[name] && !noErrorMessage && (
        <p className="sui-error-message mt-1 max-w-sm">{errors[name]}</p>
      )}
    </div>
  );
};
