import { useContext, useCallback } from "react";
import { ApolloError } from "@apollo/client";

import { RollbarContext } from "../rollbar/Rollbar";

export type LogType = "error" | "critical" | "info" | "warning";
export type MessageType = string | Error | ApolloError;

export const useLogger = () => {
  const rollbar = useContext(RollbarContext);

  const log = useCallback(
    (type: LogType, message: MessageType) => {
      rollbar[type](message);

      if (type === "error") {
        console.log(message);
      }
    },
    [rollbar]
  );

  return { log };
};
