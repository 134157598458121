import gql from "graphql-tag";

import { Booking } from "../../../types/booking";
import { BOOKING_FIELDS } from "../../fragments/booking";

export type BookingT = Pick<
  Booking,
  | "id"
  | "endTime"
  | "endUser"
  | "serviceName"
  | "startTime"
  | "state"
  | "supportProfessional"
  | "totalCost"
  | "paymentFailed"
  | "projected"
  | "scheduleId"
>;

export interface Data {
  allUpcomingBookings: Booking[];
  actionableBookings: Booking[];
}

export interface Variables {
  id: string;
}

export const SUPPORT_COORDINATOR_ACTIVE_BOOKINGS = gql`
  ${BOOKING_FIELDS}

  query SupportCoordinatorBookings($id: ID!) {
    allUpcomingBookings: upcomingClientBookings(supportCoordinatorId: $id) {
      ...BookingFields
    }

    actionableBookings: actionableClientBookings(supportCoordinatorId: $id) {
      ...BookingFields
    }
  }
`;
