import React, { Dispatch, SetStateAction } from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import { Dropdown } from "../../../layout/forms";
import {
  ServiceOffering,
  SupportProfessional,
} from "../../../../types/supportProfessional";
import {
  UPDATE_SERVICE_OFFERING,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateServiceOffering";
import { SUPPORT_PROFESSIONAL } from "../../../../graphql/queries/supportProfessional";
import { useLogger } from "../../../../hooks/useLogger";
import { Button } from "../../../layout/Button";

export const EditDurationForm = ({
  minDurationMins,
  supportProfessional,
  serviceOffering,
  setEditingDuration,
  className,
  add,
}: {
  minDurationMins: number;
  supportProfessional: SupportProfessional;
  serviceOffering: ServiceOffering;
  setEditingDuration: Dispatch<SetStateAction<boolean>>;
  className?: string;
  add: AddToast;
}) => {
  const { log } = useLogger();
  const [setMinDuration] = useMutation<NewData, Variables>(
    UPDATE_SERVICE_OFFERING
  );
  const durationOptions = [
    { text: "30 minutes", value: 30 },
    { text: "1 hour", value: 60 },
    { text: "90 minutes", value: 90 },
    { text: "2 hours", value: 120 },
  ];
  const handleSubmit = (values: any, formikApi: any) => {
    const variables = {
      minDurationMins: parseInt(values.minDurationMins),
      id: serviceOffering.id,
    };
    const unChanged =
      parseInt(values.minDurationMins) === serviceOffering.minDurationMins;
    setMinDuration({
      variables,
      refetchQueries: [
        {
          query: SUPPORT_PROFESSIONAL,
          variables: { profileId: supportProfessional.id },
        },
      ],
    })
      .then((res) => {
        formikApi.setSubmitting(false);
        if (unChanged) setEditingDuration(false);
      })
      .catch((err) => {
        add("There was an error updating your settings", {
          appearance: "error",
          autoDismiss: true,
        });
        log("error", err);
      });
  };
  return (
    <Formik initialValues={{ minDurationMins }} onSubmit={handleSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className={`flex items-center ${className}`}>
              <Dropdown
                options={durationOptions}
                name="minDurationMins"
                noLabel
              />

              <Button
                type="submit"
                loading={isSubmitting}
                size="small"
                className="ml-2"
              >
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
