import gql from "graphql-tag";

import { Booking } from "../../../types/booking";
import { Conversation } from "../../../types/message";
import { EndUser } from "../../../types/endUser";
import {
  BOOKING_FIELDS,
  CONVERSATION_FIELDS,
  SUPPORT_PROFESSIONAL_FIELDS,
  END_USER_FIELDS,
} from "../../fragments";
import { SupportProfessional } from "../../../types/supportProfessional";
import { RequiredBy } from "../../../types/helpers";

export interface Data {
  supportProfessional: RequiredBy<SupportProfessional, "bookingSchedules">;
  activeBookings: Booking[];
  inactiveBookings: Booking[];
  conversations: Conversation[];
  clients: EndUser[];
}

export interface Variables {
  supportProfessionalId: string;
}

export const SUPPORT_PROFESSIONAL_DASHBOARD_QUERY = gql`
  ${BOOKING_FIELDS}
  ${CONVERSATION_FIELDS}
  ${SUPPORT_PROFESSIONAL_FIELDS}
  ${END_USER_FIELDS}

  query SupportProfessionalDashboardQuery($supportProfessionalId: ID!) {
    supportProfessional: supportProfessional(id: $supportProfessionalId) {
      ...SupportProfessionalFields
      bookingSchedules {
        id
        rrule
        projectedBookings(limit: 2) {
          ...BookingFields
        }
      }
    }
    activeBookings: activeBookingsForSupportProfessional(
      id: $supportProfessionalId
    ) {
      ...BookingFields
    }
    inactiveBookings: inactiveBookingsForSupportProfessional(
      id: $supportProfessionalId
    ) {
      id
    }
    conversations: conversationsForSupportProfessional(
      supportProfessionalId: $supportProfessionalId
    ) {
      ...ConversationFields
    }
    clients: endUsersForSupportProfessional(
      supportProfessionalId: $supportProfessionalId
    ) {
      ...EndUserFields
    }
  }
`;
