import React from "react";

import { InputField as Input, Dropdown } from "../../../layout/forms";

export const AddressFields = () => {
  const states = ["QLD", "NSW", "VIC", "TAS", "SA", "WA", "ACT", "NT"];

  return (
    <div className="col-span-6 grid grid-cols-6 gap-x-6">
      <div className="col-span-6">
        <Input name="addressStreet" label="Street" className="mb-4" />
      </div>

      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
        <Input name="suburb" className="mb-4" />
      </div>

      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
        <Dropdown
          name="state"
          options={states.map((state) => {
            return { text: state, value: state };
          })}
          className="mb-4"
        />
      </div>

      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
        <Input name="postcode" className="mb-4" />
      </div>
    </div>
  );
};
