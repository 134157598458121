import React, { useContext, useState, useEffect } from "react";
import { ApolloProvider, InMemoryCache, ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createLink } from "apollo-absinthe-upload-link";
import { ToastProvider } from "react-toast-notifications";
import Zendesk from "react-zendesk";

import { PageLayout } from "./layout/PageLayout";
import { PublicPageLayout } from "./layout/PublicPageLayout";
import { AuthContext } from "../auth/Auth";
import { getEnv } from "../utils/helpers";
import { Router } from "./Router";
import { zendeskSettings } from "../zendesk/zendeskSettings";

declare global {
  interface Window {
    Stripe: any;
    FAB_SETTINGS: any; // used for linc
    grecaptcha: any; // used for google reCaptcha
    onGRecaptchaSubmit?: (token: string | null) => void;
  }
}

const App = () => {
  const authContext = useContext(AuthContext);
  const [unauthenticated, setUnauthenticated] = useState(false);

  useEffect(() => {
    if (
      !authContext.session &&
      (localStorage.getItem("isLoggedIn") === "false" ||
        !localStorage.getItem("isLoggedIn"))
    ) {
      setUnauthenticated(true);
    }
  }, [authContext]);

  if (!authContext.session && localStorage.getItem("isLoggedIn") === "true") {
    localStorage.setItem(
      "prevRoute",
      window.location.pathname + window.location.search + window.location.hash
    );
    authContext.checkSession();
  }

  const authLink = setContext((_request, { headers }) => {
    const token = authContext.session ? authContext.session.idToken : "";

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const uploadLink = createLink({
    uri: authContext.session
      ? getEnv("REACT_APP_API_ENDPOINT")
      : getEnv("REACT_APP_PUBLIC_ENDPOINT"),
  });

  const logoutLink = onError(({ networkError }) => {
    if (
      networkError &&
      "statusCode" in networkError &&
      networkError.statusCode === 401
    ) {
      authContext.logout();
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(logoutLink.concat(uploadLink)),
    cache: new InMemoryCache({ addTypename: true }),
  });

  return (
    <>
      {authContext.session ? (
        <ApolloProvider client={client}>
          <ToastProvider>
            <PageLayout>
              <Router />
            </PageLayout>
          </ToastProvider>
        </ApolloProvider>
      ) : (
        <ApolloProvider client={client}>
          <ToastProvider>
            <PublicPageLayout>
              <Router unauthenticated={unauthenticated} />
            </PublicPageLayout>
          </ToastProvider>
        </ApolloProvider>
      )}
      <Zendesk
        zendeskKey={getEnv("REACT_APP_ZENDESK_KEY")}
        {...zendeskSettings}
      />
    </>
  );
};

export default App;
