import gql from "graphql-tag";

export const END_USER_FIELDS = gql`
  fragment EndUserFields on EndUser {
    __typename
    id
    about
    addressStreet
    addressCity
    addressState
    addressPostcode
    dateOfBirth
    fundingType
    gender
    name
    ndisParticipantNumber
    otherFundingType
    permissions {
      booking
      message
      invoice
    }
    profileImage {
      id
      large
      med
      small
      original
    }
    servicePreferences {
      id
      service {
        id
        name
        virtual
        slug
        category
      }
    }
    supportHoursPerWeek
    virtualOnly
    virtualPreference
  }
`;
