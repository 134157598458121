import gql from "graphql-tag";

import { SupportProfessional } from "../../types/supportProfessional";
import { EndUser } from "../../types/endUser";
import { SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";

export interface Data {
  supportProfessional: SupportProfessional;
  endUser?: Required<Pick<EndUser, "id" | "dateOfBirth" | "permissions">>;
}

export interface Variables {
  profileId: string;
  endUserId: string;
}

export const SUPPORT_PROFESSIONAL_PROFILE = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}

  query SupportProfessionalProfile($profileId: ID!, $endUserId: ID!) {
    supportProfessional(id: $profileId) {
      ...SupportProfessionalFields
    }

    # Querying for the End User here to determine whether the SP is bookable (Valid WWCC)
    endUser(id: $endUserId) {
      id
      dateOfBirth
      permissions {
        booking
        message
        invoice
      }
    }
  }
`;
