import gql from "graphql-tag";

import { Conversation } from "../../types/message";
import { EndUser } from "../../types/endUser";
import { Viewer } from "../../types/viewer";
import { CONVERSATION_FIELDS } from "../fragments";
import { ServiceRole } from "../../types/profile";

export interface Data {
  conversations: Conversation[];
  viewer: Pick<Viewer, "id" | "role">;
  endUser?: Pick<EndUser, "id" | "permissions" | "supportCoordinator">;
}

export interface Variables {
  id: string;
}

const END_USER_INBOX_QUERY = gql`
  ${CONVERSATION_FIELDS}

  query EndUserInboxQuery($id: ID!) {
    conversations: conversationsForEndUser(endUserId: $id) {
      ...ConversationFields
    }

    viewer {
      id
      role
    }

    endUser(id: $id) {
      id
      permissions {
        booking
        message
        invoice
      }
      supportCoordinator {
        id
        firstName
        lastName
      }
    }
  }
`;

const SUPPORT_COORDINATOR_INBOX_QUERY = gql`
  ${CONVERSATION_FIELDS}

  query SupportCoordinatorInboxQuery($id: ID!) {
    conversations: conversationsForSupportCoordinator(
      supportCoordinatorId: $id
    ) {
      ...ConversationFields
    }

    viewer {
      id
      role
    }
  }
`;

const SUPPORT_PROFESSIONAL_INBOX_QUERY = gql`
  ${CONVERSATION_FIELDS}

  query SupportProfessionalInboxQuery($id: ID!) {
    conversations: conversationsForSupportProfessional(
      supportProfessionalId: $id
    ) {
      ...ConversationFields
    }

    viewer {
      id
      role
    }
  }
`;

export const queryForUserType = {
  [ServiceRole.END_USER]: END_USER_INBOX_QUERY,
  [ServiceRole.SUPPORT_COORDINATOR]: SUPPORT_COORDINATOR_INBOX_QUERY,
  [ServiceRole.SUPPORT_WORKER]: SUPPORT_PROFESSIONAL_INBOX_QUERY,
};
