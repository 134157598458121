import moment from "moment";
import { navigate } from "@reach/router";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";

import { AddToast } from "react-toast-notifications";
import { FormikHelpers } from "formik";

import { IUserContext, ServiceRole } from "../../../types/profile";
import { END_USER_BOOKINGS_QUERY } from "../../../graphql/queries/endUserBookings";
import { LogType, MessageType } from "../../../hooks/useLogger";
import { Booking, DeliveryType } from "../../../types/booking";
import { FormValues } from "./BookingFormData";
import {
  ServiceOffering,
  SupportProfessional,
} from "../../../types/supportProfessional";
import { EndUser } from "../../../types/endUser";
import { SUPPORT_COORDINATOR_DASHBOARD_QUERY } from "../../../graphql/queries/dashboard/supportCoordinatorDashboard";
import {
  buildStartTime,
  buildEndTime,
  getDays,
  buildDuration,
  goToStripe,
} from "./bookingFormHelpers";
import {
  NewData,
  Variables,
  RecurringBookingScheduleT,
} from "../../../graphql/mutations/createBooking";

export const handleBookingFormSubmit = ({
  serviceOfferingMap,
  endUser,
  supportProfessional,
  billable,
  createBookingRequest,
  add,
  log,
  user,
}: {
  serviceOfferingMap: { [key: string]: ServiceOffering };
  endUser?: Pick<EndUser, "id" | "virtualOnly">;
  supportProfessional: Pick<SupportProfessional, "id">;
  billable: boolean;
  createBookingRequest: (
    options?: MutationFunctionOptions<NewData, Variables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  add: AddToast;
  log: (type: LogType, message: MessageType) => void;
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  const handleSubmit = (
    values: FormValues,
    formikApi: FormikHelpers<FormValues>
  ) => {
    const startTime = buildStartTime(values);
    const endTime = buildEndTime(startTime, values);

    const {
      terms: acceptedTerms,
      deliveryType,
      clientId,
      recurring,
      interval,
      until,
    } = values;

    const serviceOffering = serviceOfferingMap[values.service];
    const variables = {
      endUserId: clientId || (endUser && endUser.id) || "",
      supportProfessionalId: supportProfessional.id,
      serviceOfferingId: serviceOffering.id,
      startTime: startTime.local(),
      endTime: billable
        ? endTime.local()
        : moment(startTime).add(15, "minutes"),
      specialInstructions: values.specialInstructions,
      paymentFlow: (billable && values.payment) || null,
      acceptedTerms,
      deliveryType:
        endUser && endUser.virtualOnly ? DeliveryType.VIRTUAL : deliveryType,
      supportCoordinatorId:
        user.__typename === ServiceRole.SUPPORT_COORDINATOR ? user.id : null,
      recurring,
      days: getDays(values),
      interval: parseInt(interval, 10),
      until: until ? moment(until) : null,
      durationMinutes: buildDuration(values),
    };

    createBookingRequest({
      variables,
      refetchQueries: endUser
        ? [
            {
              query: END_USER_BOOKINGS_QUERY,
              variables: { profileId: endUser && endUser.id },
            },
          ]
        : [
            {
              query: SUPPORT_COORDINATOR_DASHBOARD_QUERY,
              variables: { id: user.id },
            },
          ],
    })
      .then((response: { data?: NewData }) => {
        if (response.data) {
          const { createBooking: result } = response.data;

          let booking: Booking;
          let url: string = "";

          if (result.hasOwnProperty("bookings")) {
            const recurringBookingSchedule = result as RecurringBookingScheduleT;

            booking = recurringBookingSchedule.bookings
              .concat(recurringBookingSchedule.projectedBookings)
              .sort((a, b) => {
                return moment(a.startTime).isAfter(moment(b.startTime))
                  ? 1
                  : -1;
              })[0];

            formikApi.setSubmitting(false);

            url = `/bookings/schedule/${recurringBookingSchedule.id}/${moment(
              startTime
            ).format("YYYY-MM-DD")}`;
          } else {
            booking = result as Booking;

            formikApi.setSubmitting(false);

            url = `/bookings/${booking.id}`;
          }

          log(
            "info",
            `${values.payment} booking request submitted. Booking id: ${booking.id}`
          );

          values.payment === "CREDIT_CARD"
            ? goToStripe(booking.stripeCheckoutId)
            : navigate(url);
        }
      })
      .catch((err) => {
        log("error", err);
        formikApi.setSubmitting(false);
        add("There was an error creating your booking", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return handleSubmit;
};
