import React from "react";
import { Formik, Form, FormikErrors } from "formik";
import { useMutation } from "@apollo/client";
import { v4 as uuid } from "uuid";
import { AddToast } from "react-toast-notifications";

import { supportHours, fundingTypeLabels } from "../../profileHelpers";
import { Gender } from "../../../../types/profile";
import { ServicePreference, FundingType } from "../../../../types/endUser";
import {
  UPDATE_END_USER,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateEndUser";
import { useLogger } from "../../../../hooks";
import { EndUserT } from "./CompleteProfileModal";
import { Dropdown, InputField as Input } from "../../../layout/forms";
import { Tag } from "../../../layout/Tag";
import { Service } from "../../../../types/service";
import { AddServicePreference } from "./AddServicePreference";
import { Buttons } from "./Buttons";
import wavingHand from "../../../../assets/images/waving-hand-sign.png";

export interface FormValues {
  gender?: Gender | "unset" | "";
  supportHoursPerWeek: string;
  servicePreferences: ServicePreference[];
  serviceId?: string;
  fundingType: FundingType;
  ndisNumber: string;
  otherFundingType: string;
}

export const CompleteProfilePanel2 = ({
  endUser,
  hideModal,
  setPanel,
  services,
  add,
}: {
  endUser: EndUserT;
  hideModal: () => void;
  setPanel: (number: number) => void;
  services: Service[];
  add: AddToast;
}) => {
  const [updateEndUser] = useMutation<NewData, Variables>(UPDATE_END_USER);
  const { log } = useLogger();

  const [firstName] = endUser.name.split(" ");

  const initialValues: FormValues = {
    gender: endUser.gender || "",
    supportHoursPerWeek:
      Object.keys(supportHours).find(
        (key) => supportHours[key] === endUser.supportHoursPerWeek
      ) || "10 - 20",
    servicePreferences: endUser.servicePreferences || [],
    serviceId: "",
    fundingType: endUser.fundingType || FundingType.SELF_FUNDED,
    ndisNumber: endUser.ndisParticipantNumber || "",
    otherFundingType: endUser.otherFundingType || "",
  };

  const handleSubmit = (values: FormValues, formikApi: any) => {
    const {
      gender,
      supportHoursPerWeek,
      servicePreferences,
      fundingType,
      ndisNumber,
      otherFundingType,
    } = values;

    const variables = {
      id: endUser.id,
      gender: gender === "unset" ? null : gender || undefined,
      supportHoursPerWeek: supportHours[supportHoursPerWeek],
      servicePreferences: servicePreferences.map((s: ServicePreference) => {
        return {
          id: s.id,
          serviceId: s.service.id,
        };
      }),
      fundingType,
      ndisParticipantNumber: ndisNumber,
      otherFundingType,
    };

    updateEndUser({
      variables,
    })
      .then((res) => {
        setPanel(3);
      })
      .catch((err) => {
        add("There was a problem updating your details", {
          appearance: "error",
          autoDismiss: true,
        });
        log("error", err);
      });
  };

  const genderOptions = [
    { text: "female", value: Gender.FEMALE },
    { text: "male", value: Gender.MALE },
    { text: "non binary", value: Gender.NON_BINARY },
    { text: "prefer not to say", value: "unset" },
  ];

  const fundingTypeOptions = Object.values(FundingType).map((f) => {
    return { text: fundingTypeLabels[f], value: f };
  });

  return (
    <div className="">
      <div className="">
        <div className="pt-6 sm:pb-6 md:pb-10 flex flex-col items-start sm:items-center">
          <div className="flex items-center mb-4">
            <img
              src={wavingHand}
              className="w-8 h-8 mr-3"
              alt="waving hand emoji"
            />
            <h3 className="text-3xl font-medium leading-6 text-gray-900 mb-0">
              Hi {firstName}
            </h3>
          </div>
          <p className="mt-1 text-xl text-gray-500">
            Please tell us a bit more about yourself
          </p>
        </div>
        <div className="mt-5 md:mt-0">
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: FormikErrors<FormValues> = {};

              if (!values.gender) {
                errors.gender = "Required";
              }

              if (
                (values.fundingType === FundingType.NDIS_PLAN ||
                  values.fundingType === FundingType.NDIS_SELF_MANAGED) &&
                !values.ndisNumber
              ) {
                errors.ndisNumber = "Required";
              }

              if (
                values.fundingType === FundingType.OTHER &&
                !values.otherFundingType
              ) {
                errors.otherFundingType = "Required";
                errors.fundingType = " ";
              }

              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              values,
              setFieldValue,
              errors,
              touched,
              setTouched,
            }) => {
              const serviceOptions = services
                .filter(
                  (s) =>
                    !values.servicePreferences
                      .map((s) => s.service.id)
                      .includes(s.id)
                )
                .map((service) => {
                  return {
                    group: service.category,
                    text: service.name,
                    value: service.id,
                  };
                });
              return (
                <Form>
                  <div className="text-gray-800 mb-4 sm:px-10">
                    <div className="flex flex-wrap justify-start sm:justify-center items-center mb-8 sm:mb-6 text-lg sm:text-base">
                      <span className="mt-2">I am </span>
                      <div className="inline-block">
                        <Dropdown
                          noLabel
                          name="gender"
                          options={genderOptions}
                          blankDefault
                          blankDefaultPlaceholder="-- gender --"
                          className="mx-2 mt-2"
                        />
                      </div>
                      <span className="mt-2">and live in</span>
                      <span className="sm:font-bold ml-2 mt-2">
                        {endUser.addressCity}.
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-start sm:justify-center items-center mb-8 sm:mb-6 text-lg sm:text-base">
                      <span className="mt-2 mr-2">I am looking for around</span>
                      <Dropdown
                        noLabel
                        name="supportHoursPerWeek"
                        options={Object.keys(supportHours).map((v) => {
                          return { text: v, value: v };
                        })}
                        className="mr-2 mt-2 flex-none"
                      />
                      <span className="mt-2">hours of support per week.</span>
                    </div>
                    <div className="flex flex-wrap justify-start sm:justify-center items-center mb-2 flex-wrap text-lg sm:text-base">
                      <span className="">
                        The areas in which I generally need support are:
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-start sm:justify-center items-center mb-8 sm:mb-6 ">
                      {values.servicePreferences.map((s) => (
                        <Tag
                          key={uuid()}
                          text={s.service.name}
                          remove={
                            s.id
                              ? undefined
                              : () => {
                                  setFieldValue(
                                    "servicePreferences",
                                    values.servicePreferences.filter(
                                      (sp) => sp.service.id !== s.service.id
                                    )
                                  );
                                }
                          }
                          className="flex-none"
                        />
                      ))}
                      <AddServicePreference
                        serviceOptions={serviceOptions}
                        endUser={endUser}
                        values={values}
                        setFieldValue={setFieldValue}
                        services={services}
                      />
                    </div>
                    <div className="flex flex-wrap justify-start sm:justify-center items-center mb-8 sm:mb-6 text-lg sm:text-base">
                      <span className="mr-2 mt-2">
                        {values.fundingType === FundingType.AGED_CARE &&
                          "I have an"}
                        {values.fundingType === FundingType.OTHER &&
                          "My funding type is"}
                        {![FundingType.AGED_CARE, FundingType.OTHER].includes(
                          values.fundingType
                        ) && "I am"}
                      </span>
                      <div className="flex flex-wrap justify-start sm:justify-center mt-2 text-lg sm:text-base">
                        <Dropdown
                          noLabel
                          name="fundingType"
                          options={fundingTypeOptions}
                          className={`mx-2 ${
                            errors.otherFundingType && touched.otherFundingType
                              ? "mb-7"
                              : ""
                          }`}
                          customHandleChange={() => {
                            setTouched({
                              fundingType: false,
                              otherFundingType: false,
                              ndisNumber: false,
                            });
                          }}
                        />

                        {values.fundingType === FundingType.OTHER && (
                          <>
                            <Input
                              name="otherFundingType"
                              label="Funding Type"
                              noLabel
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {[
                      FundingType.NDIS_PLAN,
                      FundingType.NDIS_SELF_MANAGED,
                      FundingType.NDIA,
                    ].includes(values.fundingType) && (
                      <div className="flex flex-wrap justify-start sm:justify-center items-center mb-8 sm:mb-6 text-lg sm:text-base">
                        <span className="mr-2 mt-2">
                          My NDIS participant number is
                        </span>
                        <Input className="mt-2" name="ndisNumber" noLabel />
                      </div>
                    )}
                  </div>
                  <Buttons
                    panel={2}
                    setPanel={setPanel}
                    hideModal={hideModal}
                    isSubmitting={isSubmitting}
                    submit
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
