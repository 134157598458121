import React from "react";
import moment from "moment";

import {
  ServiceOffering,
  SupportProfessional,
} from "../../../types/supportProfessional";
import { FormValues } from "./BookingFormData";
import {
  buildEndTime,
  upcomingRecurringDates,
  buildRruleText,
  buildStartTime,
  totalCost,
  buildDuration,
} from "./bookingFormHelpers";
import { EndUser } from "../../../types/endUser";
import { Icon } from "../../layout/Icons";
import { humanize } from "../../../utils/helpers";
import {
  DeliveryType,
  PaymentFlow,
  PublicHoliday,
} from "../../../types/booking";
import { PreviewUpcomingBookingDates } from "./PreviewUpcomingBookingDates";

export const BookingPreview = ({
  values,
  endUser,
  service,
  supportProfessional,
  publicHolidays,
}: {
  values: FormValues;
  endUser: Pick<
    EndUser,
    | "id"
    | "name"
    | "addressStreet"
    | "addressCity"
    | "addressState"
    | "addressPostcode"
  >;
  service: ServiceOffering;
  supportProfessional: Pick<
    SupportProfessional,
    | "profileImage"
    | "name"
    | "id"
    | "shortDescription"
    | "location"
    | "title"
  >;
  publicHolidays: PublicHoliday[];
}) => {
  const startTime = buildStartTime(values);
  const duration =
    service.service.name === "Meet and Greet" ? 15 : buildDuration(values);
  const endTime = buildEndTime(startTime, values, duration);

  const { recurring } = values;

  const dates = upcomingRecurringDates(
    values,
    endUser,
    service,
    supportProfessional
  );

  const inPerson = values.deliveryType === DeliveryType.IN_PERSON;

  return (
    <div className="my-5 space-y-5 flex flex-col items-center">
      <div>
        <p className="text-lg text-gray-900 flex items-center justify-center space-x-2">
          <Icon name="calendar" className="w-4 h-4 text-gray-400 mr-2" />
          {recurring ? (
            <span>{buildRruleText(values)}</span>
          ) : (
            <span>{moment(startTime).format("D MMMM YYYY")}</span>
          )}
        </p>
        <p className="text-base text-gray-900 flex items-center justify-center space-x-2 mt-2">
          <Icon name="clock" className="w-4 h-4 text-gray-400 mr-2" />
          {recurring ? (
            <span>
              At {buildStartTime(values).format("ha")} for{" "}
              {duration >= 60
                ? `${duration / 60} hours`
                : `${duration} minutes`}
            </span>
          ) : (
            <span>
              {moment(startTime).format("h:mm a")} -{" "}
              {moment(endTime).format("h:mm a")}
            </span>
          )}
        </p>
      </div>
      <p className="text-lg flex items-center justify-center bg-teal-100 text-teal-900 px-4 py-1 rounded-lg font-bold">
        <Icon
          name={inPerson ? "pin" : "computer"}
          className="w-4 h-4 text-teal-700 mr-2"
        />
        {inPerson ? (
          <span className="text-base">
            {endUser.addressStreet}, {endUser.addressCity},{" "}
            {endUser.addressState}, {endUser.addressPostcode}
          </span>
        ) : (
          <span className="text-base">This booking will take place online</span>
        )}
      </p>
      {recurring ? (
        <>
          <PreviewUpcomingBookingDates
            dates={dates}
            values={values}
            publicHolidays={publicHolidays}
            service={service}
          />
          <p className="text-gray-600 mx-6 ">
            This will continue{" "}
            {values.until
              ? `until ${moment(values.until).format("Do MMMM YYYY")}`
              : "indefinitely until you cancel"}
          </p>
        </>
      ) : (
        <>
          <div className="flex justify-center">
            {values.date && (
              <dl className="">
                <dt className="text-sm leading-5 text-center font-medium text-gray-500 truncate">
                  Total Cost
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  {totalCost(
                    values,
                    moment(values.date).toDate(),
                    publicHolidays,
                    service
                  )}
                </dd>
              </dl>
            )}
          </div>
        </>
      )}

      {values.payment && (
        <p className="inline-block m-auto bg-teal-100 text-teal-800 px-3 py-1 rounded-md">
          Paid by {values.payment && humanize(values.payment)}
        </p>
      )}
      {recurring && values.payment === PaymentFlow.CREDIT_CARD && (
        <p className="p-4 border border-gray-200 bg-gray-50 text-gray-600">
          You will only be charged for your first booking. Each following
          booking will be pre authorised 3 days before the booking is to take
          place. Please contact{" "}
          <a href="mailto:support@tappon.co" className="font-bold">
            support@tappon.co
          </a>{" "}
          for help.
        </p>
      )}
    </div>
  );
};
