import React, { Dispatch, SetStateAction } from "react";
import { Room } from "twilio-video";

import { Icon } from "../../layout/Icons";
import { useLocalAudioToggle } from "./hooks/useLocalAudioToggle";
import { useLocalVideoToggle } from "./hooks/useLocalVideoToggle";

export const Controls = ({
  room,
  setEndCallConfirmation,
}: {
  room: Room;
  setEndCallConfirmation: Dispatch<SetStateAction<boolean>>;
}) => {
  const [audioIsEnabled, toggleAudio] = useLocalAudioToggle(room);
  const [videoIsEnabled, toggleVideo] = useLocalVideoToggle(room);

  return (
    <div className="fixed bottom-0 left-0 z-40 w-screen flex justify-center">
      <div className="mobile-controls text-white flex mb-10">
        <button
          className={`bg-${
            audioIsEnabled ? "teal-800" : "white"
          } rounded-full p-4 mb-2 mr-6 transition duration-150 ease-in-out`}
          onClick={toggleAudio}
        >
          <Icon
            name={audioIsEnabled ? "mic" : "micOff"}
            className={`w-8 h-8 ${
              audioIsEnabled ? "text-white" : "text-black"
            }`}
          />
        </button>
        <button
          className={`bg-${
            videoIsEnabled ? "teal-800" : "white"
          } rounded-full p-4 mb-2 mr-6 transition duration-150 ease-in-out`}
          onClick={toggleVideo}
        >
          <Icon
            name={videoIsEnabled ? "video" : "videoOff"}
            className={`w-8 h-8 ${
              videoIsEnabled ? "text-white" : "text-black"
            }`}
          />
        </button>
        <button
          onClick={() => setEndCallConfirmation(true)}
          className="bg-red-800 rounded-full p-4 mb-2"
        >
          <Icon name="callEnd" className="w-8 h-8" />
        </button>
      </div>
    </div>
  );
};
