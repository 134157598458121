import React, { Dispatch, SetStateAction } from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import {
  UPDATE_SERVICE_OFFERING,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateServiceOffering";
import { SUPPORT_PROFESSIONAL } from "../../../../graphql/queries/supportProfessional";
import { MoneyInput } from "../../../layout/forms";
import {
  RatePenalty,
  ServiceOffering,
  SupportProfessional,
} from "../../../../types/supportProfessional";
import { slugify } from "../../../../utils/helpers";
import { useLogger } from "../../../../hooks/useLogger";

export const EditRateForm = ({
  serviceOffering,
  supportProfessional,
  rate,
  setEditingRate,
  setError,
  add,
}: {
  serviceOffering: ServiceOffering;
  supportProfessional: SupportProfessional;
  rate: RatePenalty;
  setEditingRate: Dispatch<SetStateAction<boolean>>;
  setError?: Dispatch<SetStateAction<undefined | string>>;
  add: AddToast;
}) => {
  const { log } = useLogger();
  const [setRate] = useMutation<NewData, Variables>(UPDATE_SERVICE_OFFERING);

  const handleSubmit = (values: any, formikApi: any) => {
    const variables = {
      [rate]: values.delete ? null : { amount: values.rate },
      id: serviceOffering.id,
    };

    const unChanged = serviceOffering[rate]
      ? values.rate === serviceOffering[rate].amount && !values.delete
      : false;

    setRate({
      variables,
      refetchQueries: [
        {
          query: SUPPORT_PROFESSIONAL,
          variables: { profileId: supportProfessional.id },
        },
      ],
    })
      .then((res) => {
        formikApi.setSubmitting(false);
        if (unChanged) setEditingRate(false);
      })
      .catch((err) => {
        add("There was an error updating your settings", {
          appearance: "error",
          autoDismiss: true,
        });
        log("error", err);
      });
  };
  return (
    <Formik
      initialValues={{
        rate: serviceOffering[rate] ? serviceOffering[rate].amount : 0,
        delete: false,
      }}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: {
          rate?: string;
        } = {};
        if (values.rate > 25000 && !values.delete) {
          errors.rate = "Cannot be higher than $250";
        }
        if (values.rate < 2500 && !values.delete) {
          errors.rate = "Must be at least $25";
        }

        if (setError) setError(errors.rate);

        return errors;
      }}
    >
      {(props) => {
        const {
          values,
          setFieldValue,
          setTouched,
          submitForm,
          isSubmitting,
          errors,
        } = props;
        const handleChange = (value: number) => {
          setFieldValue("rate", value, true);
          setTouched({ rate: true });
        };
        return (
          <>
            <Form
              id={`${slugify(rate)}-form`}
              className="flex w-1/2 md:3/5 lg:1/2"
            >
              <div className={``}>
                <MoneyInput
                  className={`${
                    errors.rate ? "border-red-600" : "border-gray-300"
                  } w-24 text-gray-900 border  rounded py-1 px-2`}
                  name="rate"
                  value={values.rate}
                  onValueChange={handleChange}
                />
              </div>
              <div className="flex">
                <button
                  type="submit"
                  className={`block w-10 ml-4 font-bold text-teal-500 cursor-pointer ${
                    isSubmitting ? "submitting" : ""
                  }`}
                  disabled={isSubmitting}
                >
                  Save
                </button>
                {rate !== RatePenalty.WEEKDAY && serviceOffering[rate] ? (
                  <button
                    className={`block w-10 ml-4 font-bold text-red-500 cursor-pointer ${
                      isSubmitting ? "submitting" : ""
                    }`}
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue("delete", true, false);
                      submitForm();
                    }}
                  >
                    Remove
                  </button>
                ) : (
                  <div className="w-10 ml-4 font-bold text-red-500 cursor-pointer" />
                )}
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
