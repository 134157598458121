import { Service } from "./service";
import { Gender, ServiceRole, ProfileImage } from "./profile";
import { SupportCoordinator } from "./supportCoordinator";
import { RecurringBookingSchedule } from "./booking";

export interface EndUser {
  __typename: ServiceRole;
  id: string;
  about: string | null;
  addressStreet: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressPostcode: string | null;
  bookingSchedules?: RecurringBookingSchedule[];
  dateOfBirth: string | null;
  fundingType: FundingType | null;
  gender: Gender | null;
  name: string;
  ndisParticipantNumber: string | null;
  otherFundingType: string | null;
  permissions?: Permissions;
  profileImage?: ProfileImage;
  servicePreferences: ServicePreference[];
  supportHoursPerWeek: string;
  virtualOnly: boolean;
  virtualPreference: boolean;
  supportCoordinator?: Pick<
    SupportCoordinator,
    "id" | "firstName" | "lastName"
  > | null;
}

export interface ServicePreference {
  id: string;
  service: Service;
}

export enum FundingType {
  SELF_FUNDED = "SELF_FUNDED",
  NDIS_SELF_MANAGED = "NDIS_SELF_MANAGED",
  NDIS_PLAN = "NDIS_PLAN_MANAGED",
  NDIA = "NDIA_MANAGED",
  AGED_CARE = "AGED_CARE_PACKAGE",
  OTHER = "OTHER",
}

export interface Permissions {
  message: boolean;
  booking: boolean;
  invoice: boolean;
}
