import React from "react";
import { useQuery } from "@apollo/client";
import { Formik, Form } from "formik";
import { navigate } from "@reach/router";

import {
  ALL_AVAILABLE_PROFILES,
  Data,
} from "../../graphql/queries/allAvailableProfiles";
import { Loading } from "../layout/Loading";
import { ServiceRole } from "../../types/profile";
import { Dropdown } from "../layout/forms";
import { enableAdminOnly } from "../../utils/featureFlags";
import { useLogger } from "../../hooks/useLogger";
import { Button } from "../layout/Button";
import { SupportProfessional } from "../../types/supportProfessional";
import { EndUser } from "../../types/endUser";

export interface UserT {
  __typename: ServiceRole;
}

type Profile = Pick<EndUser | SupportProfessional, "id" | "name">;

export const AdminMessageButton = ({ user }: { user: UserT }) => {
  const { log } = useLogger();
  const { data, loading, error } = useQuery<Data>(ALL_AVAILABLE_PROFILES, {});

  if (error) {
    log("error", error);
    return <></>;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { viewer } = data;

  if (enableAdminOnly(viewer)) {
    const users: Profile[] =
      user.__typename === ServiceRole.SUPPORT_WORKER
        ? viewer.endUsers
        : viewer.supportProfessionals;

    const handleSubmit = (values: any, formikApi: any) => {
      navigate(`/messages/${values.user}`);
      formikApi.setSubmitting(false);
    };

    const userOptions = users.map((u: Profile) => ({
      key: u.id,
      value: u.id,
      text: u.name,
    }));

    return (
      <div className="hidden md:inline-block px-8 py-5">
        <Formik
          initialValues={{ user: userOptions[0].value }}
          onSubmit={handleSubmit}
        >
          <Form>
            <p className="text-gray-500 my-0 mb-1">Admin Only</p>
            <Dropdown
              name="user"
              options={userOptions}
              noLabel
              className="mb-4"
            />
            <Button type="submit">New Message</Button>
          </Form>
        </Formik>
      </div>
    );
  }

  return <> </>;
};
