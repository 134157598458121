import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { Icon } from "./Icons";

export interface TagProps {
  text: string;
  remove?: () => void;
  className?: string;
}

export const Tag = ({ remove, text, className }: TagProps) => {
  const [deleting, setDeleting] = useState(false);

  return (
    <>
      <li key={uuid()} className={`tag ${className}`}>
        {text}
        {remove && (
          <>
            {deleting ? (
              <Icon className="spin" name="loading" />
            ) : (
              <Icon
                className="remove"
                name="close"
                onClick={() => {
                  setDeleting(true);
                  remove();
                }}
              />
            )}
          </>
        )}
      </li>
    </>
  );
};
