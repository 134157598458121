import gql from "graphql-tag";

interface VerifyResponse {
  success: boolean;
  score: number;
}

export interface NewData {
  verifyCaptcha: VerifyResponse;
}

export interface Variables {
  token: string | null;
}

export const VERIFY_CAPTCHA = gql`
  mutation verifyCaptcha($token: String!) {
    verifyCaptcha(token: $token) {
      success
      score
    }
  }
`;
