import { Moment } from "moment";

import { Service } from "./service";
import { ProfileImage, ServiceRole } from "./profile";
import { Money, RecurringBookingSchedule } from "./booking";

export interface SupportProfessional {
  __typename: ServiceRole;
  id: string;
  acceptsFastBookings: boolean;
  acceptsTransferBookings: boolean;
  addressCity: string | null;
  availabilities: Array<Availability>;
  bookingSchedules?: RecurringBookingSchedule[];
  certifications: Array<Certification>;
  description: string;
  employmentExperience: Array<EmploymentExperience>;
  interests: Array<Interest>;
  languageAbilities: Array<LanguageAbility>;
  location: string;
  name: string;
  profileImage?: ProfileImage;
  serviceOfferings: Array<ServiceOffering>;
  shortDescription: string;
  skills: Array<Skill>;
  stripeConnectAccount?: {
    id: string;
    stripeAccountId: string;
    payoutsEnabled: boolean;
    requirementsOutstanding: boolean;
    pendingVerification: boolean;
    bankAccountAdded: boolean;
  };
  stripeConnectAccountId: string | null;
  title: string;
  virtualOnly: boolean;
  wwcc: Array<WWCC>;
}

export interface LanguageAbility {
  id: string;
  language: string;
  level: string;
}

export interface WWCC {
  id: string;
  number: string;
  verificationDate: Date;
  expiryDate: Date;
}

export interface Availability {
  id: string;
  available: boolean;
  dayNumber: number;
}

export interface ServiceOffering {
  id: string;
  enabled: boolean;
  eveningRate: Money;
  inPerson: boolean;
  nightRate: Money;
  minDurationMins: number;
  publicHolidayRate: Money;
  ratePeriod: string;
  saturdayRate: Money;
  service: Pick<Service, "id" | "name" | "virtual" | "slug" | "category">;
  sundayRate: Money;
  virtual: boolean;
  weekdayRate: Money;
}

export interface ServiceOfferingInput
  extends Exclude<Partial<ServiceOffering>, "service" | "id"> {
  serviceId: string;
}

export interface Certification {
  id: string;
  type: string;
  title: string;
}

export interface Skill {
  id: string;
  name: string;
}

export interface Interest {
  id: string;
  name: string;
}

export interface EmploymentExperience {
  id: string;
  title: string;
  company: string;
  volunteer: boolean;
  startDate: string | Moment;
  endDate: string | Moment;
  present: boolean;
  delete?: boolean;
}

export enum RatePenalty {
  WEEKDAY = "weekdayRate",
  EVENING = "eveningRate",
  NIGHT = "nightRate",
  SATURDAY = "saturdayRate",
  SUNDAY = "sundayRate",
  PUBLIC_HOLIDAY = "publicHolidayRate",
}
