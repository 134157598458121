import React from "react";
import moment from "moment";

import { BookingState, Booking } from "../../types/booking";
import { Button } from "../layout/Button";
import { Icon } from "../layout/Icons";

export const VirtualBookingInfo = ({
  booking,
}: {
  booking: Pick<Booking, "id" | "endTime" | "startTime" | "state">;
}) => {
  const activeStates = [
    BookingState.ACCEPTED_BY_SUPPORT_WORKER,
    BookingState.ACCEPTED_IN_PENALTY_PERIOD,
  ];

  const hasStarted = moment(booking.startTime).isBefore(moment());
  const hasFinished = moment(booking.endTime).isBefore(moment());
  const durationUntilStart = moment.duration(
    moment(booking.startTime).diff(moment())
  );

  const timeUntilStart = () => {
    const days = durationUntilStart.days();
    const hours = durationUntilStart.hours();
    const minutes = durationUntilStart.minutes();

    if (days) {
      return days + ` day${days > 1 ? "s" : ""}`;
    }
    if (hours) {
      return (
        hours +
        ` hour${hours > 1 ? "s" : ""}${
          minutes > 10 ? ", " + minutes + " minutes" : ""
        }`
      );
    }
    if (minutes) {
      return minutes + ` minute${minutes > 1 ? "s" : ""}`;
    }

    return `1 minute`;
  };

  const inProgress = hasStarted && !hasFinished;
  const aboutToStart =
    durationUntilStart < moment.duration(15, "minutes") &&
    durationUntilStart > moment.duration(0);

  const bookingTenseMessage = `This ${
    inProgress && activeStates.includes(booking.state)
      ? " virtual booking is happening now"
      : ` ${hasFinished ? "was" : "is"} a virtual booking`
  }`;

  const alert =
    (inProgress || aboutToStart) && activeStates.includes(booking.state);

  return (
    <>
      <div className="bg-gray-100 sm:rounded-lg">
        <div
          className={`px-4 py-3 sm:px-6 sm:py-4 ${
            alert ? "rounded-lg border-teal-600 border-2 shadow" : ""
          }`}
        >
          <div className="flex">
            {alert && (
              <Icon name="alarm" className="w-10 h-10 text-teal-700 mr-4" />
            )}
            <h3
              className={`text-lg leading-6 font-${
                alert ? "bold" : "medium"
              } text-gray-900 mb-2`}
            >
              {bookingTenseMessage}
            </h3>
          </div>
          <div className="max-w-xl text-base leading-snug text-gray-700 font-bold">
            <p>
              {!hasStarted &&
                activeStates.includes(booking.state) &&
                `It will be starting in ${timeUntilStart()}`}
            </p>
          </div>
          {activeStates.includes(booking.state) &&
            (aboutToStart || inProgress ? (
              <Button to={`/bookings/${booking.id}/video`} className="mt-4">
                Go to booking room
              </Button>
            ) : (
              <p className="mt-2 italic text-gray-600">
                {!hasFinished &&
                  "A link to the booking room will be available here closer to the start time"}
              </p>
            ))}
        </div>
      </div>
    </>
  );
};
