import React from "react";
import { Label, Popup, Icon } from "semantic-ui-react";
import moment from "moment";
import { Link } from "@reach/router";

import { BookingState, Booking } from "../../../types/booking";
import { IUserContext, ServiceRole } from "../../../types/profile";

export const AwaitingApprovalLabel = ({
  booking,
  name,
  user,
  bookingStateLabel,
  link,
}: {
  booking: Pick<Booking, "endTime" | "state">;
  name: string;
  user: Pick<IUserContext, "__typename">;
  bookingStateLabel: string;
  link?: string;
}) => {
  let message;
  const currentUserIsSP = user.__typename === ServiceRole.SUPPORT_WORKER;

  // Personalise awaiting approval from message
  if (booking.state === BookingState.COMPLETED) {
    bookingStateLabel += currentUserIsSP ? name : "you";
    message = "Please approve this completed booking";
  }

  let content = (
    <Label color="yellow" basic={true}>
      {bookingStateLabel}
    </Label>
  );

  const popup = (
    <Popup trigger={content} content={message} position="top left" />
  );

  if (link && !currentUserIsSP) return <Link to={link}>{popup}</Link>;
  if (link) return <Link to={link}>{content}</Link>;

  return (
    <>
      <p className="mb-4 italic">
        <Icon name="check" color="green" />
        Booking completed on {moment(booking.endTime).format("dddd Do MMMM")}
      </p>
      <Label basic color="orange">
        <Icon name="clock outline" color="orange" />
        {bookingStateLabel}
      </Label>
    </>
  );
};
