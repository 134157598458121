import React from "react";
import { Formik } from "formik";
import { Link } from "@reach/router";

import { EndUser } from "../../../types/endUser";
import defaultAvatar from "../../../assets/images/default-avatar-image.png";
import { InputField as Input } from "../../layout/forms";
import { useLockBody, useWindowSize } from "../../../hooks";
import { Icon } from "../../layout/Icons";
import { SupportProfessional } from "../../../types/supportProfessional";

export const SelectClientModal = ({
  clients,
  setClient,
  supportProfessional,
}: {
  clients: Pick<
    EndUser,
    "id" | "name" | "profileImage" | "permissions" | "addressCity"
  >[];
  setClient: (clientId: string) => void;
  supportProfessional: Pick<SupportProfessional, "id">;
}) => {
  useLockBody();
  const { windowWidth } = useWindowSize();

  return (
    <Formik initialValues={{ search: "" }} onSubmit={() => {}}>
      {(props) => {
        const { values } = props;

        const bookableClients = clients.filter((client) => {
          return (
            client.permissions &&
            client.permissions.booking &&
            client.name.toLowerCase().includes(values.search.toLowerCase())
          );
        });

        const numUnbookableClients = clients.filter(
          (client) => client.permissions && !client.permissions.booking
        ).length;

        const limit = windowWidth > 640 ? 6 : 4;

        let noClientsMessage =
          "None of your clients are able to make bookings yet. Update their details to enable this.";
        if (clients.length === 0) {
          noClientsMessage =
            "You don't have any clients yet. Add or invite somebody to tappON and start making bookings.";
        }
        if (values.search) {
          noClientsMessage =
            "No clients with booking permissions match that search";
        }

        return (
          <div
            id="select-client-modal"
            className="fixed z-10 inset-0 overflow-y-auto z-30"
          >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
              &#8203;
              <div
                id="select-client-panel"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white">
                  <Link
                    to={`/worker/profile/${supportProfessional.id}`}
                    className="text-teal-600 font-semibold hover:text-teal-900 flex items-center px-4 sm:px-0"
                  >
                    <Icon name="chevron" className="w-5 mr-1" rotate="90" />
                    <span>Back</span>
                  </Link>

                  <div className="mx-auto py-6 px-4 max-w-screen-xl">
                    <div className="">
                      <div className="md:max-w-xl lg:max-w-3xl xl:max-w-none sm:p-6">
                        <h2 className="text-2xl leading-9 font-extrabold tracking-tight sm:text-4xl mb-8">
                          Who is this booking for?
                        </h2>
                        <Input
                          icon={{ name: "search", size: 3 }}
                          name="search"
                          placeholder={`Search ${bookableClients.length} clients`}
                          className="mb-4"
                          noLabel
                        />
                      </div>

                      {bookableClients.length > 0 ? (
                        <ul className="grid grid-cols-2 sm:grid-cols-3 sm:gap-y-4 sm:space-y-0 lg:grid-cols-3">
                          {bookableClients.slice(0, limit).map((client) => {
                            return (
                              <li
                                key={client.id}
                                className="p-5 lg:hover:bg-gray-100 cursor-pointer rounded-lg"
                                onClick={() => setClient(client.id)}
                              >
                                <div className="space-y-4">
                                  <img
                                    className="client-avatar"
                                    src={
                                      (client.profileImage &&
                                        client.profileImage.med) ||
                                      defaultAvatar
                                    }
                                    alt={`Selected client (${client.name})`}
                                  />

                                  <div className="selected-client space-y-2">
                                    <h4 className="truncate pb-1 mb-0">
                                      {client.name}
                                    </h4>
                                    <p>{client.addressCity}</p>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <p className="px-8">{noClientsMessage}</p>
                      )}

                      <div className="flex flex-col justify-center items-center mt-6">
                        {numUnbookableClients > 0 && (
                          <p className="text-gray-500 mt-8 mb-4">
                            <span className="font-bold">
                              {numUnbookableClients}
                            </span>{" "}
                            client
                            {numUnbookableClients > 1
                              ? "s do not have "
                              : " does not have "}
                            booking permissions
                          </p>
                        )}
                        <Link to="/clients">Manage Clients</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
