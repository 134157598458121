import gql from "graphql-tag";

import { EndUser } from "../../types/endUser";
import { SupportCoordinator } from "../../types/supportCoordinator";
import { END_USER_FIELDS, SUPPORT_COORDINATOR_FIELDS } from "../fragments";

export interface Data {
  endUser: EndUser;
  supportCoordinator: SupportCoordinator;
}

export interface Variables {
  profileId: string;
}

export const END_USER_OR_SUPPORT_COORDINATOR = gql`
  ${END_USER_FIELDS}
  ${SUPPORT_COORDINATOR_FIELDS}

  query GetEndUserOrSupportCoordinator($profileId: ID!) {
    endUser(id: $profileId) {
      ...EndUserFields
    }

    supportCoordinator(id: $profileId) {
      ...SupportCoordinatorFields
    }
  }
`;
