import React from "react";
import { AddToast } from "react-toast-notifications";

import { SupportCoordinator } from "../../../../types/supportCoordinator";
import { Avatar } from "../../../layout/Avatar";
import { UpdateProfileImageForm } from "./UpdateProfileImageForm";
import { DescriptionForm } from "./DescriptionForm";
import { IUserContext } from "../../../../types/profile";

export interface SupportCoordinatorProfileSettingsProps {
  supportCoordinator: Pick<
    SupportCoordinator,
    "id" | "profileImage" | "__typename" | "about"
  >;
  add: AddToast;
  user: Pick<IUserContext, "id">;
}

export const SupportCoordinatorProfileSettings = ({
  supportCoordinator,
  add,
  user,
}: SupportCoordinatorProfileSettingsProps) => {
  return (
    <>
      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="photo"
          className="block text-sm leading-5 font-medium text-gray-700"
        >
          Profile Image
        </label>
        <div className="mt-2 sm:mt-0 sm:col-span-2">
          <div className="flex items-center">
            <Avatar user={supportCoordinator} size="md" />
          </div>
        </div>
      </div>
      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <UpdateProfileImageForm user={user} profile={supportCoordinator} />
      </div>
      <DescriptionForm profile={supportCoordinator} user={user} />
    </>
  );
};
