import gql from "graphql-tag";

import { SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";
import { Options } from "./paginatedSupportProfessionalsData";

// Uses Data from ../paginatedSupportProfessionalsData.ts

export interface Variables {
  serviceSlug: string;
  meetAndGreetFilter?: boolean;
  endUserId: string | null;
  options?: Options;
}

// ServicePage
export const PAGINATED_SUPPORT_PROFESSIONALS_BY_SERVICE_TYPE = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}

  query GetSupportProfessionalsByServiceType(
    $serviceSlug: String!
    $endUserId: ID
    $meetAndGreetFilter: Boolean
    $options: Options
  ) {
    paginatedSupportProfessionals: supportProfessionalsByServiceType(
      serviceSlug: $serviceSlug
      endUserId: $endUserId
      meetAndGreetFilter: $meetAndGreetFilter
      options: $options
    ) {
      supportProfessionals {
        ...SupportProfessionalFields
      }
      total
    }
  }
`;
