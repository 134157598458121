import React from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { DocumentNode } from "graphql";

import { ServiceRole, IUserContext } from "../../types/profile";
import { InboxLayout } from "./InboxLayout";
import { Loading } from "../layout/Loading";
import { Redirect } from "@reach/router";
import { Conversation } from "../../types/message";
import { SupportCoordinator } from "../../types/supportCoordinator";
import { Viewer } from "../../types/viewer";
import { ErrorPage } from "../layout/ErrorPage";
import {
  SUPPORT_PROFESSIONAL,
  Data as SPData,
  Variables as SPVariables,
} from "../../graphql/queries/supportProfessional";
import {
  SUPPORT_COORDINATOR_NEW_CONVERSATION_WITH_CLIENT_QUERY,
  Data as SCClientData,
  Variables,
} from "../../graphql/queries/supportCoordinatorNewConversationWithClient";
import {
  END_USER_OR_SUPPORT_COORDINATOR,
  Data as EUSCData,
  Variables as EUSCVariables,
} from "../../graphql/queries/endUserOrSupportCoordinator";

export type UserT = Pick<IUserContext, "id" | "__typename">;

export const NewConversationPage = ({
  userId,
  user,
  clientId,
  conversations,
  viewer,
  EUSupportCoordinator,
  refetchQuery,
}: {
  userId: string;
  user: UserT;
  clientId: string | null;
  conversations: Pick<
    Conversation,
    "sender" | "endUserId" | "messages" | "lastUpdated" | "slug"
  >[];
  viewer: Pick<Viewer, "id" | "role">;
  EUSupportCoordinator?: Pick<
    SupportCoordinator,
    "firstName" | "lastName"
  > | null;
  refetchQuery: DocumentNode;
}) => {
  const query = {
    [ServiceRole.END_USER]: SUPPORT_PROFESSIONAL,
    [ServiceRole.SUPPORT_COORDINATOR]: clientId
      ? SUPPORT_COORDINATOR_NEW_CONVERSATION_WITH_CLIENT_QUERY
      : SUPPORT_PROFESSIONAL,
    [ServiceRole.SUPPORT_WORKER]: END_USER_OR_SUPPORT_COORDINATOR,
  };

  const variables =
    user.__typename === ServiceRole.SUPPORT_COORDINATOR && !!clientId
      ? {
          profileId: userId,
          clientId: clientId,
        }
      : {
          profileId: userId,
        };

  const { data, error, loading } = useQuery<
    Partial<SCClientData & SPData & EUSCData>,
    Variables | SPVariables | EUSCVariables
  >(query[user.__typename], { variables });

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  const newConversationRecipient =
    data.supportProfessional || data.endUser || data.supportCoordinator;

  const { supportCoordinatorClient } = data;

  if (!newConversationRecipient || (clientId && !supportCoordinatorClient)) {
    return <Redirect noThrow to="/messages" />;
  }

  const newConversation = {
    sender: newConversationRecipient,
    endUserId: supportCoordinatorClient && supportCoordinatorClient.id,
    endUser: supportCoordinatorClient,
    messages: [],
    lastUpdated: moment().toDate(),
    slug: `${userId}&${clientId}`,
  };

  return (
    <InboxLayout
      user={user}
      conversations={conversations}
      currentConversation={newConversation}
      refetchQuery={refetchQuery}
      viewer={viewer}
      focusConversation={true}
      EUSupportCoordinator={EUSupportCoordinator}
    />
  );
};
