import gql from "graphql-tag";

import { EndUser } from "../../types/endUser";

export interface Data {
  endUser: Required<Pick<EndUser, "permissions">>;
}

export interface Variables {
  endUserId: string;
}

export const END_USER_PERMISSIONS_QUERY = gql`
  query EndUserPermissionsQuery($endUserId: ID!) {
    endUser(id: $endUserId) {
      id
      permissions {
        booking
        message
        invoice
      }
    }
  }
`;
