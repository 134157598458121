import React from "react";

import { IUserContext } from "../../../../types/profile";
import { EndUser } from "../../../../types/endUser";
import wavingHand from "../../../../assets/images/waving-hand-sign.png";
import { Service } from "../../../../types/service";
import { UpdateProfileImageForm } from "../../../pages/settings/profile/UpdateProfileImageForm";
import { Avatar } from "../../../layout/Avatar";
import { Buttons } from "./Buttons";

export interface FormValues {
  about: string;
}

export type EndUserT = Pick<
  EndUser,
  | "id"
  | "servicePreferences"
  | "supportHoursPerWeek"
  | "addressCity"
  | "name"
  | "about"
  | "profileImage"
  | "__typename"
>;

export const CompleteProfilePanel4 = ({
  endUser,
  hideModal,
  setPanel,
  user,
}: {
  endUser: EndUserT;
  hideModal: () => void;
  setPanel: (number: number) => void;
  services: Service[];
  user: Pick<IUserContext, "id" | "__typename" | "profileImage">;
}) => {
  const [firstName] = endUser.name.split(" ");

  return (
    <div className="">
      <div className="">
        <div className="pt-6 sm:pb-6 md:pb-10 flex flex-col items-start sm:items-center">
          <div className="flex items-center mb-4">
            <img
              src={wavingHand}
              className="w-8 h-8 mr-3"
              alt="waving hand emoji"
            />
            <h3 className="text-3xl font-medium leading-6 text-gray-900 mb-0">
              Hi {firstName}
            </h3>
          </div>
          <p className="mt-1 text-xl text-gray-500">Upload a profile image</p>
        </div>
        <div className="">
          <div className="">
            <div className="mt-3 flex justify-center mb-6">
              <div className="mt-2 sm:mt-0">
                <div className="flex items-center">
                  <Avatar user={endUser} size="md" />
                </div>
              </div>
            </div>
            <div className="sm:min-w-88">
              <UpdateProfileImageForm user={user} profile={endUser} />
            </div>
          </div>
          <Buttons
            panel={4}
            setPanel={setPanel}
            isSubmitting={false}
            submit={false}
            hideModal={hideModal}
            back={false}
          />
        </div>
      </div>
    </div>
  );
};
