import { ratePeriodMap } from "../../users/profileHelpers";

import { PaymentFlow, DeliveryType, DayName } from "../../../types/booking";
import {
  RatePenalty,
  ServiceOffering,
  SupportProfessional,
} from "../../../types/supportProfessional";

// Using string instead of numbers for hours/minutes dropdowns as formik somehow seems to cast them to strings and causes issues with type coersion.
// https://github.com/formium/formik/issues/1191
export interface FormValues {
  date: string | undefined;
  recurring: boolean;
  interval: "1" | "2";
  service: string;
  startMinutes: string;
  startHours: string;
  deliveryType: DeliveryType;
  durationHours: string;
  durationMinutes: string;
  specialInstructions: string;
  terms: boolean;
  payment: PaymentFlow | "";
  clientId: string | undefined;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  until: string | undefined;
}

export const initialValues = ({
  deliveryType,
}: {
  deliveryType: DeliveryType | undefined;
}): FormValues => {
  return {
    date: undefined,
    interval: "1",
    recurring: false,
    service: "",
    startMinutes: "0",
    startHours: "10",
    deliveryType: deliveryType || DeliveryType.IN_PERSON,
    durationHours: "60",
    durationMinutes: "0",
    specialInstructions: "",
    terms: false,
    payment: "",
    clientId: undefined,
    [DayName.MON]: false,
    [DayName.TUE]: false,
    [DayName.WED]: false,
    [DayName.THU]: false,
    [DayName.FRI]: false,
    [DayName.SAT]: false,
    [DayName.SUN]: false,
    until: undefined,
  };
};

export const dayOptions = [
  { name: DayName.MON, label: "Mon", dayNumber: 1 },
  { name: DayName.TUE, label: "Tue", dayNumber: 2 },
  { name: DayName.WED, label: "Wed", dayNumber: 3 },
  { name: DayName.THU, label: "Thu", dayNumber: 4 },
  { name: DayName.FRI, label: "Fri", dayNumber: 5 },
  { name: DayName.SAT, label: "Sat", dayNumber: 6 },
  { name: DayName.SUN, label: "Sun", dayNumber: 0 },
];

export const durationHoursOptions = [
  { text: "0 hours", value: "0" },
  { text: "1 hour", value: "60" },
  { text: "2 hours", value: "120" },
  { text: "3 hours", value: "180" },
  { text: "4 hours", value: "240" },
  { text: "5 hours", value: "300" },
  { text: "6 hours", value: "360" },
  { text: "7 hours", value: "420" },
  { text: "8 hours", value: "480" },
];

export const durationMinutesOptions = [
  { text: "0 minutes", value: "0" },
  { text: "15 minutes", value: "15" },
  { text: "30 minutes", value: "30" },
  { text: "45 minutes", value: "45" },
];

export const hoursOptions = [
  { text: "Midnight", value: "0" },
  { text: "1 am", value: "1" },
  { text: "2 am", value: "2" },
  { text: "3 am", value: "3" },
  { text: "4 am", value: "4" },
  { text: "5 am", value: "5" },
  { text: "6 am", value: "6" },
  { text: "7 am", value: "7" },
  { text: "8 am", value: "8" },
  { text: "9 am", value: "9" },
  { text: "10 am", value: "10" },
  { text: "11 am", value: "11" },
  { text: "Noon", value: "12" },
  { text: "1 pm", value: "13" },
  { text: "2 pm", value: "14" },
  { text: "3 pm", value: "15" },
  { text: "4 pm", value: "16" },
  { text: "5 pm", value: "17" },
  { text: "6 pm", value: "18" },
  { text: "7 pm", value: "19" },
  { text: "8 pm", value: "20" },
  { text: "9 pm", value: "21" },
  { text: "10 pm", value: "22" },
  { text: "11 pm", value: "23" },
];

export const minutesOptions = [
  { text: "00", value: "0" },
  { text: "15", value: "15" },
  { text: "30", value: "30" },
  { text: "45", value: "45" },
];

export const deliveryTypeOptions = {
  inPerson: { text: "In Person", value: DeliveryType.IN_PERSON },
  virtual: { text: "Virtual", value: DeliveryType.VIRTUAL },
};

export const paymentFlowOptions = {
  creditCard: { text: "Credit Card", value: "CREDIT_CARD" },
  invoice: { text: "Request Invoice", value: "INVOICE" },
};

export const createServiceOptions = ({
  supportProfessional,
  rate,
}: {
  supportProfessional: Pick<SupportProfessional, "serviceOfferings">;
  rate: RatePenalty;
}) => {
  return supportProfessional.serviceOfferings.map((serviceOffering) => {
    const amount = serviceOffering[rate]
      ? serviceOffering[rate].amount
      : serviceOffering.weekdayRate.amount;
    const price = `($${(amount / 100).toFixed(2)} / ${
      serviceOffering.service.name === "Meet and Greet"
        ? "15 minutes"
        : ratePeriodMap[serviceOffering.ratePeriod]
    })`;
    return {
      key: serviceOffering.id,
      text: `${serviceOffering.service.name} ${price}`,
      value: serviceOffering.id,
      virtual: serviceOffering.virtual,
      enabled: serviceOffering.enabled,
    };
  });
};

export const createPaymentOptions = ({
  supportProfessional,
}: {
  supportProfessional: Pick<
    SupportProfessional,
    "acceptsFastBookings" | "acceptsTransferBookings"
  >;
}) => {
  const options = [] as { text: string; value: string }[];
  if (supportProfessional.acceptsFastBookings)
    options.push(paymentFlowOptions.creditCard);
  if (supportProfessional.acceptsTransferBookings)
    options.push(paymentFlowOptions.invoice);

  return options;
};

export const createDeliveryTypeOptions = ({
  serviceOffering,
}: {
  serviceOffering: ServiceOffering;
}) => {
  const options = [] as { text: string; value: DeliveryType }[];
  if (serviceOffering.virtual) options.push(deliveryTypeOptions.virtual);
  if (serviceOffering.inPerson) options.push(deliveryTypeOptions.inPerson);

  return options;
};

export interface DropdownOption {
  text: string;
  value: string;
}
