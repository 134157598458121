import React from "react";
import moment from "moment";

import defaultAvatar from "../../../assets/images/default-avatar-image.png";
import { EndUserT } from "./EndUserProfilePage";

export interface EndUserDetailProps {
  endUser: EndUserT;
}

export const EndUserDetail = ({ endUser }: EndUserDetailProps) => {
  const age = moment().diff(moment(endUser.dateOfBirth), "years");

  return (
    <div className="sm:py-8">
      <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
        {/* <!-- Image --> */}
        <img
          className="inset-0 object-cover w-56 m-auto sm:m-0 sm:w-full shadow-lg rounded-lg xl:w-96 block mb-7"
          src={
            (endUser.profileImage && endUser.profileImage.large) ||
            defaultAvatar
          }
          alt=""
        />
        <div className="sm:col-span-2">
          <div className="space-y-8">
            <div className="leading-6 font-medium space-y-1">
              <h4 className="text-center text-3xl sm:text-2xl sm:text-left">
                {endUser.name}
              </h4>
            </div>

            {endUser.addressCity && (
              <div className="">
                <dt className=" leading-5 font-medium text-gray-500">
                  Location
                </dt>
                <dd className="mt-1  leading-5 text-gray-900">
                  {endUser.addressCity}
                </dd>
              </div>
            )}

            {endUser.dateOfBirth && (
              <div className="">
                <dt className="mb-2 leading-5 font-medium text-gray-500">
                  Age
                </dt>
                <dd className="mt-1  leading-5 text-gray-900">
                  <p className="mb-2">{age} years old</p>
                  {age < 18 && (
                    <p className="bg-gray-100 border border-gray-300 font-light inline-block p-2 text-gray-500">
                      Requires a current Working with Children Check for any
                      bookings
                    </p>
                  )}
                </dd>
              </div>
            )}

            <div className="">
              <dt className="mb-2 leading-5 font-medium text-gray-500">
                About me
              </dt>
              <dd className="mt-1  leading-5 text-gray-900 whitespace-pre-line">
                {endUser.about || "No description added yet"}
              </dd>
            </div>

            {endUser.servicePreferences.length > 0 && (
              <div className="">
                <dt className="mb-2 leading-5 font-medium text-gray-500">
                  Looking for support with
                </dt>
                <dd className="mt-1  leading-5 text-gray-900">
                  <ul className="mt-1 -ml-1">
                    {endUser.servicePreferences.map((s) => (
                      <span
                        key={s.id}
                        className="inline-flex items-center px-2.5 py-1 rounded-md  font-medium leading-5 bg-teal-100 text-teal-800 mr-2"
                      >
                        {s.service.name}
                      </span>
                    ))}
                  </ul>
                </dd>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
