import React, { useRef, useEffect } from "react";
import { RemoteParticipant, LocalParticipant } from "twilio-video";

import { IVideoTrack } from "./Publication";

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  participant: RemoteParticipant | LocalParticipant;
}
export function VideoTrack({ track, isLocal, participant }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    track.attach(el);
    return () => {
      track.detach(el);
    };
  }, [track]);

  // The local video track is mirrored.
  const isFrontFacing =
    track.mediaStreamTrack.getSettings().facingMode !== "environment";
  const style =
    isLocal && isFrontFacing ? { transform: "rotateY(180deg)" } : {};

  return <video ref={ref} style={style} />;
}
