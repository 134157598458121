import React from "react";

export const LoadingDots = ({
  className,
  width,
  height,
}: {
  className?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width || "50"}
      height={height || "30"}
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current text-teal-600 ${className || ""}`}
    >
      <circle cx="15" cy="15" r="15">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0s"
          dur="0.9s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.9s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="60" cy="15" r="9" fillOpacity="0.3">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0.3s"
          dur="0.9s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="0.5"
          to="0.5"
          begin="0.3s"
          dur="0.9s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0.6s"
          dur="0.9s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="1s"
          dur="0.9s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};
