import React from "react";
import * as Yup from "yup";
import { Input, Form, TextArea } from "formik-semantic-ui";
import { RouteComponentProps, navigate } from "@reach/router";
import { useQuery, useMutation } from "@apollo/client";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { ErrorPage } from "../../layout/ErrorPage";
import { PageWrapper } from "../../../styles/Styled";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData,
  Variables as MutationVariables,
} from "../../../graphql/mutations/updateSupportProfessional";
import {
  SUPPORT_PROFESSIONAL,
  Data,
  Variables,
} from "../../../graphql/queries/supportProfessional";
import { Button } from "../../layout/Button";

interface EditProfilePageProps extends RouteComponentProps {
  profileId?: string;
}

export const EditSupportProfessionalPage = ({
  profileId,
}: EditProfilePageProps) => {
  const [updateSupportWorker] = useMutation<NewData, MutationVariables>(
    UPDATE_SUPPORT_PROFESSIONAL
  );

  const { data, error, loading } = useQuery<Data, Variables>(
    SUPPORT_PROFESSIONAL,
    {
      variables: {
        profileId: profileId || "",
      },
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  const { supportProfessional } = data;

  const inputValidation = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    shortDescription: Yup.string()
      .required("Short Description is required")
      .min(10, "Minimum of 10 characters is required")
      .max(200, "Maximum of 200 characters have been exceeded"),
    description: Yup.string()
      .required("Description is required")
      .min(50, "Minimum of 50 characters is required")
      .max(1000, "Maximum of 1000 characters have been exceeded"),
  });

  return profileId ? (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        const handleSubmit = (
          values: {
            description: string;
            title: string;
            shortDescription: string;
          },
          formikApi: any
        ) => {
          const variables = {
            id: supportProfessional.id,
            description: values.description,
            title: values.title,
            shortDescription: values.shortDescription,
          };
          updateSupportWorker({
            variables,
          })
            .then((res) => {
              formikApi.setSubmitting(false);
              navigate(`/worker/profile/${profileId}`);
            })
            .catch((err) => {
              formikApi.setSubmitting(false);
              add(err.message, {
                appearance: "error",
                autoDismiss: true,
              });
            });
        };
        return (
          <PageWrapper>
            <Form
              initialValues={supportProfessional}
              onSubmit={handleSubmit}
              validationSchema={inputValidation}
            >
              {(props) => {
                return (
                  <>
                    <h1>Edit Profile</h1>
                    <Input label="Title" name="title" />
                    <TextArea
                      label="Short Description"
                      name="shortDescription"
                    />
                    <TextArea label="About Me" name="description" />

                    <div className="flex">
                      <Button type="submit" className="mr-4">
                        Save
                      </Button>
                      <Button
                        type="button"
                        secondary
                        color="gray"
                        to={`/worker/profile/${profileId}`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                );
              }}
            </Form>
          </PageWrapper>
        );
      }}
    </ToastConsumer>
  ) : (
    <h1>Profile does not exist</h1>
  );
};
