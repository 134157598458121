import gql from "graphql-tag";

import { SupportCoordinator } from "../../types/supportCoordinator";

export interface NewData {
  updateSupportCoordinator: SupportCoordinator;
}

export interface Variables {
  id: string;
  about?: string | null;
}

export const UPDATE_SUPPORT_COORDINATOR = gql`
  mutation updateSupportCoordinator($id: ID!, $about: String) {
    updateSupportCoordinator(id: $id, input: { about: $about }) {
      id
      about
    }
  }
`;
