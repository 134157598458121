import React, { useState } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { useQuery } from "@apollo/client";
import { Formik } from "formik";

import { IUserContext } from "../../../types/profile";
import { EndUser } from "../../../types/endUser";
import {
  SUPPORT_COORDINATOR_CLIENTS,
  Data,
  Variables,
} from "../../../graphql/queries/supportCoordinatorClients";
import { ErrorPage } from "../../layout/ErrorPage";
import { Loading } from "../../layout/Loading";
import { Avatar } from "../../layout/Avatar";
import { Icon } from "../../layout/Icons";
import { InputField as Input } from "../../layout/forms";
import { Button } from "../../layout/Button";
import { AddClientModal } from "./AddClientModal";

export interface ManageClientsPageProps extends RouteComponentProps {
  user: Pick<IUserContext, "id">;
}

export const ManageClientsPage = ({ user }: ManageClientsPageProps) => {
  const [addClientModalVisible, setAddClientModalVisible] = useState(false);

  const { data, loading, error, refetch: refetchClients } = useQuery<
    Data,
    Variables
  >(SUPPORT_COORDINATOR_CLIENTS, { variables: { id: user.id } });

  if (error) {
    return <ErrorPage error={error.message} location="ManageClientsPage:17" />;
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  const { clients } = data;

  const showAddClientModal = () => {
    setAddClientModalVisible(true);
  };

  const hideAddClientModal = () => {
    setAddClientModalVisible(false);
  };

  return (
    <>
      {addClientModalVisible && (
        <AddClientModal
          hideModal={hideAddClientModal}
          user={user}
          refetch={refetchClients}
        />
      )}
      <Formik initialValues={{ search: "" }} onSubmit={() => {}}>
        {(props) => {
          const { values } = props;

          const filteredClients = clients.filter((client) => {
            return client.name
              .toLowerCase()
              .includes(values.search.toLowerCase());
          });
          return (
            <div className="mx-4 sm:mx-20 mt-12 mb-6">
              <div className="pb-5 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
                <h3 className="leading-6 font-medium text-gray-900 mb-0">
                  Your Clients
                </h3>
                <div className="flex items-center mb-4 w-full sm:w-auto justify-between">
                  <div>
                    <label htmlFor="search_clients" className="sr-only">
                      Search
                    </label>
                    <div className="flex rounded-md">
                      <div className="relative flex-grow focus-within:z-10">
                        <Input
                          icon={{ name: "search", size: 3 }}
                          name="search"
                          placeholder="Search clients"
                          noLabel
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    className="flex flex-none justify-center py-2 ml-2"
                    size="small"
                    onClick={showAddClientModal}
                  >
                    Add New Client
                  </Button>
                </div>
              </div>

              <ClientList
                showAddClientModal={showAddClientModal}
                clients={filteredClients}
              />
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export const ClientList = ({
  clients,
  showAddClientModal,
}: {
  clients: Pick<
    EndUser,
    "id" | "profileImage" | "name" | "permissions" | "addressCity"
  >[];
  showAddClientModal: () => void;
}) => {
  return (
    <div className="bg-white overflow-hidden sm:rounded-md border border-gray-200">
      <ul>
        {clients.length > 0 ? (
          clients.map((client, index) => {
            const bookingsEnabled =
              client.permissions && client.permissions.booking;

            const invoiceEnabled =
              client.permissions && client.permissions.invoice;

            return (
              <li
                key={client.id}
                className={`${index !== 0 ? "border-t border-gray-100" : ""}`}
              >
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <Avatar size="sm" user={client} />
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div className="flex flex-col justify-center">
                        <div className="text-sm leading-5 font-medium text-gray-800 truncate">
                          {client.name}
                        </div>
                        <div className="flex items-center text-sm">
                          <p className="text-gray-500 text-sm">
                            {client.addressCity}
                          </p>
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <div
                            className={`mt-2 flex items-center text-sm leading-5 ${
                              bookingsEnabled
                                ? "text-green-700"
                                : "text-gray-500"
                            }`}
                          >
                            <Icon
                              name={bookingsEnabled ? "checkCircle" : "pending"}
                              className={`flex-shrink-0 mr-1.5 h-5 w-5 text-${
                                bookingsEnabled ? "green" : "gray"
                              }-400`}
                            />
                            Bookings{" "}
                            {bookingsEnabled ? "enabled" : "not yet enabled"}
                          </div>
                          <div
                            className={`mt-2 flex items-center text-sm leading-5 text-${
                              invoiceEnabled ? "green-700" : "gray-500"
                            }`}
                          >
                            <Icon
                              name={invoiceEnabled ? "checkCircle" : "pending"}
                              className={`flex-shrink-0 mr-1.5 h-5 w-5 text-${
                                invoiceEnabled ? "green" : "gray"
                              }-400`}
                            />
                            Invoice Requests{" "}
                            {invoiceEnabled ? "enabled" : "not yet enabled"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-x-5 sm:gap-x-10 text-sm">
                    <Link to={`/user/profile/${client.id}`}>View Profile</Link>
                    <Link to={`/user/profile/${client.id}/manage`}>Manage</Link>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <>
            <PlaceholderClient />
            <PlaceholderClient className="border-t opacity-75" />
            <PlaceholderClient className="border-t opacity-50" />
            <li className="border-t border-gray-100 p-6 text-gray-600 text-center">
              You have no clients yet.{" "}
              <button
                className="font-bold text-teal-600"
                onClick={showAddClientModal}
              >
                Add a new client
              </button>{" "}
              to get started.
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

const PlaceholderTextBlock = ({ width }: { width: number }) => (
  <span className={`bg-gray-100 w-${width} h-4 inline-block`} />
);

const PlaceholderClient = ({ className }: { className?: string }) => (
  <li className={`${className} border-gray-100`}>
    <div className="flex items-center px-4 py-4 sm:px-6">
      <div className="min-w-0 flex-1 flex items-center">
        <div className="flex-shrink-0">
          <Avatar size="sm" user={{}} className="grayscale-25" />
        </div>
        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div className="flex flex-col justify-center">
            <div className="text-sm leading-5 font-medium text-gray-800 truncate">
              <PlaceholderTextBlock width={32} />
            </div>
            <div className="flex items-center text-sm">
              <p className="text-gray-500 text-sm">
                <PlaceholderTextBlock width={48} />
              </p>
            </div>
          </div>
          <div className="hidden md:block">
            <div>
              <div
                className={`mt-2 flex items-center text-sm leading-5 text-gray-500`}
              >
                <Icon
                  name="checkCircle"
                  className={`flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400`}
                />
                <PlaceholderTextBlock width={40} />
              </div>
              <div
                className={`mt-2 flex items-center text-sm leading-5 text-gray-500`}
              >
                <Icon
                  name="checkCircle"
                  className={`flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400`}
                />
                <PlaceholderTextBlock width={40} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gap-x-5 sm:gap-x-10 text-sm text-gray-300 hidden sm:flex">
        <p>View Profile</p>
        <p>Manage</p>
      </div>
    </div>
  </li>
);
