import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { useLockBody } from "../../../../hooks";
import { IUserContext } from "../../../../types/profile";
import { EndUser } from "../../../../types/endUser";
import { CompleteProfilePanel1 as Panel1 } from "./CompleteProfilePanel1";
import { CompleteProfilePanel2 as Panel2 } from "./CompleteProfilePanel2";
import { CompleteProfilePanel3 as Panel3 } from "./CompleteProfilePanel3";
import { CompleteProfilePanel4 as Panel4 } from "./CompleteProfilePanel4";
import { AccountT } from "./CompleteProfilePrompt";
import {
  ALL_SERVICES_QUERY,
  Data,
} from "../../../../graphql/queries/allServices";
import { Loading } from "../../../layout/Loading";
import { ErrorPage } from "../../../layout/ErrorPage";

export type EndUserT = Pick<
  EndUser,
  | "id"
  | "name"
  | "addressStreet"
  | "addressCity"
  | "addressState"
  | "addressPostcode"
  | "dateOfBirth"
  | "fundingType"
  | "about"
  | "servicePreferences"
  | "supportHoursPerWeek"
  | "ndisParticipantNumber"
  | "gender"
  | "otherFundingType"
  | "__typename"
>;

export const CompleteProfileModal = ({
  hideModal,
  endUser,
  account,
  initialPanel,
  user,
}: {
  hideModal: () => void;
  endUser: EndUserT;
  account: AccountT;
  initialPanel?: 1 | 2 | 3 | 4;
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  useLockBody();

  const {
    addressStreet,
    addressCity,
    addressState,
    addressPostcode,
    dateOfBirth,
    fundingType,
    about,
  } = endUser;

  const choosePanel = () => {
    const addressComplete =
      !!addressStreet && !!addressCity && !!addressState && !!addressPostcode;

    if (!addressComplete || !dateOfBirth) {
      return 1;
    }

    if (!fundingType || !about) {
      return 2;
    }
  };

  const [panel, setPanel] = useState<number | undefined>(
    initialPanel || choosePanel()
  );

  const { data, error, loading } = useQuery<Data>(ALL_SERVICES_QUERY);

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { services } = data;

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        return (
          <div className="z-30 fixed bottom-0 inset-x-0 overflow-scroll inset-0 flex items-center justify-center">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
              className="md:mx-32 m-4 transform transition-all h-full overflow-scroll w-full block sm:flex items-center justify-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="rounded-lg shadow-xl bg-white w-full sm:w-auto px-6 py-5 mt-4 mb-16">
                {panel === 1 && (
                  <Panel1
                    endUser={endUser}
                    account={account}
                    hideModal={hideModal}
                    setPanel={(number: number) => setPanel(number)}
                    add={add}
                  />
                )}
                {panel === 2 && (
                  <Panel2
                    endUser={endUser}
                    hideModal={hideModal}
                    setPanel={(number: number) => setPanel(number)}
                    services={services}
                    add={add}
                  />
                )}
                {panel === 3 && (
                  <Panel3
                    endUser={endUser}
                    hideModal={hideModal}
                    setPanel={(number: number) => setPanel(number)}
                    services={services}
                    add={add}
                    account={account}
                  />
                )}
                {panel === 4 && (
                  <Panel4
                    endUser={endUser}
                    hideModal={hideModal}
                    setPanel={(number: number) => setPanel(number)}
                    services={services}
                    user={user}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }}
    </ToastConsumer>
  );
};
