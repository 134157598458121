import styled from "styled-components";

export const breakpoints = {
  small: "769px",
  medium: "960px",
  large: "1200px"
};

const { small } = breakpoints;

export interface PageWrapperProps {
  nomobile?: boolean;
}
export const PageWrapper = styled.div`
  padding: ${(props: PageWrapperProps) => (props.nomobile ? "0" : "20px 15px")};
  @media (min-width: ${small}) {
    padding: 30px 50px;
  }
`;
