import React from "react";
import moment from "moment";

import { Booking } from "../../../types/booking";
import { BookingList } from "../BookingList";
import { Button } from "../../layout/Button";
import { IUserContext } from "../../../types/profile";

export const BookingsOnDate = ({
  date,
  bookingDates,
  bookings,
  user,
}: {
  date: string;
  bookingDates: string[];
  bookings: Booking[];
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  const [firstBooking] = bookingDates;

  return (
    <>
      {moment().isSame(moment(date), "day") ? (
        <h3>Today's Bookings</h3>
      ) : (
        <h3>Bookings on {moment(date).format("MMMM Do")}</h3>
      )}
      <BookingList
        bookings={bookings}
        showState
        emptyStateMessage="No bookings on this date"
        user={user}
      />
      {bookings.length === 0 && bookingDates.length > 0 && (
        <div className="bg-blue-100 border border-blue-300 text-blue-900 p-6">
          <p className="">
            Your next booking is on {moment(firstBooking).format("MMMM Do")}
            <Button
              color="gray"
              size="small"
              className="ml-4"
              to={`/bookings/date/${moment(firstBooking).format("YYYY-MM-DD")}`}
            >
              View
            </Button>
          </p>
        </div>
      )}
    </>
  );
};
