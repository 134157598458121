import moment, { Moment } from "moment";
import { RRule, Weekday } from "rrule";

import {
  RatePenalty,
  ServiceOffering,
  SupportProfessional,
} from "../../../types/supportProfessional";
import { FormValues } from "./BookingFormData";
import { capitalize, getEnv } from "../../../utils/helpers";
import { EndUser } from "../../../types/endUser";
import { PublicHoliday, DayName } from "../../../types/booking";

export const setEveningRate = (
  startHours: number,
  startMinutes: number,
  durationHours: number,
  durationMinutes: number,
  service: ServiceOffering | undefined,
  rate: RatePenalty,
  setRate: (rate: RatePenalty) => void,
  date?: string,
  override?: boolean
) => {
  const weekdayRates = [RatePenalty.WEEKDAY, RatePenalty.EVENING];
  if (
    (date && weekdayRates.includes(rate)) ||
    (service && !service[rate]) ||
    override
  ) {
    const duration = (durationHours + durationMinutes) / 60;
    let defaultRate: RatePenalty;

    switch (moment(date).day()) {
      case 0:
        defaultRate = RatePenalty.SUNDAY;
        break;
      case 6:
        defaultRate = RatePenalty.SATURDAY;
        break;
      default:
        defaultRate = RatePenalty.WEEKDAY;
    }

    const startTime = startHours + startMinutes / 60;

    if (startTime + duration > 20) {
      setRate(RatePenalty.EVENING);
    } else if (startTime < 6) {
      setRate(RatePenalty.NIGHT);
    } else {
      setRate(defaultRate);
    }
  }
};

export const buildStartTime = (values: FormValues) => {
  return moment(values.date)
    .hour(parseInt(values.startHours, 10))
    .minute(parseInt(values.startMinutes, 10))
    .second(0)
    .millisecond(0);
};

export const buildEndTime = (
  startTime: Moment,
  values: FormValues,
  duration: number | null = null
) => {
  return moment(startTime).add(duration || buildDuration(values), "minutes");
};

export const buildDuration = (values: FormValues) => {
  return (
    parseInt(values.durationHours, 10) + parseInt(values.durationMinutes, 10)
  );
};

export const buildRruleText = (values: FormValues) => {
  const days = getDays(values).map((d) => capitalize(d));

  const lastDay = days.pop();
  const byDay = days.length > 0 ? days.join(", ") + " and " + lastDay : lastDay;
  return `${
    values.interval === "1" ? "Every Week" : "Every 2 Weeks"
  } on ${byDay}`;
};

export const getDays = (values: FormValues) => {
  return Object.values(DayName).filter((day) => values[day]);
};

export const upcomingRecurringDates = (
  values: FormValues,
  endUser: Pick<EndUser, "id" | "name" | "addressCity">,
  service: ServiceOffering,
  supportProfessional: Pick<
    SupportProfessional,
    | "profileImage"
    | "name"
    | "id"
    | "shortDescription"
    | "location"
    | "title"
  >
) => {
  const days = Object.values(DayName).filter((day) => values[day]);

  const dayMap: { [key in DayName]: Weekday } = {
    [DayName.MON]: RRule.MO,
    [DayName.TUE]: RRule.TU,
    [DayName.WED]: RRule.WE,
    [DayName.THU]: RRule.TH,
    [DayName.FRI]: RRule.FR,
    [DayName.SAT]: RRule.SA,
    [DayName.SUN]: RRule.SU,
  };

  const year = moment(values.date).year();
  const month = moment(values.date).month();
  const day = moment(values.date).date();
  const hour = moment(values.date).hour();
  const minute = moment(values.date).minute();

  const rule = new RRule({
    freq: RRule.WEEKLY,
    interval: parseInt(values.interval, 10),
    byweekday: days.map((d) => dayMap[d]),
    dtstart: new Date(Date.UTC(year, month, day, hour, minute)),
    until: values.until
      ? moment(values.until)
          .hours(23)
          .minutes(59)
          .toDate()
      : undefined,
  });

  return rule.between(
    moment().toDate(),
    moment(values.date)
      .add(1, "month")
      .toDate()
  );
};

export const totalCost = (
  values: FormValues,
  date: Date,
  publicHolidays: PublicHoliday[],
  service: ServiceOffering
): string => {
  const durationMins =
    parseInt(values.durationHours, 10) + parseInt(values.durationMinutes, 10);

  const rate = getRate(values, date, publicHolidays, durationMins);

  const rateAmount = service[rate]
    ? service[rate].amount
    : service.weekdayRate.amount;

  return `$${(((durationMins / 60) * rateAmount) / 100).toFixed(2)}`;
};

const getRate = (
  values: FormValues,
  date: Date,
  publicHolidays: PublicHoliday[],
  durationMins: number
) => {
  const dayNum = moment(date).weekday();

  const startTime = moment(date)
    .hours(parseInt(values.startHours, 10))
    .minutes(parseInt(values.startMinutes, 10));
  const endTime = startTime.add(durationMins, "minutes");

  const _8pm = moment(date)
    .hours(20)
    .minutes(0);
  const _6am = moment(date)
    .hours(6)
    .minutes(0);

  if (publicHolidays.find((p) => moment(p.date).isSame(moment(date), "day"))) {
    return RatePenalty.PUBLIC_HOLIDAY;
  } else if (dayNum === 0) {
    return RatePenalty.SUNDAY;
  } else if (dayNum === 6) {
    return RatePenalty.SATURDAY;
  } else if (endTime.isAfter(_8pm)) {
    return RatePenalty.EVENING;
  } else if (startTime.isBefore(_6am)) {
    return RatePenalty.NIGHT;
  } else {
    return RatePenalty.WEEKDAY;
  }
};

export const setDaysDisabled = (
  days: { name: string; label: string; dayNumber: number }[],
  values: FormValues,
  unavailableDaysOfWeek: number[]
) => {
  return days.map((d) => {
    const disabled: boolean | undefined =
      // Disable days where SP is unavailable
      unavailableDaysOfWeek.includes(d.dayNumber) ||
      // Force day to remain checked if that is the day of the week selected for `values.date`
      !!(values.date && moment(values.date).weekday() === d.dayNumber);

    return { ...d, disabled };
  });
};

export const goToStripe = (stripeCheckoutID: string): void => {
  const stripe = window.Stripe(getEnv("REACT_APP_STRIPE_PUBLIC_TOKEN"));
  stripe
    .redirectToCheckout({
      sessionId: stripeCheckoutID,
    })
    .then((result: any) => {});
};
