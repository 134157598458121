import React, { useState } from "react";
import { Formik, Form, FormikErrors, FormikHelpers } from "formik";
import { useMutation } from "@apollo/client";
import { ToastConsumer, AddToast } from "react-toast-notifications";
import { ApolloQueryResult } from "@apollo/client";

import { useLockBody, useLogger } from "../../../hooks";
import { Button } from "../../layout/Button";
import { InputField as Input, Checkbox } from "../../layout/forms";
import {
  SIGNUP_NEW_CLIENT,
  NewData,
  Variables,
} from "../../../graphql/mutations/signupNewClient";
import { IUserContext } from "../../../types/profile";
import { navigate } from "@reach/router";

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  endUserEmail: string;
  inviteEndUser: boolean;
}

export const AddClientModal = ({
  hideModal,
  user,
  refetch,
}: {
  hideModal(): void;
  user: Pick<IUserContext, "id">;
  refetch: (
    variables?: Record<string, any> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) => {
  useLockBody();
  const { log } = useLogger();
  const [signupNewClient] = useMutation<NewData, Variables>(SIGNUP_NEW_CLIENT);
  const [externalError, setExternalError] = useState("");

  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    endUserEmail: "",
    inviteEndUser: true,
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }

    if (values.inviteEndUser && !values.endUserEmail) {
      errors.endUserEmail = "Required";
    }

    return errors;
  };

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        const handleSubmit = (
          values: FormValues,
          formikHelpers: FormikHelpers<FormValues>
        ) => {
          const {
            firstName,
            lastName,
            phoneNumber,
            endUserEmail,
            inviteEndUser,
          } = values;

          const variables = {
            id: user.id,
            firstName,
            lastName,
            phoneNumber,
            email: inviteEndUser ? endUserEmail : null,
          };

          signupNewClient({ variables })
            .then(({ data }) => {
              if (data) {
                const { success, errors, endUser } = data.signupNewClient;
                formikHelpers.setSubmitting(false);
                if (success) {
                  refetch();
                  hideModal();
                  navigate(`/user/profile/${endUser.id}`);
                } else {
                  const { email, phoneNumber, external } = errors;
                  formikHelpers.setErrors({ endUserEmail: email, phoneNumber });

                  setExternalError(external || "");
                }
              }
            })
            .catch((err) => {
              formikHelpers.setSubmitting(false);
              add("There was a problem adding your client", {
                appearance: "error",
                autoDismiss: true,
              });
              log("error", err);
            });
        };
        return (
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validate={validate}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  {externalError && <p>{externalError}</p>}
                  <div className="fixed z-30 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      {/* <!-- Background overlay --> */}
                      <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
                      &#8203;
                      {/* <!-- Modal panel --> */}
                      <div
                        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                      >
                        <div>
                          <div className="">
                            <h3
                              className="text-xl leading-6 font-bold text-gray-900 text-left"
                              id="modal-headline"
                            >
                              Add a new client
                            </h3>
                            <div className="mt-4 grid grid-cols-2 gap-x-2">
                              <Input name="firstName" label="first name" />
                              <Input name="lastName" label="last name" />
                            </div>
                            <div className="mt-4">
                              <Input
                                name="phoneNumber"
                                label="phone number"
                                info="Please enter the mobile number that should receive SMS booking reminder notifications"
                              />
                            </div>
                            <div className="mt-6 border-t border-cool-gray-200 pt-6">
                              <Checkbox
                                name="inviteEndUser"
                                label="I would like to invite this user to tappON"
                                note="Leave this unchecked if you will be the sole manager for this profile"
                                customHandleChange={() =>
                                  setFieldValue("endUserEmail", "")
                                }
                              />
                            </div>

                            <div
                              className={`mt-6 ${
                                values.inviteEndUser
                                  ? "opacity-100"
                                  : "opacity-50"
                              }`}
                            >
                              <Input
                                name="endUserEmail"
                                label="email"
                                labelIcon="envelope"
                                info="They will be able to access and edit their profile, send messages to support professionals and make booking requests"
                                disabled={!values.inviteEndUser}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                          <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                            <Button
                              type="submit"
                              className="w-full flex justify-center"
                            >
                              Save
                            </Button>
                          </span>
                          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                            <Button
                              onClick={hideModal}
                              secondary
                              color="gray"
                              className="w-full flex justify-center"
                            >
                              Cancel
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </ToastConsumer>
  );
};
