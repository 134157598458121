import React, { useState } from "react";
import moment from "moment";
import { AddToast } from "react-toast-notifications";

import {
  EmploymentExperience,
  SupportProfessional,
} from "../../../../../types/supportProfessional";
import { EmploymentExperienceForm } from "../../../../pages/settings/profile/EmploymentExperienceForm";

export const Experience = ({
  experience,
  editButton,
  supportProfessional,
  toastAdd,
}: {
  experience: EmploymentExperience;
  editButton?: boolean;
  supportProfessional: SupportProfessional;
  toastAdd?: AddToast;
}) => {
  const [updatingExperience, setUpdatingExperience] = useState(false);
  const { title, company, startDate, endDate, volunteer, present } = experience;
  const startDateMonth = moment(startDate).format("M");
  const startDateYear = moment(startDate).format("YYYY");
  const endDateMonth = moment(endDate).format("M");
  const endDateYear = moment(endDate).format("YYYY");

  return updatingExperience ? (
    <EmploymentExperienceForm
      supportProfessional={supportProfessional}
      add={toastAdd}
      initialValues={{
        title,
        company,
        volunteer,
        present,
        startDateMonth,
        startDateYear,
        endDateMonth,
        endDateYear,
      }}
      open
      id={experience.id}
      reset={() => setUpdatingExperience(false)}
    />
  ) : (
    <div className="py-1">
      <div className="flex items-center justify-between">
        <div className="text-sm leading-5 font-medium text-gray-800 truncate">
          {experience.title}
        </div>
        {experience.volunteer && (
          <div className="ml-2 flex-shrink-0 flex">
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-teal-100 text-teal-800">
              Volunteer
            </span>
          </div>
        )}
      </div>
      <div className="truncate">
        <span className="">{experience.company}</span>
      </div>
      <div className="mt-1 sm:flex sm:justify-between">
        <div className="mt-1 flex items-center text-sm leading-5 text-gray-500 sm:mt-0 mb-2 sm:mb-0">
          <span>
            <time dateTime={moment(experience.startDate).format("YYYY-MM-DD")}>
              {moment(experience.startDate).format("MMM YYYY")}
            </time>{" "}
            -{" "}
            {experience.present ? (
              <time dateTime={moment().format("YYYY-MM-DD")}>Present</time>
            ) : (
              <time dateTime={moment(experience.endDate).format("YYYY-MM-DD")}>
                {moment(experience.endDate).format("MMM YYYY")}
              </time>
            )}
          </span>
        </div>
        {editButton && (
          <button
            onClick={() => setUpdatingExperience(true)}
            className="btn small hover:bg-teal-500"
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};
