import React, { useEffect } from "react";
import { Formik, Form, FormikHelpers, FormikErrors, FormikProps } from "formik";
import { useMutation } from "@apollo/client";

import { EndUser } from "../../../../types/endUser";
import { ServiceRole } from "../../../../types/profile";
import { AddressFields } from "./AddressFields";
import { Button } from "../../../layout/Button";
import { END_USER_PERMISSIONS_QUERY } from "../../../../graphql/queries/endUserPermissions";
import {
  UPDATE_END_USER,
  NewData as EUNewData,
  Variables as EUVariables,
} from "../../../../graphql/mutations/updateEndUser";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData as SPNewData,
  Variables as SPVariables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import { useLogger } from "../../../../hooks";
import { UpdateSuccess } from "../../../layout/forms";
import { AddToast } from "react-toast-notifications";

interface FormValues {
  addressStreet: string;
  suburb: string;
  state: string;
  postcode: string;
}

export const AddressForm = ({
  profile,
  add,
}: {
  profile: Pick<
    EndUser,
    | "id"
    | "__typename"
    | "addressStreet"
    | "addressCity"
    | "addressState"
    | "addressPostcode"
  >;
  add: AddToast;
}) => {
  const { log } = useLogger();
  const isEndUser = profile.__typename === ServiceRole.END_USER;
  const mutation = isEndUser ? UPDATE_END_USER : UPDATE_SUPPORT_PROFESSIONAL;

  const [updateProfile] = useMutation<
    EUNewData | SPNewData,
    EUVariables | SPVariables
  >(mutation);

  const initialValues: FormValues = {
    addressStreet: profile.addressStreet || "",
    suburb: profile.addressCity || "",
    state: profile.addressState || "NSW",
    postcode: profile.addressPostcode || "",
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const { addressStreet, suburb, state, postcode } = values;

    const variables = {
      id: profile.id,
      addressStreet,
      addressCity: suburb,
      addressState: state,
      addressPostcode: postcode,
    };

    const refetchQueries = isEndUser
      ? [
          {
            query: END_USER_PERMISSIONS_QUERY,
            variables: { endUserId: profile.id },
          },
        ]
      : [];

    updateProfile({
      variables,
      refetchQueries,
    })
      .then((res) => {
        formikHelpers.setSubmitting(false);
        formikHelpers.setTouched({});
      })
      .catch((err) => {
        add(err, { appearance: "error", autoDismiss: true });
        log("error", err);
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.addressStreet) {
      errors.addressStreet = "Required";
    }

    if (!values.suburb) {
      errors.suburb = "Required";
    }

    if (
      !values.postcode.match(
        /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/
      )
    ) {
      errors.postcode = "Not a valid Australian postcode";
    }

    if (!values.postcode) {
      errors.postcode = "Required";
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        initialTouched={{
          postcode: !profile.addressPostcode,
          addressStreet: !profile.addressStreet,
          suburb: !profile.addressCity,
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(props) => (
          <AddressFormComponent formikProps={props} profile={profile} />
        )}
      </Formik>
    </>
  );
};

const AddressFormComponent = ({
  formikProps,
  profile,
}: {
  formikProps: FormikProps<FormValues>;
  profile: Pick<
    EndUser,
    "addressStreet" | "addressCity" | "addressState" | "addressPostcode"
  >;
}) => {
  const { validateForm, values, submitCount } = formikProps;
  // Show missing fields on page load
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const displaySaveButton =
    values.addressStreet !== profile.addressStreet ||
    values.suburb !== profile.addressCity ||
    values.postcode !== profile.addressPostcode ||
    values.state !== profile.addressState;

  return (
    <Form>
      <AddressFields />
      {displaySaveButton && (
        <div className="flex justify-end">
          <Button type="submit">Save</Button>
        </div>
      )}
      {!displaySaveButton && submitCount > 0 && <UpdateSuccess />}
    </Form>
  );
};
