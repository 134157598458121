import gql from "graphql-tag";

export interface Data {
  authUrl: string;
}

export interface Variables {
  supportProfessionalId: string;
}

export const SUPPORT_PROFESSIONAL_AUTH_LINK_URL = gql`
  query AuthUrlQuery($supportProfessionalId: ID!) {
    authUrl(id: $supportProfessionalId)
  }
`;
