import React from "react";
import { Button } from "semantic-ui-react";
import { useQuery } from "@apollo/client";

import {
  SUPPORT_PROFESSIONAL_AUTH_LINK_URL,
  Data,
  Variables,
} from "../../../graphql/queries/supportProfessionalAuthLinkUrl";
import { Loading } from "../../layout/Loading";
import { useLogger } from "../../../hooks/useLogger";

// TODO: Delete this once the transition to custom accounts is complete

export interface StripeConnectButtonProps {
  supportProfessionalId: string;
}

export const StripeConnectButton = ({
  supportProfessionalId,
}: StripeConnectButtonProps) => {
  const { log } = useLogger();
  const { data, error, loading } = useQuery<Data, Variables>(
    SUPPORT_PROFESSIONAL_AUTH_LINK_URL,
    {
      variables: { supportProfessionalId },
    }
  );

  if (error) {
    log("error", error);
    return <div>Error: Currently unable to link to stripe.</div>;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { authUrl } = data;

  return (
    <>
      <a className="inline-block mb-4" href={authUrl}>
        <Button primary>Connect to Stripe</Button>
      </a>
      <p className="info">
        When you click on connect to stripe, a new window will open with a form.
        This form will have some of your information but you will be required to
        complete your phone number, business description and bank account
        details. By authorising tappON you will agree to receiving payments for
        services you perform with your clients.
      </p>
    </>
  );
};
