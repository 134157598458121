import React from "react";

import { Footer } from "./Footer";
import { PublicHeader } from "./PublicHeader";

export interface PublicPageLayoutProps {
  children: React.ReactChild;
}

export const PublicPageLayout = ({ children }: PublicPageLayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen">
      <PublicHeader />
      {children}
      <div className="hidden md:block">
        <Footer />
      </div>
    </div>
  );
};
