import React from "react";
import { Link } from "@reach/router";

import { IUserContext } from "../../../types/profile";
import { Conversation } from "../../../types/message";
import { ConversationList } from "../../messages/ConversationList";
import { Icon } from "../../layout/Icons";
import { BookingStateEventT } from "../../../graphql/queries/dashboard/supportCoordinatorDashboard";
import { RecentClientActivity } from "./RecentClientActivity";

export const SupportCoordinatorDashboardRightSidebar = ({
  user,
  conversations,
  recentClientActivity,
}: {
  user: Pick<IUserContext, "id" | "__typename">;
  conversations: Pick<
    Conversation,
    "messages" | "sender" | "lastUpdated" | "slug" | "endUserId"
  >[];
  recentClientActivity: BookingStateEventT[];
}) => {
  return (
    <div className="bg-gray-50 pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 min-h-96 lg:min-h-0">
      <div className="pl-6 lg:w-80">
        <div className="pt-6 pb-2">
          <h2 className="text-sm leading-5 font-semibold">Inbox</h2>
        </div>
        <div>
          {conversations.length > 0 ? (
            <ConversationList
              conversations={conversations}
              user={user}
              limit={3}
              compact
            />
          ) : (
            <p className="text-gray-500 flex items-center text-sm">
              <Icon name="envelope" className="w-3 h-3 text-gray-400 mr-2" />
              You have no new messages
            </p>
          )}

          <div className="py-4 text-sm leading-5 mt-2">
            <Link
              to="/messages"
              className="text-teal-600 font-semibold hover:text-teal-900"
            >
              View all messages &rarr;
            </Link>
          </div>
        </div>
      </div>
      {recentClientActivity.length > 0 && (
        <RecentClientActivity activity={recentClientActivity} user={user} />
      )}
    </div>
  );
};
