import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";

import {
  SEND_SUPPORT_REQUEST,
  NewData,
  Variables,
} from "../../../graphql/mutations/sendSupportRequest";
import { TextArea } from "../../layout/forms";
import { useLogger } from "../../../hooks/useLogger";
import { Button } from "../../layout/Button";
import { IUserContext } from "../../../types/profile";

export const HelpModal = ({
  hideModal,
  user,
}: {
  hideModal: () => void;
  user: Pick<IUserContext, "id" | "name">;
}) => {
  const { log } = useLogger();
  const [sendSupportRequest] = useMutation<NewData, Variables>(
    SEND_SUPPORT_REQUEST
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const transformEl = document.getElementById("transform-element");
    const prevTransformProp = transformEl ? transformEl.style.transform : "";

    function lockBodyScroll() {
      if (transformEl) {
        transformEl.style.transform = "none";
      }
      document.body.classList.add("locked");
    }
    window.addEventListener("resize", lockBodyScroll);

    lockBodyScroll();

    return function cleanup() {
      window.removeEventListener("resize", lockBodyScroll);
      if (transformEl) transformEl.style.transform = prevTransformProp;
      document.body.classList.remove("locked");
    };
  });

  const handleSubmit = (values: any, formikApi: any) => {
    const { message } = values;
    const variables = { message, serviceRoleId: user.id };

    sendSupportRequest({ variables })
      .then((res) => {
        formikApi.setSubmitting(false);
        log("info", `Support request sent by ${user.name}`);
        setSuccess(true);
      })
      .catch((err) => {
        log("error", err);
      });
  };

  return (
    <div className="fixed bottom-0 h-screen inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 flex items-center justify-center z-30">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        className={`bg-white rounded-lg ${
          success ? "px-12 py-8" : "px-4 pt-3 pb-4"
        } overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {success ? (
          <>
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-200">
                <svg
                  className="h-6 w-6 text-green-600"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Message Sent
                </h3>
                <div className="mt-2">
                  <p className="text-base leading-5 text-gray-600">
                    We'll get back to you as soon as we can.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <span className="flex w-full rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={hideModal}
                  className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-teal-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Close
                </button>
              </span>
            </div>
          </>
        ) : (
          <Formik
            initialValues={{ message: "" }}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors: {
                message?: string;
              } = {};

              if (!values.message) {
                errors.message = "Required";
              }

              return errors;
            }}
          >
            {(props) => {
              const { isSubmitting } = props;

              return (
                <Form>
                  <div>
                    <div className="mb-3">
                      <h3
                        className="text-4xl leading-6 font-medium text-gray-900 mb-0"
                        id="modal-headline"
                      >
                        Get in touch
                      </h3>
                    </div>

                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <p className="text-base text-gray-600 mb-6">
                        Tell us a bit about what kind of support you're looking
                        for. We'll be in touch within 24 hours to assist.
                      </p>
                      <div className="flex">
                        <TextArea
                          name="message"
                          noLabel
                          className="w-full shadow-sm rounded-md"
                          inputClassName="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                      <Button
                        type="submit"
                        loading={isSubmitting}
                        className="w-full justify-center"
                      >
                        Send
                      </Button>
                    </span>
                    <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                      <Button
                        type="button"
                        color="gray"
                        outline
                        onClick={hideModal}
                        loading={isSubmitting}
                        className="w-full justify-center"
                      >
                        Cancel
                      </Button>
                    </span>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};
