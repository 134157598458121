import React from "react";

import { convertCertification } from "../../profileHelpers";
import {
  capitalize,
  humanize,
  humanizeDuration,
} from "../../../../utils/helpers";
import { List } from "./List";
import { WorkingWithChildrenCheck } from "./WorkingWithChildrenCheck";
import { Availability } from "./Availability";
import {
  SupportProfessional,
  WWCC,
} from "../../../../types/supportProfessional";

export const About = ({
  supportProfessional,
  wwcc,
  bookable,
}: {
  supportProfessional: SupportProfessional;
  wwcc?: WWCC;
  bookable?: boolean;
}) => {
  const {
    description,
    certifications,
    languageAbilities,
    serviceOfferings: so,
    skills,
    interests,
  } = supportProfessional;

  const serviceOfferings = so.filter((so) => so.enabled);

  return (
    <div className={`py-8 px-3 flex flex-col`}>
      <Availability
        supportProfessional={supportProfessional}
        className="mb-5"
      />

      <div className="mb-10" />

      <h4 className="mb-0">About</h4>
      <div className="divider" />
      <p className="text-gray-700 mb-4 whitespace-pre-line">{description}</p>
      {!bookable && (
        <div className="bg-orange-100 border border-orange-300 p-4 text-orange-700 text-sm mt-6">
          This Support Professional does not have a current Working with
          Children Check and cannot be booked.
        </div>
      )}
      {wwcc && (
        <WorkingWithChildrenCheck
          wwcc={wwcc}
          className="inline-block md:hidden margin-auto mt-8"
        />
      )}

      <div className="mb-10" />

      <List
        name="services"
        className="mb-4"
        items={serviceOfferings.map((so) => {
          let deliveryType;
          if (so.inPerson && so.virtual) {
            deliveryType = "In Person / Virtual";
          } else if (so.virtual) {
            deliveryType = "Virtual";
          } else {
            deliveryType = "In Person";
          }

          return (
            <div className="mb-4">
              <div className="flex items-center justify-between mb-3">
                <div className="service-name">{so.service.name}</div>
                <div className="ml-2 flex-shrink-0 flex">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-teal-100 text-teal-800">
                    {deliveryType}
                  </span>
                </div>
              </div>
              <ul className="ml-3 mb-2">
                <li>
                  Rate: ${(so.weekdayRate.amount / 100).toFixed(2)}{" "}
                  {so.service.name === "Meet and Greet"
                    ? "for 15 minutes"
                    : humanize(so.ratePeriod, { capitalized: false })}
                </li>
                {so.eveningRate && (
                  <>
                    <li>
                      Evening: ${(so.eveningRate.amount / 100).toFixed(2)}{" "}
                      {humanize(so.ratePeriod, { capitalized: false })}
                    </li>
                  </>
                )}
                {so.nightRate && (
                  <>
                    <li>
                      Night: ${(so.nightRate.amount / 100).toFixed(2)}{" "}
                      {humanize(so.ratePeriod, { capitalized: false })}
                    </li>
                  </>
                )}
                {so.saturdayRate && (
                  <>
                    <li>
                      Saturday: ${(so.saturdayRate.amount / 100).toFixed(2)}{" "}
                      {humanize(so.ratePeriod, { capitalized: false })}
                    </li>
                  </>
                )}
                {so.sundayRate && (
                  <>
                    <li>
                      Sunday: ${(so.sundayRate.amount / 100).toFixed(2)}{" "}
                      {humanize(so.ratePeriod, { capitalized: false })}
                    </li>
                  </>
                )}
                {so.publicHolidayRate && (
                  <>
                    <li>
                      Public Holiday: $
                      {(so.publicHolidayRate.amount / 100).toFixed(2)}{" "}
                      {humanize(so.ratePeriod, { capitalized: false })}
                    </li>
                  </>
                )}
              </ul>
              {so.weekdayRate.amount > 0 && (
                <p className="ml-3 text-gray-600">
                  Minimum booking time - {humanizeDuration(so.minDurationMins)}
                </p>
              )}
            </div>
          );
        })}
        icon={servicesIcon}
      />

      {skills.length > 0 && (
        <List
          name="skills"
          items={skills.map((skill) => (
            <span className="bg-gray-200 text-gray-900 mr-2 my-1 rounded-lg py-1 px-2">
              {skill.name}
            </span>
          ))}
          icon={globeIcon}
          inline
        />
      )}
      {interests.length > 0 && (
        <List
          name="interests"
          items={interests.map((interest) => (
            <span className="bg-gray-200 text-gray-900 mr-2 my-1 rounded-lg py-1 px-2">
              {interest.name}
            </span>
          ))}
          icon={puzzleIcon}
          inline
        />
      )}
      {certifications.length > 0 && (
        <List
          name="certifications"
          items={certifications.map((cert) => convertCertification(cert))}
          icon={educationIcon}
        />
      )}
      {languageAbilities.length > 0 && (
        <List
          name="languages"
          items={languageAbilities.map((l) => (
            <>
              <span className="font-bold">{capitalize(l.language)}</span>
              <span> ({l.level.toLowerCase()})</span>
            </>
          ))}
          icon={languageIcon}
        />
      )}
    </div>
  );
};

const educationIcon = (
  <path d="M3.33 8L10 12l10-6-10-6L0 6h10v2H3.33zM0 8v8l2-2.22V9.2L0 8zm10 12l-5-3-2-1.2v-6l7 4.2 7-4.2v6L10 20z" />
);

const languageIcon = (
  <path d="M7.667 12H2v8H0V0h12l.333 2H20l-3 6 3 6H8l-.333-2z" />
);

const servicesIcon = (
  <path d="M5.33 12.77A4 4 0 1 1 3 5.13V5a4 4 0 0 1 5.71-3.62 3.5 3.5 0 0 1 6.26 1.66 2.5 2.5 0 0 1 2 2.08 4 4 0 1 1-2.7 7.49A5.02 5.02 0 0 1 12 14.58V18l2 1v1H6v-1l2-1v-3l-2.67-2.23zM5 10l3 3v-3H5z" />
);

const globeIcon = (
  <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-2.25a8 8 0 0 0 4-2.46V9a2 2 0 0 1-2-2V3.07a7.95 7.95 0 0 0-3-1V3a2 2 0 0 1-2 2v1a2 2 0 0 1-2 2v2h3a2 2 0 0 1 2 2v5.75zm-4 0V15a2 2 0 0 1-2-2v-1h-.5A1.5 1.5 0 0 1 4 10.5V8H2.25A8.01 8.01 0 0 0 8 17.75z" />
);

const puzzleIcon = (
  <path d="M20 14v4a2 2 0 0 1-2 2h-4v-2a2 2 0 0 0-2-2 2 2 0 0 0-2 2v2H6a2 2 0 0 1-2-2v-4H2a2 2 0 0 1-2-2 2 2 0 0 1 2-2h2V6c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2 2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v4h-2a2 2 0 0 0-2 2 2 2 0 0 0 2 2h2z" />
);
