import React from "react";
import { Segment, Icon, Header } from "semantic-ui-react";
import { Link } from "@reach/router";

import { PageWrapper } from "../../styles/Styled";
import { useLogger } from "../../hooks/useLogger";
import { Button } from "./Button";

export interface ErrorPageProps {
  error: string;
  deadEnd?: boolean;
  location?: string;
}

export const ErrorPage = ({ error, deadEnd, location }: ErrorPageProps) => {
  const { log } = useLogger();
  log("error", location ? `${location} - ${error}` : error);
  return (
    <PageWrapper>
      <Segment placeholder>
        <Header icon>
          <Icon name="exclamation triangle" />
          Oops! Something went wrong
        </Header>
        {deadEnd ? (
          <p className="text-center">
            We're working to get this fixed. Please try again in a few minutes
          </p>
        ) : (
          <Link to="/">
            <Button>Back Home</Button>
          </Link>
        )}
      </Segment>
    </PageWrapper>
  );
};
