import React, { useState } from "react";
import { navigate } from "@reach/router";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

import { Label } from "../../layout/forms";
import { NavLink } from "../../layout/NavLink";
import { Button } from "../../layout/Button";

export const UpcomingNav = ({
  date,
  bookingDates,
  today,
  error,
}: {
  bookingDates: string[];
  today: string;
  date?: string;
  error?: string;
}) => {
  const [datePickerFocused, setDatePickerFocused] = useState(false);

  return (
    <div className="flex items-center mb-4 flex-wrap">
      <div className={`date-picker mb-4 ${error ? "error" : ""}`}>
        <Label className="block" htmlFor="date">
          Jump to Date
        </Label>
        <SingleDatePicker
          id="date"
          date={moment(date)}
          focused={datePickerFocused}
          noBorder
          displayFormat="DD/MM/YYYY"
          onDateChange={(date) => {
            date &&
              navigate(`/bookings/date/${moment(date).format("YYYY-MM-DD")}`);
          }}
          onFocusChange={(arg: { focused: boolean | null }) => {
            arg.focused !== null && setDatePickerFocused(arg.focused);
          }}
          numberOfMonths={1}
          isDayHighlighted={(day: any) => {
            return !!bookingDates.find((date: string) =>
              moment(date).isSame(moment(day), "day")
            );
          }}
          isOutsideRange={(day: any) => {
            return (
              moment(day).isAfter(
                moment()
                  .add(3, "months")
                  .subtract(1, "day"),
                "day"
              ) || moment(day).isBefore(moment(), "day")
            );
          }}
        />
      </div>
      <NavLink to={`/bookings/date/${today}`}>
        <Button secondary color="gray" className=" mt-3 mx-4">
          Today
        </Button>
      </NavLink>
      <div className="flex items-center">
        <NavLink className="mt-3" to={`/bookings/date/all`}>
          <Button secondary color="gray" className="">
            View Upcoming
          </Button>
        </NavLink>
      </div>
    </div>
  );
};
