import React, { useState } from "react";
import { Formik, Form, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { useMutation } from "@apollo/client";
import { Link } from "@reach/router";

import {
  SIGNUP_END_USER,
  NewData,
  Variables,
} from "../../../graphql/mutations/signupEndUser";
import {
  InputField as Input,
  Radio,
  Dropdown,
  Checkbox,
} from "../../layout/forms";
import { ServiceRole } from "../../../types/profile";
import { useLogger, useGoogleReCAPTCHA } from "../../../hooks";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  accountFirstName: string;
  accountLastName: string;
  phoneNumber: string;
  serviceRole: ServiceRole;
  user: "self" | "other";
  terms: boolean;
}

export const SignupForm = ({
  success,
  setSignupSuccess,
}: {
  success: boolean;
  setSignupSuccess: () => void;
}) => {
  const [signupMutation] = useMutation<NewData, Variables>(SIGNUP_END_USER);
  const [externalError, setExternalError] = useState("");
  const { log } = useLogger();

  const initialValues: FormValues = {
    email: "",
    firstName: "",
    lastName: "",
    accountFirstName: "",
    accountLastName: "",
    phoneNumber: "",
    serviceRole: ServiceRole.END_USER,
    user: "self",
    terms: false,
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const {
      firstName,
      lastName,
      email,
      accountFirstName,
      accountLastName,
      phoneNumber,
    } = values;

    const variables = {
      email,
      phoneNumber,
      firstName,
      lastName,
      accountFirstName: accountFirstName || undefined,
      accountLastName: accountLastName || undefined,
    };

    signupMutation({ variables })
      .then(({ data }) => {
        if (data) {
          const { success, errors } = data.signupEndUser;
          formikHelpers.setSubmitting(false);
          if (success) {
            setSignupSuccess();
          } else {
            const { email, phoneNumber } = errors;
            formikHelpers.setErrors({ email, phoneNumber });

            setExternalError(errors.external || "");
          }
        }
      })
      .catch((err) => {
        formikHelpers.setSubmitting(false);
        log("error", err);
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }

    if (!values.terms) {
      errors.terms = "You must accept the terms and conditions";
    }

    if (values.user === "other" && !values.accountFirstName) {
      errors.accountFirstName = "Required";
    }

    if (values.user === "other" && !values.accountLastName) {
      errors.accountLastName = "Required";
    }

    if (values.serviceRole === ServiceRole.SUPPORT_WORKER) {
      errors.serviceRole = "Please use other registration form";
    }

    return errors;
  };

  return (
    <>
      {success ? (
        <p className="text-lg text-gray-600 ml-4">
          Once this is complete you will be able to finalise your profile and
          access a community of Support Professionals and small businesses to
          help meet your home health goals.
        </p>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {(props) => {
            return (
              <SignupFormComponent {...props} externalError={externalError} />
            );
          }}
        </Formik>
      )}
    </>
  );
};

export interface SignupFormComponentProps extends FormikProps<FormValues> {
  externalError: string;
}

export const SignupFormComponent = ({
  values,
  isSubmitting,
  submitForm,
  externalError,
  setSubmitting,
}: SignupFormComponentProps) => {
  const { captchaProps } = useGoogleReCAPTCHA({
    onSubmit: submitForm,
    setSubmitting,
  });

  const selfFields = [
    {
      groupName: "name",
      fields: [
        { name: "firstName", label: "first name", info: "" },
        { name: "lastName", label: "last name/s", info: "" },
      ],
    },
    {
      groupName: "email",
      fields: [{ name: "email", label: "email", info: "" }],
    },
    {
      groupName: "phoneNumber",
      fields: [{ name: "phoneNumber", label: "phone", info: "" }],
    },
  ];

  const onBehalfOfFields = [
    {
      groupName: "name",
      fields: [
        { name: "firstName", label: "First name", info: "" },
        { name: "lastName", label: "Last Name/s" },
      ],
    },
    {
      groupName: "phoneNumber",
      fields: [
        {
          name: "phoneNumber",
          label: "Preferred contact number",
          info:
            "This number is used to receive SMS notifications. Please use the contact number for the person who should be notified.",
        },
      ],
    },
    {
      groupName: "accountName",
      fields: [
        { name: "accountFirstName", label: "Your first name", info: "" },
        { name: "accountLastName", label: "Your last name", info: "" },
      ],
    },
    {
      groupName: "email",
      fields: [{ name: "email", label: "Your email", info: "" }],
    },
  ];

  const formFields = values.user === "self" ? selfFields : onBehalfOfFields;

  return (
    <Form>
      {externalError && <p>{externalError}</p>}
      <div className="mt-4">
        <Radio
          name="serviceRole"
          options={[
            {
              text: "I'm looking for support",
              value: ServiceRole.END_USER,
            },
            {
              text: "I'm looking for work",
              value: ServiceRole.SUPPORT_WORKER,
            },
          ]}
        />
      </div>
      <div className="divider border-gray-100 " />

      {values.serviceRole === ServiceRole.END_USER ? (
        <>
          <div className="mt-6 flex items-center">
            <span className="mr-3">This account is for </span>
            <Dropdown
              name="user"
              noLabel
              options={[
                { text: "myself", value: "self" },
                { text: "somebody else", value: "other" },
              ]}
            />
          </div>

          {formFields.map((group, index) => (
            // TODO: use grid w/gap instead of flex here
            <div className="mt-6 flex" key={index}>
              {group.fields.map((field, index) => {
                // These margins only works for field groups of 2
                let margin = "";
                if (group.fields.length > 1) {
                  margin = index === 0 ? "mr-1" : "ml-1";
                }
                return (
                  <Input
                    name={field.name}
                    key={index * 10}
                    label={field.label}
                    className={`flex-grow ${margin}`}
                    info={field.info}
                  />
                );
              })}
            </div>
          ))}

          <div className="mt-6 flex justify-end">
            <Checkbox
              name="terms"
              className="flex flex-col items-end"
              label={
                <>
                  I agree to the{" "}
                  <a
                    href="https://about.tappon.co/terms-and-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </a>
                </>
              }
            />
          </div>
        </>
      ) : (
        <div>
          <p className="text-gray-800 mt-2">
            Looking to provide support and services?
          </p>

          <a
            className="mt-3 button bg-teal-200 hover:bg-teal-100 px-5 py-2 text-teal-900 text-base font-medium rounded transition duration-150 ease-in-out inline-block "
            href="https://about.tappon.co/business-signup/"
          >
            Register as a Support Professional
          </a>
        </div>
      )}
      <div
        className={
          values.serviceRole === ServiceRole.SUPPORT_WORKER ? "hidden" : ""
        }
      >
        <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="button"
              id="signup-submit"
              disabled={isSubmitting}
              {...captchaProps}
              className={`g-recaptcha w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700 transition duration-150 ease-in-out ${
                isSubmitting ? "submitting opacity-75" : ""
              }`}
            >
              Sign Up
            </button>
          </span>
        </div>
        <div className={`mt-6 flex items-center justify-end`}>
          <div className="text-base leading-5">
            <Link
              to="/signin"
              className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer"
            >
              Already have an account?{" "}
            </Link>
          </div>
        </div>
      </div>
    </Form>
  );
};
