import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "@reach/router";
import moment from "moment";

import { Loading } from "../layout/Loading";
import { Icon } from "../layout/Icons";
import {
  BOOKING_IN_PROGRESS_QUERY,
  Data,
  Variables,
} from "../../graphql/queries/bookingInProgress";
import { useLogger } from "../../hooks/useLogger";
import { IUserContext } from "../../types/profile";

export const VirtualBookingAlert = ({
  user,
}: {
  user: Pick<IUserContext, "id">;
}) => {
  const { log } = useLogger();
  const { data, loading, error } = useQuery<Data, Variables>(
    BOOKING_IN_PROGRESS_QUERY,
    {
      variables: { serviceRoleId: user.id },
    }
  );

  if (error) {
    log("error", error);
    return <></>;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { bookingInProgress } = data;

  const bookingStarted =
    !!bookingInProgress && moment().isAfter(bookingInProgress.startTime);

  if (bookingInProgress && bookingInProgress.deliveryType === "VIRTUAL") {
    return (
      <Link className="" to={`/bookings/${bookingInProgress.id}`}>
        <div className="inset-x-0 sm:-mt-6 mb-6">
          <div className="max-w-screen-xl mx-auto">
            <div className="p-2 bg-tangerine-200 shadow sm:p-3 border border-tangerine-500">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <span className="flex p-2 rounded-lg bg-tangerine-800">
                    <Icon name="alarm" className="h-6 w-6 text-white" />
                  </span>
                  <p className="ml-3 font-medium text-tangerine-700 truncate">
                    <span className="inline font-bold text-lg">
                      You have a virtual booking{" "}
                      {bookingStarted
                        ? "in progress"
                        : "that is about to start"}
                    </span>
                  </p>
                </div>

                <div className="mr-8 text-lg text-tangerine-700 font-bold order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                  View &rarr;
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return <></>;
};
