import gql from "graphql-tag";
import { EndUser } from "../../types/endUser";

import { END_USER_FIELDS } from "../fragments";

export interface NewData {
  signupNewClient: {
    success: boolean;
    errors: {
      phoneNumber?: string;
      email?: string;
      external?: string;
      firstName?: string;
      lastName?: string;
    };
    endUser: EndUser;
  };
}

export interface Variables {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string | null;
}

export const SIGNUP_NEW_CLIENT = gql`
  ${END_USER_FIELDS}
  mutation signupNewClient(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $email: String
  ) {
    signupNewClient(
      id: $id
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
    ) {
      success
      errors {
        phoneNumber
        email
        external
        firstName
        lastName
      }
      endUser {
        ...EndUserFields
      }
    }
  }
`;
