import React, { useState } from "react";
import { RouteComponentProps, Link } from "@reach/router";

import { humanize } from "../../../utils/helpers";
import { ServiceRole, IUserContext } from "../../../types/profile";
import { Icon } from "../../layout/Icons";
import { SupportProfessionalList } from "./SupportProfessionalList";
import { PAGINATED_SUPPORT_PROFESSIONALS_BY_SERVICE_TYPE } from "../../../graphql/queries/paginatedSupportProfessionalsByServiceType";
// import { Toggle } from "../../layout/forms";

interface ServicePageProps extends RouteComponentProps {
  serviceSlug?: string;
  user: Pick<IUserContext, "id" | "__typename" | "name">;
}

export const ServicePage = ({ serviceSlug = "", user }: ServicePageProps) => {
  const margins = "sm:mx-8 md:mx-16 xm:mx-24 lg:mx-40 xl:mx-56 2xl:mx-64";
  // const [meetAndGreetFilter, setMeetAndGreetFilter] = useState(false);
  const [meetAndGreetFilter] = useState(false);

  return (
    <div className="page-wrapper">
      <div className={margins}>
        <Link
          to="/discover"
          className="flex items-center mb-8 text-teal-700 hover:text-teal-900 cursor-pointer font-bold"
        >
          <Icon name="chevron" className="w-5 h-5 transform rotate-90 mr-4" />
          <p>Find Support</p>
        </Link>
        <div className="flex justify-between items-center">
          <h1 className="mb-0">
            {humanize(serviceSlug, { capitalized: true })}
          </h1>

          {/* Uncomment below to allow filtering based on whether a Support Professional offers a meet and greet service */}
          {/* ----------------------------------------------- */}
          {/* <div className="flex justify-between">
            <label
              htmlFor="meet-and-greet-filter"
              className="mr-6 text-sm text-gray-800 w-2/3 flex-none text-right"
            >
              Only show Support Professionals that offer 'Meet & Greet'
            </label>
            <Toggle
              name="meet-and-greet-filter"
              checked={meetAndGreetFilter}
              onChange={() => setMeetAndGreetFilter((prevState) => !prevState)}
              noLabel
            />
          </div> */}
        </div>
        <div className="divider" />
      </div>
      <SupportProfessionalList
        query={PAGINATED_SUPPORT_PROFESSIONALS_BY_SERVICE_TYPE}
        queryVariables={{
          serviceSlug,
          endUserId: user.__typename === ServiceRole.END_USER ? user.id : null,
          meetAndGreetFilter,
        }}
        user={user}
        margins={margins}
        displayPrompt
      />
    </div>
  );
};
