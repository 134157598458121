import gql from "graphql-tag";

export const SUPPORT_PROFESSIONAL_FIELDS = gql`
  fragment SupportProfessionalFields on SupportProfessional {
    id
    acceptsFastBookings
    acceptsTransferBookings
    addressCity
    availabilities {
      id
      dayNumber
      available
    }
    certifications {
      id
      type
      title
      date
    }
    description
    employmentExperience {
      id
      title
      company
      startDate
      endDate
      present
      volunteer
    }
    interests {
      id
      name
    }
    languageAbilities {
      id
      language
      level
    }
    location
    name
    profileImage {
      id
      small
      med
      large
      original
    }
    serviceOfferings {
      id
      ratePeriod
      minDurationMins
      virtual
      inPerson
      enabled
      weekdayRate {
        amount
        currency
      }
      eveningRate {
        amount
        currency
      }
      nightRate {
        amount
        currency
      }
      saturdayRate {
        amount
        currency
      }
      sundayRate {
        amount
        currency
      }
      publicHolidayRate {
        amount
        currency
      }
      service {
        id
        name
        slug
        virtual
      }
    }
    shortDescription
    skills {
      id
      name
    }
    stripeConnectAccount {
      id
      stripeAccountId
      payoutsEnabled
      requirementsOutstanding
      pendingVerification
      bankAccountAdded
    }
    stripeConnectAccountId
    title
    virtualOnly
    wwcc {
      id
      number
      verificationDate
      expiryDate
    }
  }
`;
