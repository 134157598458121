import gql from "graphql-tag";

import { EndUser } from "../../types/endUser";
import { END_USER_FIELDS } from "../fragments";

export interface Data {
  clients: EndUser[];
}

export interface Variables {
  id: string;
}
export const SUPPORT_COORDINATOR_CLIENTS = gql`
  ${END_USER_FIELDS}

  query SupportCoordinatorClientsQuery($id: ID!) {
    clients: endUsersForSupportCoordinator(supportCoordinatorId: $id) {
      ...EndUserFields
      permissions {
        booking
        invoice
      }
    }
  }
`;
