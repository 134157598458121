import React from "react";
import { Link, WindowLocation } from "@reach/router";

import { capitalize } from "../../../utils/helpers";
import { EndUser } from "../../../types/endUser";
import { SupportProfessional } from "../../../types/supportProfessional";

export const SettingsNav = ({
  location,
  supportProfessional,
  endUser,
}: {
  location: WindowLocation | undefined;
  endUser?: Pick<EndUser, "id">;
  supportProfessional?: Pick<
    SupportProfessional,
    "profileImage" | "stripeConnectAccount"
  >;
}) => {
  const missingProfileImage =
    supportProfessional &&
    !supportProfessional?.profileImage
      ? 1
      : 0;

  const stripeRequirementsOutstanding =
    supportProfessional &&
    supportProfessional.stripeConnectAccount &&
    supportProfessional.stripeConnectAccount.requirementsOutstanding
      ? 1
      : 0;

  return (
    <>
      <nav id="settings-nav" className="space-x-12 xm:space-x-0">
        {!!supportProfessional && (
          <SettingsLink
            to="payment"
            location={location}
            flags={stripeRequirementsOutstanding}
          />
        )}

        {!!endUser && (
          <>
            <SettingsLink to="preferences" location={location} />
            <SettingsLink to="personal" location={location} />
            <SettingsLink to="funding" location={location} />
          </>
        )}

        <SettingsLink
          to="profile"
          location={location}
          flags={missingProfileImage}
        />

        {!!supportProfessional && (
          <>
            <SettingsLink
              to="services"
              label="Your Services"
              location={location}
            />
            <SettingsLink to="availability" location={location} />
          </>
        )}
      </nav>
    </>
  );
};

const SettingsLink = ({
  to,
  label,
  flags,
  location,
}: {
  to: string;
  label?: string;
  flags?: number;
  location: WindowLocation | undefined;
}) => {
  return (
    <Link
      to={to}
      className={`settings-nav-link ${
        location && location.pathname.includes(to) ? "active" : ""
      }`}
    >
      <span className={`truncate ${flags ? "mr-2" : ""}`}>
        {label || capitalize(to)}
      </span>
      {!!flags && flags > 0 && <span className={`flags`}>{flags}</span>}
    </Link>
  );
};
