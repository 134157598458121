import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Link } from "@reach/router";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { AddToast } from "react-toast-notifications";

import {
  SupportProfessional,
  Skill,
  Interest,
} from "../../../../types/supportProfessional";
import { Tag } from "../../../layout/Tag";
import { AddAttributeForm } from "./AddAttributeForm";
import {
  EmploymentExperienceForm,
  simplifyInput,
} from "./EmploymentExperienceForm";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import { capitalize } from "../../../../utils/helpers";
import { Experience } from "../../../users/supportProfessionals/profilePage/employmentHistory/Experience";
import { useLogger } from "../../../../hooks/useLogger";
import { UpdateProfileImageForm } from "./UpdateProfileImageForm";
import { IUserContext } from "../../../../types/profile";
import { Avatar } from "../../../layout/Avatar";

export const SupportProfessionalProfileSettings = ({
  supportProfessional,
  user,
  add,
}: {
  supportProfessional: SupportProfessional;
  user: Pick<IUserContext, "id">;
  add: AddToast;
}) => {
  const { log } = useLogger();
  const [updateSupportProfessional] = useMutation<NewData, Variables>(
    UPDATE_SUPPORT_PROFESSIONAL
  );

  const removeAttribute = (
    attributeType: "skills" | "interests",
    attributeId: string,
    add: AddToast
  ) => {
    const otherAttrs = attributeType === "skills" ? "interests" : "skills";

    const variables = {
      id: supportProfessional.id,
      [otherAttrs]: simplifyInput(supportProfessional[otherAttrs]),
      [attributeType]: simplifyInput(supportProfessional[attributeType]).map(
        (a: Skill | Interest) => {
          return a.id === attributeId ? { ...a, delete: true } : a;
        }
      ),
      employmentExperience: simplifyInput(
        supportProfessional.employmentExperience
      ),
    };
    const optimisticResponse = {
      __typename: "Mutation",
      updateSupportProfessional: {
        ...supportProfessional,
        [attributeType]: supportProfessional[attributeType].filter(
          (a) => a.id !== attributeId
        ),
      },
    };

    updateSupportProfessional({
      variables,
      optimisticResponse,
    })
      .then((res) => {})
      .catch((err) => {
        log("error", err);
        add("There was an error removing your preference", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const employmentExperience = supportProfessional.employmentExperience
    .slice()
    .sort((x, y) => {
      return moment(x.startDate).isAfter(moment(y.startDate)) ? 1 : -1;
    });

  const { profileImage } = supportProfessional;

  return (
    <>
      <Link
        to={`/worker/profile/${supportProfessional.id}/edit`}
        id="edit-profile-button"
      >
        <Button icon labelPosition="left">
          <Icon name="pencil" />
          Edit Your Profile
        </Button>
      </Link>
      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="profile-image"
          className="block text-sm leading-5 font-medium text-gray-700"
        >
          Profile Image
        </label>
        <div className="mt-2 sm:mt-0 sm:col-span-2">
          <div id="profile-image" className="flex items-center">
            <Avatar user={supportProfessional} size="md" />
          </div>
          {!profileImage && (
            <p className="mt-4 text-red-500">
              A profile image is required before you will be visible on the
              platform.
            </p>
          )}
        </div>
      </div>
      <div className="mt-6 sm:mt-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <UpdateProfileImageForm user={user} profile={supportProfessional} />
      </div>
      <div
        data-testid="skills"
        className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
      >
        <label
          htmlFor="skill-list"
          className="block text-sm leading-5 font-medium text-gray-700"
        >
          Skills
        </label>
        <div className="sm:col-span-2">
          <ul id="skill-list">
            {supportProfessional.skills.map((skill) => (
              <Tag
                key={skill.id}
                text={capitalize(skill.name, { all: true })}
                remove={() => removeAttribute("skills", skill.id, add)}
              />
            ))}
          </ul>
          <AddAttributeForm
            attributeType="skills"
            add={add}
            supportProfessional={supportProfessional}
            placeholders={[
              "Cooking",
              "Hoisting",
              "Gardening",
              "Computer Skills",
            ]}
          />
        </div>
      </div>
      <div
        data-testid="interests"
        className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
      >
        <label
          htmlFor="interests-list"
          className="block text-sm leading-5 font-medium text-gray-700"
        >
          Interests
        </label>
        <div className="sm:col-span-2">
          <ul id="interests-list">
            {supportProfessional.interests.map((interest) => (
              <Tag
                key={interest.id}
                text={capitalize(interest.name, { all: true })}
                remove={() => removeAttribute("interests", interest.id, add)}
              />
            ))}
          </ul>
          <AddAttributeForm
            attributeType="interests"
            add={add}
            supportProfessional={supportProfessional}
            placeholders={["Hiking", "Piano", "Skiing", "Fishing"]}
          />
        </div>
      </div>
      <div
        data-testid="employment-history"
        className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
      >
        <label
          htmlFor="employment-history"
          className="block text-sm leading-5 font-medium text-gray-700"
        >
          Employment History
        </label>
        <div className="sm:col-span-2">
          <ul className="mb-4" id="employment-history">
            {employmentExperience.map((experience) => (
              <li key={experience.id} className="">
                <div className="rounded-lg border border-gray-200 p-4 my-1 w-full">
                  <Experience
                    supportProfessional={supportProfessional}
                    experience={experience}
                    editButton
                  />
                </div>
              </li>
            ))}
          </ul>
          <EmploymentExperienceForm
            add={add}
            supportProfessional={supportProfessional}
          />
        </div>
      </div>
    </>
  );
};
