import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/client";

import { SearchSupportProfessional } from "../../users/supportProfessionals/SearchSupportProfessional";
import { Loading } from "../../layout/Loading";
import { VirtualBookingAlert } from "../../bookings/VirtualBookingAlert";
import { Button } from "../../layout/Button";
import { CategoryButtonList } from "./CategoryButtonList";
import { PAGINATED_RECOMMENDED_SUPPORT_PROFESSIONALS } from "../../../graphql/queries/recommendedSupportProfessionals/paginatedRecommendedSupportProfessionals";
import {
  discoverPageQueryForUserType,
  Data,
  Variables,
} from "../../../graphql/queries/discoverPage";
import { ServiceRole, IUserContext } from "../../../types/profile";
import { SupportPrompt } from "./SupportPrompt";
import { SupportProfessionalList } from "../../users/supportProfessionals/SupportProfessionalList";
import { ErrorPage } from "../../layout/ErrorPage";
import { Category, Service } from "../../../types/service";

export interface DiscoverPageProps extends RouteComponentProps {
  user: Pick<IUserContext, "id" | "__typename" | "name">;
}

export const DiscoverPage = ({ user }: DiscoverPageProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    discoverPageQueryForUserType[user.__typename],
    {
      variables: { id: user.id },
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  const isEndUser = user && user.__typename === ServiceRole.END_USER;

  const {
    services,
    servicesWithOfferings,
    recommendedSupportProfessionals,
    endUser,
  } = data;

  const availableServices = servicesWithOfferings.map(
    (s: { id: string }) => s.id
  );

  const categories = services.reduce(
    (accum: { [key in Category]?: Service[] }, service: Service) => {
      const available = availableServices.includes(service.id);
      if (available && service.slug !== "meet-and-greet") {
        (accum[service.category] = accum[service.category] || []).push({
          ...service,
          available,
        });
      }
      return accum;
    },
    {}
  );

  return (
    <div className="page-wrapper discover-page">
      <VirtualBookingAlert user={user} />

      <div className="sm:mx-8 md:mx-16 xm:mx-24 lg:mx-40 xl:mx-56 2xl:mx-80">
        <h1 className="my-4 font-bold text-4xl">Find Support</h1>
        <SearchSupportProfessional
          header={"Search all Support Professionals"}
        />
        <CategoryButtonList categories={categories} />
        <SupportPrompt
          text={"Not sure where to start?"}
          className="mb-8"
          user={user}
        />
      </div>
      {isEndUser && recommendedSupportProfessionals && (
        <>
          <div className="divider mb-16 sm:mx-8 md:mx-16 xm:mx-24 lg:mx-40 xl:mx-56 2xl:mx-80" />
          {recommendedSupportProfessionals.total > 0 ? (
            <>
              <p className="text-xl text-gray-700 mb-10 sm:mx-8 md:mx-16 xm:mx-24 lg:mx-40 xl:mx-56 2xl:mx-80">
                Here are a few recommendations based on your preferences
              </p>
              <div className="mb-12">
                <SupportProfessionalList
                  margins="sm:mx-8 md:mx-16 xm:mx-24 lg:mx-40 xl:mx-56 2xl:mx-80"
                  query={PAGINATED_RECOMMENDED_SUPPORT_PROFESSIONALS}
                  queryVariables={{ endUserId: user.id }}
                  user={user}
                  endUser={endUser}
                  rows={1}
                />
              </div>
            </>
          ) : (
            <div className="mb-12 mx-2 sm:mx-12 md:mx-20 xm:mx-32 lg:mx-48 xl:mx-64 2xl:mx-88">
              <p className="text-xl font-bold mb-3">
                You haven't set any preferences yet.
              </p>
              <p className="text-lg font-medium text-gray-700 mb-6">
                Help us get to know you a bit better and your needs.
              </p>
              <Button to="/settings">Settings</Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
