import React from "react";
import { Field } from "formik";

import { Label } from ".";

export interface TextAreaProps {
  name: string;
  ariaLabel?: string;
  label?: string;
  noLabel?: boolean;
  className?: string;
  inputClassName?: string;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
}

export const TextArea = React.forwardRef(
  (
    {
      name,
      label,
      ariaLabel,
      noLabel,
      className,
      inputClassName,
      rows,
      placeholder,
      disabled,
    }: TextAreaProps,
    ref: any
  ) => {
    return (
      <Field>
        {(props: { field: any; form: any }) => {
          const { onChange } = props.field;
          const { values, errors, touched, submitCount } = props.form;

          const error =
            errors[name] && (errors[touched] || submitCount)
              ? "placeholder-red-700 bg-red-50 border-red-200"
              : "";

          return (
            <div className={`${className}`}>
              {!noLabel && <Label htmlFor={name}>{label || name}</Label>}
              <textarea
                name={name}
                aria-label={ariaLabel || name}
                data-testid={`${name}-input`}
                value={values[name]}
                onChange={onChange}
                rows={rows || 3}
                className={`w-full border border-gray-300 rounded min-h-6 px-4 py-2 sm:text-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out ${error} ${inputClassName}`}
                placeholder={placeholder}
                disabled={disabled}
                ref={ref}
              />
              {error && (
                <p data-testid="error-message" className="mb-4">
                  {errors[name]}
                </p>
              )}
            </div>
          );
        }}
      </Field>
    );
  }
);
