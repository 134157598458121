import { useEffect, useContext, useState } from "react";
import { saveAs } from "file-saver";

import { AuthContext } from "../auth/Auth";
import { IUserContext, ServiceRole } from "../types/profile";
import { slugify, getEnv } from "../utils/helpers";

export const useDownloadBookingHistory = (
  user: Pick<IUserContext, "id" | "name" | "__typename">
) => {
  const authContext = useContext(AuthContext);
  const [downloadFile, setDownloadFile] = useState(() => () => {});

  useEffect(() => {
    const token = authContext.session ? authContext.session.idToken : "";

    const fetchBookingHistory = async () => {
      const response = await fetch(
        `${getEnv("REACT_APP_CSV_ENDPOINT")}/${user.id}`,
        {
          method: "POST",
          headers: {
            authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      if (response && response.headers && response.status === 200) {
        const blob = await response.blob();

        setDownloadFile(() => () =>
          saveAs(blob, `${slugify(user.name)}_bookings.csv`)
        );
      }
    };

    if (user.__typename === ServiceRole.SUPPORT_WORKER) {
      fetchBookingHistory();
    }
  }, [authContext, user]);

  return [downloadFile];
};
