import React, { Dispatch, SetStateAction } from "react";
import { Room } from "twilio-video";

import { BookingWithParties } from "../../../types/booking";
import { Controls } from "./Controls";
import { LoadingDots } from "../../layout/LoadingDots";

export const WaitingRoom = ({
  booking,
  room,
  setEndCallConfirmation,
}: {
  booking: Pick<BookingWithParties, "otherParty">;
  room: Room;
  setEndCallConfirmation: Dispatch<SetStateAction<boolean>>;
}) => {
  const [otherPartyFirstName] = booking.otherParty.name.split(" ");

  return (
    <>
      <div
        className="waiting-room w-full flex items-center justify-center"
        style={{
          backgroundColor: `#3d8086`,
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%233d8086'/%3E%3Cstop offset='1' stop-color='%2366c7c9'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%2375f4f4' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%2375f4f4' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%2375f4f4' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%2375f4f4' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E")`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          height: "100%",
        }}
      >
        <div className="bg-white shadow-lg py-5 px-10 text-center">
          <h4>Welcome!</h4>
          <p>Please wait for {otherPartyFirstName} to join the chat</p>
          <LoadingDots className="m-auto mb-2 mt-5" />
        </div>
        <Controls room={room} setEndCallConfirmation={setEndCallConfirmation} />
      </div>
    </>
  );
};
