import React from "react";
import { PureQueryOptions } from "@apollo/client";

import { Booking } from "../../types/booking";
import { BookingSummary } from "./BookingSummary";
import { IUserContext } from "../../types/profile";

type BookingT = Pick<
  Booking,
  | "id"
  | "endTime"
  | "endUser"
  | "insertedAt"
  | "location"
  | "paymentFailed"
  | "schedule"
  | "serviceName"
  | "startTime"
  | "state"
  | "supportProfessional"
  | "scheduleId"
>;

export const BookingList = ({
  title,
  bookings,
  showState,
  emptyStateMessage,
  refetchQueries = [],
  user,
}: {
  bookings: BookingT[];
  showState: boolean;
  emptyStateMessage: string;
  title?: string;
  refetchQueries?: (string | PureQueryOptions)[];
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  const bookingList = (
    <ul data-testid="bookingslist">
      {(bookings.length > 0 &&
        bookings.map((booking: BookingT) => (
          <BookingSummary
            key={booking.id}
            booking={booking}
            showState={showState}
            refetchQueries={refetchQueries}
            user={user}
          />
        ))) || <p className="font-normal">{emptyStateMessage}</p>}
    </ul>
  );

  return title ? (
    <div className="card rounded-lg">
      <h3 className="text-lg border-b border-gray-200 mb-0 pb-4">{title}</h3>
      {bookingList}
    </div>
  ) : (
    bookingList
  );
};
