import React from "react";
import { Formik, Form, FormikErrors, FormikHelpers } from "formik";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import wavingHand from "../../../../assets/images/waving-hand-sign.png";
import {
  UPDATE_END_USER,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateEndUser";
import { useLogger } from "../../../../hooks";
import { AddressFields } from "../../../pages/settings/personalInformation/AddressFields";
import { DateOfBirthFields } from "../../../pages/settings/personalInformation/DateOfBirthFields";
import { parseDateValues } from "../../../../utils/helpers";
import { UserCard } from "./UserCard";
import { EndUserT } from "./CompleteProfileModal";
import { AccountT } from "./CompleteProfilePrompt";
import { Buttons } from "./Buttons";

export const CompleteProfilePanel1 = ({
  endUser,
  account,
  hideModal,
  setPanel,
  add,
}: {
  endUser: EndUserT;
  account: AccountT;
  hideModal: () => void;
  setPanel: (number: number) => void;
  add: AddToast;
}) => {
  const [updateEndUser] = useMutation<NewData, Variables>(UPDATE_END_USER);
  const { log } = useLogger();

  const [firstName] = endUser.name.split(" ");

  interface FormValues {
    addressStreet: string;
    suburb: string;
    state: string;
    postcode: string;
    dobDay: number;
    dobMonth: string;
    dobYear: number;
  }

  const initialValues: FormValues = {
    addressStreet: endUser.addressStreet || "",
    suburb: endUser.addressCity || "",
    state: endUser.addressState || "NSW",
    postcode: endUser.addressPostcode || "",
    dobDay: endUser.dateOfBirth ? moment(endUser.dateOfBirth).date() : 1,
    dobMonth: endUser.dateOfBirth
      ? moment(endUser.dateOfBirth).format("MMMM")
      : "January",
    dobYear: endUser.dateOfBirth ? moment(endUser.dateOfBirth).year() : 1985,
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const dateOfBirth = parseDateValues(values).format("YYYY-MM-DD");
    const { addressStreet, suburb, state, postcode } = values;

    const variables = {
      id: endUser.id,
      dateOfBirth,
      addressStreet,
      addressCity: suburb,
      addressState: state,
      addressPostcode: postcode,
    };

    updateEndUser({
      variables,
    })
      .then((res) => {
        setPanel(2);
      })
      .catch((err) => {
        add("There was a problem updating your details", {
          appearance: "error",
          autoDismiss: true,
        });
        log("error", err);
      });
  };

  return (
    <div className="">
      <div className="">
        <div className="py-6 md:pb-10 flex flex-col items-center">
          <div className="flex items-center mb-4">
            <img
              src={wavingHand}
              className="w-8 h-8 mr-3"
              alt="waving hand emoji"
            />
            <h3 className="text-3xl font-medium leading-6 text-gray-900 mb-0">
              Hi {firstName}
            </h3>
          </div>
          <p className="mt-1 text-xl leading-5 text-gray-500">
            Help us to complete your profile...
          </p>
        </div>
        <div className="mt-5 md:mt-0">
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: FormikErrors<FormValues> = {};

              if (!values.addressStreet) {
                errors.addressStreet = "Required";
              }

              if (!values.suburb) {
                errors.suburb = "Required";
              }

              if (
                !values.postcode.match(
                  /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/
                )
              ) {
                errors.postcode = "Not a valid Australian postcode";
              }

              if (!values.postcode) {
                errors.postcode = "Required";
              }

              const dateOfBirth = parseDateValues(values);

              if (moment().diff(dateOfBirth, "hours") < 0) {
                errors.dobDay = "Cannot be in the future";
                errors.dobMonth = "Cannot be in the future";
                errors.dobYear = "Cannot be in the future";
              }

              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, isSubmitting }) => {
              const dateOfBirth = parseDateValues(values);
              const yearsOld = moment().diff(dateOfBirth, "years");

              return (
                <Form>
                  <div className="grid grid-cols-6 gap-x-6">
                    <UserCard
                      endUser={endUser}
                      account={account}
                      className="mb-6"
                    />
                    <div className="sm:flex items-center border border-dashed rounded border-teal-400 col-span-6 p-3 text-teal-700 bg-teal-50 mb-6 text-sm">
                      To change your name or email address, please contact{" "}
                      <a
                        className="text-teal-600 font-bold ml-1"
                        href="mailto:support@tappon.co"
                      >
                        support@tappon.co
                      </a>
                    </div>
                    <div className="col-span-6 sm:col-span-5">
                      <h4 className="text-gray-700 mb-3 pl-1">Date of Birth</h4>
                      <DateOfBirthFields
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div className="hidden col-span-1 sm:flex sm:items-center mb-4">
                      <p className="text-teal-700 mt-8">{yearsOld} years old</p>
                    </div>
                    <div className="col-span-6 my-4 pl-1">
                      <h4 className="text-gray-700 mb-2">Address</h4>
                      <p className="text-sm text-gray-500">
                        Please enter your primary address, where support
                        bookings will take place.
                      </p>
                    </div>
                    <AddressFields />
                  </div>
                  <Buttons
                    panel={1}
                    setPanel={setPanel}
                    hideModal={hideModal}
                    isSubmitting={isSubmitting}
                    submit
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
