import React from "react";

import { EndUser } from "../../../../types/endUser";
import { Avatar } from "../../../layout/Avatar";
import { AccountT } from "./CompleteProfilePrompt";

export type EndUserT = Pick<EndUser, "profileImage" | "name">;

export const UserCard = ({
  endUser,
  account,
  className,
}: {
  endUser: EndUserT;
  account: AccountT;
  className?: string;
}) => {
  return (
    <div className={`col-span-6 flex items-center justify-center ${className}`}>
      <div className="flex-shrink-0">
        <Avatar size="sm" user={endUser} />
      </div>
      <div className="min-w-0 px-4">
        <div>
          <div className="text-base leading-5 font-medium text-teal-700 truncate">
            {endUser.name}
          </div>
          <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
            <svg
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <span className="truncate">{account.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
