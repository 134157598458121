import React from "react";
import { Comment } from "semantic-ui-react";
import punycode from "punycode";
import Linkify from "react-linkify";
import { Link } from "@reach/router";

import { Avatar } from "../layout/Avatar";
import { Message as MessageT } from "../../types/message";
import { ServiceRole } from "../../types/profile";

export interface MessageProps {
  message: Pick<
    MessageT,
    "sender" | "message" | "supportCoordinator" | "sentBySp"
  >;
  sentByUser: boolean;
  firstOfGroup?: boolean;
  sentByClient?: boolean;
  firstOfUser?: boolean;
  sentByCoordinator?: boolean;
}

export const Message = ({
  message,
  sentByUser,
  firstOfGroup,
  sentByClient,
  firstOfUser,
  sentByCoordinator,
}: MessageProps) => {
  const textColor = sentByUser ? "text-white" : "text-black";
  const rightAligned = sentByUser || sentByClient || sentByCoordinator;

  // This is necessary to prevent homograph attacks (https://github.com/tasti/react-linkify/issues/84)
  const componentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number
  ) => (
    <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
      {punycode.toASCII(decoratedText)}
    </a>
  );

  const profileUrl = {
    [ServiceRole.SUPPORT_WORKER]: `/worker/profile/${message.sender.id}`,
    [ServiceRole.END_USER]: `/user/profile/${message.sender.id}`,
    [ServiceRole.SUPPORT_COORDINATOR]: `/support-coordinator/profile/${message.sender.id}`,
  };

  const senderRole = message.sentBySp
    ? ServiceRole.SUPPORT_WORKER
    : message.supportCoordinator
    ? ServiceRole.SUPPORT_COORDINATOR
    : ServiceRole.END_USER;

  return (
    <div className="flex items-start mt-2">
      <Link
        to={profileUrl[senderRole]}
        className={`mt-2 inline-block ${
          rightAligned ? "ml-2 order-last" : "mr-2 order-first"
        }`}
      >
        <Avatar size="xxs" user={message.sender} />
      </Link>
      <div
        className={`conversation-message rounded-xl px-2 py-1 max-w-lg lg:max-w-2xl xl:max-w-4xl ${
          rightAligned ? "ml-auto text-right " : "mr-auto "
        } ${
          sentByUser
            ? "sent-by-user bg-teal-600"
            : `bg-gray-${rightAligned ? "200" : "100"}`
        } ${sentByUser && !firstOfGroup && "rounded-br-sm"}
      ${!sentByUser && !firstOfGroup && "rounded-bl-sm"}`}
      >
        <div>
          <div className="p-1">
            <Linkify componentDecorator={componentDecorator}>
              <p className={`text-base whitespace-pre-line ${textColor}`}>
                {message.message}
              </p>
            </Linkify>
            <Comment.Actions />
          </div>
        </div>
      </div>
    </div>
  );
};
