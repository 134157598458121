import React from "react";
import moment from "moment";

import { ServiceOffering } from "../../../types/supportProfessional";
import { FormValues } from "./BookingFormData";
import { totalCost } from "./bookingFormHelpers";
import { PublicHoliday } from "../../../types/booking";

export const PreviewUpcomingBookingDates = ({
  dates,
  values,
  publicHolidays,
  service,
}: {
  dates: Date[];
  values: FormValues;
  publicHolidays: PublicHoliday[];
  service: ServiceOffering;
}) => {
  return (
    <div>
      <h3 className="text-gray-600">Upcoming booking dates</h3>

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden sm:rounded-lg">
            <table className="min-w-full">
              <thead className="sr-only">
                <tr>
                  <th className="bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Total Cost
                  </th>
                </tr>
              </thead>
              <tbody>
                {dates.slice(0, 4).map((date, index) => {
                  return (
                    <tr className="bg-white pb-2" key={index}>
                      <td className="whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                        {moment(date).format("dddd Do MMM YYYY")}
                      </td>
                      <td className="whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {totalCost(values, date, publicHolidays, service)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
