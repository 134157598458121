import React from "react";

import {
  EmploymentExperience,
  SupportProfessional,
} from "../../../../../types/supportProfessional";
import { Experience } from "./Experience";

export const EmploymentHistory = ({
  history,
  supportProfessional,
}: {
  history: EmploymentExperience[];
  supportProfessional: SupportProfessional;
}) => {
  return (
    <div className="">
      <div className="px-2 mt-4">
        <h4>Experience</h4>
        <div className="divider" />
      </div>
      <ul>
        {history.map((experience, i) => {
          return (
            <li key={experience.id} className="px-4 sm:px-6">
              {i !== 0 && <div className="divider opacity-50 py-0" />}
              <Experience
                supportProfessional={supportProfessional}
                experience={experience}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
