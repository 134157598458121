import React, { useState } from "react";
import { useMutation, PureQueryOptions } from "@apollo/client";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { BookingWithParties, BookingAction } from "../../types/booking";
import {
  UPDATE_BOOKING_STATE,
  NewData,
  Variables,
} from "../../graphql/mutations/updateBookingState";
import { useLogger } from "../../hooks/useLogger";
import { BookingActionConfirmModal } from "./BookingActionConfirmModal";

type BookingT = Pick<
  BookingWithParties,
  "id" | "actions" | "spAvailable" | "schedule" | "scheduleId"
>;

export const BookingActionButtonGroup = ({
  booking,
  refetchQueries,
}: {
  booking: BookingT;
  refetchQueries: (string | PureQueryOptions)[];
}) => {
  return (
    <div className="booking-action-button-group">
      <ToastConsumer>
        {({ add }: { add: AddToast }) =>
          booking.actions.map((action, index) => (
            <BookingActionButton
              key={action.label}
              booking={booking}
              add={add}
              action={action}
              index={index}
              refetchQueries={refetchQueries}
            />
          ))
        }
      </ToastConsumer>
    </div>
  );
};

const BookingActionButton = ({
  action,
  refetchQueries,
  index,
  add,
  booking,
}: {
  action: BookingAction;
  refetchQueries: (string | PureQueryOptions)[];
  index: number;
  add: AddToast;
  booking: BookingT;
}) => {
  const [bookingMutationFn] = useMutation<NewData, Variables>(
    UPDATE_BOOKING_STATE
  );
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoadingState] = useState(false);

  const { log } = useLogger();

  const hideModal = () => {
    setDisplayModal(false);
  };

  const showModal = () => {
    setDisplayModal(true);
  };

  const setLoading = (bool: boolean) => {
    setLoadingState(bool);
  };

  const handleActionSubmit = (
    opts: {
      cancellationReason?: string;
      affectBookingSchedule?: boolean;
    },
    setSubmitting: (bool: boolean) => void
  ) => {
    const { cancellationReason, affectBookingSchedule } = opts;

    setSubmitting(true);

    bookingMutationFn({
      refetchQueries,
      variables: {
        ...action.variables,
        cancellationReason,
        affectBookingSchedule,
      },
    })
      .then((res) => {
        add(`Booking was ${action.message}`, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        add(`There was an error processing your request`, {
          appearance: "error",
          autoDismiss: true,
        });
        log("error", err);
        setSubmitting(false);
      });
  };

  const disabled = action.label === "Accept" && !booking.spAvailable;

  const onClick =
    (!!booking.schedule && action.label !== "Approve") ||
    action.label.includes("Cancel")
      ? showModal
      : () => handleActionSubmit({}, setLoading);

  let className = "booking-action-button";
  if (index === 0) className += " rounded-l-md";
  if (index === booking.actions.length - 1) className += " rounded-r-md";
  if (loading) className += " submitting";

  return (
    <>
      {displayModal && (
        <BookingActionConfirmModal
          hideModal={hideModal}
          action={action}
          schedule={booking.schedule}
          handleActionSubmit={handleActionSubmit}
        />
      )}

      <button
        type="button"
        id={`${action.label.toLowerCase()}-booking`}
        disabled={disabled}
        onClick={onClick}
        className={`${className} border-${action.color}-400 text-${action.color}-500 hover:text-${action.color}-500`}
      >
        {action.label}
      </button>
    </>
  );
};
