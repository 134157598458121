import gql from "graphql-tag";

export interface NewData {
  signupEndUser: {
    success: boolean;
    errors: Partial<Variables> & {
      external?: string;
    };
  };
}

export interface Variables {
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  accountFirstName?: string;
  accountLastName?: string;
}

export const SIGNUP_END_USER = gql`
  mutation signupEndUser(
    $email: String!
    $phoneNumber: String!
    $firstName: String!
    $lastName: String!
    $accountFirstName: String
    $accountLastName: String
  ) {
    signupEndUser(
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      accountFirstName: $accountFirstName
      accountLastName: $accountLastName
    ) {
      success
      errors {
        phoneNumber
        email
        external
        firstName
        lastName
        accountFirstName
        accountLastName
      }
    }
  }
`;
