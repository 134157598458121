import React, { useState } from "react";
import { AddToast } from "react-toast-notifications";

import { EditRateForm } from "./EditRateForm";
import {
  RatePenalty,
  ServiceOffering,
  SupportProfessional,
} from "../../../../types/supportProfessional";
import { unCamelize, slugify } from "../../../../utils/helpers";
import { Icon } from "../../../layout/Icons";

export const RateRow = ({
  rate,
  serviceOffering,
  ratePeriod,
  supportProfessional,
  add,
}: {
  rate: RatePenalty;
  serviceOffering: ServiceOffering;
  ratePeriod: string;
  supportProfessional: SupportProfessional;
  add: AddToast;
}) => {
  const [editingRate, setEditingRate] = useState(false);
  const [mobileEditing, setMobileEditing] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const makeLabel = (rate: string) => unCamelize(rate.replace("Rate", ""));

  const toggleMobileEditing = () => {
    setMobileEditing((prev) => !prev);
  };

  return (
    <>
      <li
        id={`${slugify(rate)}-settings`}
        className={editingRate ? "" : "pr-5 sm:pr-12 "}
      >
        <div className="flex justify-between items-center">
          <span className="w-24 xs:w-32 sm:w-56 pl-6 mr-8 text-left sm:text-right font-bold text-gray-700 text-sm flex-none">
            {makeLabel(rate)}
          </span>
          {editingRate ? (
            <EditRateForm
              serviceOffering={serviceOffering}
              supportProfessional={supportProfessional}
              rate={rate}
              setEditingRate={setEditingRate}
              setError={setError}
              add={add}
            />
          ) : (
            <span
              className={`rate-amount ${
                mobileEditing ? "text-gray-500" : "text-gray-700"
              }`}
            >
              {serviceOffering[rate]
                ? "$" + (serviceOffering[rate].amount / 100).toFixed(2)
                : "-"}{" "}
              {serviceOffering[rate]
                ? ratePeriod.toLowerCase().replace("_", " ")
                : ""}
            </span>
          )}
          {editingRate && !serviceOffering[rate] && (
            <Icon
              name="close"
              className="h-3 w-3 cursor-pointer hidden md:block absolute right-0 mr-12"
              onClick={() => setEditingRate(false)}
            />
          )}
          {!editingRate && (
            <div
              className={`hidden sm:flex items-center  ${
                editingRate ? "opacity-0 w-0" : "opacity-100 cursor-pointer"
              }`}
              onClick={() => setEditingRate(true)}
            >
              <Icon name="edit" className="h-3 w-3 mr-3" />
              <span>Edit</span>
            </div>
          )}
          <div
            className={`block sm:hidden ${
              mobileEditing ? "" : "transform -rotate-90"
            }`}
            onClick={() => toggleMobileEditing()}
          >
            <Icon name="chevron" className="h-5 w-5" />
          </div>
        </div>
        {mobileEditing && (
          <>
            <div className={`w-full flex justify-around pt-5`}>
              <EditRateForm
                serviceOffering={serviceOffering}
                supportProfessional={supportProfessional}
                rate={rate}
                setEditingRate={setEditingRate}
                setError={setError}
                add={add}
              />
            </div>
          </>
        )}

        {error && (
          <div className="error block m-auto w-3/4 text-center pt-3">
            {error}
          </div>
        )}
      </li>
    </>
  );
};
