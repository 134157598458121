import React, { useEffect, useContext } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useQuery, ApolloQueryResult } from "@apollo/client";

import {
  BOOKING_AND_VIEWER,
  Data,
  Variables,
} from "../../../graphql/queries/bookingAndViewer";
import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import { IUserContext } from "../../../types/profile";
import { Booking } from "../../../types/booking";
import { AuthContext } from "../../../auth/Auth";
import { EndUser } from "../../../types/endUser";
import { SupportProfessional } from "../../../types/supportProfessional";
import { VideoRoomContainer } from "./VideoRoomContainer";

export interface BookingVideoPageProps extends RouteComponentProps {
  bookingId?: string;
}

export const BookingVideoPage = ({ bookingId }: BookingVideoPageProps) => {
  const { user, updateUser } = useContext(AuthContext);

  const { data, error, loading, refetch: refetchBooking } = useQuery<
    Data,
    Variables
  >(BOOKING_AND_VIEWER, {
    variables: {
      bookingId: bookingId || "",
    },
  });

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !user || !data) {
    return <Loading />;
  }

  if (!bookingId) {
    return <Redirect noThrow to="/" />;
  }

  const { booking, viewer } = data;

  const availableUsers: Array<EndUser | SupportProfessional> = [
    ...viewer.endUsers,
    ...viewer.supportProfessionals,
  ];

  return (
    <BookingVideoPageComponent
      booking={booking}
      user={user}
      availableUsers={availableUsers}
      updateUser={updateUser}
      refetchBooking={refetchBooking}
    />
  );
};

const BookingVideoPageComponent = ({
  booking,
  user,
  availableUsers,
  updateUser,
  refetchBooking,
}: {
  booking: Booking;
  user: Pick<IUserContext, "id" | "__typename">;
  availableUsers: Pick<
    EndUser | SupportProfessional,
    "id" | "name" | "profileImage" | "__typename"
  >[];
  updateUser: (
    user: Pick<IUserContext, "id" | "name" | "__typename" | "profileImage">
  ) => void;
  refetchBooking: (
    variables?: { bookingId: string | undefined } | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) => {
  const userInBooking = (user: Pick<IUserContext, "id">) => {
    return (
      user.id === booking.endUser.id ||
      user.id === booking.supportProfessional.id
    );
  };

  const correctUser = availableUsers.find((u) => userInBooking(u));

  // Check if user is part of booking

  useEffect(() => {
    // //  If NOT, switch user
    if (!userInBooking(user) && correctUser) {
      updateUser(correctUser);
    }
  });

  // //  if can't switch user - redirect

  if (!userInBooking(user) && !correctUser) {
    return <Redirect noThrow to="/bookings" />;
  }

  return (
    <VideoRoomContainer
      refetchBooking={refetchBooking}
      booking={booking}
      user={user}
    />
  );
};
