import React from "react";
import moment from "moment";

import { WWCC } from "../../../../types/supportProfessional";

export const WorkingWithChildrenCheck = ({
  wwcc,
  className,
}: {
  wwcc: WWCC;
  className?: string;
}) => {
  return (
    <div className={`${className}`}>
      <div className="flex items-center px-3 mb-2 justify-center">
        <svg className="w-8 h-8 fill-current mr-4" viewBox="0 0 20 20">
          <path d="M10 12a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-3a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm4 2.75V20l-4-4-4 4v-8.25a6.97 6.97 0 0 0 8 0z" />
        </svg>
        <p className="font-bold">NSW Working with Children Check. </p>
      </div>
      <p className="text-sm text-gray-600 text-center mb-1">
        Verified: {moment(wwcc.verificationDate).format("DD/MM/YY")}
      </p>
      <p className="text-sm text-gray-600 text-center mb-1">
        Expires: {moment(wwcc.expiryDate).format("DD/MM/YY")}{" "}
      </p>
    </div>
  );
};
