import React from "react";
import { RouteComponentProps } from "@reach/router";

import { SupportCoordinatorBookingList } from "../users/supportCoordinators/SupportCoordinatorBookingList";
import { useQuery } from "@apollo/client";
import { ErrorPage } from "../layout/ErrorPage";
import { Loading } from "../layout/Loading";
import { IUserContext } from "../../types/profile";

import {
  SUPPORT_COORDINATOR_ACTIVE_BOOKINGS,
  Data,
  Variables,
} from "../../graphql/queries/activeBookings/supportCoordinatorActiveBookings";

export interface SupportCoordinatorBookingsProps extends RouteComponentProps {
  user: Pick<IUserContext, "id">;
}

export const SupportCoordinatorBookings = ({
  user,
}: SupportCoordinatorBookingsProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    SUPPORT_COORDINATOR_ACTIVE_BOOKINGS,
    { variables: { id: user.id } }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { allUpcomingBookings, actionableBookings } = data;

  return (
    <>
      {actionableBookings.length > 0 && (
        <SupportCoordinatorBookingList
          title="Actionable Bookings"
          bookings={actionableBookings}
          showHeaderRow
        />
      )}

      <SupportCoordinatorBookingList
        title="Upcoming Bookings"
        bookings={allUpcomingBookings}
        className="mt-6"
        showHeaderRow
      />
    </>
  );
};
