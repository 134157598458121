import React, { useState } from "react";
import { Link } from "@reach/router";
import { ApolloQueryResult } from "@apollo/client";
import { v4 as uuid } from "uuid";
import { AddToast } from "react-toast-notifications";

import {
  RatePenalty,
  ServiceOffering,
  SupportProfessional,
} from "../../../../types/supportProfessional";
import { humanizeDuration } from "../../../../utils/helpers";
import { Icon } from "../../../layout/Icons";
import { RateRow } from "./RateRow";
import { EditDurationForm } from "./EditDurationForm";
import { EditDeliveryTypesForm } from "./EditDeliveryTypesForm";
import { EditEnabledForm } from "./EditEnabledForm";

const { ToastConsumer } = require("react-toast-notifications");

export const ServiceOfferingSettings = ({
  serviceOffering,
  supportProfessional,
  open,
  refetch,
}: {
  serviceOffering: ServiceOffering;
  supportProfessional: SupportProfessional;
  open: Boolean;
  refetch: (
    variables?: { profileId: string } | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) => {
  const [editingDuration, setEditingDuration] = useState(false);
  const { service, minDurationMins, weekdayRate, ratePeriod } = serviceOffering;
  const [errorMessage, setErrorMessage] = useState(false);

  const billable = weekdayRate.amount > 0;

  const isMeetAndGreet = serviceOffering.service.name === "Meet and Greet";
  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        return (
          <div
            id="service-offering-settings"
            data-testid={`${
              billable ? "" : "non-"
            }billable-service-offering-settings`}
            className="mb-6"
          >
            <div className="so-settings-container">
              <Link
                to={
                  open
                    ? "/settings/services"
                    : `/settings/services/${serviceOffering.id}`
                }
              >
                <div className="flex bg-gray-200 p-3 items-center justify-between cursor-pointer">
                  <p className="text-lg font-bold">{service.name}</p>
                  <Icon
                    name="chevron"
                    className={`w-5 h-5  ${
                      open ? "transform rotate-0" : "transform -rotate-90"
                    }`}
                  />
                </div>
              </Link>
              {open && (
                <div>
                  <ul className="text-gray-400">
                    {isMeetAndGreet && (
                      <li
                        className={`p-5 flex items-center justify-center border-b border-light border-gray-100 hover:text-gray-700 hover:bg-gray-100`}
                      >
                        <EditEnabledForm
                          serviceOffering={serviceOffering}
                          supportProfessional={supportProfessional}
                          refetch={refetch}
                          isMeetAndGreet={isMeetAndGreet}
                        />
                      </li>
                    )}
                    {serviceOffering.enabled && (
                      <>
                        <li
                          className={`py-5 pr-5 sm:pr-12 pl-6 xm:pl-8 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:items-center border-b border-light border-gray-100 hover:text-gray-700 hover:bg-gray-100 transition duration-150 ease-in-out`}
                        >
                          <span className="mb-4 sm:mb-0 text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700 ml-4 col-span-2 sm:col-span-1">
                            {isMeetAndGreet
                              ? "Duration"
                              : "Minimum booking duration"}
                          </span>
                          <div
                            className={`text-gray-800 ${
                              editingDuration ? "w-56" : "w-40"
                            } mr-8 ml-4`}
                          >
                            {editingDuration ? (
                              <EditDurationForm
                                minDurationMins={minDurationMins}
                                supportProfessional={supportProfessional}
                                serviceOffering={serviceOffering}
                                setEditingDuration={setEditingDuration}
                                add={add}
                              />
                            ) : (
                              humanizeDuration(
                                isMeetAndGreet ? 15 : minDurationMins
                              )
                            )}
                          </div>
                          {editingDuration ? (
                            <Icon
                              name="close"
                              className="h3 w-3 cursor-pointer hidden md:block"
                              onClick={() => setEditingDuration(false)}
                            />
                          ) : (
                            <>
                              {!isMeetAndGreet && (
                                <>
                                  <div
                                    className="flex items-center cursor-pointer sm:justify-end"
                                    onClick={() => setEditingDuration(true)}
                                  >
                                    <Icon
                                      name="edit"
                                      className="h-3 w-3 mr-3"
                                    />
                                    <span>Edit</span>
                                  </div>
                                  <div className="hidden transform -rotate-90">
                                    <Icon name="chevron" className="h-5 w-5" />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </li>
                        {!supportProfessional.virtualOnly && (
                          <li className={`transition duration-150 ease-in-out`}>
                            {serviceOffering.service.virtual ? (
                              <>
                                <EditDeliveryTypesForm
                                  supportProfessional={supportProfessional}
                                  serviceOffering={serviceOffering}
                                  refetch={refetch}
                                  setErrorMessage={setErrorMessage}
                                />
                                {errorMessage && (
                                  <div className="bg-blue-100 border-t border-b border-blue-200 text-blue-800 p-3 text-sm">
                                    <p className="text-center">
                                      At least one option must be enabled
                                    </p>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="bg-blue-100 border-t border-b border-blue-200 text-blue-800 p-3 text-sm">
                                <p className="text-center">
                                  This service can be provided in person only
                                </p>
                              </div>
                            )}
                          </li>
                        )}
                      </>
                    )}
                  </ul>

                  {billable ? (
                    <>
                      <div className="bg-gray-100 p-3">
                        <p className="font-bold mb-1">Rates</p>
                        <p className="text-gray-700 text-sm">
                          Please note tappON's 20% service fee will apply to
                          these rates
                        </p>
                      </div>
                      <ul id="rates" className="text-gray-400">
                        {Object.values(RatePenalty).map((rate) => (
                          <RateRow
                            rate={rate}
                            serviceOffering={serviceOffering}
                            ratePeriod={ratePeriod}
                            supportProfessional={supportProfessional}
                            key={uuid()}
                            add={add}
                          />
                        ))}
                      </ul>
                      <Info>
                        If a rate is not set, that time period will default to
                        the weekday rate.
                      </Info>
                    </>
                  ) : (
                    <div className="bg-gray-100 border-gray-200 text-gray-800 p-3 text-sm">
                      <p className="text-center">
                        This service is non billable
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      }}
    </ToastConsumer>
  );
};

const Info = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="alert info">
      <Icon name="info" className="w-6 h-6 md:w-4 md:h-4 mr-6" />
      <p className="">{children}</p>
    </div>
  );
};
