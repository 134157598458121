import React from "react";

import { SupportProfessional } from "../../../../types/supportProfessional";
import { StripeConnectButton } from "../../../users/supportProfessionals/StripeConnectButton";

// TODO: Delete this once the transition to custom accounts is complete

export const OldStripeImplementation = ({
  supportProfessional,
}: {
  supportProfessional: SupportProfessional;
}) => {
  return (
    <>
      {supportProfessional.stripeConnectAccountId ? (
        <p className="mb-4 text-gray-700">
          Your account is succesfully linked to Stripe. Please log in to Stripe
          or contact tappON staff if you would like to change these settings.
        </p>
      ) : (
        <StripeConnectButton supportProfessionalId={supportProfessional.id} />
      )}
    </>
  );
};
