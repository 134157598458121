import React from "react";
import { Formik, Form, FormikHelpers, FormikErrors } from "formik";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import { fundingTypeLabels } from "../../../users/profileHelpers";
import { Button } from "../../../layout/Button";
import {
  UPDATE_END_USER,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateEndUser";
import { useLogger } from "../../../../hooks";
import {
  UpdateSuccess,
  Dropdown,
  InputField as Input,
} from "../../../layout/forms";
import { EndUser, FundingType } from "../../../../types/endUser";

interface FormValues {
  fundingType: FundingType | "";
  ndisParticipantNumber: string;
  otherFundingType: string;
}

export type EndUserT = Pick<
  EndUser,
  "fundingType" | "id" | "ndisParticipantNumber" | "otherFundingType"
>;

export const FundingTypeForm = ({
  endUser,
  add,
}: {
  endUser: EndUserT;
  add: AddToast;
}) => {
  const { log } = useLogger();

  const [updateProfile] = useMutation<NewData, Variables>(UPDATE_END_USER);

  const initialValues: FormValues = {
    fundingType: endUser.fundingType || "",
    ndisParticipantNumber: endUser.ndisParticipantNumber || "",
    otherFundingType: endUser.otherFundingType || "",
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const { fundingType, ndisParticipantNumber, otherFundingType } = values;

    const variables = {
      id: endUser.id,
      fundingType: fundingType || undefined,
      otherFundingType,
      ndisParticipantNumber,
    };

    updateProfile({
      variables,
    })
      .then((res) => {
        formikHelpers.setSubmitting(false);
        formikHelpers.setTouched({});
      })
      .catch((err) => {
        add("There was a problem updating your details", {
          appearance: "error",
          autoDismiss: true,
        });
        log("error", err);
      });
  };

  const NDISFundingTypes = [
    FundingType.NDIS_SELF_MANAGED,
    FundingType.NDIS_PLAN,
    FundingType.NDIA,
  ];

  const fundingTypeOptions = Object.values(FundingType).map((f) => {
    return { text: fundingTypeLabels[f], value: f };
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.fundingType) {
          errors.fundingType = "Required";
        }

        if (
          values.fundingType &&
          NDISFundingTypes.includes(values.fundingType) &&
          !values.ndisParticipantNumber
        ) {
          errors.ndisParticipantNumber = "Required";
        }

        if (
          values.fundingType === FundingType.OTHER &&
          !values.otherFundingType
        ) {
          errors.otherFundingType = "Required";
        }
        return errors;
      }}
    >
      {({ values, submitCount, setFieldValue }) => {
        const displaySaveButton =
          endUser.fundingType !== values.fundingType ||
          (values.ndisParticipantNumber &&
            endUser.ndisParticipantNumber !== values.ndisParticipantNumber) ||
          (values.otherFundingType &&
            endUser.otherFundingType !== values.otherFundingType);

        return (
          <Form>
            <div className="mt-6 sm:mt-5">
              <div className="mt-6 sm:mt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                <div className="pb-2 pr-8">
                  <h3 className="text-base leading-6 font-medium text-gray-900">
                    Funding Type
                  </h3>
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Dropdown
                    noLabel
                    name="fundingType"
                    options={fundingTypeOptions}
                    className="mb-2"
                    blankDefault
                    customHandleChange={() => {
                      setFieldValue("otherFundingType", "");
                      setFieldValue(
                        "ndisParticipantNumber",
                        initialValues.ndisParticipantNumber
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            {values.fundingType &&
              NDISFundingTypes.includes(values.fundingType) && (
                <div className="mt-8 sm:mt-5 ">
                  <div className="mt-6 sm:mt-5">
                    <div className="mt-6 sm:mt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                      <div className="pb-8 pr-8">
                        <h3 className="text-base leading-6 font-medium text-gray-900">
                          NDIS Participant Number
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                          Your NDIS participant number can be found on your
                          approval letter.
                        </p>
                      </div>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Input
                          name="ndisParticipantNumber"
                          noLabel
                          className="mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {values.fundingType === FundingType.OTHER && (
              <div className="mt-8 sm:mt-5 ">
                <div className="mt-6 sm:mt-5">
                  <div className="mt-6 sm:mt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                    <div className="pb-8 pr-8">
                      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Please describe your funding type
                      </p>
                    </div>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Input name="otherFundingType" noLabel className="mb-2" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {displaySaveButton && (
              <div className="flex justify-end">
                <Button type="submit">Save</Button>
              </div>
            )}
            {!displaySaveButton && submitCount > 0 && <UpdateSuccess />}
          </Form>
        );
      }}
    </Formik>
  );
};
