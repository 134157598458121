import React from "react";
import { Link } from "@reach/router";

import { Icon } from "../../../layout/Icons";

export const Alert = ({ text, url }: { text: string; url: string }) => {
  return (
    <div className="rounded-md bg-blue-100 px-6 py-3">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon name="user" className="w-6 h-6 text-blue-800" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-base text-blue-700 p-3">{text}</p>

          <p className="mt-0 text-base md:ml-6 py-3 px-4 rounded-md font-medium text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 transition ease-in-out duration-150">
            <Link
              to={url}
              className="whitespace-no-wrap font-medium text-blue-700 hover:text-blue-600 transition ease-in-out duration-150"
            >
              Settings &rarr;
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
