import React from "react";

import { Flash } from "../../layout/Flash"

export const StripeMessage = ({
  message,
  success,
}: {
  message: string;
  success?: boolean;
}) => {
  return (
    <Flash success={!!success} text={message} />
  );
};
