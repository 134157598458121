import React, { useState } from "react";
import { Field } from "formik";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment, { Moment } from "moment";

import { Availability } from "../../../types/supportProfessional";
import { Label } from ".";

export const DatePicker = ({
  name,
  label,
  disabled,
  rangeStart,
  rangeEnd,
  showClearDate,
  onDateChange,
  unavailableDaysOfWeek = [],
}: {
  name: string;
  label: string;
  disabled?: boolean;
  availabilities: Availability[];
  rangeStart?: Moment;
  rangeEnd?: Moment;
  showClearDate?: boolean;
  onDateChange: (date: Moment) => void;
  unavailableDaysOfWeek?: number[];
}) => {
  const [state, setState] = useState({ datePickerFocused: false });
  const isMobile = window.innerWidth < 768;

  const dateRange = {
    start: rangeStart || moment(),
    end:
      rangeEnd ||
      moment()
        .add(3, "months")
        .subtract(1, "day"),
  };

  return (
    <Field name={name}>
      {(props: { field: any; form: any }) => {
        const { field, form } = props;
        const {
          errors,
          touched,
          setFieldValue,
          setTouched,
          validateForm,
        } = form;

        const error = form.touched[name] && form.errors[name];
        return (
          <div className={`date-picker mb-4 ${error ? "error" : ""}`}>
            <Label className="block" htmlFor={name}>
              {label}
            </Label>
            <SingleDatePicker
              disabled={disabled}
              date={field.value}
              displayFormat="D MMM YYYY"
              focused={!!state.datePickerFocused}
              id={name}
              isDayBlocked={(day: any) => {
                return unavailableDaysOfWeek.includes(moment(day).day());
              }}
              isOutsideRange={(day: any) => {
                return (
                  moment(day).isAfter(dateRange.end, "day") ||
                  moment(day).isBefore(dateRange.start, "day")
                );
              }}
              // HACK this allows setting date state
              // No idea why yet
              keepOpenOnDateSelect
              noBorder
              numberOfMonths={1}
              onDateChange={async (date) => {
                if (date) {
                  onDateChange(date);
                }
                // Have to manually trigger validation here since updating to Formik 2.0. Not sure why.
                await setFieldValue(name, date, true);
                validateForm();

                setTouched({ [name]: true });
                setState({ datePickerFocused: false });
              }}
              onFocusChange={({ focused }) =>
                setState({
                  ...state,
                  datePickerFocused: !!focused,
                })
              }
              placeholder={label}
              withPortal={isMobile}
              showClearDate={showClearDate}
            />
            {errors[name] && touched[name] && (
              <p className="sui-error-message mt-1">{errors[name]}</p>
            )}
          </div>
        );
      }}
    </Field>
  );
};
