import React, { useContext } from "react";
import { Menu, Icon as SemanticIcon } from "semantic-ui-react";
import styled from "styled-components";

import { AuthContext } from "../../../auth/Auth";
import { NavLink } from "../NavLink";
import { breakpoints } from "../../../styles/Styled";
import { Icon } from "../Icons";
import { Button } from "../Button";

const MenuCommon = () => {
  return (
    <NavLink id="home-link" to="/">
      <Icon name="home" />
      Home
    </NavLink>
  );
};

const MenuUser = () => {
  return (
    <>
      <NavLink id="discover-link" to="/discover">
        <Icon name="explore" />
        Discover
      </NavLink>
      <NavLink to="/bookings">
        <Icon name="calendar" />
        Bookings
      </NavLink>
      <NavLink to="/messages">
        <Icon name="envelope" />
        Messages
      </NavLink>
      <NavLink className="mobile-only" to="/settings">
        <Icon name="cog" />
        <span>Settings</span>
      </NavLink>
    </>
  );
};

const TopNav = () => {
  const auth = useContext(AuthContext);

  return (
    <nav className="main-nav md:mx-16 lg:mx-40 xl:mx-64 md:max-w-3xl">
      <MenuCommon />
      {auth.session ? <MenuUser /> : <></>}
    </nav>
  );
};
export default TopNav;

export const SecondaryNav = () => {
  const authContext = useContext(AuthContext);
  const handleLogout = authContext.logout;

  return (
    <>
      <Menu.Item onClick={handleLogout}>
        <MenuIcon name="arrow left" />
        Log Out
      </Menu.Item>
    </>
  );
};

export const CurrentUser = ({
  toggleUserSelect,
}: {
  toggleUserSelect: () => any;
}) => {
  return (
    <div className="flex justify-center mb-20">
      <Button size="xl" onClick={toggleUserSelect}>
        Switch User
      </Button>
    </div>
  );
};

const { small } = breakpoints;

const MenuIcon = styled(SemanticIcon)`
  display: inline-block;

  @media (min-width: ${small}) {
    display: none !important;
  }
  float: left !important;
  margin-right: 25px !important;
  opacity: 0.7 !important;
`;
