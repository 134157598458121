import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { navigate, Link } from "@reach/router";
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

import { PageHeader } from "./Header";
import { Footer } from "./Footer";
import { AuthContext } from "../../auth/Auth";
import { MobileNav } from "./nav/MobileNav";
import {
  ALL_AVAILABLE_PROFILES,
  Data,
} from "../../graphql/queries/allAvailableProfiles";
import { IUserContext, ServiceRole } from "../../types/profile";
import { Loading } from "./Loading";
import { enableAdminOnly } from "../../utils/featureFlags";
import { concatAvailableUsers } from "../../utils/helpers";
import { ErrorPage } from "./ErrorPage";
import { EnvironmentLabel } from "./EnvironmentLabel";
import { TermsModal } from "./TermsModal";
import { Viewer } from "../../types/viewer";
import tapponLogo from "../../assets/images/tappON_partwhite.png";
import { RollbarProvider } from "../../rollbar/Rollbar";

export const PageLayout = ({ children }: { children: React.ReactNode }) => {
  const { updateUser, logout } = useContext(AuthContext);

  const { data, error, loading } = useQuery<Data>(ALL_AVAILABLE_PROFILES);

  if (error) {
    return <ErrorPage error={error.message} deadEnd />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const availableUsers = concatAvailableUsers(data);

  if (availableUsers.length === 0) {
    logout();
    return <></>;
  }

  const switchUser = async (id: string) => {
    const newUser = availableUsers.find((user: IUserContext) => user.id === id);

    if (newUser) await updateUser(newUser);

    navigate("/");
  };

  const firstUser: IUserContext =
    availableUsers.find(
      (u) => u.__typename === ServiceRole.SUPPORT_COORDINATOR
    ) || availableUsers[0];

  return (
    <>
      <EnvironmentLabel />
      <MenuContainer
        userOptions={availableUsers}
        switchUser={switchUser}
        firstUser={firstUser}
        viewer={data.viewer}
      >
        <TermsModal />
        <PageContent admin={enableAdminOnly(data.viewer) ? 1 : 0}>
          {children}
        </PageContent>
        <Footer />
      </MenuContainer>
    </>
  );
};

const MenuContainer = ({
  children,
  firstUser,
  userOptions,
  switchUser,
  viewer,
}: {
  children: React.ReactNode;
  firstUser: IUserContext;
  userOptions: IUserContext[];
  switchUser: (id: string) => void;
  viewer: Pick<Viewer, "id" | "role">;
}) => {
  const { user, logout, updateUser } = useContext(AuthContext);

  const [sidebarOpened, setSidebarOpened] = useState(false);

  const hideSideBar = () => {
    setSidebarOpened(false);
    setUserSelect(false);
  };

  const [userSelect, setUserSelect] = useState(false);

  const toggleUserSelect = () => setUserSelect(userSelect ? false : true);

  const openUserOptions = () => {
    setSidebarOpened(true);
    setUserSelect(true);
  };

  const scrollLocked = sidebarOpened ? "hidden" : "scroll";

  useEffect(() => {
    document.body.style.overflow = scrollLocked;

    if (!user) {
      if (firstUser) updateUser(firstUser);
    }

    return function cleanup() {
      document.body.style.overflow = "scroll";
    };
  });

  const selected = user ? user.id : firstUser.id;
  const selectedUser = userOptions.find((user: any) => {
    return user.id === selected;
  });

  if (!selectedUser) {
    logout();
    return <></>;
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <>
      <Responsive minWidth={769}>
        <Visibility once={false}>
          <MenuWrapper inverted textAlign="center" vertical>
            <PageHeader
              userOptions={userOptions}
              switchUser={switchUser}
              handleLogout={logout}
              openUserOptions={openUserOptions}
              user={user}
              viewer={viewer}
            />
          </MenuWrapper>
          {/* Moved RollbarProvider here to ensure the user is set correctly for personData */}
          <RollbarProvider user={user}>{children}</RollbarProvider>
        </Visibility>
      </Responsive>

      {/* Sidebar */}
      <Responsive
        id="transform-element"
        as={Sidebar.Pushable}
        maxWidth={768}
        className="h-screen"
      >
        <MobileNav
          hideSidebar={hideSideBar}
          sidebarOpened={sidebarOpened}
          userSelect={userSelect}
          toggleUserSelect={toggleUserSelect}
          userOptions={userOptions}
          switchUser={switchUser}
          user={user}
          viewer={viewer}
        />

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <MenuWrapper inverted textAlign="center" vertical>
            <Container>
              <Menu
                inverted
                pointing
                secondary
                size="large"
                style={{ borderColor: "rgb(61, 128, 134)" }}
              >
                <Menu.Item onClick={() => setSidebarOpened(true)}>
                  <Icon name="sidebar" size="big" />
                </Menu.Item>
                <Link to="/">
                  <img
                    alt="tappON logo"
                    src={tapponLogo}
                    className="tappon-logo"
                  />
                </Link>
              </Menu>
            </Container>
          </MenuWrapper>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    </>
  );
};

export interface UserOption {
  id: string;
  name: string;
  role: ServiceRole;
  image: {
    avatar: boolean;
    src: string;
  };
}

const PageContent = styled(Container)`
  &&& {
    min-height: calc(90vh - ${(props) => (props.admin ? "108" : "79")}px);
    margin: auto !important;
    width: 100% !important;

    /* Portrait and Landscape */
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
      min-height: -webkit-fill-available;
    }
  }
`;

const MenuWrapper = styled(Segment)`
  &&& {
    background-color: rgb(61, 128, 134);
  }
`;
