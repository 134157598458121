import gql from "graphql-tag";

import { SupportProfessional } from "../../types/supportProfessional";
import { SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";

export interface Data {
  supportProfessional: SupportProfessional;
}

export interface Variables {
  profileId: string;
}

export const SUPPORT_PROFESSIONAL = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}
  query SupportProfessional($profileId: ID!) {
    supportProfessional(id: $profileId) {
      ...SupportProfessionalFields
    }
  }
`;