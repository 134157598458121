import React from "react";
import { Segment, Header, Icon, Button } from "semantic-ui-react";
import { Link } from "@reach/router";

import { IUserContext, ServiceRole } from "../../types/profile";

export const NoMessages = ({
  user,
}: {
  user: Pick<IUserContext, "__typename">;
}) => {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name="envelope open outline" />
        You have no messages yet
      </Header>
      {user.__typename === ServiceRole.END_USER && (
        <Link to="/discover">
          <Button primary>Browse Support Professionals</Button>
        </Link>
      )}
      {user.__typename === ServiceRole.SUPPORT_WORKER && (
        <p className="text-center font-light text-gray-900">
          When a member sends you a message you will receive an email
          notification and it will appear here.
        </p>
      )}
    </Segment>
  );
};
