import gql from "graphql-tag";

import { Booking } from "../../types/booking";
import { Viewer } from "../../types/viewer";
import { BOOKING_FIELDS } from "../fragments";

export interface Data {
  bookingInProgress: Booking | null;
  viewer: Viewer;
}

export interface Variables {
  serviceRoleId: string;
}

export const BOOKING_IN_PROGRESS_QUERY = gql`
  ${BOOKING_FIELDS}

  query BookingInProgress($serviceRoleId: ID!) {
    bookingInProgress(serviceRoleId: $serviceRoleId) {
      ...BookingFields
    }

    viewer {
      id
      role
    }
  }
`;
