import React from "react";
import moment from "moment";

import { ServiceRole, IUserContext } from "../../types/profile";
import { Booking, PaymentFlow } from "../../types/booking";
import { paymentTypeMessages } from "./BookingHelpers";
import { Icon } from "../layout/Icons";

export const PaymentTypeMessage = ({
  booking,
  user,
}: {
  booking: Pick<Booking, "paymentFlow" | "spPaid">;
  user: Pick<IUserContext, "__typename">;
}) => {
  const { paymentFlow, spPaid } = booking;
  const message = paymentTypeMessages[paymentFlow][user.__typename];

  const isFastBooking = paymentFlow === PaymentFlow.CREDIT_CARD;
  const isSupportProfessional = user.__typename === ServiceRole.SUPPORT_WORKER;

  return (
    <div className="payment-message payment-type">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            name={isFastBooking ? "creditCard" : "bank"}
            className="payment-message-icon"
          />
        </div>
        <div className="ml-3">
          <h3 className="payment-message-header">
            Payment Type: {isFastBooking ? "Fast" : "Transfer"}
          </h3>
          {(!isSupportProfessional || !spPaid) && (
            <div className="payment-message-text mt-2">
              <p>{message}</p>
            </div>
          )}
          {isSupportProfessional && spPaid && (
            <div className="sp-paid-date">
              This payment was transferred to you on{" "}
              {moment(spPaid).format("MMMM Do YYYY")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
