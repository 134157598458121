import React from "react";
import { FlagNameValues } from "semantic-ui-react";

import { EndUser, FundingType } from "../../types/endUser";
import { Message } from "../../types/message";
import { IUserContext, ServiceRole } from "../../types/profile";
import { SupportCoordinator } from "../../types/supportCoordinator";
import {
  SupportProfessional,
  Certification,
} from "../../types/supportProfessional";
import { Viewer } from "../../types/viewer";

export function isSupportProfessional(
  party: SupportProfessional | EndUser
): party is SupportProfessional {
  return (party as SupportProfessional).serviceOfferings !== undefined;
}

export function isSupportProfessionalUser(user: IUserContext): boolean {
  return user.__typename === ServiceRole.SUPPORT_WORKER;
}

// Who will you reply to on a message? Avoids replying to yourself
export function replyTo(
  message: Message,
  user: IUserContext
): Pick<SupportProfessional | EndUser, "id" | "name"> {
  return isSupportProfessionalUser(user)
    ? message.endUser
    : message.supportProfessional;
}

// Who will you send from on a message?
export function sendFrom(
  message: Message,
  user: IUserContext
): Pick<SupportProfessional | EndUser, "id" | "name"> {
  return isSupportProfessionalUser(user)
    ? message.supportProfessional
    : message.endUser;
}

export interface Rate {
  rate: number;
  ratePeriod: string;
}

export const fundingTypeLabels: { [key in FundingType]: string } = {
  [FundingType.SELF_FUNDED]: "self funded",
  [FundingType.NDIS_SELF_MANAGED]: "NDIS self managed",
  [FundingType.NDIS_PLAN]: "NDIS plan managed",
  [FundingType.NDIA]: "NDIA managed",
  [FundingType.AGED_CARE]: "Aged Care package",
  [FundingType.OTHER]: "other",
};

export const supportHours: { [key: string]: string } = {
  "1 - 10": "1_10",
  "10 - 20": "10_20",
  "20 - 30": "20_30",
  "30+": "30+",
};

export const languageMap: {
  [key: string]: { label: string; flag: FlagNameValues };
} = {
  ENGLISH: { label: "English", flag: "uk" },
  RUSSIAN: { label: "Russian", flag: "ru" },
  ITALIAN: { label: "Italian", flag: "it" },
  HINDI: { label: "Hindi", flag: "in" },
  HUNGARIAN: { label: "Hungarian", flag: "hu" },
  FRENCH: { label: "French", flag: "fr" },
  MANDARIN: { label: "Mandarin", flag: "cn" },
  CANTONESE: { label: "Cantonese", flag: "cn" },
  FILIPINO: { label: "Filipino", flag: "ph" },
};

export const certificationMap: {
  [key: string]: string;
} = {
  DIPLOMA: "Diploma",
  BACHELORS_DEGREE: "Bachelor",
  MASTERS_DEGREE: "Master",
  PHD_DEGREE: "PhD",
  CERTIFICATE_III: "Cert III",
  CERTIFICATE_IV: "Cert IV",
  ESSA_ACCREDITED: "ESSA",
  FIRST_AID: "First Aid",
  REGISTERED_NURSE: "Registered Nurse",
};

export const ratePeriodMap: {
  [key: string]: string;
} = {
  PER_HOUR: "hour",
  PER_SESSION: "session",
  PER_DAY: "day",
  PER_OVERNIGHT: "overnight stay",
};

export function convertCertification(cert: Certification): React.ReactNode {
  if (cert.type === "FIRST_AID") {
    return <span className="font-bold">First Aid</span>;
  } else if (cert.type === "OTHER") {
    return <span className="font-bold">{cert.title}</span>;
  } else {
    return (
      <div>
        <span className="font-bold">{certificationMap[cert.type]}</span> of{" "}
        {cert.title}
      </div>
    );
  }
}

export const checkAvailability = (
  dayNumber: number,
  supportProfessional: Pick<SupportProfessional, "availabilities">
) => {
  const availability = supportProfessional.availabilities.find(
    (a) => a.dayNumber === dayNumber
  );
  return !availability || availability.available;
};

export interface AvailableUsersData {
  viewer: Viewer;
  adminSupportCoordinators: Pick<
    SupportCoordinator,
    "id" | "firstName" | "lastName" | "profileImage" | "__typename"
  >[];
}
