import gql from "graphql-tag";
import { Moment } from "moment";

import {
  Booking,
  DeliveryType,
  PaymentFlow,
  DayName,
  RecurringBookingSchedule,
} from "../../types/booking";
import { BOOKING_FIELDS } from "../fragments";

export type RecurringBookingScheduleT = Required<
  Pick<
    RecurringBookingSchedule,
    "id" | "rrule" | "bookings" | "projectedBookings"
  >
>;

export interface NewData {
  createBooking: Booking | RecurringBookingScheduleT;
}

export interface Variables {
  serviceOfferingId: string;
  startTime: Moment;
  endTime: Moment;
  supportProfessionalId: string;
  endUserId: string;
  paymentFlow: PaymentFlow | null;
  specialInstructions: string;
  acceptedTerms: boolean;
  deliveryType: DeliveryType;
  supportCoordinatorId: string | null;
  recurring: boolean;
  days: DayName[];
  interval: number;
  until: Moment | null;
}

// BookingForm
export const CREATE_BOOKING_MUTATION = gql`
  ${BOOKING_FIELDS}

  mutation CreateBooking(
    $serviceOfferingId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $supportProfessionalId: ID!
    $endUserId: ID!
    $paymentFlow: PaymentFlow
    $specialInstructions: String
    $acceptedTerms: Boolean!
    $deliveryType: DeliveryType
    $supportCoordinatorId: ID
    $recurring: Boolean!
    $days: [String]!
    $interval: Integer!
    $until: DateTime
    $durationMinutes: Integer!
  ) {
    createBooking(
      input: {
        serviceOfferingId: $serviceOfferingId
        startTime: $startTime
        endTime: $endTime
        endUserId: $endUserId
        supportProfessionalId: $supportProfessionalId
        acceptedTerms: $acceptedTerms
        specialInstructions: $specialInstructions
        paymentFlow: $paymentFlow
        deliveryType: $deliveryType
        supportCoordinatorId: $supportCoordinatorId
        recurring: $recurring
        days: $days
        interval: $interval
        until: $until
        durationMinutes: $durationMinutes
      }
    ) {
      ... on Booking {
        ...BookingFields
      }
      ... on RecurringBookingSchedule {
        id
        rrule
        bookings {
          ...BookingFields
        }
        projectedBookings(limit: 1) {
          ...BookingFields
        }
      }
    }
  }
`;
