import React, { useContext, useState } from "react";
import { Router as ReachRouter, Redirect } from "@reach/router";

import { SupportProfessionalProfilePage } from "./users/supportProfessionals/profilePage/SupportProfessionalProfilePage";
import { BookingsPage } from "./bookings/BookingsPage";
import { EndUserDashboardPage } from "./users/endUsers/EndUserDashboardPage";
import { ServicePage } from "./users/supportProfessionals/ServicePage";
import { EndUserProfilePage } from "./users/endUsers/EndUserProfilePage";
import { SupportCoordinatorProfilePage } from "./users/supportCoordinators/SupportCoordinatorProfilePage";
import { DiscoverPage } from "./pages/discover/DiscoverPage";
import { CallbackPage } from "../auth/CallbackPage";
import { SearchResultsPage } from "./pages/SearchResultsPage";
import { SupportProfessionalDashboardPage } from "./users/supportProfessionals/SupportProfessionalDashboardPage";
import { SupportCoordinatorDashboardPage } from "./users/supportCoordinators/SupportCoordinatorDashboardPage";
import { EditSupportProfessionalPage } from "./users/supportProfessionals/EditSupportProfessionalPage";
import { InboxPage } from "./messages/InboxPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { ServiceRole } from "../types/profile";
import { AuthContext } from "../auth/Auth";
import { SwitchUserPage } from "./pages/SwitchUserPage";
import { SettingsPage } from "./pages/settings/SettingsPage";
import { Loading } from "./layout/Loading";
import { NewBookingPage } from "./bookings/new/NewBookingPage";
import { SignupPage } from "./pages/signup/SignupPage";
import { SignInPage } from "./pages/signup/SignInPage";
import { ManageClientsPage } from "./users/supportCoordinators/ManageClientsPage";
import { ManageClientPage } from "./users/supportCoordinators/ManageClientPage";

export interface RouterProps {
  unauthenticated?: boolean;
}

export const Router = ({ unauthenticated }: RouterProps) => {
  const { session, user } = useContext(AuthContext);
  const [firstVisitDashboard, setFirstVisitDashboard] = useState(true);

  const setVisited = () => {
    setFirstVisitDashboard(false);
  };

  if (!unauthenticated && !session) {
    return <Loading />;
  }

  if (unauthenticated) {
    return (
      <ReachRouter className="flex flex-grow h-full">
        <CallbackPage path="/callback/:action" />
        <SignupPage path="/signup" />
        <SignInPage path="/signin" default />
      </ReachRouter>
    );
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <ReachRouter className="min-h-inherit">
      <BookingsPage path="/bookings/*" user={user} />
      <CallbackPage path="/callback/:action" />
      <DiscoverPage path="/discover" user={user} />
      {user.__typename === ServiceRole.END_USER && (
        <>
          <EndUserDashboardPage
            path="/"
            user={user}
            setVisited={setVisited}
            firstVisit={firstVisitDashboard}
          />
          <NewBookingPage
            path="/worker/profile/:id/new-booking"
            // Rebuilding user object here because typename can never be ServiceRole.SUPPORT_WORKER
            user={{ id: user.id, __typename: user.__typename }}
          />
          <Redirect noThrow from="/profile" to={`/user/profile/${user.id}`} />
        </>
      )}
      {user.__typename === ServiceRole.SUPPORT_WORKER && (
        <>
          <SupportProfessionalDashboardPage path="/" user={user} />
          <Redirect noThrow from="/profile" to={`/worker/profile/${user.id}`} />
          <Redirect
            noThrow
            from="/worker/profile/:id/new-booking"
            to={`/worker/profile/:id`}
          />
        </>
      )}
      {user.__typename === ServiceRole.SUPPORT_COORDINATOR && (
        <>
          <SupportCoordinatorDashboardPage path="/" user={user} />
          <ManageClientsPage path="/clients" user={user} />
          <ManageClientPage
            path="/user/profile/:profileId/manage"
            user={user}
          />
          <NewBookingPage
            path="/worker/profile/:id/new-booking"
            // Rebuilding user object here because typename can never be ServiceRole.SUPPORT_WORKER
            user={{ id: user.id, __typename: user.__typename }}
          />
          <Redirect
            noThrow
            from="/profile"
            to={`/support-coordinator/profile/${user.id}`}
          />
        </>
      )}

      <EndUserProfilePage path="/user/profile/:profileId" user={user} />
      <SupportCoordinatorProfilePage
        path="/support-coordinator/profile/:profileId"
        user={user}
      />
      <EditSupportProfessionalPage
        path={`/worker/profile/${user.id}/edit`}
        profileId={user.id}
      />
      <InboxPage path="/messages/:userIds" user={user} />
      <InboxPage path="/messages" user={user} />
      <Redirect
        noThrow
        from="/worker/profile/:profileId/edit"
        to="/worker/profile/:profileId"
      />
      <Redirect
        noThrow
        from="/user/profile/:profileId/edit"
        to="/user/profile/:profileId"
      />
      <SearchResultsPage path="/search" user={user} />
      <ServicePage path="/services/:serviceSlug" user={user} />
      <SettingsPage path="/settings/*" user={user} />
      <SupportProfessionalProfilePage
        path="worker/profile/:profileId"
        user={user}
      />
      <SwitchUserPage path="/switch-user" user={user} />
      <Redirect noThrow from="/signup" to="/" />
      <NotFoundPage default />
    </ReachRouter>
  );
};
