import React, { Dispatch, SetStateAction } from "react";

export interface DropdownListItemProps {
  children: React.ReactNode;
  userChoice?: boolean;
  onListItemClick?: (id: string) => void;
  userId?: string;
  hideDropdown: Dispatch<SetStateAction<boolean>>;
  hoverable?: boolean;
}

export const DropdownListItem = ({
  children,
  userChoice,
  onListItemClick,
  userId,
  hideDropdown,
  hoverable,
}: DropdownListItemProps) => {
  const switchUser = () => {
    if (onListItemClick) {
      if (userId) {
        onListItemClick(userId);
      }
    }
    hideDropdown(false);
  };

  const hoverBgClass = hoverable ? "hover:bg-gray-100" : null;
  const justifyClass = userChoice ? " justify-start" : " justify-center";
  const heightClass = userChoice ? "h-16" : "h-12";

  return (
    <li
      onClick={switchUser}
      className={`flex cursor-pointer bg-white py-1 text-base ${hoverBgClass} ${justifyClass} items-center pl-8 pr-10 ${heightClass} text-black`}
    >
      {children}
    </li>
  );
};
