import React from "react";
import { useQuery } from "@apollo/client";
import { RouteComponentProps, Link } from "@reach/router";

import { BookingList } from "../../bookings/BookingList";
import { ConversationList } from "../../messages/ConversationList";
import { Booking, BookingState } from "../../../types/booking";
import { IUserContext } from "../../../types/profile";
import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import {
  END_USER_DASHBOARD_QUERY,
  Data,
  Variables,
} from "../../../graphql/queries/dashboard/endUserDashboard";
import { Icon } from "../../layout/Icons";
import { VirtualBookingAlert } from "../../bookings/VirtualBookingAlert";
import { Button } from "../../layout/Button";
import { CrewList } from "./CrewList";
import { CompleteProfilePrompt } from "./CompleteProfile/CompleteProfilePrompt";
import { concatProjectedBookingsForProfile } from "../../bookings/BookingHelpers";

export interface EndUserDashboardPageProps extends RouteComponentProps {
  user: Pick<IUserContext, "id" | "__typename">;
  setVisited: () => void;
  firstVisit: boolean;
}

export const EndUserDashboardPage = ({
  user,
  setVisited,
  firstVisit,
}: EndUserDashboardPageProps) => {
  const variables = {
    endUserId: user.id,
  };

  const { data, error, loading } = useQuery<Data, Variables>(
    END_USER_DASHBOARD_QUERY,
    {
      variables,
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const {
    activeBookings,
    inactiveBookings,
    conversations,
    endUser,
    account,
    crewListForEndUser,
  } = data;

  const projectedBookings = concatProjectedBookingsForProfile(endUser);

  const upcomingBookings = [...activeBookings, ...projectedBookings];

  const confirmedBookings = upcomingBookings.filter(
    (b: Booking) =>
      (b.state === BookingState.ACCEPTED_BY_SUPPORT_WORKER ||
        b.state === BookingState.ACCEPTED_IN_PENALTY_PERIOD) &&
      !b.paymentFailed
  );

  const completedBookings = upcomingBookings.filter(
    (b: Booking) => b.state === BookingState.COMPLETED
  );

  const pendingBookings = upcomingBookings.filter(
    (b: Booking) => b.state === BookingState.REQUESTED_BY_END_USER
  );

  const failedPayments = upcomingBookings.filter(
    (b: Booking) => b.paymentFailed
  );

  return (
    <div className="page-wrapper">
      <VirtualBookingAlert user={user} />

      <div className="dashboard-extra-content">
        {activeBookings.length === 0 && inactiveBookings.length === 0 && (
          <h1>Welcome to your dashboard</h1>
        )}

        <CompleteProfilePrompt
          endUser={endUser}
          account={account}
          firstVisit={firstVisit}
          setVisited={setVisited}
          user={user}
        />
      </div>

      <div className="flex flex-wrap">
        <div
          data-testid="your-crew"
          className="w-full xm:w-3/12 p-3 order-2 xm:order-1"
        >
          <CrewList crew={crewListForEndUser} />
        </div>
        <div
          data-testid="eu-bookings-list"
          className="w-full xm:w-5/12 p-3 order-1 xm:order-2"
        >
          <h2>Bookings</h2>
          {failedPayments.length > 0 && (
            <BookingList
              title="Bookings Needing Action"
              bookings={failedPayments}
              showState
              emptyStateMessage="No bookings needing action"
              refetchQueries={[{ query: END_USER_DASHBOARD_QUERY, variables }]}
              user={user}
            />
          )}
          {completedBookings.length > 0 && (
            <BookingList
              title="Bookings Awaiting Approval"
              bookings={completedBookings}
              showState
              emptyStateMessage="No bookings awaiting approval"
              refetchQueries={[{ query: END_USER_DASHBOARD_QUERY, variables }]}
              user={user}
            />
          )}
          {pendingBookings.length > 0 && (
            <BookingList
              title="Pending Booking Requests"
              bookings={pendingBookings}
              showState
              emptyStateMessage="No pending booking requests"
              user={user}
            />
          )}
          {confirmedBookings.length > 0 && (
            <BookingList
              title="Upcoming Confirmed Bookings"
              bookings={confirmedBookings}
              showState
              emptyStateMessage="No upcoming bookings"
              user={user}
            />
          )}
          {upcomingBookings.length === 0 && (
            <div className="message info">
              <Icon name="calendar" className="w-5 h-5" />
              <p className="mb-4">
                Any upcoming or actionable bookings will appear here
              </p>
              <Button to="/discover">Browse support professionals</Button>
            </div>
          )}
        </div>
        <div
          data-testid="eu-conversations"
          className="w-full xm:w-4/12 p-3 order-last"
        >
          <h2>Inbox</h2>
          {conversations.length > 0 ? (
            <ConversationList conversations={conversations} user={user} />
          ) : (
            <div className="message info">
              <Icon name="envelope" />
              <p className="mb-2">New conversations will appear here.</p>
              <p>
                You can contact
                <Link className="font-bold mx-1" to="/discover">
                  Support Professionals
                </Link>
                directly from their profiles
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
