import React from "react";
import { Field, FieldProps } from "formik";
import { v4 as uuid } from "uuid";

export interface Option {
  text: string;
  value: string | number;
}

export interface RadioProps {
  name: string;
  options: Option[];
  customHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  layout?: "horizontal" | "vertical";
}

export interface FormValues {
  [key: string]: any;
}

// This component must exist inside a Formik <Form> component
export const Radio = ({
  name,
  options,
  customHandleChange,
  layout = "vertical",
}: RadioProps) => {
  return (
    <Field name={name}>
      {({ form, field, meta }: FieldProps) => {
        const { onChange } = field;
        const { values, touched, setTouched } = form;

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setTouched({ ...touched, [name]: true });
          onChange(event);

          if (customHandleChange) {
            customHandleChange(event);
          }
        };

        return (
          <>
            <div
              className={`${layout === "vertical" ? "" : "flex items-center"} ${
                layout === "vertical" ? "space-y-4" : "space-x-4"
              }`}
            >
              {options.map((o) => {
                const { text, value } = o;
                return (
                  <div className={`flex items-center`} key={uuid()}>
                    <input
                      id={`${name}-${value}-input`}
                      value={value}
                      name={name}
                      type="radio"
                      checked={values[name] === value}
                      className="form-radio h-4 w-4 text-teal-600 transition duration-150 ease-in-out cursor-pointer"
                      onChange={handleChange}
                    />
                    <label
                      htmlFor={`${name}-${value}-input`}
                      className="ml-3 cursor-pointer"
                    >
                      <span className="block text-sm leading-5 font-medium text-gray-700">
                        {text}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        );
      }}
    </Field>
  );
};
