import React from "react";
import { Link } from "@reach/router";

export const NavLink = (props: any) => {
  const { className } = props;
  return (
    <Link
      {...props}
      getProps={({ href, isCurrent, isPartiallyCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        const isActive = href === "/" ? isCurrent : isPartiallyCurrent;
        return {
          className: `${className ? className : ""} ${
            isActive ? "active" : ""
          }`,
        };
      }}
    />
  );
};
