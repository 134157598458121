import gql from "graphql-tag";

import { Booking } from "../../../types/booking";
import { Conversation } from "../../../types/message";
import { EndUser } from "../../../types/endUser";
import {
  BOOKING_FIELDS,
  CONVERSATION_FIELDS,
  END_USER_FIELDS,
  SUPPORT_PROFESSIONAL_FIELDS,
} from "../../fragments";
import { SupportProfessional } from "../../../types/supportProfessional";
import { RequiredBy } from "../../../types/helpers";

export interface Data {
  endUser: RequiredBy<EndUser, "bookingSchedules">;
  activeBookings: Booking[];
  inactiveBookings: Booking[];
  conversations: Conversation[];
  account: {
    id: string;
    email: string;
  };
  crewListForEndUser: SupportProfessional[];
}

export interface Variables {
  endUserId: string;
}

export const END_USER_DASHBOARD_QUERY = gql`
  ${BOOKING_FIELDS}
  ${CONVERSATION_FIELDS}
  ${END_USER_FIELDS}
  ${SUPPORT_PROFESSIONAL_FIELDS}

  query EndUserDashboardQuery($endUserId: ID!) {
    endUser(id: $endUserId) {
      ...EndUserFields
      bookingSchedules {
        id
        rrule
        projectedBookings(limit: 2) {
          ...BookingFields
        }
      }
    }
    activeBookings: activeBookingsForEndUser(id: $endUserId) {
      ...BookingFields
    }
    inactiveBookings: inactiveBookingsForEndUser(id: $endUserId) {
      id
    }
    conversations: conversationsForEndUser(endUserId: $endUserId) {
      ...ConversationFields
    }
    account(serviceRoleId: $endUserId) {
      id
      email
    }
    crewListForEndUser(endUserId: $endUserId) {
      ...SupportProfessionalFields
    }
  }
`;
