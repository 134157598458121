import React, { useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import { AuthContext } from "./Auth";
import { Loading } from "../components/layout/Loading";

interface CallbackPageProps extends RouteComponentProps {
  action?: string;
}

export const CallbackPage = (props: CallbackPageProps) => {
  let message;
  switch (props.action) {
    case "auth0":
      message = CallbackAuth(props);
      break;
    default:
      message = <></>;
  }

  return <>{message}</>;
};

const CallbackAuth = (props: CallbackPageProps) => {
  const authContext = useContext(AuthContext);
  if (
    props.action === "auth0" &&
    props.location &&
    /access_token|id_token|error/.test(props.location.hash)
  ) {
    authContext.handleAuthentication();
  }

  return <Loading />;
};
