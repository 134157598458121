import React, { useEffect } from "react";
import { Formik, Form, FormikHelpers, FormikErrors, FormikProps } from "formik";
import { useMutation } from "@apollo/client";
import moment from "moment";

import { EndUser } from "../../../../types/endUser";
import { DateOfBirthFields } from "./DateOfBirthFields";
import { Button } from "../../../layout/Button";
import { RECOMMENDED_SUPPORT_PROFESSIONALS } from "../../../../graphql/queries/recommendedSupportProfessionals/recommendedSupportProfessionals";
import { useLogger } from "../../../../hooks";
import { parseDateValues } from "../../../../utils/helpers";
import { UpdateSuccess } from "../../../layout/forms";
import { AddToast } from "react-toast-notifications";
import { ServiceRole } from "../../../../types/profile";
import {
  UPDATE_END_USER,
  NewData as EUNewData,
  Variables as EUVariables,
} from "../../../../graphql/mutations/updateEndUser";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData as SPNewData,
  Variables as SPVariables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import { END_USER_PERMISSIONS_QUERY } from "../../../../graphql/queries/endUserPermissions";

interface FormValues {
  dobDay: number;
  dobMonth: string;
  dobYear: number;
}

export const DateOfBirthForm = ({
  profile,
  add,
}: {
  profile: Pick<EndUser, "id" | "__typename" | "dateOfBirth">;
  add: AddToast;
}) => {
  const { log } = useLogger();
  const isEndUser = profile.__typename === ServiceRole.END_USER;
  const mutation = isEndUser ? UPDATE_END_USER : UPDATE_SUPPORT_PROFESSIONAL;

  const [updateProfile] = useMutation<
    EUNewData | SPNewData,
    EUVariables | SPVariables
  >(mutation);

  const initialValues: FormValues = {
    dobDay: profile.dateOfBirth ? moment(profile.dateOfBirth).date() : 0,
    dobMonth: profile.dateOfBirth
      ? moment(profile.dateOfBirth).format("MMMM")
      : "",
    dobYear: profile.dateOfBirth ? moment(profile.dateOfBirth).year() : 0,
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const dateOfBirth = parseDateValues(values).format("YYYY-MM-DD");

    const variables = {
      id: profile.id,
      dateOfBirth,
    };

    const refetchQueries = isEndUser
      ? [
          {
            query: END_USER_PERMISSIONS_QUERY,
            variables: { endUserId: profile.id },
          },

          {
            query: RECOMMENDED_SUPPORT_PROFESSIONALS,
            variables: { endUserId: profile.id },
          },
        ]
      : [];

    updateProfile({
      variables,
      refetchQueries,
    })
      .then((res) => {
        formikHelpers.setSubmitting(false);
        formikHelpers.setTouched({});
      })
      .catch((err) => {
        log("error", err);
        add("There was an error updating your date of birth", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};

          const dateOfBirth = parseDateValues(values);

          if (!values.dobDay) {
            errors.dobDay = "Required";
          }

          if (!values.dobMonth) {
            errors.dobMonth = "Required";
          }

          if (!values.dobYear) {
            errors.dobYear = "Required";
          }

          if (!dateOfBirth.isValid()) {
            errors.dobDay = "Required";
            errors.dobMonth = "Required";
            errors.dobYear = "Required";
          }

          if (moment().diff(dateOfBirth, "years") < 7) {
            errors.dobDay = "Must be at least 7 years old";
            errors.dobMonth = "Must be at least 7 years old";
            errors.dobYear = "Must be at least 7 years old";
          }

          return errors;
        }}
      >
        {(props) => (
          <DateOfBirthFormComponent formikProps={props} profile={profile} />
        )}
      </Formik>
    </>
  );
};

const DateOfBirthFormComponent = ({
  formikProps,
  profile,
}: {
  formikProps: FormikProps<FormValues>;
  profile: Pick<EndUser, "id" | "dateOfBirth">;
}) => {
  const { validateForm, values, errors, touched, submitCount } = formikProps;
  // Show missing fields on page load
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const displaySaveButton = !parseDateValues(values).isSame(
    moment(profile.dateOfBirth),
    "date"
  );

  return (
    <Form>
      {!profile.dateOfBirth && (
        <p className="text-red-600 text-sm mb-4">
          This is required before making bookings
        </p>
      )}
      <DateOfBirthFields values={values} errors={errors} touched={touched} />
      {displaySaveButton && (
        <div className="flex justify-end">
          <Button type="submit">Save</Button>
        </div>
      )}
      {!displaySaveButton && submitCount > 0 && <UpdateSuccess />}
    </Form>
  );
};
