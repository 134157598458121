import React from "react";
import { RouteComponentProps, Redirect, Link } from "@reach/router";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import { Booking, BookingState } from "../../../types/booking";
import { BookingList } from "../BookingList";
import { ServiceRole, IUserContext } from "../../../types/profile";
import { Button } from "../../layout/Button";
import { BookingsOnDate } from "./BookingsOnDate";
import { UpcomingNav } from "./Nav";
import {
  upcomingBookingsQueryForUserType,
  Data,
  Variables,
} from "../../../graphql/queries/upcomingBookings";
import { ActionableBookings } from "./ActionableBookings";
import { concatProjectedBookingsForProfile } from "../BookingHelpers";

export interface UpcomingBookingsProps extends RouteComponentProps {
  date?: string;
  user: Pick<IUserContext, "id" | "__typename">;
}

export const UpcomingBookings = ({ date, user }: UpcomingBookingsProps) => {
  const { id, __typename } = user;

  const today = moment().format("YYYY-MM-DD");

  const variables = { id, date: date || today };

  const { data, error, loading } = useQuery<Data, Variables>(
    upcomingBookingsQueryForUserType[__typename],
    {
      variables,
      fetchPolicy: "no-cache",
    }
  );

  // Redirect to today is no date is passed in or if the date
  // falls outside of the 3 month range
  if (
    date &&
    !moment(date).isBetween(
      moment().subtract(1, "day"),
      moment().add(3, "month")
    )
  ) {
    return <Redirect noThrow to={`/bookings/date/all`} />;
  }

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  const profile = data && (data.supportProfessional || data.endUser);

  const projectedBookings = profile
    ? concatProjectedBookingsForProfile(profile)
    : [];

  const upcomingBookings =
    data &&
    data.allUpcomingBookings
      .filter((b: Booking) =>
        user.__typename === ServiceRole.END_USER
          ? b.state !== BookingState.COMPLETED
          : b.state !== BookingState.REQUESTED_BY_END_USER
      )
      .concat(projectedBookings)
      .sort((a, b) => {
        return moment(a.startTime).isAfter(moment(b.startTime)) ? 1 : -1;
      });

  return (
    <>
      <ActionableBookings user={user} />

      {upcomingBookings && upcomingBookings.length === 0 ? (
        <div className="message info">
          <p className="mb-4">You have no upcoming bookings at this stage</p>
          {user.__typename === ServiceRole.END_USER ? (
            <Link to="/discover" className="block">
              <Button className="m-auto">Browse Support Professionals</Button>
            </Link>
          ) : (
            <>
              <p className="mb-4">
                Keep your profile up to date to receive relevant booking
                requests
              </p>
              <Link to={`/profile`} className="block">
                <Button className="m-auto">Your Profile</Button>
              </Link>
            </>
          )}
        </div>
      ) : (
        <>
          {loading || !data ? (
            <Loading contentOnly />
          ) : (
            <div className="border-container md:shadow">
              <UpcomingNav
                error={error}
                date={date || today}
                bookingDates={data.upcomingBookingDates}
                today={today}
              />
              {date ? (
                <BookingsOnDate
                  date={date || today}
                  bookings={data.bookingsForDate}
                  bookingDates={data.upcomingBookingDates}
                  user={user}
                />
              ) : (
                <UpcomingBookingList
                  upcomingBookings={upcomingBookings || []}
                  upcomingBookingDates={data.upcomingBookingDates}
                  user={user}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export const UpcomingBookingList = ({
  upcomingBookings,
  upcomingBookingDates,
  user,
}: {
  upcomingBookings: Booking[];
  upcomingBookingDates: string[];
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  return (
    <>
      <h3>Upcoming Bookings</h3>
      <BookingList
        bookings={upcomingBookings}
        showState={true}
        emptyStateMessage=""
        user={user}
      />
      {upcomingBookingDates.length > upcomingBookings.length && (
        <>
          <p className="text-center text-gray-600 mt-10">
            Select a date above to see more bookings
          </p>
        </>
      )}
    </>
  );
};
