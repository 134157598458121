import React from "react";
import { Link as ReachLink } from "@reach/router";

import { IconType, Icon } from "./Icons";

export type Size = "small" | "base" | "large" | "xl";
export type ButtonColor = "teal" | "gray" | "red" | "green";
export type ButtonType = "button" | "submit" | "reset";

export const Button = ({
  to,
  children,
  size = "base",
  color = "teal",
  secondary,
  icon,
  outline,
  newWindow,
  disabled,
  onClick,
  type = "button",
  className,
  loading,
}: {
  to?: string;
  children: React.ReactNode;
  size?: Size;
  color?: ButtonColor;
  secondary?: boolean;
  icon?: IconType;
  outline?: boolean;
  newWindow?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  type?: ButtonType;
  className?: string;
  loading?: boolean;
}) => {
  const textSizes: { [key in Size]: string } = {
    small: "sm",
    base: "base",
    large: "lg",
    xl: "lg",
  };

  const iconSizes: { [key in Size]: number } = secondary
    ? {
        small: 4,
        base: 4,
        large: 4,
        xl: 5,
      }
    : {
        small: 4,
        base: 5,
        large: 5,
        xl: 6,
      };

  const shade: { [key in ButtonColor]?: number } = secondary
    ? {
        teal: 200,
        gray: 200,
        red: 200,
        green: 200,
      }
    : {
        teal: 600,
        gray: 400,
        red: 500,
        green: 500,
      };

  const hoverShade: { [key in ButtonColor]?: number } = secondary
    ? {
        teal: 100,
        gray: 100,
        red: 100,
        green: 100,
      }
    : {
        teal: 500,
        gray: 500,
        red: 600,
        green: 600,
      };

  const textColor = secondary ? `${color}-900` : "white";

  const padding: { [key in Size]: string } = icon
    ? {
        small: "px-3 py-1",
        base: "pr-5 pl-4 py-2",
        large: "pr-6 pl-5 py-2",
        xl: "pr-10 pl-6 py-3",
      }
    : {
        small: "px-3 py-1",
        base: "px-5 py-2",
        large: "px-6 py-2",
        xl: "px-10 py-3",
      };

  const fontStyle = secondary || outline ? "medium" : "bold";

  const backGround = outline
    ? "bg-white"
    : `bg-${color}-${shade[color]} hover:bg-${color}-${hoverShade[color]}`;

  const cursor = disabled ? "default" : "pointer";

  const button = (
    <button
      onClick={!to && onClick ? onClick : undefined}
      disabled={disabled || loading}
      type={type}
      className={`
        button
        ${backGround} ${padding[size]} 
        text-${textColor} text-${textSizes[size]} font-${fontStyle}
        flex items-center rounded transition duration-150 ease-in-out
        ${
          outline
            ? `outline text-${color}-600 border border-${color}-300 hover:text-${color}-500`
            : ""
        }
        ${className}
        ${loading ? "submitting" : ""}
        cursor-${cursor}
        `}
    >
      {icon && (
        <Icon
          name={icon}
          className={`w-${iconSizes[size]} h-${iconSizes[size]} mr-3`}
        />
      )}
      {children}
    </button>
  );

  return to ? (
    <Link newWindow={newWindow} to={to} className={`inline-block ${className}`}>
      {button}
    </Link>
  ) : (
    button
  );
};

const Link = ({
  children,
  newWindow,
  to,
  className,
}: {
  children: React.ReactNode;
  newWindow?: boolean;
  to: string;
  className: string;
}) => {
  return newWindow ? (
    <a
      className={className}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <ReachLink to={to} className={className}>
      {children}
    </ReachLink>
  );
};
