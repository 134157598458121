import React from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { useQuery } from "@apollo/client";
import * as queryString from "query-string";

import { SupportProfessionalSummaryCardGroup } from "../users/supportProfessionals/SupportProfessionalSummaryCardGroup";
import { Loading } from "../layout/Loading";
import {
  SEARCH_SUPPORT_PROFESSIONALS,
  Data,
  Variables,
} from "../../graphql/queries/searchSupportProfessionals";
import { SearchSupportProfessional } from "../users/supportProfessionals/SearchSupportProfessional";
import { PageWrapper } from "../../styles/Styled";
import { ErrorPage } from "../layout/ErrorPage";
import { ServiceRole, IUserContext } from "../../types/profile";
import { SupportPrompt } from "./discover/SupportPrompt";
import { Icon } from "../layout/Icons";
import { SupportProfessional } from "../../types/supportProfessional";

export interface SearchResultsPageProps extends RouteComponentProps {
  user: Pick<IUserContext, "id" | "__typename" | "name">;
}

export interface SearchResultsPageData {
  searchSupportProfessionals: Pick<
    SupportProfessional,
    | "id"
    | "serviceOfferings"
    | "location"
    | "name"
    | "title"
    | "shortDescription"
    | "profileImage"
  >[];
}

export const SearchResultsPage = ({
  user,
  location,
}: SearchResultsPageProps) => {
  const queryParams = queryString.parse((location && location.search) || "");
  const { data, error, loading } = useQuery<Data, Variables>(
    SEARCH_SUPPORT_PROFESSIONALS,
    {
      variables: {
        query: queryParams.query || "",
        endUserId: user.__typename === ServiceRole.END_USER ? user.id : null,
      },
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { searchSupportProfessionals: supportProfessionals } = data;

  return (
    <PageWrapper className="sm:mx-6 md:mx-12 lg:mx-32 xl:mx-40">
      <Link
        to="/discover"
        className="flex items-center mb-8 text-teal-700 hover:text-teal-900 cursor-pointer font-bold"
      >
        <Icon name="chevron" className="w-5 h-5 transform rotate-90 mr-4" />
        <p>Find Support</p>
      </Link>
      <h1 className="mb-8 ">Search Results</h1>
      <div className="mb-8">
        {supportProfessionals.length > 0 ? (
          <div className="mb-12">
            <SupportProfessionalSummaryCardGroup
              supportWorkers={supportProfessionals}
            />
          </div>
        ) : (
          <>
            <p className="text-xl text-gray-700 mb-10">
              Sorry, we couldn't find any Support Professionals matching that
              search term.{" "}
            </p>
          </>
        )}
        <SupportPrompt
          text={"Having trouble finding something?"}
          className=""
          user={user}
        />
      </div>
      <div className="px-1 sm:px-10 pt-6 border-t sm:border border-gray-300">
        <SearchSupportProfessional header={"Search again"} />
      </div>
    </PageWrapper>
  );
};
