import React from "react";

import { Icon } from "../../layout/Icons";
import { SupportProfessional } from "../../../types/supportProfessional";
import { SupportProfessionalSummaryCard } from "../supportProfessionals/SupportProfessionalSummaryCard";

type SupportProfessionalT = Pick<
  SupportProfessional,
  | "id"
  | "serviceOfferings"
  | "location"
  | "name"
  | "title"
  | "shortDescription"
  | "profileImage"
>;

export const CrewList = ({ crew }: { crew: SupportProfessionalT[] }) => {
  return (
    <>
      <h2 className="mb-4">Your Crew</h2>
      <ul>
        {crew.length > 0 ? (
          crew.map((sp) => (
            <li key={sp.id}>
              <SupportProfessionalSummaryCard
                supportProfessional={sp}
                compact
              />
            </li>
          ))
        ) : (
          <div className="message info">
            <Icon name="userGroup" />
            <p>
              Once you have completed a booking with a support professional,
              they will appear here
            </p>
          </div>
        )}
      </ul>
    </>
  );
};
