import gql from "graphql-tag";

import { SupportProfessional } from "../../types/supportProfessional";
import { SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";

export interface NewData {
  UpdateSupportProfessionalBankDetails: SupportProfessional;
}

export interface Variables {
  id: string;
  accountName: string;
  bsb: string;
  accountNumber: string;
}

export const UPDATE_SP_BANK_DETAILS = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}

  mutation UpdateSupportProfessionalBankDetails(
    $id: ID!
    $accountName: String!
    $bsb: String!
    $accountNumber: String!
  ) {
    updateBankDetailsStripe(
      supportProfessionalId: $id
      accountName: $accountName
      bsb: $bsb
      accountNumber: $accountNumber
    ) {
      ...SupportProfessionalFields
    }
  }
`;
