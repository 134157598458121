import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import moment from "moment";

import { DropdownGroup } from "../../../layout/forms";

export interface DateOfBirthFieldValues {
  dobDay: number;
  dobMonth: string;
  dobYear: number;
  [key: string]: any;
}

export const DateOfBirthFields = ({
  values,
  errors,
  touched,
}: {
  values: DateOfBirthFieldValues;
  errors: FormikErrors<DateOfBirthFieldValues>;
  touched: FormikTouched<DateOfBirthFieldValues>;
}) => {
  const days = Array(
    moment(values["dobMonth"] || "January", "MMMM").daysInMonth()
  )
    .fill(null)
    .map((x, i) => {
      return { text: `${i + 1}`, value: i + 1 };
    });

  const startingYear = 1920;
  const years = Array(moment().year() - startingYear - 6)
    .fill(null)
    .map((x, i) => {
      return {
        text: `${i + startingYear}`,
        value: i + startingYear,
      };
    });

  return (
    <DropdownGroup
      values={[
        {
          name: "dobDay",
          options: days,
          blankDefault: true,
        },
        {
          name: "dobMonth",
          options: moment.months().map((month) => {
            return { text: month, value: month };
          }),
          blankDefault: true,
        },
        {
          name: "dobYear",
          options: years,
          blankDefault: true,
        },
      ]}
      noLabel
      errors={errors}
      touched={touched}
      gap={2}
    />
  );
};
