import React, { useState } from "react";
import { IUserContext } from "../types/profile";
import { WebAuth, Auth0DecodedHash, Auth0Error } from "auth0-js";
import { navigate } from "@reach/router";
import { getEnv } from "../utils/helpers";

import { useCurrentUser } from "../hooks/useCurrentUser";

export interface ISession {
  accessToken: string;
  idToken: string;
  expiresAt: number;
}

export type UserT = Pick<
  IUserContext,
  "id" | "name" | "__typename" | "profileImage" | "role"
>;

export interface AuthState {
  // signup(): void,
  logout(): void;
  login(): void;
  handleAuthentication(): void;
  checkSession(): void;
  updateUser(user: UserT): void;
  session: ISession | null;
  user: UserT | null;
}

const auth0 = new WebAuth({
  domain: getEnv("REACT_APP_AUTH0_DOMAIN") || "",
  clientID: getEnv("REACT_APP_AUTH0_CLIENTID") || "",
  redirectUri: window.location.origin + "/callback/auth0",
  responseType: "token id_token",
  scope: "openid",
});

let updateAuth = (_newSession: ISession | null) => {};

const logout = () => {
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("user");
  localStorage.removeItem("userId");

  auth0.logout({ returnTo: window.location.origin });

  navigate("/");
};

const handleCheckSession = (
  err: Auth0Error | null,
  authResult: Auth0DecodedHash | null
) => {
  if (authResult && authResult.accessToken && authResult.idToken) {
    setSession(authResult);
  } else if (err) {
    logout();
  }
};

const handleParseHash = (
  err: Auth0Error | null,
  authResult: Auth0DecodedHash | null
) => {
  if (authResult && authResult.accessToken && authResult.idToken) {
    setSession(authResult);
  } else if (err) {
    navigate("/");
  }
};

const setSession = (authResult: Auth0DecodedHash): void => {
  if (authResult.accessToken && authResult.idToken) {
    localStorage.setItem("isLoggedIn", "true");

    let expiresAt =
      authResult && authResult.expiresIn
        ? authResult.expiresIn * 1000 + new Date().getTime()
        : 0;

    updateAuth({
      accessToken: authResult.accessToken,
      idToken: authResult.idToken,
      expiresAt: expiresAt,
    });

    const prevRoute = localStorage.getItem("prevRoute");

    if (prevRoute) {
      navigate(prevRoute);
    } else {
      navigate("/");
    }
  }
};

const defaultAuth: AuthState = {
  session: null,
  user: null,
  // signup,
  logout,
  login: () => {
    auth0.authorize();
  },
  handleAuthentication: () => {
    auth0.parseHash(handleParseHash);
  },
  checkSession: () => {
    auth0.checkSession({}, handleCheckSession);
  },
  updateUser: (_user: UserT) => {},
};

const AuthContext = React.createContext(defaultAuth);

const AuthProvider = (props: any) => {
  const { user, updateUser } = useCurrentUser();

  const [state, setState] = useState({
    ...defaultAuth,
    updateUser,
  });

  updateAuth = (session: ISession | null) => {
    setState((prevState: AuthState) => {
      return { ...prevState, session };
    });
  };

  return (
    <AuthContext.Provider value={{ ...state, user }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
