import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useMutation, useQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { ServiceRole } from "../../../../types/profile";
import { EndUser } from "../../../../types/endUser";
import {
  ALL_SERVICES_QUERY,
  Data,
} from "../../../../graphql/queries/allServices";
import { AddPreferenceForm } from "./AddPreferenceForm";
import { Loading } from "../../../layout/Loading";
import { ErrorPage } from "../../../layout/ErrorPage";
import { Tag } from "../../../layout/Tag";
import { UpdateVirtualPreferenceForm } from "./UpdateVirtualPreferenceForm";
import { SupportHoursForm } from "./SupportHoursForm";
import { Service } from "../../../../types/service";
import {
  UPDATE_END_USER,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateEndUser";
import { RECOMMENDED_SUPPORT_PROFESSIONALS } from "../../../../graphql/queries/recommendedSupportProfessionals/recommendedSupportProfessionals";

export interface PreferencesSettingsProps extends RouteComponentProps {
  endUser: EndUser;
}

export const PreferencesSettings = ({ endUser }: PreferencesSettingsProps) => {
  const { data, error, loading } = useQuery<Data>(ALL_SERVICES_QUERY);

  if (error) return <ErrorPage error={error.message} />;
  if (loading || !data) return <Loading />;

  if (endUser.__typename === ServiceRole.SUPPORT_WORKER) {
    return <Redirect noThrow to="/settings/payment" />;
  }

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        return (
          <div
            data-testid="preferences"
            className="border-container mx-2 xm:ml-12 lg:mx-12 mb-8"
          >
            <h3>Preferences</h3>

            <PreferencesSettingsContent
              services={data.services}
              endUser={endUser}
              add={add}
            />
          </div>
        );
      }}
    </ToastConsumer>
  );
};

export const PreferencesSettingsContent = ({
  services,
  endUser,
  add,
}: {
  services: Service[];
  endUser: EndUser;
  add: AddToast;
}) => {
  const [updateEndUser] = useMutation<NewData, Variables>(UPDATE_END_USER);
  const { servicePreferences, id } = endUser;

  const removePreference = (servicePreferenceId: string, add: AddToast) => {
    const variables = {
      id,
      servicePreferences: servicePreferences.map((s) => {
        const { id, service } = s;
        const servicePreference = { id, serviceId: service.id };
        return s.id === servicePreferenceId
          ? { ...servicePreference, delete: true }
          : servicePreference;
      }),
    };
    const optimisticResponse = {
      __typename: "Mutation",
      updateEndUser: {
        ...endUser,
        servicePreferences: endUser.servicePreferences.filter(
          (s) => s.id !== servicePreferenceId
        ),
      },
    };

    updateEndUser({
      variables,
      optimisticResponse,
      refetchQueries: [
        {
          query: RECOMMENDED_SUPPORT_PROFESSIONALS,
          variables: { endUserId: id },
        },
      ],
    })
      .then((res) => res)
      .catch((err) =>
        add("There was an error removing your preference", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const anyServicePreferencesVirtual = !!servicePreferences.find(
    (sp) => sp.service.virtual
  );
  return (
    <>
      <div className="mb-10">
        <div className="mt-8 border-t mb-5 pb-4 border-gray-200 sm:mt-5 ">
          <div className="mt-6 sm:mt-5">
            <div className="mt-6 sm:mt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
              <div className="pb-2 pr-8">
                <h3 className="text-base leading-6 font-medium text-gray-900">
                  Hours of support needed per week
                </h3>
              </div>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <SupportHoursForm endUser={endUser} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t mb-5 pb-4 border-gray-200 sm:mt-5 ">
          <div className="mt-6 sm:mt-5">
            <div className="mt-6 sm:mt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
              <div className="pb-2 pr-8">
                <h3 className="text-base leading-6 font-medium text-gray-900">
                  Set which services/skills you are currently looking to hire
                </h3>
              </div>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="mb-6">
                  {servicePreferences.length > 0 ? (
                    <ul>
                      {servicePreferences.map((s) => (
                        <Tag
                          key={uuid()}
                          text={s.service.name}
                          remove={() => removePreference(s.id, add)}
                        />
                      ))}
                    </ul>
                  ) : (
                    <p className="italic text-gray-600">
                      You currently have no service preferences set
                    </p>
                  )}
                </div>
                <AddPreferenceForm
                  endUser={endUser}
                  services={services}
                  add={add}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {anyServicePreferencesVirtual && (
        <div className="mb-6">
          <h4 className="text-lg font-bold mb-4">Virtual</h4>
          <UpdateVirtualPreferenceForm endUser={endUser} add={add} />
        </div>
      )}
    </>
  );
};
