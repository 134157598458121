import React, { useState } from "react";
import Rollbar from "rollbar";

import { getEnv } from "../utils/helpers";
import { IUserContext } from "../types/profile";

interface RollbarConfig {
  accessToken: string;
  captureUncaught: boolean;
  captureUnhandledRejections: boolean;
  enabled: boolean;
  payload: {
    environment: "staging" | "production" | "dev";
    person: {
      id: string;
      username: string;
    };
  };
}

const defaultConfig = {
  accessToken: getEnv("REACT_APP_ROLLBAR_ACCESS_TOKEN"),
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: getEnv("REACT_APP_ROLLBAR_ENVIRONMENT") !== "dev",
  payload: {
    environment: getEnv("REACT_APP_ROLLBAR_ENVIRONMENT"),
    person: {
      id: "",
      username: "",
    },
  },
};

const RollbarContext = React.createContext(new Rollbar(defaultConfig));

const RollbarProvider = ({
  user,
  children,
}: {
  user: Pick<IUserContext, "id" | "name">;
  children: React.ReactNode;
}) => {
  const rollbarConfig: RollbarConfig = {
    ...defaultConfig,
    payload: {
      ...defaultConfig.payload,
      person: {
        id: user ? user.id : "",
        username: user ? user.name : "",
      },
    },
  };

  const [rollbar] = useState<Rollbar>(new Rollbar(rollbarConfig));

  return (
    <RollbarContext.Provider value={rollbar}>
      {children}
    </RollbarContext.Provider>
  );
};

export { RollbarContext, RollbarProvider };
