import React from "react";
import { Statistic } from "semantic-ui-react";

import { ServiceOffering } from "../../../types/supportProfessional";
import { ratePeriodMap } from "../profileHelpers";

export const RatePerPeriod = ({ service }: { service: ServiceOffering }) => {
  return (
    <Statistic>
      <Statistic.Label style={{ marginBottom: "0px" }}>From</Statistic.Label>
      <Statistic horizontal size="small" style={{ marginTop: "0px" }}>
        <Statistic.Label>$</Statistic.Label>
        <Statistic.Value>
          {(service.weekdayRate.amount / 100).toFixed(0)}
        </Statistic.Value>
        <Statistic.Label>/ {ratePeriodMap[service.ratePeriod]}</Statistic.Label>
      </Statistic>
    </Statistic>
  );
};

export const NewRatePerPeriod = ({ service }: { service: ServiceOffering }) => {
  return (
    <div>
      <p className="text-gray-600 text-sm font-light">From</p>

      <p className="font-bold text-lg">
        ${(service.weekdayRate.amount / 100).toFixed(0)}/Hour
        {/* {ratePeriodMap[service.ratePeriod]} */}
      </p>
    </div>
  );
};
