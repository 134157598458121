import gql from "graphql-tag";

import { Money } from "../../types/booking";
import { ServiceOffering } from "../../types/supportProfessional";
import { SERVICE_OFFERING_FIELDS } from "../fragments";

export interface NewData {
  UpdateServiceOffering: ServiceOffering;
}

export interface Variables {
  id: string;
  minDurationMins?: number;
  weekdayRate?: Money;
  eveningRate?: Money;
  nightRate?: Money;
  saturdayRate?: Money;
  sundayRate?: Money;
  publicHolidayRate?: Money;
}

export const UPDATE_SERVICE_OFFERING = gql`
  ${SERVICE_OFFERING_FIELDS}
  mutation UpdateServiceOffering(
    $id: ID!
    $minDurationMins: Int
    $weekdayRate: MoneyInput
    $eveningRate: MoneyInput
    $nightRate: MoneyInput
    $saturdayRate: MoneyInput
    $sundayRate: MoneyInput
    $publicHolidayRate: MoneyInput
  ) {
    updateServiceOffering(
      id: $id
      input: {
        minDurationMins: $minDurationMins
        weekdayRate: $weekdayRate
        eveningRate: $eveningRate
        nightRate: $nightRate
        saturdayRate: $saturdayRate
        sundayRate: $sundayRate
        publicHolidayRate: $publicHolidayRate
      }
    ) {
      ...ServiceOfferingFields
    }
  }
`;
