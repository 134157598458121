/**
 * A drop-in replacement for Semantic-UI's Message component.
 */

import React, { FunctionComponent } from "react";

import { Icon, IconType } from "./Icons"

export interface FlashProps {
  success: boolean;
  icon?: IconType;
  heading?: string;
  text: string;
}

export const Flash: FunctionComponent<FlashProps> = ({ success, icon, heading, text }) => {
  const theme = success ? "green" : "red"

  return (
    <div className={`rounded-md bg-${theme}-50 p-4 border-${theme}-400 border`}>
      <div className={icon ? "flex" : ""}>
        {icon &&
          <div className="flex-shrink-0 mr-3">
            <Icon name={icon} className={`w-14 mr-3 text-${theme}-600`} />
          </div>
        }
        <div>
          {heading &&
            <h3 className={`text-lg leading-5 font-bold text-${theme}-800 mb-3`}>
              {heading}
            </h3>
          }
          <div className={`leading-5 text-${theme}-600`}>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
