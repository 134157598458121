import gql from "graphql-tag";

import { Booking } from "../../types/booking";
import { ServiceRole } from "../../types/profile";
import { BOOKING_FIELDS } from "../fragments";

export interface Data {
  actionableBookings: Booking[];
}

export interface Variables {
  id: string;
}

const ACTIONABLE_BOOKINGS_FOR_END_USER = gql`
  ${BOOKING_FIELDS}

  query ActionableBookingsForEndUser($id: ID!) {
    actionableBookings: actionableBookingsForEndUser(id: $id) {
      ...BookingFields
    }
  }
`;

const ACTIONABLE_BOOKINGS_FOR_SUPPORT_PROFESSIONAL = gql`
  ${BOOKING_FIELDS}

  query ActionableBookingsForSupportProfessional($id: ID!) {
    actionableBookings: actionableBookingsForSupportProfessional(id: $id) {
      ...BookingFields
    }
  }
`;

export const actionableBookingsQueryForUserType = {
  [ServiceRole.END_USER]: ACTIONABLE_BOOKINGS_FOR_END_USER,
  [ServiceRole.SUPPORT_WORKER]: ACTIONABLE_BOOKINGS_FOR_SUPPORT_PROFESSIONAL,
  [ServiceRole.SUPPORT_COORDINATOR]: ACTIONABLE_BOOKINGS_FOR_END_USER,
};
