import React, { useState } from "react";
import { Link } from "@reach/router";
import { v4 as uuid } from "uuid";

import { Icon, IconType } from "../../layout/Icons";
import { humanize } from "../../../utils/helpers";
import { Service, Category } from "../../../types/service";
import { slugify } from "../../../utils/helpers";

export const CategoryButtonList = ({
  categories,
}: {
  categories: { [key in Category]?: Service[] };
}) => {
  const [
    categoryNameSelected,
    setCategoryNameSelected,
  ] = useState<Category | null>(null);

  const iconMap: { [key in Category]: { name: IconType } } = {
    ADMINISTRATION_SUPPORT: { name: "admin" },
    SOCIAL_SUPPORT: { name: "health" },
    DOMESTIC_SUPPORT: { name: "potPlant" },
    MENTAL_HEALTH: { name: "brain" },
    HEALTH_WELLBEING: { name: "apple" },
    PERSONAL_CARE: { name: "nurse" },
    FITNESS: { name: "heart" },
    GENERAL_HEALTH: { name: "stethoscope" },
  };

  const categorySelected =
    categoryNameSelected && categories[categoryNameSelected];

  return categoryNameSelected ? (
    <>
      <div
        onClick={() => setCategoryNameSelected(null)}
        className="go-back-link"
      >
        <Icon name="chevron" className="go-back-link-chevron mr-4" />
        <p>All Categories</p>
      </div>
      <h2>{humanize(categoryNameSelected, { capitalized: true })}</h2>
      <div className="bg-white overflow-hidden">
        <ul id="services-list" className="grid grid-cols-2">
          {categorySelected &&
            categorySelected.map((s) => {
              return (
                s.available && (
                  <li key={uuid()} className="col-span-2 sm:col-span-1">
                    <Link
                      to={`/services/${slugify(s.name)}`}
                      className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                    >
                      <div className="px-4 py-4 flex items-center sm:px-6 text-gray-600 rounded-md hover:text-gray-900 bg-white hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150">
                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                          <div>
                            <div className="text-sm leading-5 font-medium truncate">
                              {s.name}
                            </div>
                          </div>
                        </div>
                        <div className="ml-5 flex-shrink-0">
                          <Icon
                            name="chevron"
                            className="w-5 h-5 transform -rotate-90"
                          />
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              );
            })}
        </ul>
        <div className="divider mb-12 mt-20" />
      </div>
    </>
  ) : (
    <ul id="service-categories" className="grid grid-cols-6 mb-12">
      {// Object.entries ovverrides the object key type as String. Unsure how to fix this yet.
      // @ts-ignore
      Object.entries(categories).map((c: [Category, Service[]], index) => {
        const [categoryName] = c;

        const odd = index % 2;

        return (
          <li
            className={`service-category-link col-span-6 sm:col-span-3 xl:col-span-2 sm:m${
              odd ? "l" : "r"
            }-2 xl:ml-2 xl:mr-2 mb-3`}
            key={index}
          >
            <button
              type="button"
              onClick={() => {
                setCategoryNameSelected(categoryName);
              }}
            >
              <Icon
                name={iconMap[categoryName].name}
                className="h-12 w-12 mr-4 text-green-900"
              />
              {categoryName === "HEALTH_WELLBEING"
                ? "Health & Wellbeing"
                : humanize(categoryName, { capitalized: true })}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
