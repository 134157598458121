import gql from "graphql-tag";

import { SupportProfessional } from "../../types/supportProfessional";
import { SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";

export interface Data {
  searchSupportProfessionals: SupportProfessional[];
}

export interface Variables {
  query: string | string[];
  endUserId: string | null;
}

export const SEARCH_SUPPORT_PROFESSIONALS = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}

  query SearchSupportProfessionals($query: String!, $endUserId: ID) {
    searchSupportProfessionals(query: $query, endUserId: $endUserId) {
      ...SupportProfessionalFields
    }
  }
`;
