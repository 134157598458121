import gql from "graphql-tag";

import { SUPPORT_PROFESSIONAL_FIELDS } from "../../fragments";
import { SupportProfessional } from "../../../types/supportProfessional";

export interface Data {
  recommendedSupportProfessionals: SupportProfessional[];
}

export interface Variables {
  endUserId: string;
}

// Used for refetching cache on settings update
export const RECOMMENDED_SUPPORT_PROFESSIONALS = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}

  query RecommendedSupportProfessionals($endUserId: ID!) {
    recommendedSupportProfessionals(id: $endUserId) {
      ...SupportProfessionalFields
    }
  }
`;
