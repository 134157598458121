import React from "react";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

export const Loading = ({ contentOnly }: { contentOnly?: boolean }) =>
  contentOnly ? <ContentLoader active /> : <CustomLoader active />;

const CustomLoader = styled(Loader)`
  &&& {
    width: 100vw;
    background-color: rgb(61, 128, 134);
    position: fixed;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &&&:before,
  &&&:after {
    top: 43vh;
  }
`;

const ContentLoader = styled(Loader)``;
