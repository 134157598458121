import React from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

import { PageWrapper } from "../../styles/Styled";

export const NotFoundPage = (props: RouteComponentProps) => (
  <PageWrapper>
    <h1>404: Not Found</h1>
    <Note>Sorry! this page does not exist</Note>
    <Link to="/">
      <Button primary>Take me home</Button>
    </Link>
  </PageWrapper>
);

const Note = styled.h2`
  font-weight: 400;
  margin-bottom: 30px;
`;
