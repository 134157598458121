import gql from "graphql-tag";

import { Viewer } from "../../types/viewer";

export interface Data {
  viewer: Pick<Viewer, "id" | "role" | "acceptedTerms">;
}

export const CURRENT_USER = gql`
  query CurrentAccount {
    viewer {
      id
      role
      acceptedTerms
    }
  }
`;
