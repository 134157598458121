import React from "react";
import { Link } from "@reach/router";

import { Avatar } from "../../layout/Avatar";
import { ClientList } from "./ClientList";
import { Booking } from "../../../types/booking";
import { Button } from "../../layout/Button";
import { Icon } from "../../layout/Icons";
import { IUserContext } from "../../../types/profile";
import { SupportCoordinator } from "../../../types/supportCoordinator";
import { EndUser } from "../../../types/endUser";

export const SupportCoordinatorDashboardLeftSidebar = ({
  user,
  supportCoordinator,
  showAddClientModal,
  clients,
  upcomingClientBookings,
}: {
  user: Pick<IUserContext, "profileImage">;
  supportCoordinator: Pick<
    SupportCoordinator,
    "firstName" | "lastName" | "company" | "about" | "profileImage"
  >;
  showAddClientModal: () => void;
  clients: Pick<EndUser, "name" | "profileImage" | "id">[];
  upcomingClientBookings: Pick<Booking, "id" | "endUser">[];
}) => {
  let percentageComplete = 33;
  if (supportCoordinator.profileImage) percentageComplete += 33;
  if (supportCoordinator.about) percentageComplete += 34;

  return (
    <div className="xl:flex-shrink-0 xl:w-88 xl:border-r xl:border-gray-200 bg-white">
      <div className="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8">
        <div className="flex items-center justify-between">
          <div className="flex-1 space-y-8 max-w-full">
            <div className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-8">
              {/* <!-- Profile --> */}
              <div className="flex items-center space-x-3">
                <Avatar user={user} size="sm" />
                <div className="space-y-1">
                  <div className="text-sm leading-5 font-medium text-gray-900">
                    {`${supportCoordinator.firstName} ${supportCoordinator.lastName}`}
                  </div>
                  <p className="group flex items-center space-x-2.5">
                    <span className="text-sm leading-5 text-gray-500 font-medium">
                      {supportCoordinator.company || "Support Coordinator"}
                    </span>
                  </p>
                </div>
              </div>
              {/* <!-- Action buttons --> */}

              <div className="flex flex-col sm:flex-row xl:flex-col">
                <Button
                  className="flex justify-center py-2"
                  size="small"
                  onClick={showAddClientModal}
                >
                  Add New Client
                </Button>
                <Button
                  to="/clients"
                  color="gray"
                  size="small"
                  outline
                  className="flex justify-center py-2 sm:py-0 xl:py-2 ml-0 sm:ml-2 xl:ml-0 w-full sm:w-auto xl:w-full"
                >
                  Manage Clients
                </Button>
              </div>
            </div>
            {/* <!-- Meta info --> */}
            <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
              <Link to="/settings">
                <div className="flex items-center space-x-2">
                  <Icon
                    name={percentageComplete === 100 ? "checkBadge" : "pending"}
                    className="h-5 w-5 text-gray-400"
                  />
                  <span className="text-sm text-gray-500 leading-5 font-medium">
                    Profile
                    {percentageComplete !== 100 &&
                      ` ${percentageComplete}%`}{" "}
                    Complete
                  </span>
                </div>
              </Link>
              <div>
                <Link to="/clients">
                  <div className="flex items-center space-x-2">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                    </svg>

                    <span className="text-sm text-gray-500 leading-5 font-medium">
                      {clients.length} Clients
                    </span>
                  </div>
                </Link>

                <ClientList
                  clients={clients}
                  bookings={upcomingClientBookings}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
