import React from "react";
import { Label, Icon } from "semantic-ui-react";
import moment from "moment";
import { Link } from "@reach/router";

import { BookingState, Booking } from "../../../types/booking";
import { IUserContext } from "../../../types/profile";

export const ApprovedLabel = ({
  booking,
  user,
  bookingStateLabel,
  link,
}: {
  booking: Pick<Booking, "stateEvents">;
  user: Pick<IUserContext, "__typename" | "id">;
  bookingStateLabel: string;
  link?: string;
}) => {
  if (link) {
    return (
      <Link to={link}>
        <Label>{bookingStateLabel}</Label>
      </Link>
    );
  }

  const approvedAtEvent =
    booking.stateEvents &&
    booking.stateEvents.find((bse) => bse.newState === BookingState.APPROVED);

  let onBehalfOf:
    | { id: string; firstName?: string; lastName?: string; name?: string }
    | undefined;

  if (approvedAtEvent && approvedAtEvent.onBehalfOf === "END_USER") {
    onBehalfOf = approvedAtEvent.endUser;
  }
  if (approvedAtEvent && approvedAtEvent.onBehalfOf === "SUPPORT_COORDINATOR") {
    onBehalfOf = approvedAtEvent.supportCoordinator;
  }

  const name =
    onBehalfOf &&
    (onBehalfOf.id === user.id
      ? "you"
      : onBehalfOf.name ||
        `${onBehalfOf.firstName} ${onBehalfOf.lastName} (Support Coordinator)`);

  const bookingAcceptedNote = approvedAtEvent && (
    <p className="italic text-lg">
      <Icon name="check" color="green" />
      Booking approved by {name} on{" "}
      {moment(approvedAtEvent.insertedAt).format("dddd Do MMMM")}
    </p>
  );

  return <>{bookingAcceptedNote}</>;
};
