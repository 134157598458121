import React from "react";

import { ProfileImage } from "../../types/profile";
import defaultAvatar from "../../assets/images/default-avatar-image.png";

export type Size = "xxs" | "xs" | "sm" | "md" | "lg";

export interface Profile {
  profileImage?: Pick<ProfileImage, "small">;
}

export interface AvatarProps {
  user?: Profile | null;
  size: Size;
  float?: string;
  className?: string;
  responsive?: {
    xs?: Size;
    sm?: Size;
    md?: Size;
    xm?: Size;
    lg?: Size;
    xl?: Size;
  };
}

const sizes: { [key: string]: number } = {
  xxs: 6,
  xs: 10,
  sm: 14,
  md: 24,
  lg: 40,
};

export const Avatar = ({
  user,
  size,
  float,
  className,
  responsive,
}: AvatarProps) => {
  const url =
    (user &&
      user.profileImage && user.profileImage.small) ||
    defaultAvatar;

  const responsiveSizes =
    responsive &&
    Object.entries(responsive).reduce(
      (acc: string, value: (Size | string | undefined)[]) => {
        const [breakpoint, size] = value;
        if (size) {
          return `${acc} ${breakpoint}:w-${sizes[size]} ${breakpoint}:h-${sizes[size]}`;
        } else {
          return "";
        }
      },
      ""
    );

  return (
    <div
      style={{ backgroundImage: `url(${url})` }}
      className={`w-${sizes[size]} h-${sizes[size]} ${responsiveSizes} flex-shrink-0 rounded-full bg-cover bg-center float-${float} ${className}`}
    ></div>
  );
};
