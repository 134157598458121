import React from "react";

import { useLockBody } from "../../../hooks";
import {
  ServiceOffering,
  SupportProfessional,
} from "../../../types/supportProfessional";
import { Button } from "../../layout/Button";
import { Avatar } from "../../layout/Avatar";
import { FormValues } from "./BookingFormData";
import { EndUser } from "../../../types/endUser";
import { PublicHoliday } from "../../../types/booking";
import { BookingPreview } from "./BookingPreview";

export const BookingConfirmationModal = ({
  isSubmitting,
  hideConfirmationModal,
  submitForm,
  supportProfessional,
  values,
  service,
  endUser,
  publicHolidays,
}: {
  isSubmitting: boolean;
  hideConfirmationModal: () => void;
  submitForm: () => void;
  supportProfessional: Pick<
    SupportProfessional,
    | "profileImage"
    | "name"
    | "id"
    | "location"
    | "shortDescription"
    | "title"
  >;
  values: FormValues;
  service: ServiceOffering | undefined;
  endUser:
    | Pick<
        EndUser,
        | "addressCity"
        | "addressPostcode"
        | "addressState"
        | "addressStreet"
        | "id"
        | "name"
      >
    | undefined;
  publicHolidays: PublicHoliday[];
}) => {
  useLockBody();

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        {/* <!-- This element is to trick the browser into centering the modal contents (From Tailwind UI). --> */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className="w-11/12 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="pt-4">
            <div className="flex items-center justify-center mb-8">
              <Avatar size="md" user={supportProfessional} />
              <div className="space-y-1 ml-5">
                {service && (
                  <p className="text-black block text-2xl mb-0">
                    {service.service.name}
                  </p>
                )}
                <p className="text-lg">With {supportProfessional.name}</p>
              </div>
            </div>
            {endUser && service && (
              <BookingPreview
                values={values}
                endUser={endUser}
                service={service}
                supportProfessional={supportProfessional}
                publicHolidays={publicHolidays}
              />
            )}
          </div>
          <div className="mt-5 sm:mt-6 sm:flex">
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 space-x-2">
              <Button
                onClick={hideConfirmationModal}
                color="gray"
                className="w-full flex justify-center"
                outline
              >
                Cancel
              </Button>
              <Button
                onClick={submitForm}
                className="w-full flex justify-center"
                loading={isSubmitting}
              >
                Confirm booking request
              </Button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
