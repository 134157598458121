import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";

import { SignupForm } from "./SignupForm";

export interface SignupPageProps extends RouteComponentProps {}

export const SignupPage = (props: SignupPageProps) => {
  const [success, setSuccess] = useState(false);

  const setSignupSuccess = () => {
    setSuccess(true);
  };

  return (
    <div className="flex flex-grow">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm">
          <div>
            <div className="">
              {success ? (
                <div className="flex items-center mb-4">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-green-400 mr-3"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <h2 className="text-green-800 mb-0">Success</h2>
                </div>
              ) : (
                <h2>Sign Up</h2>
              )}
            </div>

            {success ? (
              <div className="rounded-md bg-green-50 p-6">
                <p className="text-base font-medium text-green-800">
                  Please check your inbox to verify your email and set your
                  tappON password.
                </p>
              </div>
            ) : (
              <p className="text-lg text-gray-600">
                Access a community of trusted Support Professionals to help meet
                your home health goals.
              </p>
            )}
          </div>

          <div className="mt-8">
            <div className="mt-6 pb-14">
              <SignupForm
                success={success}
                setSignupSuccess={setSignupSuccess}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://about.tappon.co/wp-content/uploads/2019/02/tappOn-Hero-.jpg"
          alt=""
        />
      </div>
    </div>
  );
};
