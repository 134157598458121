import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import styled from "styled-components";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "@reach/router";

import { DropdownListItem } from "./DropdownListItem";
import { Avatar } from "../Avatar";
import { breakpoints } from "../../../styles/Styled";
import { enableAdminOnly } from "../../../utils/featureFlags";
import { IUserContext, ServiceRole } from "../../../types/profile";
import { Viewer } from "../../../types/viewer";

export interface DropdownProps {
  users: IUserContext[];
  switchUser: (id: string) => void;
  handleLogout: () => any;
  openUserOptions: () => any;
  user: Pick<
    IUserContext,
    "profileImage" | "name" | "__typename"
  >;
  viewer: Pick<Viewer, "id" | "role">;
}

export const Dropdown = ({
  user,
  users,
  switchUser,
  handleLogout,
  openUserOptions,
  viewer,
}: DropdownProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    dropdownVisible ? setDropdownVisible(false) : setDropdownVisible(true);
  };

  const node = useRef();

  const handleClickOutside = (e: Event) => {
    // @ts-ignore
    if (node.current.contains(e.target)) {
      return;
    }
    setDropdownVisible(false);
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  return (
    <DropdownWrapper
      data-testid="user-dropdown"
      ref={node}
      className="flex-none z-20"
    >
      <DropdownHeader id="user-dropdown" onClick={toggleDropdown}>
        <Avatar float="left" size="sm" user={user} />
        <UserTitle>{user ? user.name : ""}</UserTitle>
        <Icon name="chevron down" />
      </DropdownHeader>
      <DropdownList
        visible={dropdownVisible}
        users={users}
        switchUser={switchUser}
        handleLogout={handleLogout}
        openUserOptions={openUserOptions}
        setDropdownVisible={setDropdownVisible}
        user={user}
        viewer={viewer}
      />
    </DropdownWrapper>
  );
};

const DropdownList = ({
  visible,
  users,
  switchUser,
  handleLogout,
  openUserOptions,
  setDropdownVisible,
  user,
  viewer,
}: {
  visible: boolean;
  users: IUserContext[];
  switchUser: (id: string) => void;
  handleLogout: () => any;
  openUserOptions: () => any;
  setDropdownVisible: Dispatch<SetStateAction<boolean>>;
  user: Pick<IUserContext, "__typename">;
  viewer: Pick<Viewer, "id" | "role">;
}) => {
  const visibleClass = visible ? "block" : "hidden";

  return (
    <ul
      className={
        visibleClass + " absolute z-10 shadow min-w-56 mt-4 tappon-dropdown"
      }
    >
      {(user.__typename !== ServiceRole.SUPPORT_COORDINATOR ||
        enableAdminOnly(viewer)) && (
        <>
          {/* This only appears above 768px */}
          {users.length > 1 && users.length < 5 && (
            <UserList>
              <SectionTitle>Available Users</SectionTitle>

              {users.map((user) => (
                <DropdownListItem
                  userChoice
                  onListItemClick={switchUser}
                  key={user.id}
                  userId={user.id}
                  hideDropdown={setDropdownVisible}
                  hoverable
                >
                  <Avatar float="left" size="sm" user={user} />
                  <Name>{user.name}</Name>
                </DropdownListItem>
              ))}
            </UserList>
          )}
        </>
      )}
      {(user.__typename !== ServiceRole.SUPPORT_COORDINATOR ||
        enableAdminOnly(viewer)) && (
        <>
          {users.length > 4 && (
            <Link to="/switch-user" className="block">
              <DropdownListItem hoverable hideDropdown={setDropdownVisible}>
                <p className="text-black py-2">Switch User</p>
              </DropdownListItem>
            </Link>
          )}
        </>
      )}

      <Divider light />

      <Link to="/settings">
        <DropdownListItem hoverable hideDropdown={setDropdownVisible}>
          <p className="text-black py-2">Settings</p>
        </DropdownListItem>
      </Link>

      {(user.__typename !== ServiceRole.SUPPORT_COORDINATOR ||
        enableAdminOnly(viewer)) && (
        <>
          {/* This only appears above 768px */}
          {users.length > 1 && (
            <SwitchUserButton onClick={openUserOptions} color="teal" basic>
              Switch User
            </SwitchUserButton>
          )}
        </>
      )}

      <Divider light />
      <Link to="/profile">
        <DropdownListItem hoverable hideDropdown={setDropdownVisible}>
          <p className="text-black py-2">Your Profile</p>
        </DropdownListItem>
      </Link>

      <Divider />
      <DropdownListItem hideDropdown={setDropdownVisible}>
        <IndentedButton onClick={handleLogout}>Log Out</IndentedButton>
      </DropdownListItem>
    </ul>
  );
};

export interface ListProps {
  visible: boolean;
}

const { small } = breakpoints;

export const IndentedButton = styled(Button)`
  &&& {
    margin-left: 0.5em;
  }
`;

const DropdownWrapper: any = styled.div`
  padding: 0 30px;
`;
const DropdownHeader = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserTitle = styled.h4`
  display: inline-block;
  margin: 0 6px;
`;

const SectionTitle = styled.h5`
  background-color: rgb(230, 230, 230);
  font-size: 10pt;
  text-transform: uppercase;
  text-align: left;
  padding: 6px 10px;
  margin: 0;
  color: rgb(110, 110, 110);
`;

export interface DividerProps {
  light?: boolean;
}

const Divider = styled.div`
  border-bottom: 1px solid rgb(220, 220, 220);
  opacity: ${(props: DividerProps) => (props.light ? 0.6 : 1)};
`;

const Name = styled.p`
  margin-left: 20px;
`;

const UserList = styled.div`
  display: none;
  @media (min-width: ${small}) {
    display: block;
  }
`;

const SwitchUserButton = styled(Button)`
  display: inline-block !important;
  margin: 15px 0 !important;
  @media (min-width: ${small}) {
    display: none !important;
  }
`;
