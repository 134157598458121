import React from "react";
import { Formik, Form, FormikErrors } from "formik";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import { supportHours } from "../../profileHelpers";
import wavingHand from "../../../../assets/images/waving-hand-sign.png";
import {
  UPDATE_END_USER,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateEndUser";
import { useLogger } from "../../../../hooks";
import { EndUserT } from "./CompleteProfileModal";
import { TextArea } from "../../../layout/forms";
import { Service } from "../../../../types/service";
import { AccountT } from "./CompleteProfilePrompt";
import { humanize } from "../../../../utils/helpers";
import { Buttons } from "./Buttons";

export interface FormValues {
  about: string;
}

export const CompleteProfilePanel3 = ({
  endUser,
  hideModal,
  setPanel,
  account,
  add,
}: {
  endUser: EndUserT;
  hideModal: () => void;
  setPanel: (number: number) => void;
  services: Service[];
  add: AddToast;
  account: AccountT;
}) => {
  const [updateEndUser] = useMutation<NewData, Variables>(UPDATE_END_USER);
  const { log } = useLogger();

  const [firstName] = endUser.name.split(" ");

  const supportCategories: string[] = endUser.servicePreferences.map(
    (s) => s.service.category
  );

  const supportHoursLabel = Object.keys(supportHours).find(
    (key) => supportHours[key] === endUser.supportHoursPerWeek
  );

  const suggestedAbout = `I am looking for about ${supportHoursLabel} hours of support per week ${
    supportCategories.length > 0
      ? `with ${humanize(supportCategories[0])} `
      : ""
  }at my home in ${endUser.addressCity}. `;

  const initialValues: FormValues = {
    about: endUser.about || suggestedAbout,
  };

  const handleSubmit = (values: FormValues, formikApi: any) => {
    const { about } = values;

    const variables = {
      id: endUser.id,
      about,
    };

    updateEndUser({
      variables,
    })
      .then((res) => {
        setPanel(4);
      })
      .catch((err) => {
        add("There was a problem updating your details", {
          appearance: "error",
          autoDismiss: true,
        });
        log("error", err);
      });
  };

  return (
    <div className="">
      <div className="">
        <div className="pt-6 sm:pb-6 md:pb-10 flex flex-col items-start sm:items-center">
          <div className="flex items-center mb-4">
            <img
              src={wavingHand}
              className="w-8 h-8 mr-3"
              alt="waving hand emoji"
            />
            <h3 className="text-3xl font-medium leading-6 text-gray-900 mb-0">
              Hi {firstName}
            </h3>
          </div>
          <p className="mt-1 text-xl text-gray-500">
            Please tell us a bit more about yourself
          </p>
        </div>
        <div className="mt-5 md:mt-0">
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: FormikErrors<FormValues> = {};

              if (!values.about) {
                errors.about = "Required";
              }

              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <div className="mt-4">
                    <h4 className="text-gray-700 mb-3 pl-1 text-lg sm:text-base">
                      About Me
                    </h4>
                    <p className="my-2 text-base sm:text-sm text-gray-500">
                      Write a few sentences about yourself.
                    </p>
                    <TextArea name="about" rows={5} noLabel />
                  </div>
                  <Buttons
                    panel={3}
                    setPanel={setPanel}
                    isSubmitting={isSubmitting}
                    hideModal={hideModal}
                    submit
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
