import gql from "graphql-tag";

import { SupportCoordinator } from "../../types/supportCoordinator";
import { SUPPORT_COORDINATOR_FIELDS } from "../fragments";

export interface Data {
  supportCoordinator: SupportCoordinator;
}

export interface Variables {
  profileId: string;
}

export const SUPPORT_COORDINATOR = gql`
  ${SUPPORT_COORDINATOR_FIELDS}
  query GetSupportCoordinatorById($profileId: ID!) {
    supportCoordinator(id: $profileId) {
      ...SupportCoordinatorFields
    }
  }
`;