import React from "react";
import {
  Link,
  RouteComponentProps,
  WindowLocation,
  Redirect,
} from "@reach/router";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { parse } from "query-string";

import { BookingDetail } from "./BookingDetail";
import { DeliveryType, BookingState } from "../../types/booking";
import { addPartiesToBooking } from "./addPartiesToBooking";
import { PaymentMessage } from "./PaymentMessage";
import { Loading } from "../layout/Loading";
import { PageWrapper } from "../../styles/Styled";
import { Button } from "../layout/Button";
import { upcomingBookingsQueryForUserType } from "../../graphql/queries/upcomingBookings";
import { Avatar } from "../layout/Avatar";
import { ServiceRole, IUserContext } from "../../types/profile";
import { Icon } from "../layout/Icons";
import { VirtualBookingInfo } from "./VirtualBookingInfo";
import { useLogger } from "../../hooks";
import { BOOKING, Data, Variables } from "../../graphql/queries/booking";
import { Booking } from "../../types/booking";
import { bookingHistoryQueryForUserType } from "../../graphql/queries/bookingHistory";

export interface BookingPageProps extends RouteComponentProps {
  bookingId?: string;
  scheduleId?: string;
  date?: string;
  location?: location;
  user: Pick<IUserContext, "id" | "__typename">;
}

export type BookingT = Pick<
  Booking,
  | "id"
  | "endUser"
  | "supportProfessional"
  | "state"
  | "serviceName"
  | "totalCost"
  | "deliveryType"
  | "location"
  | "endTime"
  | "startTime"
  | "cancellationReason"
  | "specialInstructions"
  | "stateEvents"
  | "paymentFailed"
  | "paymentFlow"
  | "insertedAt"
  | "spAvailable"
  | "spPaid"
  | "schedule"
  | "scheduleId"
>;

export const BookingPage = ({
  bookingId,
  scheduleId,
  date,
  location,
  user,
}: BookingPageProps) => {
  const { log } = useLogger();

  const { data, error, loading, networkStatus } = useQuery<Data, Variables>(
    BOOKING,
    {
      variables: { bookingId, scheduleId, date },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );
  const params = parse(window.location ? window.location.search : "");

  if (error) {
    log("error", error.message);
    return (
      <PageWrapper>
        <div className="bg-gray-100 border border-gray-300 rounded p-5 m-auto text-center flex flex-col justify-center items-center">
          <Icon name="alert" className="w-12 h-12 m-auto mb-2 text-gray-700" />
          <h2>Oops, something went wrong</h2>
          <Link to="/bookings">
            <Button>View your bookings</Button>
          </Link>
        </div>
      </PageWrapper>
    );
  }

  if (!user || (networkStatus && networkStatus < 1) || loading || !data) {
    return <Loading />;
  }

  const isEndUser = user.__typename === ServiceRole.END_USER;
  const isSupportCoordinator =
    user.__typename === ServiceRole.SUPPORT_COORDINATOR;

  // Have to pass in a date to get all grouped bookings back
  const today = moment().format("YYYY-MM-DD");
  const refetchQueries = [
    {
      query: BOOKING,
      variables: {
        bookingId,
        scheduleId,
        date,
        endUserId: data.booking.endUser.id,
      },
    },
    {
      query: upcomingBookingsQueryForUserType[user.__typename],
      variables: { id: user.id, date: today },
      nextFetchPolicy: "cache-first",
    },
    {
      query: bookingHistoryQueryForUserType[user.__typename],
      variables: {
        id: user.id,
        limit: user.__typename === ServiceRole.SUPPORT_COORDINATOR ? 8 : 6,
        offset: 0,
      },
    },
  ];

  const booking = addPartiesToBooking<BookingT>(data.booking, user);

  const profileUrl =
    user.__typename === ServiceRole.SUPPORT_WORKER
      ? `/user/profile/${booking.otherParty.id}`
      : `/worker/profile/${booking.otherParty.id}`;

  const disconnected =
    !!location && !!location.state && location.state.disconnected;

  if (
    user.id !== booking.supportProfessional.id &&
    user.id !== booking.endUser.id &&
    user.__typename !== ServiceRole.SUPPORT_COORDINATOR
  ) {
    return <Redirect noThrow to="/bookings" />;
  }

  return (
    <div className="booking-page">
      <div className="mb-4">
        {params.payment && <PaymentMessage success={params.payment === "success"} />}
      </div>
      <div>
        <h1>Your Booking</h1>
      </div>
      {location && location.state && location.state.message && (
        <div className="bg-blue-100 border border-blue-200 p-4 mb-4 text-blue-800">
          {location.state.error === "videoError" &&
            "Video chat could not be started because the"}{" "}
          {location.state.message}
        </div>
      )}
      {disconnected && (
        <div className="bg-blue-100 border border-blue-200 p-4 mb-4 text-blue-800">
          {booking.state === BookingState.COMPLETED
            ? `${booking.otherParty.name} ended the booking.
            ${
              isEndUser
                ? " Please approve the booking to allow payment to be released."
                : ""
            }`
            : `You have been disconnected from the video call. This may be because you connected from a different device.`}
        </div>
      )}

      <div className="flex flex-wrap border-container">
        <div className="w-full xm:w-5/12 p-4">
          <div className="flex items-center mb-6 flex-auto sm:flex-wrap lg:flex-auto gap-y-5">
            {isSupportCoordinator && (
              <Avatar
                user={booking.endUser}
                size="md"
                float="left"
                className="mr-4 flex-none"
              />
            )}
            <Link
              to={profileUrl}
              className={`${isSupportCoordinator ? "-ml-8" : ""}`}
            >
              <Avatar
                user={booking.otherParty}
                size="md"
                float="left"
                className="mr-4 flex-none text-white shadow-solid"
              />
            </Link>

            <div className="ml-3">
              <p className="text-xl leading-5 font-bold mb-3 min-w-64">
                {isSupportCoordinator && <span>{booking.endUser.name} & </span>}
                {booking.otherParty.name}
              </p>
              <p className="text-lg leading-4 font-medium text-gray-600">
                {booking.serviceName}
                {booking.totalCost.amount > 0 && " Appointment"}
              </p>
            </div>
          </div>

          {booking.deliveryType === DeliveryType.IN_PERSON &&
            booking.location && <Location>{booking.location}</Location>}

          {booking.deliveryType === DeliveryType.VIRTUAL && (
            <VirtualBookingInfo booking={booking} />
          )}
        </div>

        <div className="w-full xm:w-7/12 p-4">
          <BookingDetail
            booking={booking}
            refetchQueries={refetchQueries}
            user={user}
          />
        </div>
      </div>
    </div>
  );
};

const Location = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center bg-gray-100 sm:rounded-lg text-gray-700 px-4 py-3 sm:px-6 sm:py-4 border border-gray-200">
    <Icon name="pin" className="w-3 h-3 mr-3" />
    <p className=" text-base">{children}</p>
  </div>
);

export interface location extends WindowLocation {
  state: {
    message?: string;
    success?: boolean;
    error?: string;
    disconnected?: boolean;
  };
}
