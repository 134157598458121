import gql from "graphql-tag";

import { Service } from "../../types/service";
import { SERVICE_FIELDS } from "../fragments";

export interface Data {
  services: Service[];
}

export const ALL_SERVICES_QUERY = gql`
  ${SERVICE_FIELDS}

  query ServicesQuery {
    services {
      ...ServiceFields
    }
  }
`;
