import React, { ChangeEvent } from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";

import { SUPPORT_PROFESSIONAL } from "../../../../graphql/queries/supportProfessional";
import { SupportProfessional } from "../../../../types/supportProfessional";
import { ToggleField as Toggle } from "../../../layout/forms";
import { useLogger } from "../../../../hooks/useLogger";
import {
  CREATE_AVAILABILITY,
  NewData as CreateNewData,
  Variables as CreateVariables,
} from "../../../../graphql/mutations/createAvailability";
import {
  DELETE_AVAILABILITY,
  NewData as DeleteNewData,
  Variables as DeleteVariables,
} from "../../../../graphql/mutations/deleteAvailability";

export interface EditDayProps {
  supportProfessional: Pick<SupportProfessional, "id" | "availabilities">;
  dayNumber: number;
}

const days = [
  /* Ignoring zero index because Timex uses numbers 1..7 for days in the backend */
  "",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const EditDay = ({ supportProfessional, dayNumber }: EditDayProps) => {
  const { log } = useLogger();
  const [createAvailability] = useMutation<CreateNewData, CreateVariables>(
    CREATE_AVAILABILITY
  );
  const [removeAvailability] = useMutation<DeleteNewData, DeleteVariables>(
    DELETE_AVAILABILITY
  );

  const { availabilities } = supportProfessional;
  const availability = availabilities.find((a) => a.dayNumber === dayNumber);
  const available = availability ? availability.available : true;

  const handleSubmit = (values: any, formikApi: any) => {
    const variables = {
      available: values[days[dayNumber]],
      dayNumber: dayNumber,
      supportProfessionalId: supportProfessional.id,
    };

    const refetchQueries = [
      {
        query: SUPPORT_PROFESSIONAL,
        variables: { profileId: supportProfessional.id },
      },
    ];

    if (variables.available && availability) {
      removeAvailability({
        variables: { id: availability.id },
        refetchQueries,
      })
        .then((res) => {})
        .catch((err) => {
          log("error", err);
        });
    } else {
      createAvailability({ variables, refetchQueries })
        .then((res) => {})
        .catch((err) => {
          log("error", err);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        [days[dayNumber]]: available,
      }}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: {
          rate?: string;
        } = {};

        return errors;
      }}
    >
      {(props) => {
        const { submitForm, isSubmitting } = props;
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
          submitForm();
        };
        return (
          <>
            <Form className="flex w-1/2 md:3/5 lg:1/2">
              <Toggle
                name={days[dayNumber]}
                ariaLabel={days[dayNumber]}
                onChange={handleChange}
                className="mb-6 mt-3"
                disabled={isSubmitting}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
