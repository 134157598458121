import React from "react";
import { useQuery } from "@apollo/client";

import {
  GET_SUPPORT_PROFESSIONAL_ACCOUNT_LINK_URL,
  Data,
  Variables,
} from "../../../../graphql/queries/supportProfessionalAccountLinkUrl";
import { Loading } from "../../../layout/Loading";
import { useLogger } from "../../../../hooks/useLogger";

export const VerificationNotice = ({
  supportProfessionalId,
}: {
  supportProfessionalId: string;
}) => {
  const { log } = useLogger();
  const { data, error, loading } = useQuery<Data, Variables>(
    GET_SUPPORT_PROFESSIONAL_ACCOUNT_LINK_URL,
    {
      variables: { supportProfessionalId },
    }
  );

  if (error) {
    log("error", error);
    return <div>Error: Currently unable to link to stripe.</div>;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { accountLink } = data;

  return (
    <div className="rounded-md bg-orange-50 p-5 border border-orange-200 mb-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-yellow-600"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-base leading-5 font-bold text-yellow-800">
            Attention Needed
          </h3>
          <div className="mt-2 text-base leading-5 text-yellow-700">
            <p>
              We need you to verify a few details before you can receive
              payments
            </p>
            <a className="inline-block mt-4" href={accountLink}>
              <button
                type="button"
                className="px-2 py-1.5 rounded-md text-base leading-5 font-medium text-orange-800 bg-orange-200 hover:bg-orange-300 focus:outline-none focus:bg-orange-100 transition ease-in-out duration-150"
              >
                Verify
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
