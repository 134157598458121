import React from "react";
import { Link } from "@reach/router";

import { ServiceRole } from "../../types/profile";
import { Avatar } from "../layout/Avatar";
import { Icon } from "../layout/Icons";
import { Conversation } from "../../types/message";

export type ConversationT = Pick<
  Conversation,
  "sender" | "endUser" | "messages"
>;

export interface UserT {
  __typename: ServiceRole;
}

export const ConversationHeader = ({
  currentConversation,
  user,
}: {
  currentConversation: ConversationT;
  user: UserT;
}) => {
  const profileUrl = {
    [ServiceRole.SUPPORT_WORKER]: `/worker/profile/${currentConversation.sender.id}`,
    [ServiceRole.END_USER]: `/user/profile/${currentConversation.sender.id}`,
    [ServiceRole.SUPPORT_COORDINATOR]: `/support-coordinator/profile/${currentConversation.sender.id}`,
  };

  return (
    <>
      {/* <!-- Search header --> */}
      <div className="relative z-10 flex-shrink-0 flex bg-white border-b border-gray-200">
        {/* <!-- Sidebar toggle, controls the 'sidebarOpen' sidebar state. --> */}
        <Link
          to="/messages"
          className="flex items-center px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
          aria-label="Open sidebar"
        >
          <Icon name="chevron" className="h-6 w-6" rotate="90" />
        </Link>
        <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0">
            <Link
              to={
                profileUrl[
                  currentConversation.sender.role ||
                    currentConversation.sender.__typename
                ]
              }
              className="text-black"
            >
              <div className="flex items-center py-6 relative left-0 pl-6 w-full">
                <Avatar
                  float="left"
                  size="xs"
                  responsive={{ md: "sm", lg: "md" }}
                  user={currentConversation.sender}
                />
                <div className="ml-6">
                  <h1 className="mb-0 text-base md:text-xl lg:text-2xl">
                    {currentConversation.sender.name ||
                      currentConversation.sender.firstName +
                        " " +
                        currentConversation.sender.lastName}
                  </h1>
                  {user.__typename === ServiceRole.SUPPORT_COORDINATOR &&
                    !!currentConversation.endUser && (
                      <div className="flex items-center lg:mt-2">
                        <Avatar
                          user={currentConversation.endUser}
                          size="xxs"
                          className="mr-2 hidden lg:inline-block"
                        />
                        <span className="inline-block lg:hidden text-sm sm:text-base mr-1">
                          &
                        </span>
                        <h2 className="mb-0 text-sm sm:text-base lg:text-lg font-light text-gray-700">
                          {currentConversation.endUser.name}
                        </h2>
                      </div>
                    )}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
