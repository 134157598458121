import { IUserContext } from "../types/profile";
import { Viewer } from "../types/viewer";

export type ViewerT = Pick<Viewer, "id" | "role">;

export const enableRecurringBookingRequests = (
  viewer: ViewerT,
  user: Pick<IUserContext, "id">
) => {
  const allowedIds = [
    // Daniel Hillyer: "fb1994c1-9e13-4d52-b7cd-6398b96ec16a"
    "fb1994c1-9e13-4d52-b7cd-6398b96ec16a",
  ];

  return viewer.role === "admin" || allowedIds.includes(user.id);
};

export const enableAdminOnly = (viewer: ViewerT) => {
  return viewer.role === "admin";
};
