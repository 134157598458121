import { useEffect, useState, useCallback } from "react";
import { useMutation } from "@apollo/client";

import { getEnv } from "../utils/helpers";
import {
  VERIFY_CAPTCHA,
  NewData,
  Variables,
} from "../graphql/mutations/verifyCaptcha";
import { useLogger } from "../hooks/";

export const useGoogleReCAPTCHA = ({
  onSubmit,
  setSubmitting,
}: {
  onSubmit: () => void;
  setSubmitting: (isSubmitting: boolean) => void;
}): {
  captchaProps: {
    "data-sitekey": string;
    "data-badge": string;
    "data-callback": string;
    "data-action": string;
  };
  success: boolean;
} => {
  const { log } = useLogger();
  const [verify] = useMutation<NewData, Variables>(VERIFY_CAPTCHA);
  const [success, setSuccess] = useState(false);
  const threshold = 0;

  window.onGRecaptchaSubmit = useCallback(
    (token: string | null) => {
      setSubmitting(true);

      verify({ variables: { token } })
        .then(({ data }) => {
          if (
            data &&
            data.verifyCaptcha.success &&
            data.verifyCaptcha.score > threshold
          ) {
            setSuccess(true);
            onSubmit();
          }
        })
        .catch((err) => {
          log("error", err);
        });
    },
    [onSubmit, verify, setSubmitting, log]
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js`;
    document.body.appendChild(script);
  }, []);

  const captchaProps = {
    "data-sitekey": getEnv("REACT_APP_CAPTCHA_API_KEY"),
    "data-badge": "bottomleft",
    "data-callback": "onGRecaptchaSubmit",
    "data-action": "submit",
  };

  return { captchaProps, success };
};
