import React, { useState, useEffect } from "react";
import { RouteComponentProps, Link, Redirect } from "@reach/router";
import { useQuery } from "@apollo/client";

import {
  Data,
  Variables,
  bookingHistoryQueryForUserType,
} from "../../graphql/queries/bookingHistory";
import { BookingList } from "./BookingList";
import { Loading } from "../layout/Loading";
import { ErrorPage } from "../layout/ErrorPage";
import { ServiceRole, IUserContext } from "../../types/profile";
import { Icon } from "../layout/Icons";
import { NavLink } from "../layout/NavLink";
import { SupportCoordinatorBookingList } from "../users/supportCoordinators/SupportCoordinatorBookingList";

export interface BookingHistoryProps extends RouteComponentProps {
  page?: string;
  user: Pick<IUserContext, "id" | "__typename">;
}

export const BookingHistory = ({ page, user }: BookingHistoryProps) => {
  const { id, __typename } = user;

  const currentPage = parseInt(page || "1");
  const limit = user.__typename === ServiceRole.SUPPORT_COORDINATOR ? 8 : 6;

  // Ensures that no negative offset is passed to the query
  const offset = currentPage - 1 > 0 ? (currentPage - 1) * limit : 0;

  const variables = { id, limit, offset };
  const { data, error, loading } = useQuery<Data, Variables>(
    bookingHistoryQueryForUserType[__typename],
    {
      variables,
    }
  );

  const getNumPageLinks = (width: number) => {
    if (width > 639) return 7;
    if (width > 997) return 9;
    if (width > 1302) return 11;
    if (width > 1606) return 13;
    return 3;
  };

  const [visiblePageLinks, setVisiblePageLinks] = useState(
    getNumPageLinks(window.innerWidth)
  );

  useEffect(() => {
    function handleResize() {
      setVisiblePageLinks(getNumPageLinks(window.innerWidth));
    }
    window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  const { bookingHistory } = data;
  const { total, bookings } = bookingHistory;

  if (total === 0) {
    return (
      <div className="message info">
        <Icon name="box" />
        <p className="mb-4">You have no completed bookings</p>
      </div>
    );
  }
  const totalPages = Math.ceil(total / limit);

  if (currentPage > totalPages || currentPage < 1 || !page) {
    return <Redirect noThrow to="/bookings/history/1" />;
  }

  return (
    <div className="border-container md:shadow">
      <h2>Past Bookings</h2>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          numPageLinks={visiblePageLinks}
        />
      )}
      {user.__typename === ServiceRole.SUPPORT_COORDINATOR ? (
        <SupportCoordinatorBookingList bookings={bookings} className="my-10" />
      ) : (
        <BookingList
          bookings={bookings}
          showState={true}
          emptyStateMessage="No completed bookings"
          user={user}
        />
      )}

      {totalPages > 1 && (
        <div className="mt-5">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            numPageLinks={visiblePageLinks}
          />
        </div>
      )}
    </div>
  );
};

const Pagination = ({
  currentPage,
  totalPages,
  numPageLinks,
}: {
  currentPage: number;
  totalPages: number;
  numPageLinks: number;
}) => {
  const half = Math.ceil(numPageLinks / 2);
  const pages = Array.from(
    Array(totalPages > numPageLinks ? numPageLinks : totalPages).keys()
  ).map((page) => {
    let offset = 1;
    if (totalPages <= numPageLinks) return page + offset;
    if (currentPage > half) offset = offset + currentPage - half;
    if (currentPage > totalPages - half + 1)
      offset = totalPages - (numPageLinks - 1);
    return page + offset;
  });
  return (
    <ul className="pagination">
      {currentPage > 1 && totalPages > numPageLinks ? (
        <Link to={`/bookings/history/${currentPage - 1}`}>
          <li className="page-number">
            <Icon className="w-4 h-4 transform rotate-90" name="chevron" />
          </li>
        </Link>
      ) : (
        <div className="w-16 sm:w-10 h-4" />
      )}
      {pages.map((page, index) => {
        return (
          <NavLink to={`/bookings/history/${page}`} key={index}>
            <li className="page-number">{page}</li>
          </NavLink>
        );
      })}
      {currentPage < totalPages && totalPages > numPageLinks ? (
        <Link to={`/bookings/history/${currentPage + 1}`}>
          <li className="page-number">
            <Icon className="w-4 h-4 transform -rotate-90" name="chevron" />
          </li>
        </Link>
      ) : (
        <div className="w-10" />
      )}
    </ul>
  );
};
