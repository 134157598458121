import React from "react";

import tapponLogo from "../../assets/images/tappON_partwhite.png";

export const Footer = () => {
  return (
    <div className="bg-tappon-bg hidden md:block">
      <div className="footer">
        <div className="mx-6 md:mx-32 pb-10 pt-4 text-base">
          <div className="md:grid md:grid-cols-5">
            <div className="mb-6">
              <h4 className="text-white">About</h4>
              <ul>
                <FooterItem
                  url="https://support.tappon.co/hc/en-us"
                  text="FAQ"
                />
                <FooterItem
                  url="https://about.tappon.co/refer-a-friend/"
                  text="Refer a Friend"
                />
                <FooterItem
                  url="https://about.tappon.co/privacy-policy/"
                  text="Privacy Policy"
                />
                <FooterItem
                  url="https://about.tappon.co/terms-and-conditions/"
                  text="Terms of Use"
                />
              </ul>
            </div>
            <div className="mb-6">
              <h4 className="text-white">Support</h4>
              <ul>
                <FooterItem
                  url="https://about.tappon.co/contact/"
                  text="Contact Us"
                />
                <FooterItem
                  url="https://about.tappon.co/hand-washing/"
                  text="Hand Hygiene"
                />
              </ul>
            </div>
            <div className="col-span-3">
              <img
                src={tapponLogo}
                alt="tappON logo"
                className="footer-logo mb-2 -ml-4 md:ml-0"
              />
              <p className="pl-1 md:pl-5 text-white">
                Connecting services for happier and healthier communities
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FooterItem = ({ url, text }: { url: string; text: string }) => {
  return (
    <li>
      <a
        className="footer-link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    </li>
  );
};
