import React from "react";
import { Formik, Form, FormikErrors, FormikHelpers } from "formik";

import { BookingAction, RecurringBookingSchedule } from "../../types/booking";
import { Icon } from "../layout/Icons";
import { TextArea } from "../layout/forms";
import { Button } from "../layout/Button";
import { useLockBody } from "../../hooks";

export interface FormValues {
  cancellationReason?: string;
  affectBookingSchedule?: boolean;
}

export const BookingActionConfirmModal = ({
  hideModal,
  action,
  schedule,
  handleActionSubmit,
}: {
  hideModal(): void;
  action: BookingAction;
  schedule: Pick<RecurringBookingSchedule, "id" | "rrule"> | null;
  handleActionSubmit: (
    opts: {
      cancellationReason?: string;
      affectBookingSchedule?: boolean;
    },
    setSubmitting: (bool: boolean) => void
  ) => void;
}) => {
  useLockBody();

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    const isCancelAction =
      action.label.includes("Cancel") || action.label.includes("Reject");

    if (isCancelAction && !values.cancellationReason) {
      errors.cancellationReason = "Required";
    }

    return errors;
  };

  const color = action.color === "green" ? "teal" : action.color;

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const { cancellationReason, affectBookingSchedule } = values;

    handleActionSubmit(
      { cancellationReason, affectBookingSchedule },
      formikHelpers.setSubmitting
    );
  };

  const initialValues: FormValues = {
    cancellationReason: "",
    affectBookingSchedule: false,
  };

  return (
    <div className="booking-action-confirm-modal">
      <div className="content">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validate}
        >
          {({ isSubmitting, submitForm, setFieldValue }) => (
            <Form>
              <div className="modal-overlay">
                <div className="bg"></div>
              </div>
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
              &#8203;
              <div
                className="panel"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="sm:flex sm:items-start">
                  <div className={`icon bg-${color}-100`}>
                    <Icon
                      name="calendar"
                      className={`h-5 w-5 text-${color}-600`}
                    />
                  </div>
                  <div className="main">
                    <h3 className="action-label" id="modal-headline">
                      {action.label}
                    </h3>
                    {action.label === "Cancel with Fee" && (
                      <p className="flex items-center text-red-600">
                        <Icon name="alert" className="w-4 h-4 mr-2" />
                        Cancellation will incur a penalty
                      </p>
                    )}
                    <div className="mt-2">
                      {action.variables.newState.includes("CANCELLED") && (
                        <TextArea
                          className="w-full"
                          placeholder={`Please provide a reason for declining this booking`}
                          name="cancellationReason"
                          noLabel
                        />
                      )}
                      {schedule && (
                        <div className="mt-4">
                          <p className="rrule">
                            This booking repeats{" "}
                            <span className="font-bold">{schedule.rrule}</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <div className="sm:flex">
                    <span className="submit">
                      <Button
                        color={color}
                        outline
                        className={`w-full justify-center ${
                          isSubmitting ? "submitting" : ""
                        }`}
                        onClick={() => submitForm()}
                      >
                        {action.label}
                        {action.label !== "Cancel with Fee" &&
                          (schedule ? " this booking" : " Booking")}
                      </Button>
                    </span>
                    {schedule && action.label !== "Cancel with Fee" && (
                      <span className="submit affect-booking-schedule">
                        <Button
                          color={color}
                          className={`w-full justify-center ${
                            isSubmitting ? "submitting" : ""
                          }`}
                          onClick={async () => {
                            await setFieldValue("affectBookingSchedule", true);
                            submitForm();
                          }}
                        >
                          {action.label} all
                        </Button>
                      </span>
                    )}
                  </div>
                  <span className="mt-10 flex w-full rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
                    <Button
                      color="gray"
                      secondary
                      onClick={hideModal}
                      className="w-full justify-center"
                    >
                      Back
                    </Button>
                  </span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
