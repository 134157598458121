import gql from "graphql-tag";

import { Booking } from "../../../types/booking";
import { BookingStateEvent } from "../../../types/bookingStateEvent";
import { Conversation } from "../../../types/message";
import { SupportCoordinator } from "../../../types/supportCoordinator";
import { EndUser } from "../../../types/endUser";
import { BOOKING_FIELDS, CONVERSATION_FIELDS } from "../../fragments";

type BookingT = Pick<
  Booking,
  | "id"
  | "endUser"
  | "endTime"
  | "serviceName"
  | "startTime"
  | "state"
  | "supportProfessional"
  | "totalCost"
  | "paymentFailed"
  | "projected"
  | "scheduleId"
>;

export type BookingStateEventT = Pick<
  BookingStateEvent,
  | "id"
  | "endUser"
  | "supportProfessional"
  | "insertedAt"
  | "account"
  | "supportCoordinator"
  | "newState"
  | "onBehalfOf"
  | "booking"
>;

export interface Data {
  supportCoordinator: Pick<
    SupportCoordinator,
    "id" | "firstName" | "lastName" | "about" | "company" | "profileImage"
  >;
  upcomingClientBookings: BookingT[];
  actionableClientBookings: BookingT[];
  endUsersForSupportCoordinator: Pick<
    EndUser,
    "id" | "profileImage" | "name"
  >[];
  conversations: Pick<
    Conversation,
    "messages" | "sender" | "lastUpdated" | "slug" | "endUserId"
  >[];
  recentClientActivity: BookingStateEventT[];
}

export interface Variables {
  id: string;
}

export const SUPPORT_COORDINATOR_DASHBOARD_QUERY = gql`
  ${BOOKING_FIELDS}
  ${CONVERSATION_FIELDS}

  query SupportCoordinatorDashboardQuery($id: ID!) {
    supportCoordinator(id: $id) {
      id
      firstName
      lastName
      about
      company
      profileImage {
        id
        small
        med
        large
      }
    }

    upcomingClientBookings(supportCoordinatorId: $id) {
      ...BookingFields
    }

    actionableClientBookings(supportCoordinatorId: $id) {
      ...BookingFields
    }

    endUsersForSupportCoordinator(supportCoordinatorId: $id) {
      id
      profileImage {
        id
        small
        med
        large
      }
      name
    }

    conversations: conversationsForSupportCoordinator(
      supportCoordinatorId: $id
    ) {
      ...ConversationFields
    }

    recentClientActivity(supportCoordinatorId: $id) {
      id
      endUser {
        id
        name
      }
      supportProfessional {
        id
        name
      }
      insertedAt
      account {
        id
        role
        firstName
        lastName
      }
      supportCoordinator {
        id
        firstName
        lastName
      }
      newState
      onBehalfOf
      booking {
        id
        endUser {
          id
          name
        }
        supportProfessional {
          id
          name
        }
        serviceName
      }
    }
  }
`;
