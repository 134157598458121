import gql from "graphql-tag";
import { EndUser } from "../../types/endUser";

import { ServiceRole } from "../../types/profile";
import { Service } from "../../types/service";
import { SERVICE_FIELDS, END_USER_FIELDS } from "../fragments";

export interface Data {
  services: Service[];
  servicesWithOfferings: {
    id: string;
  }[];
  recommendedSupportProfessionals?: {
    supportProfessionals: { id: string }[];
    total: number;
  };
  endUser?: EndUser;
}

export interface Variables {
  id?: string;
}

export const SUPPORT_PROFESSIONAL_DISCOVER_PAGE = gql`
  ${SERVICE_FIELDS}
  query DiscoverPageQuery {
    services {
      ...ServiceFields
    }

    servicesWithOfferings {
      id
    }
  }
`;

export const END_USER_DISCOVER_PAGE = gql`
  ${SERVICE_FIELDS}
  ${END_USER_FIELDS}
  query DiscoverPageQuery($id: ID!) {
    recommendedSupportProfessionals: recommendedSupportProfessionalsForEndUser(
      id: $id
      options: { offset: 0, limit: 1, randomSeed: 0.1 }
    ) {
      supportProfessionals {
        id
      }
      total
    }

    services {
      ...ServiceFields
    }

    servicesWithOfferings(endUserId: $id) {
      id
    }

    endUser(id: $id) {
      ...EndUserFields
    }
  }
`;

export const discoverPageQueryForUserType = {
  [ServiceRole.END_USER]: END_USER_DISCOVER_PAGE,
  [ServiceRole.SUPPORT_WORKER]: SUPPORT_PROFESSIONAL_DISCOVER_PAGE,
  [ServiceRole.SUPPORT_COORDINATOR]: SUPPORT_PROFESSIONAL_DISCOVER_PAGE,
};
