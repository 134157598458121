import React from "react";
import { PureQueryOptions } from "@apollo/client";
import { Link } from "@reach/router";
import moment from "moment";

import { Parties, BookingState } from "../../types/booking";
import { BookingActionButtonGroup } from "./BookingActionButtonGroup";
import { BookingStateLabel } from "./bookingStateLabels/BookingStateLabel";
import { PaymentTypeMessage } from "./PaymentTypeMessage";
import { IUserContext, ServiceRole } from "../../types/profile";
import { Button } from "../layout/Button";
import { BookingT } from "./BookingPage";
import { Icon } from "../layout/Icons";
import { PaymentFailedNotification } from "./PaymentFailedNotification";

export const BookingDetail = ({
  booking,
  refetchQueries,
  user,
}: {
  booking: BookingT & Parties;
  refetchQueries: (string | PureQueryOptions)[];
  user: Pick<IUserContext, "__typename" | "id">;
}) => {
  const bookingCancelled = [
    BookingState.CANCELLED_BY_END_USER_WITHOUT_NOTICE,
    BookingState.CANCELLED_BY_END_USER_WITH_NOTICE,
    BookingState.CANCELLED_BY_SUPPORT_WORKER,
  ].includes(booking.state);

  const isSupportCoordinator =
    user.__typename === ServiceRole.SUPPORT_COORDINATOR;

  const requestedEvent =
    booking.stateEvents && booking.stateEvents.find((e) => e.oldState === null);

  return (
    <div className="space-y-6">
      <dl className="flex space-x-10">
        <div>
          <dt className="text-base mb-2">Date</dt>
          <dd className="text-xl font-bold">
            {`${moment(booking.startTime).format("D MMMM YYYY")}`}
          </dd>
        </div>
        <div>
          <dt className="text-base mb-2">Start Time</dt>
          <dd className="text-xl font-bold">{`${moment(
            booking.startTime
          ).format("h:mma")}`}</dd>
        </div>
        <div>
          <dt className="text-base mb-2">End Time</dt>
          <dd className="text-xl font-bold">{`${moment(booking.endTime).format(
            "h:mma"
          )}`}</dd>
        </div>
      </dl>

      {booking.schedule && (
        <p className="font-bold rounded-lg inline-block flex items-center text-gray-700">
          <Icon name="info" className="mr-3 h-4 w-4" />
          {booking.schedule.active ? "Repeats" : "Repeated"}{" "}
          {booking.schedule.rrule}
        </p>
      )}
      {requestedEvent &&
        (user.id === booking.endUser.id ||
          (booking.endUser.supportCoordinator &&
            user.id === booking.endUser.supportCoordinator.id)) && (
          <div>
            <p className="bg-teal-100 inline-block px-3 py-2 rounded text-teal-800">
              Booking{" "}
              {requestedEvent.onBehalfOf in ["SUPPORT_COORDINATOR", "END_USER"]
                ? "requested by "
                : "created by "}
              {requestedEvent.onBehalfOf === "SUPPORT_COORDINATOR" &&
                requestedEvent.supportCoordinator &&
                (requestedEvent.supportCoordinator.id === user.id
                  ? "you"
                  : `${requestedEvent.supportCoordinator.firstName} ${requestedEvent.supportCoordinator.lastName}`)}
              {requestedEvent.onBehalfOf === "END_USER" &&
                requestedEvent.endUser &&
                (requestedEvent.endUser.id === user.id
                  ? "you"
                  : requestedEvent.endUser.name)}
              {requestedEvent.onBehalfOf === "ADMIN" && "tappON admin"}
              {requestedEvent.onBehalfOf === "SYSTEM" && "tappON system"}
              {booking.schedule && " as part of a recurring booking schedule"}
            </p>
          </div>
        )}

      <div>
        <BookingStateLabel booking={booking} user={user} />
      </div>
      <dl>
        {booking.totalCost.amount > 0 && (
          <>
            <dt className="text-base mb-2">Total Cost</dt>
            <dd className="text-3xl font-bold mt-4 mb-8">
              ${(booking.totalCost.amount / 100).toFixed(2)}
            </dd>
            {booking.paymentFailed &&
            user.__typename !== ServiceRole.SUPPORT_WORKER ? (
              <PaymentFailedNotification booking={booking} />
            ) : (
              <PaymentTypeMessage booking={booking} user={user} />
            )}
          </>
        )}
      </dl>
      <dl>
        {booking.specialInstructions && (
          <div>
            <p className="text-base mb-2">Special Instructions</p>
            <dd className="font-bold">{booking.specialInstructions}</dd>
          </div>
        )}
        {booking.cancellationReason && bookingCancelled && (
          <div>
            <p className="text-base mb-2">Cancellation Reason</p>
            <dd className="font-bold">{booking.cancellationReason}</dd>
          </div>
        )}
      </dl>
      <div>
        <div className="extra flex items-center space-x-2">
          {booking.actions.length > 0 && (
            <BookingActionButtonGroup
              booking={booking}
              refetchQueries={refetchQueries}
            />
          )}

          <Link
            to={`/messages/${booking.otherParty.id}${
              isSupportCoordinator ? `&${booking.endUser.id}` : ""
            }`}
          >
            <Button color="gray" outline icon="envelope">
              Contact {booking.otherParty.name}{" "}
              {isSupportCoordinator && "about this booking"}
            </Button>
          </Link>
        </div>
        {booking.state === BookingState.REQUESTED_BY_END_USER &&
          !booking.spAvailable &&
          user.id === booking.supportProfessional.id && (
            <div className="p-4 border border-gray-300 bg-gray-100 mt-4">
              <p>
                You can't accept this booking because you already have an
                existing booking at this time. Please contact{" "}
                {booking.endUser.name.split(" ")[0]} to suggest an alternative
                time.
              </p>
            </div>
          )}
      </div>
    </div>
  );
};
