import React, { ChangeEvent } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { v4 as uuid } from "uuid";

import { Dropdown, Label } from ".";

export interface DropdownGroupProps {
  values: {
    name: string;
    options: { text: string; value: string | number; key?: string }[];
    onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
    blankDefault?: boolean;
  }[];
  label?: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  disabled?: boolean;
  gap?: number;
  noLabel?: boolean;
}

export const DropdownGroup = ({
  values,
  label,
  errors,
  touched,
  disabled,
  gap,
  noLabel,
}: DropdownGroupProps) => {
  return (
    <div className="mb-4">
      {!noLabel && (
        <Label htmlFor={values[0].name}>{label || values[0].name}</Label>
      )}
      <div className="flex">
        {values.map((value, index) => {
          return (
            <Dropdown
              name={value.name}
              options={value.options}
              inline
              noLabel
              key={uuid()}
              customHandleChange={value.onChange}
              blankDefault={value.blankDefault}
              disabled={disabled}
              className={index > 0 ? `ml-${gap}` : ""}
            />
          );
        })}
      </div>
      {errors[values[0].name] && touched[values[0].name] && (
        <p className="sui-error-message mt-1">{errors[values[0].name]}</p>
      )}
    </div>
  );
};
