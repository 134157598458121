import gql from "graphql-tag";

export const MESSAGE_FIELDS = gql`
  fragment MessageFields on Message {
    id
    message
    endUser {
      id
      name
      profileImage {
        id
        small
      }
    }
    supportProfessional {
      id
      name
      profileImage {
        id
        small
      }
    }
    supportCoordinator {
      id
      firstName
      lastName
      profileImage {
        id
        small
      }
    }
    sentBySp

    insertedAt
    sender {
      id
      name
      firstName
      lastName
      profileImage {
        id
        small
      }
    }
    recipient {
      id
      name
      profileImage {
        id
        small
      }
    }
  }
`;

export const CONVERSATION_FIELDS = gql`
  ${MESSAGE_FIELDS}

  fragment ConversationFields on Conversation {
    messages {
      ...MessageFields
    }
    sender {
      id
      name
      firstName
      lastName
      role
      profileImage {
        id
        small
      }
    }
    lastUpdated
    slug
    endUser {
      id
      name
      profileImage {
        id
        small
      }
    }
    endUserId
    supportCoordinatorId
    supportProfessionalId
  }
`;
