import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/client";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { Loading } from "../../layout/Loading";
import { NotFoundPage } from "../../pages/NotFoundPage";
import {
  MANAGE_CLIENT_PAGE,
  Data,
  Variables,
} from "../../../graphql/queries/manageClient";
import { ErrorPage } from "../../layout/ErrorPage";
import { IUserContext } from "../../../types/profile";
import { SupportCoordinatorClientNav } from "../supportCoordinators/SupportCoordinatorClientNav";
import { EndUserProfileSettings } from "../../pages/settings/profile/EndUserProfileSettings";
import { PersonalInformationSettingsForms as PersonalInformationSettings } from "../../pages/settings/personalInformation/PersonalInformationSettings";
import { FundingSettingsContent as FundingSettings } from "../../pages/settings/funding/FundingSettings";
import { PreferencesSettingsContent as PreferencesSettings } from "../../pages/settings/preferences/PreferencesSettings";

export interface ManageClientProps extends RouteComponentProps {
  profileId?: string;
  user: Pick<IUserContext, "id" | "__typename">;
}

export const ManageClientPage = ({ profileId, user }: ManageClientProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    MANAGE_CLIENT_PAGE,
    { variables: { profileId: profileId || "" } }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { endUser, services } = data;

  if (!endUser) {
    return <NotFoundPage />;
  }

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        return (
          <div className="mx-6 sm:mx-20 my-16">
            <SupportCoordinatorClientNav endUser={endUser} />
            <h3 className="mt-16 text-gray-800">Personal Information</h3>
            <PersonalInformationSettings endUser={endUser} add={add} />
            <h3 className="mt-16 text-gray-800">Funding Details</h3>
            <FundingSettings
              endUser={endUser}
              add={add}
              permissions={endUser.permissions}
            />
            <h3 className="mt-16 text-gray-800">Preferences</h3>
            <PreferencesSettings
              endUser={endUser}
              services={services}
              add={add}
            />
            <h3 className="mt-16 text-gray-800">Profile</h3>
            <EndUserProfileSettings endUser={endUser} user={user} />
          </div>
        );
      }}
    </ToastConsumer>
  );
};
