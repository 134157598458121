import React from "react";
import {
  LocalTrackPublication,
  RemoteTrackPublication,
  LocalVideoTrack,
  AudioTrack as IAudioTrack,
  LocalParticipant,
  RemoteParticipant,
} from "twilio-video";

import { RemoteVideoTrack } from "./VideoRoom";
import { useTrack } from "./hooks/useTrack";
import { VideoTrack } from "./VideoTrack";
import { AudioTrack } from "./AudioTrack";
import { useIsTrackEnabled } from "./hooks/useIsTrackEnabled";
import { Icon } from "../../layout/Icons";
import defaultAvatar from "../../../assets/images/default-avatar-image.png";
import { EndUser } from "../../../types/endUser";
import { SupportProfessional } from "../../../types/supportProfessional";

export const Publication = ({
  publication,
  isLocal,
  disableAudio,
  participant,
  serviceRole,
}: {
  publication: LocalTrackPublication | RemoteTrackPublication | undefined;
  isLocal?: boolean;
  disableAudio?: boolean;
  participant: LocalParticipant | RemoteParticipant;
  serviceRole: Pick<EndUser | SupportProfessional, "profileImage" | "name">;
}) => {
  const track = useTrack(publication);

  //@ts-ignore
  const isDisabled = !useIsTrackEnabled(track);

  if (!track) return null;

  switch (track.kind) {
    case "video":
      return (
        <>
          {isDisabled && !isLocal && (
            <PlaceholderProfileImage
              imageUrl={serviceRole.profileImage?.small || defaultAvatar}
            />
          )}
          <VideoTrack
            track={track as IVideoTrack}
            isLocal={isLocal}
            participant={participant}
          />
        </>
      );
    case "audio":
      return (
        <>
          {isDisabled && !isLocal && <MutedBanner name={serviceRole.name} />}
          {disableAudio ? null : <AudioTrack track={track as IAudioTrack} />}
        </>
      );
    default:
      return null;
  }
};

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

const MutedBanner = ({ name }: { name: string }) => {
  return (
    <div className="w-full fixed top-0 left-0 bg-black bg-opacity-50 py-3 text-white flex justify-center">
      <Icon name="micOff" className="w-5 h-5 mr-4" />
      <p>{name} is on mute</p>
    </div>
  );
};

const PlaceholderProfileImage = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <div className="fixed w-screen h-screen flex justify-center items-center top-0 left-0">
      <div
        className="w-24 h-24"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
        }}
      />
    </div>
  );
};
