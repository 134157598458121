import React from "react";

import { Avatar } from "../../../layout/Avatar";
import { UpdateProfileImageForm } from "./UpdateProfileImageForm";
import { IUserContext } from "../../../../types/profile";
import { EndUser } from "../../../../types/endUser";
import { DescriptionForm } from "./DescriptionForm";

export const EndUserProfileSettings = ({
  endUser,
  user,
}: {
  endUser: Pick<
    EndUser,
    "id" | "profileImage" | "about" | "__typename" | "name"
  >;
  user: Pick<IUserContext, "id" | "__typename" | "profileImage">;
}) => {
  return (
    <>
      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="photo"
          className="block text-sm leading-5 font-medium text-gray-700"
        >
          Profile Image
        </label>
        <div className="mt-2 sm:mt-0 sm:col-span-2">
          <div className="flex items-center">
            <Avatar user={endUser} size="md" />
          </div>
        </div>
      </div>
      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <UpdateProfileImageForm user={user} profile={endUser} />
      </div>
      <DescriptionForm profile={endUser} user={user} />
    </>
  );
};
