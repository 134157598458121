import React from "react";

import { useWindowSize } from "../../../../hooks";
import { Button } from "../../../layout/Button";

export const Buttons = ({
  panel,
  setPanel,
  isSubmitting,
  submit,
  hideModal,
  back = true,
}: {
  panel: number;
  setPanel: (number: number) => void;
  isSubmitting: boolean;
  submit: boolean;
  hideModal: () => void;
  back?: boolean;
}) => {
  const { windowWidth } = useWindowSize();

  return (
    <div className="flex justify-between items-center mt-6">
      <Button
        color="gray"
        size={windowWidth > 768 ? "base" : "small"}
        secondary
        onClick={hideModal}
      >
        Skip for now
      </Button>
      <div className="flex">
        {panel > 1 && back && (
          <Button
            size={windowWidth > 768 ? "large" : "base"}
            type="button"
            secondary
            color={windowWidth > 768 ? "gray" : "teal"}
            onClick={() => setPanel(panel - 1)}
            className="mr-2"
          >
            Back
          </Button>
        )}
        {submit && (
          <Button
            size={windowWidth > 768 ? "large" : "base"}
            type="submit"
            loading={isSubmitting}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};
