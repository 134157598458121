import { useEffect, useState } from "react";
import Twilio, { Room, RemoteParticipant } from "twilio-video";

import { useLogger } from "../../../../hooks/useLogger";

export const useRoom = (accessToken: string) => {
  const [room, setRoom] = useState<Room | null>(null);
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
  const [disconnected, setDisconnected] = useState(false);
  const { log } = useLogger();

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant: RemoteParticipant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    Twilio.connect(accessToken, {})
      .then((room: Room) => {
        setRoom(room);
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
        room.on("disconnected", (room: any) => {
          setDisconnected(true);
          // Detach the local media elements
          room.localParticipant.tracks.forEach((track: any) =>
            track.track.stop()
          );
        });
      })
      .catch((err) => {
        log("error", err);
      });

    const disconnectRoom = () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };

    window.addEventListener("beforeunload", () => {
      disconnectRoom();
    });

    return disconnectRoom;
  }, [accessToken, log]);

  return { room, participants, disconnected };
};
