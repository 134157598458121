import gql from "graphql-tag";

import { Booking } from "../../types/booking";
import { BOOKING_FIELDS, BOOKING_STATE_EVENTS_FIELDS } from "../fragments";

export interface Data {
  booking: Booking;
}

export interface Variables {
  // Takes either a booking ID OR a recurring booking schedule ID & date
  bookingId?: string;
  scheduleId?: string;
  date?: string;
}

export const BOOKING = gql`
  ${BOOKING_FIELDS}
  ${BOOKING_STATE_EVENTS_FIELDS}

  query Booking($bookingId: ID, $scheduleId: ID, $date: String) {
    booking(id: $bookingId, scheduleId: $scheduleId, date: $date) {
      ...BookingFields
      endUser {
        supportCoordinator {
          id
          firstName
          lastName
        }
      }
      ...BookingStateEventsFields

      schedule {
        active
      }
    }
  }
`;
