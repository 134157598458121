import React, { useEffect } from "react";
import { Formik, Form, FormikHelpers, FormikErrors, FormikProps } from "formik";
import { useMutation } from "@apollo/client";

import { supportHours } from "../../../users/profileHelpers";
import { Button } from "../../../layout/Button";
import { RECOMMENDED_SUPPORT_PROFESSIONALS } from "../../../../graphql/queries/recommendedSupportProfessionals/recommendedSupportProfessionals";
import { useLogger } from "../../../../hooks";
import { UpdateSuccess, Dropdown } from "../../../layout/forms";
import { EndUser } from "../../../../types/endUser";
import { ServiceRole } from "../../../../types/profile";
import {
  UPDATE_END_USER,
  NewData as EUNewData,
  Variables as EUVariables,
} from "../../../../graphql/mutations/updateEndUser";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData as SPNewData,
  Variables as SPVariables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import { END_USER_PERMISSIONS_QUERY } from "../../../../graphql/queries/endUserPermissions";

interface FormValues {
  supportHoursPerWeek: string;
}

export type EndUserT = Pick<
  EndUser,
  "id" | "supportHoursPerWeek" | "__typename"
>;

export const SupportHoursForm = ({ endUser }: { endUser: EndUserT }) => {
  const { log } = useLogger();
  const isEndUser = endUser.__typename === ServiceRole.END_USER;
  const mutation = isEndUser ? UPDATE_END_USER : UPDATE_SUPPORT_PROFESSIONAL;

  const [updateProfile] = useMutation<
    EUNewData | SPNewData,
    EUVariables | SPVariables
  >(mutation);

  const initialValues: FormValues = {
    supportHoursPerWeek:
      Object.keys(supportHours).find(
        (key) => supportHours[key] === endUser.supportHoursPerWeek
      ) || "",
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const { supportHoursPerWeek } = values;

    const variables = {
      id: endUser.id,
      supportHoursPerWeek: supportHours[supportHoursPerWeek],
    };

    const refetchQueries = isEndUser
      ? [
          {
            query: END_USER_PERMISSIONS_QUERY,
            variables: { endUserId: endUser.id },
          },

          {
            query: RECOMMENDED_SUPPORT_PROFESSIONALS,
            variables: { endUserId: endUser.id },
          },
        ]
      : [];

    updateProfile({
      variables,
      refetchQueries,
    })
      .then((res) => {
        formikHelpers.setSubmitting(false);
        formikHelpers.setTouched({});
      })
      .catch((err) => log("error", err));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};

          if (!values.supportHoursPerWeek) {
            errors.supportHoursPerWeek = "Required";
          }

          return errors;
        }}
      >
        {(props) => (
          <SupportHoursFormComponent formikProps={props} endUser={endUser} />
        )}
      </Formik>
    </>
  );
};

const SupportHoursFormComponent = ({
  formikProps,
  endUser,
}: {
  formikProps: FormikProps<FormValues>;
  endUser: EndUserT;
}) => {
  const { validateForm, values, submitCount } = formikProps;
  // Show missing fields on page load
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const displaySaveButton =
    endUser.supportHoursPerWeek !== supportHours[values.supportHoursPerWeek];

  return (
    <Form>
      <Dropdown
        noLabel
        name="supportHoursPerWeek"
        options={Object.keys(supportHours).map((v) => {
          return { text: v, value: v };
        })}
        className="mb-2"
        blankDefault
      />
      {displaySaveButton && (
        <div className="flex justify-end">
          <Button type="submit">Save</Button>
        </div>
      )}
      {!displaySaveButton && submitCount > 0 && <UpdateSuccess />}
    </Form>
  );
};
