import React, { MutableRefObject, ChangeEvent } from "react";
import { Field, FormikErrors, FormikTouched } from "formik";

import { Label } from ".";
import { capitalize } from "../../../utils/helpers";
import { IconType, Icon } from "../Icons";

export interface InputFieldProps {
  name: string;
  icon?: {
    name: IconType;
    size: number;
  };
  ariaLabel?: string;
  label?: string;
  noLabel?: boolean;
  className?: string;
  placeholders?: string[];
  placeholder?: string;
  inputClassName?: string;
  info?: string | boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  labelIcon?: IconType;
}

export const InputField = React.forwardRef(
  (
    {
      name,
      icon,
      label,
      ariaLabel,
      noLabel,
      className,
      placeholders,
      placeholder,
      inputClassName,
      info,
      disabled,
      onChange: customHandleChange,
      labelIcon,
    }: InputFieldProps,
    ref:
      | ((instance: HTMLInputElement | null) => void)
      | MutableRefObject<HTMLInputElement | null>
      | null
  ) => {
    return (
      <Field>
        {(props: { field: any; form: any }) => {
          const { onChange } = props.field;
          const { values, errors, touched, setTouched } = props.form;

          const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
            await onChange(e);

            if (customHandleChange) {
              customHandleChange(e);
            }
          };

          const inputProps = {
            name,
            icon,
            label,
            ariaLabel,
            noLabel,
            className,
            placeholders,
            placeholder,
            inputClassName,
            info,
            errors,
            values,
            touched,
            setTouched,
            disabled,
            labelIcon,
          };

          return <Input {...inputProps} onChange={handleChange} ref={ref} />;
        }}
      </Field>
    );
  }
);

export interface InputProps extends InputFieldProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  errors?: FormikErrors<any>;
  values?: any;
  touched?: FormikTouched<any>;
  setTouched?: (touched: any) => void;
}

export const Input = React.forwardRef(
  (
    {
      name,
      icon,
      label,
      ariaLabel,
      noLabel,
      className,
      placeholders,
      placeholder,
      inputClassName,
      info,
      errors,
      touched,
      values,
      setTouched,
      disabled,
      labelIcon,
      onChange = () => {},
    }: InputProps,
    ref:
      | ((instance: HTMLInputElement | null) => void)
      | MutableRefObject<HTMLInputElement | null>
      | null
  ) => {
    const error = errors && errors[name] && touched && touched[name];

    return (
      <div className={className}>
        <Label htmlFor={name} className={`${noLabel ? "sr-only" : "mb-1"}`}>
          <div className="flex items-center">
            {labelIcon && (
              <Icon name={labelIcon} className="w-4 h-4 text-gray-500 mr-2" />
            )}
            {label ? capitalize(label) : capitalize(name)}
          </div>
        </Label>
        {info && (
          <span className="text-sm text-gray-600 leading-snug block mb-2">
            {info}
          </span>
        )}
        <div className="relative rounded-md">
          {icon && (
            <div className="absolute left-0 top-0">
              <Icon
                name={icon.name}
                className={`w-${icon.size} h-${
                  icon.size
                } text-gray-500 mx-4 my-${3 +
                  Math.floor(icon.size / 5)} text-gray-400`}
              />
            </div>
          )}
          <input
            ref={ref}
            id={name}
            disabled={disabled}
            className={`form-input block w-full py-2 px-3 ${
              icon ? "pl-12" : ""
            } border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 truncate ${
              error
                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300"
                : ""
            } ${inputClassName}`}
            aria-label={ariaLabel || label || name}
            value={values[name]}
            onChange={onChange}
            onBlur={() => setTouched && setTouched({ [name]: true })}
            placeholder={
              placeholder ||
              (placeholders
                ? `E.g. ${
                    placeholders[
                      Math.floor(Math.random() * placeholders.length)
                    ]
                  }...`
                : "")
            }
          />
          {error && (
            <p
              data-testid="error-message"
              className="mt-2 text-sm text-red-600"
            >
              {errors && errors[name]}
            </p>
          )}
        </div>
      </div>
    );
  }
);
