import React, {
  useRef,
  MouseEvent,
  ChangeEvent,
  useState,
  useContext,
} from "react";
import { useMutation } from "@apollo/client";

import { Icon } from "../../../layout/Icons";
import { Button } from "../../../layout/Button";
import {
  UPLOAD_PROFILE_IMAGE,
  NewData,
  Variables,
} from "../../../../graphql/mutations/uploadProfileImage";
import { useLogger } from "../../../../hooks";
import { IUserContext } from "../../../../types/profile";
import { AuthContext } from "../../../../auth/Auth";
import { EndUser } from "../../../../types/endUser";
import { SupportCoordinator } from "../../../../types/supportCoordinator";
import { SupportProfessional } from "../../../../types/supportProfessional";

export const UpdateProfileImageForm = ({
  profile,
  user,
}: {
  profile:
    | Pick<EndUser, "id" | "__typename">
    | Pick<SupportCoordinator, "id" | "__typename">
    | Pick<SupportProfessional, "id" | "__typename">;
  user: Pick<IUserContext, "id">;
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploadImage, { loading }] = useMutation<NewData, Variables>(
    UPLOAD_PROFILE_IMAGE
  );
  const { log } = useLogger();
  const { updateUser } = useContext(AuthContext);

  const validFileTypes = ["image/png", "image/jpeg", "image/gif"];

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const fileUploaded = event.target.files[0];
      setFile(fileUploaded);
    }
  };

  const handleButtonClick = (event: MouseEvent) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSubmit = () => {
    if (file) {
      const serviceRole = profile.__typename
        .replace(/(\S)([A-Z])/g, "$1_$2")
        .toUpperCase();

      const variables = {
        fileData: file,
        id: profile.id,
        serviceRole,
      };

      uploadImage({ variables })
        .then(({ data }) => {
          if (data) {
            const { uploadProfileImage: userData } = data;
            const {
              id,
              name,
              profileImage,
              __typename,
              firstName,
              lastName,
            } = userData;

            if (user.id === profile.id) {
              updateUser({
                id,
                name: name || `${firstName} ${lastName}`,
                profileImage,
                role: __typename,
                __typename,
              });
            }
          }
        })
        .catch((err) => log("error", err));
    }
  };

  return (
    <div className="mt-2 sm:mt-0 sm:col-span-2 sm:col-start-2">
      <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="text-center">
          {loading ? (
            <Loading />
          ) : (
            <>
              {file ? (
                <>
                  <div className="flex items-center text-gray-600 justify-center">
                    <Icon name="image" className="w-4 h-4 mr-2" />
                    <span>{file.name}</span>
                  </div>
                  <div className="flex justify-center m-2">
                    {file.size <= 8000000 &&
                      validFileTypes.includes(file.type) && (
                        <Button onClick={handleSubmit} size="base" secondary>
                          Upload
                        </Button>
                      )}
                    {file.size > 8000000 && (
                      <div className="flex items-center text-red-800">
                        <Icon name="close" className="w-3 h-3 mr-3" />
                        <span>File too large</span>
                      </div>
                    )}
                    {!validFileTypes.includes(file.type) && (
                      <div className="flex items-center text-red-800">
                        <Icon name="close" className="w-3 h-3 mr-3" />
                        <span>Must be JPG, PNG or GIF</span>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <p className="mt-1 text-base text-gray-600">
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileInputChange}
                  className="hidden"
                />
                <button
                  type="button"
                  onClick={handleButtonClick}
                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1"
                >
                  {file ? "Change" : "Upload a file"}
                </button>
              </p>
              <p className="mt-1 text-sm text-gray-500">
                PNG, JPG, GIF up to 8MB
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Loading = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      className="my-10"
    >
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor="#3d8187" stopOpacity="0" offset="0%" />
          <stop stopColor="#3d8187" stopOpacity=".631" offset="63.146%" />
          <stop stopColor="#3d8187" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            id="Oval-2"
            stroke="url(#a)"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill="#3d8187" cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  );
};
