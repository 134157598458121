import React from "react";
import { PureQueryOptions } from "@apollo/client";
import { Link } from "@reach/router";
import moment from "moment";

import { Booking, BookingState } from "../../types/booking";
import { addPartiesToBooking } from "./addPartiesToBooking";
import { BookingActionButtonGroup } from "./BookingActionButtonGroup";
import { ServiceRole, IUserContext } from "../../types/profile";
import { BookingStateLabel } from "./bookingStateLabels/BookingStateLabel";
import { Avatar } from "../layout/Avatar";
import { Icon } from "../layout/Icons";

export const BookingSummary = ({
  booking,
  showState,
  refetchQueries,
  user,
}: {
  booking: Pick<
    Booking,
    | "id"
    | "endTime"
    | "endUser"
    | "insertedAt"
    | "location"
    | "paymentFailed"
    | "projected"
    | "schedule"
    | "scheduleId"
    | "serviceName"
    | "spAvailable"
    | "startTime"
    | "state"
    | "supportProfessional"
  >;
  showState: boolean;
  refetchQueries: (string | PureQueryOptions)[];
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  const bookingWithParties = addPartiesToBooking<
    Pick<
      Booking,
      | "id"
      | "endUser"
      | "schedule"
      | "supportProfessional"
      | "state"
      | "scheduleId"
      | "startTime"
    >
  >(booking, user);

  const showButton =
    booking.state === BookingState.COMPLETED &&
    user.__typename === ServiceRole.END_USER;

  const date = moment(booking.startTime).format("YYYY-MM-DD");

  const linkUrl = booking.projected
    ? `/bookings/schedule/${booking.scheduleId}/${date}`
    : `/bookings/${booking.id}`;

  return (
    <li className="border-b border-gray-200 py-7">
      <div className="flex">
        <Link to={linkUrl}>
          <Avatar
            size="xs"
            user={bookingWithParties.otherParty}
            className="mt-1"
          />
        </Link>
        <div className="space-y-1 ml-5">
          <Link to={linkUrl} className="text-gray-400">
            {moment(booking.startTime).format("D MMMM YYYY, h:mm a")} -{" "}
            {moment(booking.endTime).format("h:mm a")}
          </Link>
          <Link to={linkUrl} className="text-black block pb-2">
            {`${booking.serviceName}`}
            {` with ${bookingWithParties.otherParty.name}`}
          </Link>
          <div>
            {showState && showButton && (
              <BookingActionButtonGroup
                booking={bookingWithParties}
                refetchQueries={refetchQueries}
              />
            )}
            {showState &&
              !showButton &&
              (booking.paymentFailed &&
              user.__typename === ServiceRole.END_USER ? (
                <span
                  className={`border border-red-600 inline-flex items-center text-red-500 rounded px-2 text-sm font-bold py-1`}
                >
                  <Icon name="alert" className="w-4 mr-2" />
                  payment failed
                </span>
              ) : (
                <BookingStateLabel
                  booking={booking}
                  link={linkUrl}
                  user={user}
                />
              ))}
          </div>
        </div>
      </div>
    </li>
  );
};
