import React, { useEffect } from "react";
import { DocumentNode } from "graphql";
import styled from "styled-components";

import { Conversation as ConversationType } from "../../types/message";
import { ContactForm } from "./ContactForm";
import { enableAdminOnly } from "../../utils/featureFlags";
import { useWindowSize, useLockBody } from "../../hooks";
import { InboxSidebar as Sidebar } from "./InboxSidebar";
import { ConversationHeader } from "./ConversationHeader";
import { Conversation } from "./Conversation";
import { ServiceRole, ProfileImage } from "../../types/profile";
import { SupportCoordinator } from "../../types/supportCoordinator";
import { Viewer } from "../../types/viewer";

export interface UserT {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  __typename: ServiceRole;
  profileImage?: ProfileImage;
}

export type ViewerT = Pick<Viewer, "id" | "role">;

export type ConversationT = Pick<
  ConversationType,
  "sender" | "messages" | "lastUpdated" | "slug" | "endUser" | "endUserId"
>;

export type SupportCoordinatorT = Pick<
  SupportCoordinator,
  "firstName" | "lastName"
>;

export const InboxLayout = ({
  user,
  conversations,
  currentConversation,
  refetchQuery,
  viewer,
  focusConversation,
  EUSupportCoordinator,
}: {
  user: UserT;
  conversations: ConversationT[];
  currentConversation: ConversationT;
  refetchQuery: DocumentNode;
  viewer: ViewerT;
  focusConversation: boolean;
  EUSupportCoordinator?: SupportCoordinatorT | null;
}) => {
  useEffect(() => {
    const messages = document.getElementById("messages");
    if (messages) messages.scrollTop = messages.scrollHeight;
    window.scrollTo(0, 0);
  });

  const { windowHeight } = useWindowSize();
  useLockBody();

  const otherPartyName =
    currentConversation.sender.name ||
    currentConversation.sender.firstName +
      " " +
      currentConversation.sender.lastName;

  const currentUserIsSupportCoordinator =
    user.__typename === ServiceRole.SUPPORT_COORDINATOR;
  const currentUserIsEndUser = user.__typename === ServiceRole.END_USER;

  const clientName =
    currentConversation.endUser &&
    currentConversation.endUser.name.split(" ")[0];

  return (
    <Layout
      className="flex overflow-hidden bg-white"
      admin={enableAdminOnly(viewer) ? 1 : 0}
      windowHeight={windowHeight}
    >
      <Sidebar
        user={user}
        conversations={conversations}
        focusConversation={focusConversation}
      />
      {/* <!-- Main column --> */}
      <main
        className={`w-full flex-col ${
          focusConversation ? "flex" : "hidden md:flex"
        }`}
      >
        <ConversationHeader
          currentConversation={currentConversation}
          user={user}
        />
        {(currentUserIsSupportCoordinator ||
          (currentUserIsEndUser && EUSupportCoordinator)) && (
          <div className="bg-cool-gray-50 px-12 py-5 hidden md:block conversation-info">
            <div className="leading-5">
              <p className="font-medium text-cool-gray-700">
                {currentUserIsSupportCoordinator &&
                  !currentConversation.endUser &&
                  `This is a direct conversation between you and ${otherPartyName}`}
                {currentUserIsSupportCoordinator &&
                  !!currentConversation.endUser &&
                  `Use this chat for messages regarding ${clientName}'s bookings and support. ${clientName} is also able to view and send messages here.`}
                {currentUserIsEndUser &&
                  EUSupportCoordinator &&
                  `Your support coordinator ${EUSupportCoordinator.firstName} ${EUSupportCoordinator.lastName} will be able to view and send messages within this chat`}
              </p>
            </div>
          </div>
        )}

        <Conversation conversation={currentConversation} user={user} />
        <div className="p-4 border-gray-300 border-t px-4 pt-4 shadow-2xl">
          <ContactForm
            refetchQuery={refetchQuery}
            recipient={currentConversation.sender}
            clientId={
              currentUserIsSupportCoordinator
                ? currentConversation.endUserId
                : undefined
            }
            user={user}
          />
        </div>
      </main>
    </Layout>
  );
};

// Using JS derived window height instead of vh as it is more consistent on mobile devices.
const Layout = styled("div")<{ admin: number; windowHeight: number }>`
  height: calc(
    ${(props) => props.windowHeight}px -
      ${(props) => (props.admin ? "108px" : "79px")}
  );
`;
