import React from "react";
import moment from "moment";
import { Link } from "@reach/router";
import { Formik, Form } from "formik";

import { Avatar } from "../layout/Avatar";
import { Icon } from "../layout/Icons";
import { InputField as Input } from "../layout/forms";
import { Conversation } from "../../types/message";
import { ServiceRole, IUserContext } from "../../types/profile";

export type ConversationT = Pick<
  Conversation,
  "messages" | "sender" | "lastUpdated" | "slug" | "endUserId"
>;

export type UserT = Pick<IUserContext, "__typename">;

export const ConversationList = ({
  conversations = [],
  className,
  search,
  user,
  limit,
  compact,
}: {
  conversations: ConversationT[];
  className?: string;
  search?: boolean;
  user: UserT;
  limit?: number;
  compact?: boolean;
}) => {
  return search ? (
    <Formik initialValues={{ search: "" }} onSubmit={() => {}}>
      {(props) => {
        const { values } = props;

        const filteredConversations = conversations.filter((c) => {
          const name =
            (c.sender.name && c.sender.name.toLowerCase()) ||
            `${c.sender.firstName?.toLowerCase()} ${c.sender.lastName?.toLowerCase()}`;

          const clientName =
            (user.__typename === ServiceRole.SUPPORT_COORDINATOR &&
              c.messages[0].endUser &&
              c.messages[0].endUser.name) ||
            "";

          return (
            name.includes(values.search.toLowerCase()) ||
            clientName.toLowerCase().includes(values.search.toLowerCase())
          );
        });

        return (
          <Form>
            <Input
              icon={{ name: "search", size: 3 }}
              name="search"
              placeholder="Search conversations"
              className="mb-4"
              noLabel
            />
            {
              <List
                conversations={filteredConversations}
                className={className}
                user={user}
                compact={compact}
              />
            }
          </Form>
        );
      }}
    </Formik>
  ) : (
    <List
      conversations={limit ? conversations.slice(0, limit) : conversations}
      className={className}
      user={user}
      compact={compact}
    />
  );
};

const List = ({
  conversations,
  className,
  user,
  compact,
}: {
  conversations: ConversationT[];
  className?: string;
  user: UserT;
  compact?: boolean;
}) => {
  const mostRecent = (date: Date) => {
    if (moment().diff(moment(date), "days") < 1) {
      return moment(date).format("h:mma");
    } else if (moment().diff(moment(date), "days") < 2) {
      return "Yesterday";
    } else if (moment().diff(moment(date), "weeks") < 1) {
      return moment(date).format("dddd");
    } else {
      return moment(date).format("DD/MM/YY");
    }
  };

  return (
    <ul
      data-testid="conversation-list"
      className={`conversation-list rounded-lg border-gray-200 ${className}`}
    >
      {conversations.map((conversation, index) => {
        const { messages, sender, lastUpdated, slug } = conversation;

        const clientConversation =
          user.__typename === ServiceRole.SUPPORT_COORDINATOR &&
          conversation.endUserId;

        return (
          <li
            key={slug}
            className={index > 0 ? "border-t border-gray-100" : ""}
          >
            <Link to={`/messages/${slug}`} className="conversation-link">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div
                    className={`flex flex-shrink-0 ${
                      compact ? "w-16 sm:w-8 mr-4 sm:mr-2" : "w-16"
                    } justify-center`}
                  >
                    {clientConversation && (
                      <Avatar
                        user={conversation.messages[0].endUser}
                        size={"xs"}
                        className="text-white shadow-solid inline-block"
                      />
                    )}
                    <Avatar
                      user={sender}
                      size={clientConversation ? "xs" : "sm"}
                      className={`${
                        clientConversation ? (compact ? "-ml-5" : "-ml-3") : ""
                      } inline-block text-white shadow-solid`}
                    />
                  </div>
                  <div className="flex-shrink-0"></div>
                  <div className="min-w-0 flex-1 px-4">
                    <div>
                      <div className="flex justify-between items-center">
                        <div className="sender-name">
                          {sender.name ||
                            `${sender.firstName} ${sender.lastName}`}
                        </div>
                        <span className="text-base text-gray-400">
                          {mostRecent(lastUpdated)}
                        </span>
                      </div>
                      <div className="mt-2 message-excerpt">
                        <span className="truncate">
                          {messages
                            ? messages[messages.length - 1].message
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Icon
                    name="chevron"
                    className="h-5 w-5 text-gray-400"
                    rotate="-90"
                  />
                </div>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
