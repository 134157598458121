import React from "react";
import { RouteComponentProps } from "@reach/router";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { IUserContext } from "../../../../types/profile";
import { EndUserProfileSettings } from "./EndUserProfileSettings";
import { SupportProfessionalProfileSettings } from "./SupportProfessionalProfileSettings";
import { SupportCoordinatorProfileSettings } from "./SupportCoordinatorProfileSettings";
import { Button } from "../../../layout/Button";
import { SupportProfessional } from "../../../../types/supportProfessional";
import { EndUser } from "../../../../types/endUser";
import { SupportCoordinator } from "../../../../types/supportCoordinator";

export interface ProfileSettingsProps extends RouteComponentProps {
  supportProfessional?: SupportProfessional;
  endUser?: EndUser;
  supportCoordinator?: SupportCoordinator;
  user: Pick<IUserContext, "id" | "__typename">;
}

export const ProfileSettings = ({
  supportProfessional,
  supportCoordinator,
  endUser,
  user,
}: ProfileSettingsProps) => {
  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        return (
          <div className="border-container mx-1 xm:ml-12 lg:mx-12">
            <div className="flex justify-between items-center">
              <h3 className="mb-1">Profile Settings</h3>

              <Button to="/profile" className="" size="small" secondary>
                View your profile
              </Button>
            </div>

            {endUser && (
              <EndUserProfileSettings endUser={endUser} user={user} />
            )}

            {supportProfessional && (
              <SupportProfessionalProfileSettings
                supportProfessional={supportProfessional}
                user={user}
                add={add}
              />
            )}
            {supportCoordinator && (
              <SupportCoordinatorProfileSettings
                supportCoordinator={supportCoordinator}
                user={user}
                add={add}
              />
            )}
          </div>
        );
      }}
    </ToastConsumer>
  );
};
