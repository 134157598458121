import React from "react";
import { Card } from "semantic-ui-react";

import { EndUser } from "../../../types/endUser";
import { EndUserSummaryCard } from "./EndUserSummaryCard";
import { Icon } from "../../layout/Icons";

export const EndUserSummaryCardGroup = ({
  endUsers,
}: {
  endUsers: Pick<EndUser, "id" | "name" | "profileImage">[];
}) => {
  if (endUsers.length > 0) {
    return (
      <Card.Group>
        {endUsers.map((endUser) => (
          <EndUserSummaryCard endUser={endUser} key={endUser.id} />
        ))}
      </Card.Group>
    );
  } else {
    return (
      <div className="message info">
        <Icon name="userGroup" />
        <p>
          Once you have completed a booking with a user, they will appear here
        </p>
      </div>
    );
  }
};
