import React from "react";
import { navigate } from "@reach/router";
import moment from "moment";
import { Link } from "@reach/router";

import { Avatar } from "../../layout/Avatar";
import { Icon } from "../../layout/Icons";
import { Booking } from "../../../types/booking";
import { shortStateLabelMap } from "../../bookings/BookingHelpers";

export const SupportCoordinatorBookingList = ({
  bookings,
  title,
  className,
  viewAllLink,
  showHeaderRow,
}: {
  bookings: Pick<
    Booking,
    | "id"
    | "state"
    | "endUser"
    | "supportProfessional"
    | "serviceName"
    | "startTime"
    | "endTime"
    | "totalCost"
    | "paymentFailed"
    | "projected"
    | "scheduleId"
  >[];
  title?: string;
  className?: string;
  viewAllLink?: boolean;
  showHeaderRow?: boolean;
}) => {
  return (
    <div className={className}>
      {title && (
        <div
          className={`pl-4 pr-6 pt-4 pb-4 border-b border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0`}
        >
          <div className="flex items-center">
            <h2 className="text-xl leading-7 font-medium mb-2">{title}</h2>
          </div>
        </div>
      )}

      <div className="flex flex-col overflow-hidden">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className={showHeaderRow ? "" : "sr-only"}>
                  <tr>
                    <th className="px-6 lg:pr-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Client / Support Professional
                    </th>
                    <th className="px-6 lg:px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Service
                    </th>
                    <th className="px-6 lg:px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                      Status
                    </th>
                    <th className="px-6 lg:px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                      Total
                    </th>
                    <th className="px-6 lg:pl-2 py-3 bg-gray-50 hidden 2xl:table-cell"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {bookings.length > 0 ? (
                    bookings.map((booking) => {
                      const {
                        id,
                        endUser,
                        state,
                        supportProfessional,
                        serviceName,
                        startTime,
                        endTime,
                        totalCost,
                      } = booking;

                      const date = moment(booking.startTime).format(
                        "YYYY-MM-DD"
                      );

                      const linkUrl = booking.projected
                        ? `/bookings/schedule/${booking.scheduleId}/${date}`
                        : `/bookings/${booking.id}`;

                      const stateColor = booking.paymentFailed
                        ? "red"
                        : shortStateLabelMap[state].color;

                      const stateText = booking.paymentFailed ? (
                        <>
                          <Icon name="alert" className="w-3 mr-2" />
                          payment failed
                        </>
                      ) : (
                        shortStateLabelMap[state].text
                      );
                      return (
                        <tr
                          key={id}
                          onClick={() => navigate(linkUrl)}
                          className="cursor-pointer hover:bg-gray-100"
                        >
                          <td className="px-6 lg:pr-2 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="overflow-hidden hidden xs:flex">
                                <Avatar
                                  user={endUser}
                                  size="xs"
                                  className="inline-block text-white shadow-solid"
                                />
                                <Avatar
                                  user={supportProfessional}
                                  size="xs"
                                  className="-ml-1 text-white shadow-solid hidden sm:inline-block"
                                />
                              </div>
                              <div className="xs:ml-4">
                                <div className="text-sm leading-5 font-medium text-gray-900">
                                  {endUser.name}
                                </div>
                                <div className="text-sm leading-5 text-gray-500">
                                  & {supportProfessional.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 lg:px-2 py-4 whitespace-no-wrap">
                            {/* Including a link here for accessibility on mobile */}
                            <Link
                              to={linkUrl}
                              className="text-sm leading-5 text-gray-900"
                            >
                              {serviceName}
                            </Link>
                            <div className="text-sm leading-5 text-gray-500">
                              {`${moment(startTime).format(
                                "D MMMM YYYY, h:mm a"
                              )} - ${moment(endTime).format("h:mm a")}`}
                            </div>
                          </td>
                          <td className="px-6 lg:px-2 py-4 whitespace-no-wrap hidden md:table-cell">
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${stateColor}-100 text-${stateColor}-800`}
                            >
                              {stateText}
                            </span>
                          </td>
                          <td className="px-6 lg:px-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 hidden md:table-cell">
                            ${totalCost.amount / 100}
                          </td>
                          <td className="px-6 lg:pl-2 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium hidden 2xl:table-cell">
                            <Link
                              to={linkUrl}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className="py-5 px-10 text-sm text-gray-500"
                      >
                        No upcoming bookings, use Discover to find and book
                        support professionals for your clients
                      </td>
                    </tr>
                  )}
                  {viewAllLink && (
                    <tr>
                      <td colSpan={5}>
                        <Link
                          to="/bookings"
                          className="block text-right text-teal-600 text-sm font-semibold hover:text-teal-900 py-4 px-8"
                        >
                          View all bookings &rarr;
                        </Link>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
