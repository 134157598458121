import React, { useRef } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { DocumentNode } from "graphql";

import { PartyIdsWithOptionalBooking } from "../../types/booking";
import { ServiceRole } from "../../types/profile";
import { Button } from "../layout/Button";
import { TextArea, InputField as Input } from "../layout/forms";
import { useLogger } from "../../hooks";
import {
  SEND_MESSAGE,
  NewData,
  Variables,
} from "../../graphql/mutations/sendMessage";

export interface RecipientT {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  __typename?: ServiceRole;
  role?: ServiceRole;
}

export interface UserT {
  id: string;
  __typename: ServiceRole;
  role?: ServiceRole;
}

interface FormValues {
  message: string;
}

export const ContactForm = ({
  recipient,
  user,
  refetchQuery,
  clientId,
}: {
  recipient: RecipientT;
  booking?: PartyIdsWithOptionalBooking;
  user: UserT;
  refetchQuery: DocumentNode;
  clientId?: string;
}) => {
  const [sendMessage] = useMutation<NewData, Variables>(SEND_MESSAGE);
  const { log } = useLogger();

  const input = useRef<HTMLInputElement>(null);

  const isSupportProfessionalUser =
    user.__typename === ServiceRole.SUPPORT_WORKER;

  const messageContext = {
    sentBySp: isSupportProfessionalUser,
    supportProfessionalId: isSupportProfessionalUser ? user.id : recipient.id,
    supportUserId: isSupportProfessionalUser ? recipient.id : user.id,
  };

  const sendMessageFn = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const supportUser = isSupportProfessionalUser ? recipient : user;
    const supportUserTypeId =
      supportUser.__typename === ServiceRole.END_USER ||
      supportUser.role === ServiceRole.END_USER
        ? "endUserId"
        : "supportCoordinatorId";

    const variables = {
      message: values.message,
      // Passing in client ID for messages sent by a support coordinator
      endUserId: clientId,
      [supportUserTypeId]: messageContext.supportUserId,
      supportProfessionalId: messageContext.supportProfessionalId,
      sentBySp: messageContext.sentBySp,
    };

    const refetchOptions = {
      awaitRefetchQueries: false,
      refetchQueries: [
        {
          query: refetchQuery,
          variables: {
            id: user.id,
          },
        },
      ],
    };

    return sendMessage({
      ...refetchOptions,
      variables,
    })
      .then(async (res) => {
        formikHelpers.setSubmitting(false);

        if (input.current) {
          await input.current.blur();
          formikHelpers.resetForm({ values: { message: "" } });
        }
      })
      .catch((err) => {
        log("error", err);
      });
  };
  const firstName =
    recipient.firstName || (recipient.name && recipient.name.split(" ")[0]);
  return (
    <Formik
      initialValues={{ message: "" }}
      onSubmit={sendMessageFn}
      validationSchema={Yup.object().shape({
        message: Yup.string(),
      })}
      validate={(values) => {
        const errors: {
          message?: string;
        } = {};

        if (!values.message) {
          errors.message = "Can't send an empty message";
        }

        return errors;
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form data-testid="contact-form">
            <div className="flex lg:block items-start">
              <TextArea
                name="message"
                className="message-input hidden lg:block"
                placeholder={`Message ${firstName}...`}
                noLabel
              />
              <Input
                name="message"
                className="message-input w-5/6 lg:hidden"
                noLabel
                ref={input}
              />
              <Button
                type="submit"
                className={`ml-2 lg:ml-0 flex-grow flex justify-center ${
                  isSubmitting ? "submitting" : ""
                }`}
                disabled={isSubmitting}
              >
                Send<span className="hidden ml-1 lg:inline-block">Message</span>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
