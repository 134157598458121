import React from "react";
import { Link } from "@reach/router";

import { EndUser } from "../../../types/endUser";
import { Icon } from "../../layout/Icons";

export const SupportCoordinatorClientNav = ({
  endUser,
}: {
  endUser: Pick<EndUser, "id" | "name">;
}) => {
  const NavLink = (props: any) => (
    <Link
      {...props}
      getProps={({ isCurrent }) => ({
        className: `${
          isCurrent ? "font-bold border-b-2 border-gray-800" : "opacity-75"
        } ${props.className}`,
      })}
    />
  );

  return (
    <div className="relative pb-5 border-b border-gray-200 space-y-4 sm:pb-0">
      <Link
        to="/clients"
        className="flex items-center text-teal-600 text-base font-semibold mb-12"
      >
        <Icon name="chevron" rotate="90" className="w-4 h-4 mr-2" />
        <span>View all clients</span>
      </Link>

      <div>
        {/* <!-- Tabs at small breakpoint and up --> */}
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            <NavLink
              to={`/user/profile/${endUser.id}`}
              className="whitespace-no-wrap pb-4 px-1 border-b-2 border-transparent font-medium text-base leading-5 text-gray-500 hover:text-teal-800 hover:border-teal-700 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              Profile
            </NavLink>

            <NavLink
              to={`/user/profile/${endUser.id}/manage`}
              className="whitespace-no-wrap pb-4 px-1 border-b-2 border-transparent font-medium text-base leading-5 text-gray-500 hover:text-teal-800 hover:border-teal-700 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              Manage
            </NavLink>
          </nav>
        </div>
      </div>
    </div>
  );
};
