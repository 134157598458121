import React from "react";
import moment from "moment";

import { Message } from "./Message";
import { Conversation as ConversationType } from "../../types/message";
import { IUserContext, ServiceRole } from "../../types/profile";

export type ConversationT = Pick<
  ConversationType,
  "messages" | "endUser" | "endUserId" | "supportCoordinatorId" | "sender"
>;

export type UserT = Pick<IUserContext, "id" | "__typename">;

export const Conversation = ({
  conversation,
  user,
}: {
  conversation: ConversationT;
  user: UserT;
}) => {
  return (
    <div className="overflow-auto flex-grow pb-5 pl-3" id="messages">
      {conversation.messages.length > 0 ? (
        conversation.messages.map((message, index) => {
          const sentByUser = !!user && user.id === message.sender.id;

          const sentByClient =
            user.__typename === ServiceRole.SUPPORT_COORDINATOR &&
            conversation.endUserId === message.sender.id;

          const sentByCoordinator =
            user.__typename === ServiceRole.END_USER &&
            conversation.supportCoordinatorId === message.sender.id;

          const firstOfGroup =
            index === 0 ||
            moment(message.insertedAt).diff(
              moment(conversation.messages[index - 1].insertedAt),
              "minutes"
            ) > 10;

          const firstOfUser =
            index === 0 ||
            message.sender.id !== conversation.messages[index - 1].sender.id;

          const showSender =
            user.__typename === ServiceRole.SUPPORT_WORKER
              ? !sentByUser
              : sentByUser || sentByClient || sentByCoordinator;

          return (
            <div
              className={`flex flex-col ${
                firstOfGroup || firstOfUser ? "mt-6" : ""
              } ${
                sentByUser || sentByClient || sentByCoordinator
                  ? "sent-by-user ml-auto mr-4 items-end"
                  : "mr-auto ml-4 items-start"
              }`}
              key={message.id}
            >
              {firstOfGroup && (
                <span className={`text-sm text-gray-500 opacity-85 ml-2`}>
                  {moment(message.insertedAt).fromNow()}
                </span>
              )}
              {firstOfUser && showSender && (
                <span className={`text-sm text-gray-500 opacity-85 ml-2 mt-1`}>
                  Sent by{" "}
                  {!sentByUser &&
                    ((message.sender.name &&
                      message.sender.name.split(" ")[0]) ||
                      message.sender.firstName)}
                  {message.supportCoordinator &&
                    message.sender.id === message.supportCoordinator.id &&
                    !sentByUser &&
                    " (Support Coordinator)"}
                  {sentByUser && "you"}
                </span>
              )}

              <Message
                sentByUser={sentByUser}
                sentByClient={sentByClient}
                sentByCoordinator={sentByCoordinator}
                message={message}
                firstOfGroup={firstOfGroup}
                firstOfUser={firstOfUser}
              ></Message>
            </div>
          );
        })
      ) : (
        <p className="p-10 text-gray-700">
          This is the start of your new conversation with{" "}
          {conversation.sender.name ||
            `${conversation.sender.firstName} ${conversation.sender.lastName}`}
          ...{" "}
        </p>
      )}
    </div>
  );
};
