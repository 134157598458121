import gql from "graphql-tag";

import { SUPPORT_PROFESSIONAL_FIELDS } from "../../fragments";
import { Options } from "../paginatedSupportProfessionalsData";

// Uses Data from ../paginatedSupportProfessionalsTypes.ts

export interface Variables {
  endUserId: string;
  options?: Options;
}

export const PAGINATED_RECOMMENDED_SUPPORT_PROFESSIONALS = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}

  query RecommendedSupportProfessionals($endUserId: ID!, $options: Options) {
    paginatedSupportProfessionals: recommendedSupportProfessionalsForEndUser(
      id: $endUserId
      options: $options
    ) {
      supportProfessionals {
        ...SupportProfessionalFields
      }
      total
    }
  }
`;
