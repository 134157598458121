import gql from "graphql-tag";
import { Moment } from "moment";

import {
  ServiceOfferingInput,
  SupportProfessional,
} from "../../types/supportProfessional";
import { SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";

export interface NewData {
  updateSupportProfessional: SupportProfessional;
}

export interface Variables {
  id: string;
  title?: string;
  shortDescription?: string;
  description?: string;
  acceptsTransferBookings?: boolean;
  acceptsFastBookings?: boolean;
  employmentExperience?: {
    id: string;
    title: string;
    company: string;
    startDate: Moment | string;
    endDate: Moment | string;
    present?: boolean;
    volunteer?: boolean;
    delete?: boolean;
  }[];
  skills?: {
    id: string;
    name: string;
    delete?: boolean;
  }[];
  interests?: {
    id: string;
    name: string;
    delete?: boolean;
  }[];
  serviceOfferings?: ServiceOfferingInput[];
}

// EditSupportWorkerPage
export const UPDATE_SUPPORT_PROFESSIONAL = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}
  mutation UpdateSupportProfessional(
    $id: ID!
    $title: String
    $shortDescription: String
    $description: String
    $acceptsTransferBookings: Boolean
    $acceptsFastBookings: Boolean
    $employmentExperience: [EmploymentExperienceInput]
    $skills: [SkillInput]
    $interests: [InterestInput]
    $serviceOfferings: [ServiceOfferingInput]
  ) {
    updateSupportProfessional(
      id: $id
      input: {
        title: $title
        shortDescription: $shortDescription
        description: $description
        acceptsTransferBookings: $acceptsTransferBookings
        acceptsFastBookings: $acceptsFastBookings
        employmentExperience: $employmentExperience
        interests: $interests
        skills: $skills
        serviceOfferings: $serviceOfferings
      }
    ) {
      ...SupportProfessionalFields
    }
  }
`;
