import React, { ChangeEvent } from "react";
import { Field } from "formik";

import { capitalize } from "../../../utils/helpers";

export interface ToggleProps {
  name: string;
  ariaLabel: string;
  label?: string | React.ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  noLabel?: boolean;
  labelSize?: "small" | "med";
}

export const ToggleField = ({
  name,
  onChange: customHandleChange,
  className,
  disabled,
  label,
  noLabel,
  ariaLabel,
  labelSize,
}: ToggleProps) => {
  return (
    <Field>
      {(props: { field: any; form: any }) => {
        const { onChange } = props.field;
        const { values } = props.form;

        const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
          await onChange(e);

          if (customHandleChange) {
            customHandleChange(e);
          }
        };

        return (
          <Toggle
            name={name}
            className={className}
            ariaLabel={ariaLabel}
            checked={!!values[name]}
            onChange={handleChange}
            disabled={disabled}
            noLabel={noLabel}
            labelSize={labelSize}
            label={label}
          />
        );
      }}
    </Field>
  );
};

export const Toggle = ({
  name,
  className,
  ariaLabel,
  checked,
  onChange,
  disabled,
  noLabel,
  labelSize,
  label,
}: {
  name: string;
  className?: string;
  ariaLabel?: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  noLabel?: boolean;
  labelSize?: "small" | "med";
  label?: string | React.ReactNode;
}) => {
  return (
    <div className={`${className} flex items-center w-full`}>
      <label htmlFor={name} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            aria-label={ariaLabel}
            name={name}
            id={name}
            type="checkbox"
            className="hidden"
            value={checked ? "checked" : undefined}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <div className="toggle__line w-10 h-4 bg-gray-300 rounded-full shadow-inner"></div>
          <div className="toggle-dot"></div>
        </div>
        {!noLabel && (
          <div
            className={`ml-6 text-gray-700 font-medium ${
              labelSize === "small" ? "text-sm" : "text-base"
            } ${checked ? "opacity-100" : "opacity-75"}`}
          >
            {label || capitalize(name)}
          </div>
        )}
      </label>
    </div>
  );
};
