import gql from "graphql-tag";

export interface Data {
  bookingVideoAccessToken: string | null;
}

export interface Variables {
  bookingId: string;
  serviceRoleId: string;
}

export const BOOKING_VIDEO_ACCESS_TOKEN_QUERY = gql`
  query BookingVideoAccessToken($bookingId: ID!, $serviceRoleId: ID!) {
    bookingVideoAccessToken(
      bookingId: $bookingId
      serviceRoleId: $serviceRoleId
    )
  }
`;
