import gql from "graphql-tag";

import { EndUser, FundingType } from "../../types/endUser";
import { Gender } from "../../types/profile";
import { END_USER_FIELDS } from "../fragments";

export interface NewData {
  updateEndUser: EndUser;
}

export interface Variables {
  id: string;
  about?: string | null;
  servicePreferences?: {
    id?: string;
    endUserId?: string;
    serviceId: string;
    delete?: boolean;
  }[];
  virtualPreference?: boolean;
  dateOfBirth?: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressPostcode?: string;
  fundingType?: FundingType;
  ndisParticipantNumber?: string;
  supportHoursPerWeek?: string;
  gender?: Gender | null;
  otherFundingType?: string;
}

// EditEndUserPage
export const UPDATE_END_USER = gql`
  ${END_USER_FIELDS}
  mutation UpdateEndUser(
    $id: ID!
    $about: String
    $servicePreferences: [ServicePreferenceInput!]
    $virtualPreference: Boolean
    $dateOfBirth: Date
    $addressStreet: String
    $addressCity: String
    $addressState: String
    $addressPostcode: String
    $fundingType: FundingType
    $ndisParticipantNumber: String
    $supportHoursPerWeek: String
    $gender: Gender
    $otherFundingType: String
  ) {
    updateEndUser(
      id: $id
      input: {
        servicePreferences: $servicePreferences
        virtualPreference: $virtualPreference
        dateOfBirth: $dateOfBirth
        addressStreet: $addressStreet
        addressCity: $addressCity
        addressState: $addressState
        addressPostcode: $addressPostcode
        about: $about
        fundingType: $fundingType
        ndisParticipantNumber: $ndisParticipantNumber
        supportHoursPerWeek: $supportHoursPerWeek
        gender: $gender
        otherFundingType: $otherFundingType
      }
    ) {
      ...EndUserFields
    }
  }
`;
