import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/client";

import { Loading } from "../../layout/Loading";
import { NotFoundPage } from "../../pages/NotFoundPage";
import { END_USER, Data, Variables } from "../../../graphql/queries/endUser";
import { ErrorPage } from "../../layout/ErrorPage";
import { IUserContext } from "../../../types/profile";
import { fundingTypeLabels } from "../profileHelpers";
import { SupportCoordinatorClientNav } from "../supportCoordinators/SupportCoordinatorClientNav";
import { EndUser, FundingType } from "../../../types/endUser";
import { EndUserDetail } from "./EndUserDetail";

export interface EndUserProfileProps extends RouteComponentProps {
  profileId?: string;
  user: Pick<IUserContext, "id" | "__typename">;
}

export type EndUserT = Pick<
  EndUser,
  | "id"
  | "name"
  | "profileImage"
  | "addressCity"
  | "about"
  | "dateOfBirth"
  | "servicePreferences"
  | "fundingType"
  | "ndisParticipantNumber"
  | "addressStreet"
  | "addressState"
  | "addressPostcode"
  | "supportHoursPerWeek"
>;

export const EndUserProfilePage = ({
  profileId,
  user,
}: EndUserProfileProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(END_USER, {
    variables: {
      profileId: profileId || "",
    },
  });

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { endUser } = data;
  const { supportCoordinator } = endUser;

  if (!endUser) {
    return <NotFoundPage />;
  }

  const { addressStreet, addressCity, addressState, addressPostcode } = endUser;

  return (
    <div>
      <div className="header mx-6 sm:mx-20 my-16">
        {supportCoordinator && supportCoordinator.id === user.id && (
          <SupportCoordinatorClientNav endUser={endUser} />
        )}
        <div className="mt-8">
          <EndUserDetail endUser={endUser} />
          {endUser.id === user.id ||
            (supportCoordinator && supportCoordinator.id === user.id && (
              <>
                <div className="rounded-md bg-blue-50 p-4 mt-12">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-blue-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm leading-5 text-blue-700">
                        The information below is not publicly displayed
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-5 border-t border-gray-200 pt-5">
                  <dl>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Funding Type
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {endUser.fundingType ? (
                          <span>{fundingTypeLabels[endUser.fundingType]}</span>
                        ) : (
                          <span className="text-gray-500">Not entered</span>
                        )}
                      </dd>
                    </div>
                    <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        NDIS Participant Number
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {endUser.fundingType &&
                        [
                          FundingType.NDIA,
                          FundingType.NDIS_PLAN,
                          FundingType.NDIS_SELF_MANAGED,
                        ].includes(endUser.fundingType) ? (
                          <span>{endUser.ndisParticipantNumber}</span>
                        ) : (
                          <span className="text-gray-500">Not entered</span>
                        )}
                      </dd>
                    </div>
                    <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Address
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {addressStreet &&
                        addressCity &&
                        addressState &&
                        addressPostcode ? (
                          <>
                            <span className="block">
                              {endUser.addressStreet}
                            </span>
                            <span className="block">{endUser.addressCity}</span>
                            <span className="block">
                              {endUser.addressState}
                            </span>
                            <span className="block">
                              {endUser.addressPostcode}
                            </span>
                          </>
                        ) : (
                          <span className="text-gray-500">Not entered</span>
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
