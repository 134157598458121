import React from "react";
import { Label, Popup } from "semantic-ui-react";
import { Link } from "@reach/router";

import { stateLabelMap } from "../BookingHelpers";
import { BookingState, Booking } from "../../../types/booking";
import { AwaitingApprovalLabel } from "./AwaitingApproval";
import { AwaitingConfirmationLabel } from "./AwaitingConfirmation";
import { ConfirmedLabel } from "./Confirmed";
import { ApprovedLabel } from "./Approved";
import { IUserContext } from "../../../types/profile";

export const BookingStateLabel = ({
  booking,
  link,
  user,
}: {
  booking: Pick<
    Booking,
    | "supportProfessional"
    | "state"
    | "endUser"
    | "insertedAt"
    | "stateEvents"
    | "endTime"
  >;
  link?: string;
  user: Pick<IUserContext, "__typename" | "id">;
}) => {
  let bookingStateLabel = stateLabelMap[booking.state];
  let awaitingUserAction = false;

  const spFirstName = booking.supportProfessional.name.split(" ")[0];
  const euFirstName = booking.endUser.name.split(" ")[0];
  let message;

  switch (booking.state) {
    case BookingState.REQUESTED_BY_END_USER:
      return (
        <AwaitingConfirmationLabel
          booking={booking}
          name={spFirstName}
          user={user}
          bookingStateLabel={bookingStateLabel}
          link={link}
        />
      );
    case BookingState.ACCEPTED_BY_SUPPORT_WORKER:
      return (
        <ConfirmedLabel
          booking={booking}
          name={spFirstName}
          user={user}
          bookingStateLabel={bookingStateLabel}
          link={link}
        />
      );
    case BookingState.ACCEPTED_IN_PENALTY_PERIOD:
      return (
        <ConfirmedLabel
          booking={booking}
          name={spFirstName}
          user={user}
          bookingStateLabel={bookingStateLabel}
          link={link}
          penalty
        />
      );
    case BookingState.COMPLETED:
      return (
        <AwaitingApprovalLabel
          booking={booking}
          name={euFirstName}
          user={user}
          bookingStateLabel={bookingStateLabel}
          link={link}
        />
      );
    case BookingState.APPROVED:
      return (
        <ApprovedLabel
          booking={booking}
          user={user}
          bookingStateLabel={bookingStateLabel}
          link={link}
        />
      );
  }

  let content = (
    <Label
      color={awaitingUserAction ? "yellow" : undefined}
      basic={awaitingUserAction}
    >
      {bookingStateLabel}
    </Label>
  );

  if (awaitingUserAction && link) {
    return (
      <Link to={link}>
        <Popup trigger={content} content={message} position="top left" />
      </Link>
    );
  }

  if (!link) return content;

  return <Link to={link}>{content}</Link>;
};
