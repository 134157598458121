import gql from "graphql-tag";

import { EndUser } from "../../types/endUser";
import { SupportProfessional } from "../../types/supportProfessional";
import { END_USER_FIELDS, SUPPORT_PROFESSIONAL_FIELDS } from "../fragments";

export interface Data {
  supportProfessional: SupportProfessional;
  supportCoordinatorClient: EndUser;
}

export interface Variables {
  profileId: string;
  clientId?: string;
}

export const SUPPORT_COORDINATOR_NEW_CONVERSATION_WITH_CLIENT_QUERY = gql`
  ${SUPPORT_PROFESSIONAL_FIELDS}
  ${END_USER_FIELDS}

  query SupportCoordinatorInboxQuery($clientId: ID, $profileId: ID!) {
    supportProfessional(id: $profileId) {
      ...SupportProfessionalFields
    }

    supportCoordinatorClient: endUser(id: $clientId) {
      ...EndUserFields
    }
  }
`;
