import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";

import { Booking, BookingState } from "../../../types/booking";
import { Icon } from "../../layout/Icons";
import { BOOKING_AND_VIEWER } from "../../../graphql/queries/bookingAndViewer";
import { navigate } from "@reach/router";
import { useLogger } from "../../../hooks/useLogger";
import { IUserContext } from "../../../types/profile";
import {
  UPDATE_BOOKING_STATE,
  NewData,
  Variables,
} from "../../../graphql/mutations/updateBookingState";

export const EndCallConfirmationModal = ({
  setEndCallConfirmation,
  booking,
  user,
}: {
  setEndCallConfirmation: Dispatch<SetStateAction<boolean>>;
  booking: Booking;
  user: Pick<IUserContext, "id">;
}) => {
  const { log } = useLogger();
  const [bookingMutationFn, { loading }] = useMutation<NewData, Variables>(
    UPDATE_BOOKING_STATE
  );
  const { id } = booking;

  const handleEndCall = () => {
    const variables = {
      newState: BookingState.COMPLETED,
      bookingId: id,
      scheduleId: booking.scheduleId,
      date: moment(booking.startTime).format("YYYY-MM-DD"),
      personId: user.id,
    };
    const refetchQueries = [
      { query: BOOKING_AND_VIEWER, variables: { bookingId: id } },
    ];

    bookingMutationFn({ variables, refetchQueries })
      .then((res) => {
        navigate(`/bookings/${id}`);
      })
      .catch((err) => log("error", err));
  };

  return (
    <div className="fixed bottom-0 h-screen inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-200">
            <Icon name="callEnd" className="w-8 h-8 text-gray-800" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Leave the call?
            </h3>
            <div className="mt-2">
              <p className="text-lg leading-5 text-gray-600">
                This will end the booking (this cannot be undone)
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button
              onClick={handleEndCall}
              type="button"
              disabled={loading}
              className={`${
                loading ? "cursor-wait" : ""
              } inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-teal-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
            >
              End Booking
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
            <button
              onClick={() => setEndCallConfirmation(false)}
              type="button"
              className={`${
                loading ? "cursor-not-allowed" : ""
              } inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};
