import React from "react";
import { useMutation } from "@apollo/client";
import { AddToast, ToastConsumer } from "react-toast-notifications";

import { Icon } from "../layout/Icons";
import { Button } from "../layout/Button";
import {
  UPDATE_PAYMENT_METHOD,
  NewData,
  Variables,
} from "../../graphql/mutations/updateBookingPaymentMethod";
import { useLogger } from "../../hooks";
import { Booking } from "../../types/booking";
import { goToStripe } from "./bookingForm/bookingFormHelpers";

export const PaymentFailedNotification = ({
  booking,
}: {
  booking: Pick<Booking, "id">;
}) => {
  const [updatePaymentMethodMutation, { loading }] = useMutation<
    NewData,
    Variables
  >(UPDATE_PAYMENT_METHOD);
  const { log } = useLogger();

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        const updatePaymentMethod = () => {
          updatePaymentMethodMutation({ variables: { id: booking.id } })
            .then(({ data }) => {
              if (data) {
                const { booking } = data;
                goToStripe(booking.stripeCheckoutId);
              }
            })
            .catch((err) => {
              add("There was an error processing your request", {
                appearance: "error",
                autoDismiss: true,
              });
              log("error", err);
            });
        };

        return (
          <div className="payment-message payment-failed">
            <div className="flex">
              <div className="flex-shrink-0">
                <Icon name="alert" className="payment-message-icon" />
              </div>
              <div className="ml-3">
                <h3 className="payment-message-header font-bold">
                  An attempt to authorise your card was unsuccessful
                </h3>
                <p className="payment-message-text mb-4">
                  Please update your payment method to avoid having your booking
                  cancelled.
                </p>
                <Button
                  loading={loading}
                  color="red"
                  onClick={updatePaymentMethod}
                >
                  Update payment details
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </ToastConsumer>
  );
};
