import React from "react";
import { Link } from "@reach/router";

import TopNav from "./nav/TopNav";
import { Dropdown } from "./nav/Dropdown";
import { IUserContext } from "../../types/profile";
import { Viewer } from "../../types/viewer";
import tapponLogo from "../../assets/images/tappON_partwhite.png";

export interface PageHeaderProps {
  openUserOptions: () => any;
  userOptions: IUserContext[];
  switchUser: (id: string) => void;
  handleLogout: () => void;
  user: Pick<
    IUserContext,
    "profileImage" | "name" | "__typename"
  >;
  viewer: Pick<Viewer, "id" | "role">;
}

export const PageHeader = ({
  openUserOptions,
  userOptions,
  switchUser,
  handleLogout,
  user,
  viewer,
}: PageHeaderProps) => (
  <div className="flex justify-between items-center">
    <Link to="/">
      <img alt="tappON logo" src={tapponLogo} className="tappon-logo" />
    </Link>
    <div className="flex justify-around flex-grow items-center">
      <TopNav />
      <Dropdown
        users={userOptions}
        switchUser={switchUser}
        handleLogout={handleLogout}
        openUserOptions={openUserOptions}
        user={user}
        viewer={viewer}
      />
    </div>
  </div>
);
