import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/client";
import { Grid } from "semantic-ui-react";

import { Data, Variables, queryForUserType } from "../../graphql/queries/inbox";
import { ServiceRole, IUserContext } from "../../types/profile";
import { Loading } from "../layout/Loading";
import { NoMessages } from "./NoMessages";
import { PageTitle } from "./MessagesStyles";
import { ErrorPage } from "../layout/ErrorPage";
import { AdminMessageButton } from "./AdminMessageButton";
import { Icon } from "../layout/Icons";
import { Button } from "../layout/Button";
import { InboxLayout } from "./InboxLayout";
import { NewConversationPage } from "./NewConversationPage";

export interface InboxPageProps extends RouteComponentProps {
  userIds?: string;
  user: Pick<IUserContext, "id" | "role" | "__typename">;
}

export const InboxPage = ({ user, userIds }: InboxPageProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    queryForUserType[user.role || user.__typename],
    {
      variables: { id: user.id },
    }
  );

  const [userId, clientId = null] = userIds ? userIds.split("&") : [""];

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  const { conversations, endUser, viewer } = data;

  if (endUser && endUser.permissions && !endUser.permissions.message) {
    return <IncompleteEndUserProfileAlert />;
  }

  // Check if specified user (in the URL) has an existing conversation with the current user
  const findConversation = conversations.find((c) => {
    const isOtherParty = c.sender.id === userId;
    const isClient = c.endUserId === clientId;

    return user.__typename === ServiceRole.SUPPORT_COORDINATOR
      ? isOtherParty && isClient
      : isOtherParty;
  });

  if (!!userId && !findConversation) {
    return (
      <NewConversationPage
        userId={userId}
        clientId={clientId}
        user={user}
        conversations={conversations}
        viewer={viewer}
        EUSupportCoordinator={endUser && endUser.supportCoordinator}
        refetchQuery={queryForUserType[user.__typename]}
      />
    );
  }

  const currentConversation = findConversation || conversations[0];

  if (currentConversation) {
    return (
      <InboxLayout
        user={user}
        conversations={conversations}
        currentConversation={currentConversation}
        refetchQuery={queryForUserType[user.__typename]}
        viewer={viewer}
        focusConversation={!!userId}
        EUSupportCoordinator={endUser && endUser.supportCoordinator}
      />
    );
  }
  return (
    <div className="p-0 sm:py-12 sm:px-20">
      <AdminMessageButton user={user} />

      <Grid colums={16}>
        <Grid.Column width={2} />
        <Grid.Column width={12}>
          <PageTitle mobileview={!!userId ? 1 : 0}>Messages</PageTitle>
          <NoMessages user={user} />
          <Grid columns={16}></Grid>
        </Grid.Column>
        <Grid.Column width={2} />
      </Grid>
    </div>
  );
};

const IncompleteEndUserProfileAlert = () => {
  return (
    <div className="my-8 mx-40">
      <h2>Messages</h2>
      <div
        className="w-full bg-teal-50 border border-teal-400 text-teal-700 p-8 mb-3 mb-4 flex flex-col items-center"
        role="alert"
      >
        <div className="flex items-center justify-center mb-6">
          <Icon className="h-8 w-8 mr-6 flex-none" name="alert" />
          <p>
            Lets complete your profile set up so you can start reaching out to
            support professionals
          </p>
        </div>
        <Button to="/settings/personal">Complete your profile</Button>
      </div>
    </div>
  );
};
