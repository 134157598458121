export enum AccountRole {
  USER = "user",
  ADMIN = "admin",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NON_BINARY = "NON_BINARY",
}

export enum ServiceRole {
  END_USER = "EndUser",
  SUPPORT_WORKER = "SupportProfessional",
  SUPPORT_COORDINATOR = "SupportCoordinator",
}

export interface ProfileImage {
  id: string;
  small: string;
  med?: string;
  large?: string;
  original?: string;
}

// TODO: Clean this up - only use one attribute for role/type
export interface IUserContext {
  __typename: ServiceRole;
  role?: ServiceRole;
  id: string;
  email?: string;
  name: string;
  profileImage?: ProfileImage;
  dateOfBirth?: string;
}
