import React from "react";
import { RouteComponentProps } from "@reach/router";
import { ToastConsumer, AddToast } from "react-toast-notifications";

import { EndUser } from "../../../../types/endUser";
import { AddressForm } from "./AddressForm";
import { DateOfBirthForm } from "./DateOfBirthForm";

export interface PersonalInformationSettingsProps extends RouteComponentProps {
  endUser: Pick<
    EndUser,
    | "id"
    | "__typename"
    | "addressStreet"
    | "addressCity"
    | "addressState"
    | "addressPostcode"
    | "dateOfBirth"
  >;
}

export const PersonalInformationSettings = ({
  endUser,
}: PersonalInformationSettingsProps) => {
  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        return (
          <div className="border-container mx-1 xm:ml-12 lg:mx-12">
            <h3 className="mb-0">Personal Information</h3>
            <div className="mt-8 border-t border-gray-200 sm:mt-5">
              <PersonalInformationSettingsForms endUser={endUser} add={add} />
            </div>
          </div>
        );
      }}
    </ToastConsumer>
  );
};

export const PersonalInformationSettingsForms = ({
  endUser,
  add,
}: {
  endUser: Pick<
    EndUser,
    | "id"
    | "__typename"
    | "addressStreet"
    | "addressCity"
    | "addressState"
    | "addressPostcode"
    | "dateOfBirth"
  >;
  add: AddToast;
}) => {
  return (
    <>
      <div className="">
        <div className="mt-6 sm:mt-5">
          <div className="mt-6 sm:mt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
            <div className="pb-2 pr-8">
              <h3 className="text-base leading-6 font-medium text-gray-900">
                Date of Birth
              </h3>
            </div>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <DateOfBirthForm profile={endUser} add={add} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-gray-200 sm:mt-5 ">
        <div className="mt-6 sm:mt-5">
          <div className="mt-6 sm:mt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
            <div className="pb-8 pr-8">
              <h3 className="text-base leading-6 font-medium text-gray-900">
                Address
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                Please enter your primary address, where support bookings will
                take place.
              </p>
            </div>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <AddressForm profile={endUser} add={add} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
