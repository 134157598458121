import gql from "graphql-tag";

import { EndUser } from "../../types/endUser";
import { Service } from "../../types/service";
import { END_USER_FIELDS, SERVICE_FIELDS } from "../fragments";

export interface Data {
  endUser: EndUser;
  services: Service[];
}

export interface Variables {
  profileId: string;
}

export const MANAGE_CLIENT_PAGE = gql`
  ${END_USER_FIELDS}
  ${SERVICE_FIELDS}

  query ManageClientPageQuery($profileId: ID!) {
    endUser(id: $profileId) {
      ...EndUserFields
    }

    services {
      ...ServiceFields
    }
  }
`;
