import { useCallback, useState, useEffect } from "react";
import {
  LocalTrackPublication,
  Room,
  LocalAudioTrackPublication,
} from "twilio-video";

export const useLocalAudioToggle = (room: Room) => {
  const [localTracks, setLocalTracks] = useState<LocalTrackPublication[]>([]);
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    setLocalTracks(Array.from(room.localParticipant.tracks.values()));
  }, [room]);

  useEffect(() => {
    const track = localTracks.find((track) => track.kind === "audio");

    if (track) setIsEnabled(track.isTrackEnabled);
  }, [localTracks]);

  const publication = localTracks.find(
    (track) => track.kind === "audio"
  ) as LocalAudioTrackPublication;

  const disableTrack = (publication: LocalAudioTrackPublication) => {
    publication.track.disable();
    setIsEnabled(false);
  };

  const enableTrack = (publication: LocalAudioTrackPublication) => {
    publication.track.enable();
    setIsEnabled(true);
  };

  const toggleAudioEnabled = useCallback(() => {
    if (publication) {
      publication.track.isEnabled
        ? disableTrack(publication)
        : enableTrack(publication);
    }
  }, [publication]);

  return [isEnabled, toggleAudioEnabled] as const;
};
