import React from "react";
import { useQuery } from "@apollo/client";

import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import {
  actionableBookingsQueryForUserType,
  Data,
  Variables,
} from "../../../graphql/queries/actionableBookings";
import { IUserContext, ServiceRole } from "../../../types/profile";
import { BookingList } from "../BookingList";

export const ActionableBookings = ({
  user,
}: {
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  const variables = { id: user.id };
  const { data, error, loading } = useQuery<Data, Variables>(
    actionableBookingsQueryForUserType[user.__typename],
    {
      variables,
      fetchPolicy: "no-cache",
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  const { actionableBookings } = data;

  const actionableMessage =
    user.__typename === ServiceRole.END_USER ? "approval" : "confirmation";

  return actionableBookings.length > 0 ? (
    <BookingList
      title={`Awaiting ${actionableMessage}`}
      bookings={actionableBookings}
      showState
      emptyStateMessage={`No bookings awaiting ${actionableMessage}`}
      refetchQueries={[
        {
          query: actionableBookingsQueryForUserType[user.__typename],
          variables,
        },
      ]}
      user={user}
    />
  ) : (
    <></>
  );
};
