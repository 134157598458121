import React from "react";
import { Card } from "semantic-ui-react";

import { EndUser } from "../../../types/endUser";
import { Avatar } from "../../layout/Avatar";
import { Button } from "../../layout/Button";

export const EndUserSummaryCard = ({
  endUser,
}: {
  endUser: Pick<EndUser, "id" | "name" | "profileImage">;
}) => (
  <Card className="center">
    <Card.Content>
      <Avatar float="left" size="md" className="mr-4" user={endUser} />
      <Card.Header>{endUser.name}</Card.Header>
    </Card.Content>
    <Card.Content extra>
      <div className="ui two buttons">
        <Button to={`/user/profile/${endUser.id}`}>View Profile</Button>
      </div>
    </Card.Content>
  </Card>
);
