import React from "react";
import {
  RouteComponentProps,
  Router,
  Redirect,
  WindowLocation,
} from "@reach/router";
import { useQuery } from "@apollo/client";

import { Loading } from "../../layout/Loading";
import { ServiceRole, IUserContext } from "../../../types/profile";
import { ErrorPage } from "../../layout/ErrorPage";
import { SettingsNav } from "./SettingsNav";
import { PaymentSettings } from "./payment/PaymentSettings";
import { ProfileSettings } from "./profile/ProfileSettings";
import { ServiceSettings } from "./services/ServiceSettings";
import { PreferencesSettings } from "./preferences/PreferencesSettings";
import { PersonalInformationSettings } from "./personalInformation/PersonalInformationSettings";
import {
  END_USER,
  Data as EData,
  Variables as EVariables,
} from "../../../graphql/queries/endUser";
import {
  SUPPORT_PROFESSIONAL_SETTINGS,
  Data as SPData,
  Variables as SPVariables,
} from "../../../graphql/queries/settings/supportProfessionalSettings";
import {
  SUPPORT_COORDINATOR,
  Data as SCData,
  Variables as SCVariables,
} from "../../../graphql/queries/supportCoordinator";
import { AvailabilitySettings } from "./availability/AvailabilitySettings";
import { StripeMessage } from "./StripeMessage";
import { FundingSettings } from "./funding/FundingSettings";

export interface Location extends WindowLocation {
  state: {
    message?: string;
    success?: boolean;
  };
}

export interface SettingsPageProps extends RouteComponentProps {
  location?: Location;
  user: Pick<IUserContext, "id" | "__typename">;
}

export const SettingsPage = ({ location, user }: SettingsPageProps) => {
  const query = {
    [ServiceRole.END_USER]: END_USER,
    [ServiceRole.SUPPORT_COORDINATOR]: SUPPORT_COORDINATOR,
    [ServiceRole.SUPPORT_WORKER]: SUPPORT_PROFESSIONAL_SETTINGS,
  };
  const defaultRedirect = {
    [ServiceRole.END_USER]: "preferences",
    [ServiceRole.SUPPORT_COORDINATOR]: "profile",
    [ServiceRole.SUPPORT_WORKER]: "payment",
  };

  const { data, error, loading, refetch } = useQuery<
    Partial<EData & SPData & SCData>,
    EVariables | SPVariables | SCVariables
  >(query[user.__typename], {
    variables: {
      profileId: user.id,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { endUser, supportProfessional, supportCoordinator } = data;
  const { stripeCustomAccountBankDetails: bankDetails } = data;

  return (
    <div className="page-wrapper">
      {location && location.state && location.state.message && (
        <StripeMessage
          message={location.state.message}
          success={location.state.success}
        />
      )}
      <h1 className="mt-8 ml-3">Settings</h1>

      <div className="xm:flex">
        <SettingsNav
          endUser={endUser}
          supportProfessional={supportProfessional}
          location={location}
        />
        <div className="w-full xm:pr-12">
          <Router primary={false}>
            {!!supportProfessional && (
              <>
                <AvailabilitySettings
                  path="availability"
                  supportProfessional={supportProfessional}
                />
                <PaymentSettings
                  path="payment"
                  supportProfessional={supportProfessional}
                  bankDetails={bankDetails}
                  refetch={refetch}
                />
                <ServiceSettings
                  refetch={refetch}
                  path="services"
                  supportProfessional={supportProfessional}
                />
                <ServiceSettings
                  path="services/:serviceId"
                  supportProfessional={supportProfessional}
                  refetch={refetch}
                />
              </>
            )}
            {!!endUser && (
              <>
                <PersonalInformationSettings
                  path="personal"
                  endUser={endUser}
                />
                <FundingSettings path="funding" endUser={endUser} />
                <PreferencesSettings path="preferences" endUser={endUser} />
              </>
            )}
            <ProfileSettings
              path="profile"
              endUser={endUser}
              supportProfessional={supportProfessional}
              supportCoordinator={supportCoordinator}
              user={user}
            />

            <DefaultPath default redirect={defaultRedirect[user.__typename]} />
          </Router>
        </div>
      </div>
    </div>
  );
};

export interface DefaultPathProps extends RouteComponentProps {
  redirect: string;
}

export const DefaultPath = ({ redirect }: DefaultPathProps) => {
  return <Redirect noThrow to={`/settings/${redirect}`} />;
};
