import React from "react";
import { RouteComponentProps, Router, Redirect } from "@reach/router";

import { BookingHistory } from "./BookingHistory";
import { Loading } from "../layout/Loading";
import { UpcomingBookings } from "./upcomingBookings/UpcomingBookingsPage";
import { BookingNav } from "./BookingNav";
import { BookingPage } from "./BookingPage";
import { BookingVideoPage } from "./video/BookingVideoPage";
import { IUserContext, ServiceRole } from "../../types/profile";
import { SupportCoordinatorBookings } from "./SupportCoordinatorBookings";

export interface BookingsPageProps extends RouteComponentProps {
  user: Pick<IUserContext, "id" | "name" | "__typename">;
}

export const BookingsPage = ({ location, user }: BookingsPageProps) => {
  if (!location) {
    return <Loading />;
  }

  const { pathname } = location;
  const displayNav =
    pathname.includes("history") ||
    pathname.includes("date") ||
    pathname.includes("upcoming");

  return (
    <div className="page-wrapper bookings-page">
      {displayNav && <BookingNav user={user} pathname={pathname} />}
      <Router primary={false}>
        {user.__typename === ServiceRole.SUPPORT_COORDINATOR ? (
          <>
            <SupportCoordinatorBookings path="/upcoming" user={user} />
          </>
        ) : (
          <>
            <UpcomingBookings user={user} path="/date" />
            <UpcomingBookings user={user} path="/date/all" />
            <UpcomingBookings user={user} path="/date/:date" />
          </>
        )}
        <BookingHistory user={user} path="/history" />
        <BookingHistory user={user} path="/history/:page" />
        <BookingPage path="/schedule/:scheduleId/:date" user={user} />
        <BookingPage path="/:bookingId" user={user} />
        <BookingVideoPage path="/:bookingId/video" />
        <DefaultPath user={user} default />
      </Router>
    </div>
  );
};

interface DefaultPathProps extends RouteComponentProps {
  user: Pick<IUserContext, "__typename">;
}

const DefaultPath = ({ user }: DefaultPathProps) => {
  return (
    <Redirect
      noThrow
      to={
        user.__typename === ServiceRole.SUPPORT_COORDINATOR
          ? "/bookings/upcoming"
          : `/bookings/date/all`
      }
    />
  );
};
