import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { v4 as uuid } from "uuid";

import { SupportProfessional } from "../../../../types/supportProfessional";
import { ServiceRole } from "../../../../types/profile";
import { EditDay } from "./EditDay";

export interface AvailabilitySettingsProps extends RouteComponentProps {
  supportProfessional: Pick<
    SupportProfessional,
    "id" | "__typename" | "availabilities"
  >;
}

export const AvailabilitySettings = ({
  supportProfessional,
}: AvailabilitySettingsProps) => {
  if (supportProfessional.__typename === ServiceRole.END_USER) {
    return <Redirect noThrow to="/settings/preferences" />;
  }
  const dayNums = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="border-container mx-1 xm:ml-12 lg:mx-12 mb-8">
      <h3>Availability</h3>
      <div className="divider" />
      <p className="mb-4">Set which days you are available for appointments</p>
      <p className="bg-gray-100 border border-gray-300 p-3">
        <span className="font-bold">Note: </span>Changes here will not affect
        any existing recurring bookings that have already been requested. Please
        cancel these if you are no longer available to attend them.
      </p>
      <div className="mt-6">
        {dayNums.map((num) => {
          return (
            <EditDay
              key={uuid()}
              supportProfessional={supportProfessional}
              dayNumber={num}
            />
          );
        })}
      </div>
    </div>
  );
};
