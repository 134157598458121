import gql from "graphql-tag";

import { Booking } from "../../types/booking";
import { BOOKING_FIELDS } from "../fragments";

export interface Data {
  activeBookings: Booking[];
  inactiveBookings: Booking[];
}

export interface Variables {
  profileId: string;
}

export const END_USER_BOOKINGS_QUERY = gql`
  ${BOOKING_FIELDS}

  query EndUserBookingsQuery($profileId: ID!) {
    activeBookings: activeBookingsForEndUser(id: $profileId) {
      ...BookingFields
    }
    inactiveBookings: inactiveBookingsForEndUser(id: $profileId) {
      ...BookingFields
    }
  }
`;
