import React from "react";
import { Label, Popup, Icon } from "semantic-ui-react";
import moment from "moment";
import { Link } from "@reach/router";

import { BookingState, Booking } from "../../../types/booking";
import { IUserContext, ServiceRole } from "../../../types/profile";

export const AwaitingConfirmationLabel = ({
  booking,
  name,
  user,
  bookingStateLabel,
  link,
}: {
  booking: Pick<Booking, "state" | "insertedAt">;
  name: string;
  user: Pick<IUserContext, "__typename">;
  bookingStateLabel: string;
  link?: string;
}) => {
  let message;
  const currentUserIsEU = user.__typename === ServiceRole.END_USER;
  const currentUserIsSP = user.__typename === ServiceRole.SUPPORT_WORKER;

  // Personalise awaiting confirmation from message
  if (booking.state === BookingState.REQUESTED_BY_END_USER) {
    bookingStateLabel += currentUserIsSP ? "you" : name;
    message = "Click here to accept or reject this booking";
  }

  let content = (
    <Label color="yellow" basic={true}>
      {bookingStateLabel}
    </Label>
  );

  const popup = (
    <Popup trigger={content} content={message} position="top left" />
  );

  if (link && currentUserIsEU) return <Link to={link}>{content}</Link>;

  if (link && !currentUserIsEU) return <Link to={link}>{popup}</Link>;

  return (
    <>
      <p className="italic mb-4">
        <Icon name="bookmark" color="grey" />
        Booking requested on {moment(booking.insertedAt).format("dddd Do MMMM")}
      </p>
      <Label basic color="orange">
        <Icon name="clock outline" color="orange" />
        {bookingStateLabel}
      </Label>
    </>
  );
};
