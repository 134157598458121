import moment from "moment";

import { AvailableUsersData } from "../components/users/profileHelpers";
import { AccountRole, ServiceRole, IUserContext } from "../types/profile";

export function humanize(
  slug: string,
  opts?: { capitalized: boolean }
): string {
  if (!opts) {
    opts = { capitalized: false };
  }
  let words = slug.toLowerCase().split(/[-_\s]/);
  words = opts.capitalized ? words.map((word) => capitalize(word)) : words;
  return words.join(" ");
}

export const unCamelize = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

export function capitalize(
  string: string,
  opts: { all: boolean } = { all: false }
): string {
  return opts.all
    ? string
        .split(" ")
        .map((word) => doCapitalize(word))
        .join(" ")
    : doCapitalize(string);
}

function doCapitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
}

export const humanizeDuration = (mins: number) => {
  const label =
    mins % 60 === 0
      ? `${mins / 60} hour${mins / 60 > 1 ? "s" : ""}`
      : `${mins} minutes`;
  return label;
};

export const escape = (string: string) => {
  const reg = /[&<>"'\\/]/gi;
  return string.replace(reg, (match) => `\\${match}`);
};

// Taken from https://gist.github.com/codeguy/6684588
export const slugify = (string: string) => {
  string = string.replace(/^\s+|\s+$/g, ""); // trim
  string = string.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    string = string.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  string = string
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return string;
};

export const getEnv = (name: string) => {
  return process.env[name] || window.FAB_SETTINGS[name];
};

export interface DateOfBirthValues {
  dobDay: number;
  dobMonth: string;
  dobYear: number;
}

export const parseDateValues = (values: DateOfBirthValues) => {
  return moment([
    values["dobYear"],
    moment()
      .month(values["dobMonth"])
      .month(),
    values["dobDay"],
  ]);
};

export interface AvailableUserType {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  __typename: ServiceRole;
  [key: string]: any;
}

export const concatAvailableUsers = ({
  viewer,
  adminSupportCoordinators,
}: AvailableUsersData): IUserContext[] => {
  const supportCoordinators =
    viewer.role === AccountRole.ADMIN
      ? adminSupportCoordinators
      : [viewer.supportCoordinator];

  const availableUsers: IUserContext[] = [
    ...viewer.endUsers,
    ...viewer.supportProfessionals,
    ...supportCoordinators,
  ]
    .filter((sc?: AvailableUserType): sc is AvailableUserType => !!sc)
    .map((user?: AvailableUserType) => {
      return {
        id: user!.id,
        __typename: user!.__typename,
        type: user!.__typename,
        name: user!.name || `${user!.firstName} ${user!.lastName}`,
        profileImage: user!.profileImage,
      };
    });

  return availableUsers;
};

// Source: https://github.com/moment/moment/issues/2781
export const timeSince = (d: string | Date) => {
  const date = typeof d !== "object" ? d : new Date(d);

  const seconds = moment().diff(moment(date), "seconds");
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "y";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "mo";
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = "d";
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "h";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "m";
          } else {
            interval = seconds;
            intervalType = "s";
          }
        }
      }
    }
  }

  return `${interval}${intervalType}`;
};
