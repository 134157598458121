import gql from "graphql-tag";

export interface NewData {
  DeleteAvailability: {
    id: string;
  };
}

export interface Variables {
  id: string;
}

export const DELETE_AVAILABILITY = gql`
  mutation DeleteAvailability($id: ID!) {
    deleteAvailability(id: $id) {
      id
    }
  }
`;
