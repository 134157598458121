import React from "react";
import { Menu, Sidebar, Segment, Icon, Container } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Link } from "@reach/router";

import { UserSelect } from "./UserSelect";
import { Avatar } from "../Avatar";
import { Loading } from "../Loading";
import TopNav, { SecondaryNav, CurrentUser } from "./TopNav";
import {
  END_USER,
  Data as EData,
  Variables as EVariables,
} from "../../../graphql/queries/endUser";
import {
  SUPPORT_COORDINATOR,
  Data as SCData,
  Variables as SCVariables,
} from "../../../graphql/queries/supportCoordinator";
import {
  SUPPORT_PROFESSIONAL,
  Data as SPData,
  Variables as SPVariables,
} from "../../../graphql/queries/supportProfessional";
import tapponLogo from "../../../assets/images/tappON_partwhite.png";
import { ServiceRole, IUserContext } from "../../../types/profile";
import { Viewer } from "../../../types/viewer";
import { useLogger } from "../../../hooks/useLogger";
import { enableAdminOnly } from "../../../utils/featureFlags";

const pageQuery = {
  [ServiceRole.END_USER]: END_USER,
  [ServiceRole.SUPPORT_WORKER]: SUPPORT_PROFESSIONAL,
  [ServiceRole.SUPPORT_COORDINATOR]: SUPPORT_COORDINATOR,
};

export const MobileNav = ({
  hideSidebar,
  sidebarOpened,
  userSelect,
  toggleUserSelect,
  userOptions,
  switchUser,
  user,
  viewer,
}: {
  hideSidebar: () => void;
  sidebarOpened: boolean;
  userSelect: boolean;
  toggleUserSelect: () => void;
  userOptions: IUserContext[];
  switchUser: (id: string) => void;
  user: Pick<IUserContext, "id" | "__typename" | "profileImage">;
  viewer: Pick<Viewer, "id" | "role">;
}) => {
  const { log } = useLogger();

  const { data, error, loading } = useQuery<
    Partial<EData & SCData & SPData>,
    EVariables | SCVariables | SPVariables
  >(pageQuery[user.__typename], {
    variables: { profileId: user.id },
  });

  if (error) {
    log("error", error);
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { endUser, supportProfessional, supportCoordinator } = data;

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      inverted
      onHide={hideSidebar}
      vertical
      visible={sidebarOpened}
      width="very wide"
      className="tappon-sidebar"
      style={{ maxWidth: "100vw", maxHeight: "100vh" }}
    >
      <MobileNavHeader>
        <Segment inverted onClick={hideSidebar} className="tappon-sidebar">
          <Icon name="close" size="big" className="cursor-pointer" />
        </Segment>
        <img
          alt="tappON logo"
          src={tapponLogo}
          className="absolute w-48 right-0 mr-8"
        />
      </MobileNavHeader>
      {userSelect ? (
        <>
          <BackButton onClick={toggleUserSelect}>
            <Icon name="chevron left" />
            Back
          </BackButton>
          <Container onClick={hideSidebar}>
            {(user.__typename !== ServiceRole.SUPPORT_COORDINATOR ||
              enableAdminOnly(viewer)) && (
              <UserSelect userOptions={userOptions} switchUser={switchUser} />
            )}
          </Container>
        </>
      ) : (
        <>
          <Container onClick={hideSidebar}>
            <Link
              to="/profile"
              className="text-white flex relative text-2xl items-center px-8 py-4"
            >
              <Avatar className="mr-4" float="left" user={user} size="sm" />
              <CurrentUserName>
                {(endUser && endUser.name) ||
                  (supportProfessional && supportProfessional.name) ||
                  (supportCoordinator &&
                    `${supportCoordinator.firstName} ${supportCoordinator.lastName}`)}
              </CurrentUserName>
            </Link>
          </Container>
          <Divider />
          <Container onClick={hideSidebar}>
            <TopNav />
          </Container>
          <Divider />
          <Container onClick={hideSidebar}>
            <SecondaryNav />
          </Container>
          <Divider />
        </>
      )}

      {(user.__typename !== ServiceRole.SUPPORT_COORDINATOR ||
        enableAdminOnly(viewer)) && (
        <>
          {!userSelect && userOptions.length > 1 && (
            <CurrentUser toggleUserSelect={toggleUserSelect} />
          )}
        </>
      )}
    </Sidebar>
  );
};

const MobileNavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 0 25px;
`;

const BackButton = styled.div`
  cursor: pointer;
  padding: 0px 0 20px 26px;
  color: #fff;
`;

const Divider = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  width: 97%;
  margin: 10px auto;
  opacity: 0.7;
`;

const CurrentUserName = styled.span`
  position: relative;
  top: 3px;
`;
