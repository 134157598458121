import gql from "graphql-tag";

import { Message } from "../../types/message";
import { MESSAGE_FIELDS } from "../fragments";

export interface NewData {
  sendMessage: Message;
}

export interface Variables {
  endUserId?: string;
  supportProfessionalId?: string;
  supportCoordinatorId?: string;
  message: string;
  sentBySp: boolean;
}

export const SEND_MESSAGE = gql`
  ${MESSAGE_FIELDS}
  mutation sendMessage(
    $endUserId: ID
    $supportProfessionalId: ID!
    $supportCoordinatorId: ID
    $message: String!
    $sentBySp: Boolean!
  ) {
    sendMessage(
      input: {
        endUserId: $endUserId
        supportProfessionalId: $supportProfessionalId
        supportCoordinatorId: $supportCoordinatorId
        message: $message
        sentBySp: $sentBySp
      }
    ) {
      ...MessageFields
    }
  }
`;
