import React, { useState } from "react";

import { Button } from "../../../layout/Button";
import { EndUserT } from "./CompleteProfileModal";
import { Dropdown } from "../../../layout/forms";
import { Service } from "../../../../types/service";
import { Icon } from "../../../layout/Icons";
import { FormValues } from "./CompleteProfilePanel2";

export type EndUserT2 = Pick<EndUserT, "servicePreferences">;

export const AddServicePreference = ({
  serviceOptions,
  endUser,
  values,
  setFieldValue,
  services,
}: {
  serviceOptions: { text: string; value: string }[];
  endUser: EndUserT2;
  values: FormValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  services: Service[];
}) => {
  const [showServiceDropdown, setShowServiceDropdown] = useState(
    endUser.servicePreferences.length === 0
  );

  return (
    <div className="flex items-center">
      {serviceOptions.length > 0 && values.servicePreferences.length < 5 && (
        <>
          {showServiceDropdown ? (
            <>
              <Dropdown
                noLabel
                name="serviceId"
                options={serviceOptions}
                blankDefault
                className="mx-2"
              />
              <Button
                size="small"
                onClick={() => {
                  if (values.serviceId) {
                    setFieldValue("servicePreferences", [
                      ...values.servicePreferences,
                      {
                        serviceId: values.serviceId,
                        service: services.find(
                          (s) => s.id === values.serviceId
                        ),
                      },
                    ]);
                    setFieldValue("serviceId", "");
                    setShowServiceDropdown(false);
                  }
                }}
              >
                Add
              </Button>
            </>
          ) : (
            <Button size="small" onClick={() => setShowServiceDropdown(true)}>
              <Icon name="add" className="w-6 h-6" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};
