/* 
Polyfilling language features for stable browsers (using browserList in package.json)
https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md#polyfilling-other-language-features
*/
import "react-app-polyfill/stable";
import { getEnv } from "./utils/helpers";

import React from "react";
import ReactDOM from "react-dom";
import "./styles/tailwind.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./auth/Auth";

if (getEnv("REACT_APP_AUTH0_DOMAIN")) {
  ReactDOM.render(
    <AuthProvider>
      <App />
    </AuthProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
