import React from "react";
import { Formik, Form, FormikHelpers, FormikErrors } from "formik";
import { useMutation, useQuery } from "@apollo/client";

import tapponLogo from "../../assets/images/tappON_logo_color.png";
import { ErrorPage } from "./ErrorPage";
import { Loading } from "./Loading";
import { useLogger } from "../../hooks";
import { Checkbox } from "./forms";
import {
  UPDATE_ACCOUNT,
  NewData,
  Variables,
} from "../../graphql/mutations/updateAccount";
import { CURRENT_USER, Data } from "../../graphql/queries/currentUser";

export interface FormValues {
  terms: boolean;
}

export const TermsModal = () => {
  const [updateAccount] = useMutation<NewData, Variables>(UPDATE_ACCOUNT);
  const { data, loading, error } = useQuery<Data>(CURRENT_USER);
  const { log } = useLogger();

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { viewer } = data;

  if (viewer.acceptedTerms) {
    return <></>;
  }

  const handleSubmit = (
    values: FormValues,
    formikApi: FormikHelpers<FormValues>
  ) => {
    const variables = {
      id: viewer.id,
      acceptedTerms: values.terms,
    };
    updateAccount({ variables }).catch((err) => {
      formikApi.setSubmitting(false);
      log("error", err);
    });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ terms: false }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.terms) {
          errors.terms = "Required";
        }

        return errors;
      }}
    >
      {({ isSubmitting }) => {
        return isSubmitting ? (
          <Loading />
        ) : (
          <Form>
            <div className="fixed z-40 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-tappon-bg opacity-100"></div>
                </div>
                {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
                &#8203;
                <div
                  className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div>
                    <img
                      className="w-48 m-auto"
                      src={tapponLogo}
                      alt="tappOn Logo"
                    />
                    <div className="mt-4 text-center sm:mt-5">
                      <div className="mt-2 text-base leading-5 text-gray-500">
                        <p className="mb-4">
                          Welcome to tappON! You have been invited to join our
                          community.
                        </p>
                        <p>
                          Please read and accept the{" "}
                          <a
                            href="https://about.tappon.co/terms-and-conditions/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of Use
                          </a>{" "}
                          before continuing to the platform
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center my-6">
                    <Checkbox
                      name="terms"
                      label="I accept the terms of use"
                      errorClassName="text-center text-sm"
                    />
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <span className="flex w-full rounded-md shadow-sm">
                      <button
                        type="submit"
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-teal-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      >
                        Continue
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
