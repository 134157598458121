import React from "react";

import { Icon } from "../../../layout/Icons";

export const AddBankAccount = () => {
  return (
    <div className="rounded-md bg-blue-100 p-5 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon name="bank" className="w-6 h-6 text-blue-800" />
        </div>
        <div className="ml-3">
          <h3 className="text-base leading-5 font-bold text-blue-800">
            Bank Details Required
          </h3>
          <div className="mt-2 text-base leading-5 text-blue-800">
            <p>
              You need to enter your bank account details below before you can
              receive bookings and payments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
