import React from "react";
import { RouteComponentProps } from "@reach/router";

import { SupportProfessional } from "../../../../types/supportProfessional";
import { EditPaymentOptions } from "./EditPaymentOptions";
import { BankDetailsForm } from "./BankDetailsForm";
import { OldStripeImplementation } from "./OldStripeImplementation";
import { VerificationNotice } from "./VerificationNotice";
import { PendingVerification } from "./PendingVerification";
import { AddBankAccount } from "./AddBankAccount";

export interface PaymentSettingsProps extends RouteComponentProps {
  supportProfessional: SupportProfessional;
  bankDetails?: BankDetails;
  refetch: () => void;
}

export interface BankDetails {
  accountName?: string;
  bsb?: string;
  lastFour?: string;
}

export const PaymentSettings = ({
  supportProfessional,
  bankDetails,
  refetch,
}: PaymentSettingsProps) => {
  const noBankDetails = !bankDetails || !bankDetails.bsb;

  return (
    <div
      data-testid="payment-settings"
      className="border-container mx-1 xm:ml-12 lg:mx-12"
    >
      {supportProfessional.stripeConnectAccount && (
        <>
          {supportProfessional.stripeConnectAccount.requirementsOutstanding && (
            <VerificationNotice
              supportProfessionalId={supportProfessional.id}
            />
          )}
          {supportProfessional.stripeConnectAccount.pendingVerification &&
            !supportProfessional.stripeConnectAccount
              .requirementsOutstanding && <PendingVerification />}
          {noBankDetails &&
            !supportProfessional.stripeConnectAccount
              .requirementsOutstanding && <AddBankAccount />}
        </>
      )}
      {supportProfessional.stripeConnectAccount ? (
        <div className="mb-6">
          <div className="flex justify-between items-end">
            <div>
              <h3 className="mb-0">Bank Account Details</h3>
              <p className="mb-4 text-gray-700">For receiving payments</p>
            </div>
          </div>

          <div className="mb-2">
            <p className="text-sm text-gray-700">
              Please keep these details up to date. A tappON team member will be
              in touch after the completion of your first invoice (transfer
              booking) to confirm your bank details for payment. Fast (credit
              card) bookings will automatically use the below details
            </p>
          </div>
          <BankDetailsForm
            bankDetails={bankDetails}
            supportProfessional={supportProfessional}
            refetch={refetch}
          />
        </div>
      ) : (
        <OldStripeImplementation supportProfessional={supportProfessional} />
      )}
      <div className="sm:border-t sm:border-gray-200 sm:pt-6">
        <h3>Payment Settings</h3>
        <EditPaymentOptions supportProfessional={supportProfessional} />
      </div>
    </div>
  );
};
