import React from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { useQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";

import { BookingForm } from "../bookingForm/BookingForm";
import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import {
  NEW_BOOKING_PAGE,
  Data as BData,
  Variables as BVariables,
} from "../../../graphql/queries/newBookingPage";
import {
  SUPPORT_PROFESSIONAL,
  Data as SPData,
  Variables as SPVariables,
} from "../../../graphql/queries/supportProfessional";
import { checkAvailability } from "../../users/profileHelpers";
import { Icon } from "../../layout/Icons";
import { capitalize } from "../../../utils/helpers";
import { NotFoundPage } from "../../pages/NotFoundPage";
import { ServiceRole } from "../../../types/profile";
import defaultAvatar from "../../../assets/images/default-avatar-image.png";

export interface NewBookingPageProps extends RouteComponentProps {
  id?: string;
  user: {
    id: string;
    __typename: ServiceRole.END_USER | ServiceRole.SUPPORT_COORDINATOR;
  };
}

export const NewBookingPage = ({ id, user }: NewBookingPageProps) => {
  const query =
    user.__typename === ServiceRole.SUPPORT_COORDINATOR
      ? SUPPORT_PROFESSIONAL
      : NEW_BOOKING_PAGE;

  const variables =
    user.__typename === ServiceRole.SUPPORT_COORDINATOR
      ? { profileId: id! }
      : { supportProfessionalId: id!, endUserId: user.id };

  const { data, loading, error } = useQuery<
    Partial<BData & SPData>,
    BVariables | SPVariables
  >(query, {
    variables,
  });

  if (error) return <ErrorPage error={error.message} />;

  if (loading || !data) return <Loading />;

  const { supportProfessional, endUser } = data;

  if (!supportProfessional) {
    return <NotFoundPage />;
  }

  const { name, profileImage, title } = supportProfessional;

  const avatarImage = profileImage && profileImage.med;

  const days = {
    monday: checkAvailability(1, supportProfessional),
    tuesday: checkAvailability(2, supportProfessional),
    wednesday: checkAvailability(3, supportProfessional),
    thursday: checkAvailability(4, supportProfessional),
    friday: checkAvailability(5, supportProfessional),
    saturday: checkAvailability(6, supportProfessional),
    sunday: checkAvailability(7, supportProfessional),
  };

  return (
    <div className="m-8">
      <div className="flex justify-center w-full flex-wrap">
        <div className="sm:mr-8 w-full md:w-1/3">
          <Link
            to={`/worker/profile/${id}`}
            className="inline-block text-black w-full"
          >
            <div className="p-6 bg-gray-100 border border-light border-gray-300 mb-4 w-full">
              <div className="flex justify-start">
                <div>
                  <div
                    className="w-24 rounded-full square bg-cover bg-center mr-4"
                    title={`${name}'s Profile Image`}
                    style={{
                      backgroundImage: `url(${avatarImage || defaultAvatar})`,
                    }}
                  />
                </div>
                <div className="truncate">
                  <p className="truncate">New Booking Request</p>
                  <p className="font-bold text-2xl mb-1 truncate">{name}</p>
                  <p className="text-base w-56 truncate">{title}</p>
                </div>
              </div>
            </div>
          </Link>
          <div className="p-6 bg-gray-100 border border-light border-gray-200">
            <h4 className="font-bold">Availability</h4>
            <ul className="mt-2 flex max-w-full flex-wrap font-bold">
              {Object.entries(days).map((day) => {
                const [dayName, available] = day;
                return (
                  <li
                    key={uuid()}
                    className={`mr-4 ${
                      available ? "text-gray-800" : "text-gray-500"
                    } `}
                  >
                    {capitalize(dayName).slice(0, 3)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="w-full md:w-auto mt-6 mr-6 md:mr-0 md:mt-0 max-w-100 sm:min-w-88 mb-4">
          {endUser && endUser.permissions && !endUser.permissions.booking ? (
            <p
              className="w-80 bg-teal-200 border border-teal-400 text-teal-700 p-4 mb-3 flex items-center mb-4"
              role="alert"
            >
              <Icon className="h-8 w-8 mr-6 flex-none" name="alert" />
              Lets complete your profile set up so you can start reaching out to
              support professionals
            </p>
          ) : (
            <BookingForm
              user={user}
              supportProfessional={supportProfessional}
              disabled={
                endUser && endUser.permissions && !endUser.permissions.booking
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
