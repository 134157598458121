import React from "react";

export const LocationMarker = ({
  location,
  className
}: {
  location: string;
  className?: string;
}) => (
  <div
    className={`flex text-gray-700 items-end leading-none mt-1 ${className}`}
  >
    <svg className="h-4 w-4 fill-current mr-2" viewBox="0 0 20 20">
      <path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    </svg>
    <p className="">{location}</p>
  </div>
);
