import gql from "graphql-tag";

import { EndUser } from "../../types/endUser";
import { END_USER_FIELDS } from "../fragments";

export interface Data {
  endUser: EndUser;
}

export interface Variables {
  profileId: string;
}

export const END_USER = gql`
  ${END_USER_FIELDS}
  query EndUser($profileId: ID!) {
    endUser(id: $profileId) {
      ...EndUserFields
      supportCoordinator {
        id
        firstName
        lastName
      }
    }
  }
`;
