import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import { RECOMMENDED_SUPPORT_PROFESSIONALS } from "../../../../graphql/queries/recommendedSupportProfessionals/recommendedSupportProfessionals";
import { EndUser } from "../../../../types/endUser";
import { Dropdown } from "../../../layout/forms";
import { Button } from "../../../layout/Button";
import { Icon } from "../../../layout/Icons";
import { Service } from "../../../../types/service";
import {
  UPDATE_END_USER,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateEndUser";

export interface AddPreferenceFormProps {
  endUser: EndUser;
  services: Service[];
  add: AddToast;
}

interface FormValues {
  servicePreference: string;
}

export const AddPreferenceForm = ({
  endUser,
  services,
  add,
}: AddPreferenceFormProps) => {
  const [updateEndUser] = useMutation<NewData, Variables>(UPDATE_END_USER);
  const [addingPreference, setAddingPreference] = useState(false);

  const { id, servicePreferences } = endUser;

  const existingServices = servicePreferences.map((s) => s.service.id);

  const serviceOptions = services
    .filter((s) => !existingServices.includes(s.id))
    .map((service) => {
      return { group: service.category, text: service.name, value: service.id };
    });

  const handleSubmit = (
    values: FormValues,
    formikApi: FormikHelpers<FormValues>
  ) => {
    const { servicePreference } = values;
    const variables = {
      id: endUser.id,
      servicePreferences: [
        {
          serviceId: servicePreference,
        },
        ...endUser.servicePreferences.map((s) => {
          return {
            id: s.id,
            endUserId: id,
            serviceId: s.service.id,
          };
        }),
      ],
    };

    const service: Service = services.find((s) => s.id === servicePreference)!;

    const optimisticResponse = {
      __typename: "Mutation",
      updateEndUser: {
        ...endUser,
        servicePreferences: [
          {
            __typename: "ServicePreference",
            id: "1",
            serviceId: servicePreference,
            service: service,
          },
          ...endUser.servicePreferences,
        ],
      },
    };

    setAddingPreference(false);
    formikApi.resetForm();

    updateEndUser({
      variables,
      optimisticResponse,
      refetchQueries: [
        {
          query: RECOMMENDED_SUPPORT_PROFESSIONALS,
          variables: { endUserId: id },
        },
      ],
    })
      .then((res) => res)
      .catch((err) =>
        add("There was an error creating your preference", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const initialValues: FormValues = {
    servicePreference: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: {
          servicePreference?: string;
        } = {};

        if (!values.servicePreference) {
          errors.servicePreference = "Required";
        }

        return errors;
      }}
    >
      {(props) => {
        const { isSubmitting } = props;

        return addingPreference ? (
          <>
            <Form className="flex w-1/2 md:3/5 lg:1/2 items-center">
              <Dropdown
                options={serviceOptions}
                name="servicePreference"
                noLabel
                blankDefault
                noErrorMessage
              />
              <Button className="ml-4" type="submit" disabled={isSubmitting}>
                Add
              </Button>
              <Icon
                className="w-3 h-3 ml-4 text-gray-600 cursor-pointer"
                name="close"
                onClick={() => setAddingPreference(false)}
              />
            </Form>
          </>
        ) : (
          <Button
            className="flex items-center"
            onClick={() => setAddingPreference(true)}
          >
            <Icon className="h-5 w-5 mr-4" name="addCircle" />
            Add
          </Button>
        );
      }}
    </Formik>
  );
};
