import React from "react";
import { Formik, Form } from "formik";
import { navigate } from "@reach/router";

import { InputField as Input } from "../../layout/forms/Input";
import { useLogger } from "../../../hooks";

export const SearchSupportProfessional = ({ header }: { header: string }) => {
  const { log } = useLogger();

  const handleSubmit = (values: any, formikApi: any) => {
    const { query } = values;

    const searchQuery = query
      .split(" ")
      .join("+")
      .toLowerCase();

    log("info", `New search query: ${query}`);

    navigate(`/search?query=${searchQuery}`);
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ query: "" }}>
      <Form action="/search" method="GET">
        <p className="text-lg font-light text-gray-700 ml-1 mb-3">{header}</p>

        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full px-3">
            <Input
              inputClassName="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              name="query"
              icon={{ name: "search", size: 4 }}
              placeholder="Search by services, interests, skills, location or name"
              noLabel
              className="mb-4"
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};
