import React from "react";
import { v4 as uuid } from "uuid";

import { capitalize } from "../../../../utils/helpers";

export const List = ({
  name,
  items,
  icon,
  inline,
  className,
}: {
  name: string;
  items: React.ReactNode[];
  icon: React.ReactNode;
  inline?: boolean;
  className?: string;
}) => (
  <div id={`${name}-list`} className={`profile-attribute-list ${className}`}>
    <div className="title-container">
      <svg className="title-icon" viewBox="0 0 20 20">
        {icon}
      </svg>
      <span className="title-text">{capitalize(name)}</span>
    </div>
    <ul className="items">
      {items.map((i) => (
        <li className={`item ${inline ? "inline-block" : ""}`} key={uuid()}>
          {i}
        </li>
      ))}
    </ul>
  </div>
);
