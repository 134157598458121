import { Booking, Parties } from "../../types/booking";
import { actionsForBooking } from "./BookingHelpers";
import { IUserContext, ServiceRole } from "../../types/profile";

// Add person field to booking which is the other side of the
// booking transaction (party).
// So we can just use this to build our components once

export function addPartiesToBooking<
  TBooking extends Pick<
    Booking,
    | "id"
    | "endUser"
    | "supportProfessional"
    | "state"
    | "scheduleId"
    | "startTime"
  >
>(
  booking: TBooking,
  user: Pick<IUserContext, "id" | "__typename">
): TBooking & Parties {
  return [ServiceRole.END_USER, ServiceRole.SUPPORT_COORDINATOR].includes(
    user.__typename
  )
    ? {
        ...booking,
        party: booking.endUser,
        otherParty: booking.supportProfessional,
        actions: actionsForBooking(booking, user),
      }
    : {
        ...booking,
        party: booking.supportProfessional,
        otherParty: booking.endUser,
        actions: actionsForBooking(booking, user),
      };
}
