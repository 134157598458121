import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useMutation } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import { SupportProfessional } from "../../../../types/supportProfessional";
import { InputField as Input } from "../../../layout/forms";
import { Button } from "../../../layout/Button";
import { Icon } from "../../../layout/Icons";
import { capitalize } from "../../../../utils/helpers";
import { simplifyInput } from "./EmploymentExperienceForm";
import { useLogger } from "../../../../hooks/useLogger";

export interface AddAttributeFormProps {
  supportProfessional: SupportProfessional;
  add: AddToast;
  attributeType: "skills" | "interests";
  placeholders?: string[];
}

interface FormValues {
  newAttribute: string;
}

export const AddAttributeForm = ({
  supportProfessional,
  add,
  attributeType,
  placeholders,
}: AddAttributeFormProps) => {
  const { log } = useLogger();
  const [updateSupportProfessional] = useMutation<NewData, Variables>(
    UPDATE_SUPPORT_PROFESSIONAL
  );
  const [addingAttribute, setAddingAttribute] = useState(false);
  const __typename = capitalize(attributeType.slice(0, -1));

  const attributes = supportProfessional[attributeType];

  const handleSubmit = (
    values: FormValues,
    formikApi: FormikHelpers<FormValues>
  ) => {
    const { newAttribute } = values;

    const variables = {
      id: supportProfessional.id,
      [attributeType]: [{ name: newAttribute }, ...simplifyInput(attributes)],
    };

    const optimisticResponse = {
      __typename: "Mutation",
      updateSupportProfessional: {
        ...supportProfessional,
        [attributeType]: [
          { __typename, id: null, name: newAttribute },
          ...attributes,
        ],
      },
    };

    setAddingAttribute(false);
    formikApi.resetForm();

    updateSupportProfessional({
      variables,
      optimisticResponse,
    })
      .then((res) => {})
      .catch((err) => {
        log("error", err);
        add(`There was an error creating your ${__typename}`, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div className="mt-2">
      <Formik
        initialValues={{
          newAttribute: "",
        }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: {
            newAttribute?: string;
          } = {};

          if (!values.newAttribute) {
            errors.newAttribute = "Required";
          }

          if (values.newAttribute && values.newAttribute.length > 100) {
            errors.newAttribute = "Exceeds character length of 100";
          }

          if (
            values.newAttribute &&
            supportProfessional[attributeType]
              .map((a) => a.name.toLowerCase())
              .includes(values.newAttribute.toLowerCase())
          ) {
            errors.newAttribute = "Already exists";
          }

          return errors;
        }}
      >
        {(props) => {
          const { isSubmitting, setTouched } = props;

          return addingAttribute ? (
            <>
              <Form className="xm:w-3/5 lg:w-1/2">
                <Input
                  name="newAttribute"
                  ariaLabel={`New ${__typename} name`}
                  noLabel
                  placeholders={placeholders}
                  className="mb-4"
                />
                <div className="flex items-center">
                  <Button type="submit" loading={isSubmitting}>
                    Add
                  </Button>
                  <Icon
                    className="w-3 h-3 ml-4 text-gray-600 cursor-pointer"
                    name="close"
                    onClick={() => {
                      setAddingAttribute(false);
                      setTouched({ newAttribute: false });
                    }}
                  />
                </div>
              </Form>
            </>
          ) : (
            <Button onClick={() => setAddingAttribute(true)} icon="add">
              Add
            </Button>
          );
        }}
      </Formik>
    </div>
  );
};
