import React from "react";
import { Link } from "@reach/router";

import { EndUser } from "../../../types/endUser";
import { ProfileImage } from "../../../types/profile";
import { Booking } from "../../../types/booking";
import defaultAvatar from "../../../assets/images/default-avatar-image.png";
import { Icon } from "../../layout/Icons";

export const ClientList = ({
  clients,
  bookings,
  limit = 9,
}: {
  clients: Pick<EndUser, "id" | "name" | "profileImage">[];
  bookings: Pick<Booking, "id" | "endUser">[];
  limit?: number;
}) => {
  const clientsWithNumUpcomingBookings = clients
    .map((c) => {
      return {
        ...c,
        numBookings: bookings.filter((b) => b.endUser.id === c.id).length,
      };
    })
    .sort((a, b) => b.numBookings - a.numBookings);

  return (
    <div className="hidden xl:block">
      <ul className="mt-3">
        {clientsWithNumUpcomingBookings.slice(0, limit).map((client) => {
          return <ClientCard client={client} key={client.id} />;
        })}
      </ul>
      <div className="py-4 text-sm leading-5 mt-2">
        <Link
          to="/clients"
          className="text-teal-600 font-semibold hover:text-teal-900"
        >
          View all clients &rarr;
        </Link>
      </div>
    </div>
  );
};

export const ClientCard = ({
  client,
}: {
  client: {
    id: string;
    profileImage?: ProfileImage;
    name: string;
    numBookings: number;
  };
}) => {
  const image = client.profileImage
    ? client.profileImage.small
    : defaultAvatar;
  return (
    <li className="">
      <Link
        to={`/user/profile/${client.id}`}
        className="flex shadow-sm rounded-md mb-2"
      >
        <div
          className="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm leading-5 font-medium rounded-l-md bg-cover"
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md w-4/5 relative">
          <div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
            <p className="text-black font-medium transition ease-in-out duration-150 mb-2 truncate">
              {client.name}
            </p>
            <div className="flex">
              <p className="text-gray-500 flex items-center mr-4">
                <Icon name="calendar" className="w-3 h-3 text-gray-400 mr-2" />
                {client.numBookings}
              </p>

              <p className="text-gray-500 text-sm">Bookings upcoming</p>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};
