import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/client";

import { Loading } from "../../layout/Loading";
import { NotFoundPage } from "../../pages/NotFoundPage";
import {
  SUPPORT_COORDINATOR,
  Data,
  Variables,
} from "../../../graphql/queries/supportCoordinator";
import { Avatar } from "../../layout/Avatar";
import { Button } from "../../layout/Button";
import { IUserContext, ServiceRole } from "../../../types/profile";
import { ErrorPage } from "../../layout/ErrorPage";

interface SupportCoordinatorProfileProps extends RouteComponentProps {
  user: Pick<IUserContext, "id" | "__typename">;
  profileId?: string;
}

export const SupportCoordinatorProfilePage = ({
  user,
  profileId,
}: SupportCoordinatorProfileProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    SUPPORT_COORDINATOR,
    {
      variables: {
        profileId: profileId || "",
      },
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  if (!data.supportCoordinator) {
    return <NotFoundPage />;
  }

  const { supportCoordinator } = data;

  return (
    <div className="flex flex-wrap md:flex-no-wrap mx-6 my-8 md:m-16 md:ml-24 lg:ml-40">
      <div className="w-full md:w-1/4">
        <Avatar user={supportCoordinator} size="lg" />
      </div>
      <div
        data-testid="sp-profile-container"
        className="md:relative w-full md:w-3/4 xl:w-3/5 xl:ml-16 md:pl-16 py-2"
      >
        {user.id === supportCoordinator.id && (
          <Button
            className="absolute top-0 right-0"
            icon="edit"
            to={`/settings/profile`}
          >
            Edit
          </Button>
        )}
        <div className="text-center md:text-left">
          <h1 className="leading-none mb-2">
            {supportCoordinator.firstName}
            {supportCoordinator.lastName}
          </h1>
          <div className="md:flex items-center">
            <p className="text-gray-600 font-normal text-2xl mr-4 mb-4 md:mb-0">
              {supportCoordinator.company || "Support Coordinator"}
            </p>
          </div>
        </div>
        <div data-testid="book-now-container" className="relative">
          {user.id !== supportCoordinator.id && (
            <div className="mb-3 mt-10">
              {user.__typename === ServiceRole.SUPPORT_WORKER && (
                <>
                  <div className="mx-5 flex items-center justify-between">
                    <Button
                      data-testid="contact-button"
                      secondary
                      color="gray"
                      icon="textBubble"
                      to={`/messages/${supportCoordinator.id}`}
                      className="contact-button"
                    >
                      Contact {supportCoordinator.firstName}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <p className="text-gray-700 my-2 max-w-3xl whitespace-pre-line">
          {supportCoordinator.about}
        </p>
      </div>
    </div>
  );
};
