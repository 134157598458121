import gql from "graphql-tag";

import { Booking, BookingState } from "../../types/booking";
import { BOOKING_FIELDS } from "../fragments";

export interface NewData {
  booking: Booking;
}

export interface Variables {
  newState: BookingState;
  bookingId: string | null;
  scheduleId: string | null;
  date: string;
  personId: string;
  cancellationReason?: string;
  supportCoordinatorId?: string;
  affectBookingSchedule?: boolean;
}

export const UPDATE_BOOKING_STATE = gql`
  ${BOOKING_FIELDS}
  mutation UpdateBookingState(
    $newState: BookingState!
    $bookingId: ID
    $scheduleId: ID
    $date: String
    $personId: ID!
    $cancellationReason: String
    $supportCoordinatorId: String
    $affectBookingSchedule: Boolean = false
  ) {
    updateBookingState(
      id: $bookingId
      scheduleId: $scheduleId
      date: $date
      state: $newState
      serviceRoleId: $personId
      cancellationReason: $cancellationReason
      supportCoordinatorId: $supportCoordinatorId
      affectBookingSchedule: $affectBookingSchedule
    ) {
      ...BookingFields
    }
  }
`;
