import gql from "graphql-tag";

import { Booking, RecurringBookingSchedule } from "../../types/booking";
import { ServiceRole } from "../../types/profile";
import {
  BOOKING_FIELDS,
  RECURRING_BOOKING_SCHEDULE_FIELDS,
} from "../fragments";

export interface Data {
  allUpcomingBookings: Booking[];
  bookingsForDate: Booking[];
  endUser?: {
    id: string;
    bookingSchedules: RecurringBookingSchedule[];
  };
  supportProfessional?: {
    id: string;
    bookingSchedules: RecurringBookingSchedule[];
  };
  upcomingBookingDates: string[];
}

export interface Variables {
  id: string;
  date: string;
}

const UPCOMING_BOOKINGS_FOR_END_USER = gql`
  ${BOOKING_FIELDS}
  ${RECURRING_BOOKING_SCHEDULE_FIELDS}

  query UpcomingBookingsForEndUser($id: ID!, $date: String!) {
    allUpcomingBookings: activeBookingsForEndUser(id: $id) {
      ...BookingFields
    }

    upcomingBookingDates: upcomingBookingDatesForEndUser(endUserId: $id)

    bookingsForDate: bookingsForEndUserOnDate(id: $id, date: $date) {
      ...BookingFields
    }

    endUser(id: $id) {
      id
      bookingSchedules {
        ...RecurringBookingScheduleFields
      }
    }
  }
`;

const UPCOMING_BOOKINGS_FOR_SUPPORT_PROFESSIONAL = gql`
  ${BOOKING_FIELDS}
  ${RECURRING_BOOKING_SCHEDULE_FIELDS}

  query UpcomingBookingsForSupportProfessional($id: ID!, $date: String!) {
    allUpcomingBookings: activeBookingsForSupportProfessional(id: $id) {
      ...BookingFields
    }

    upcomingBookingDates: upcomingBookingDatesForSupportProfessional(
      supportProfessionalId: $id
    )

    bookingsForDate: bookingsForSupportProfessionalOnDate(
      id: $id
      date: $date
    ) {
      ...BookingFields
    }

    supportProfessional(id: $id) {
      id
      bookingSchedules {
        ...RecurringBookingScheduleFields
      }
    }
  }
`;

const UPCOMING_BOOKINGS_FOR_SUPPORT_COORDINATOR = gql`
  ${BOOKING_FIELDS}

  query UpcomingBookingsForSupportProfessional($id: ID!) {
    allUpcomingBookings: upcomingClientBookings(supportCoordinatorId: $id) {
      ...BookingFields
    }

    actionableBookings: actionableClientBookings(supportCoordinatorId: $id) {
      ...BookingFields
    }
  }
`;

export const upcomingBookingsQueryForUserType = {
  [ServiceRole.END_USER]: UPCOMING_BOOKINGS_FOR_END_USER,
  [ServiceRole.SUPPORT_WORKER]: UPCOMING_BOOKINGS_FOR_SUPPORT_PROFESSIONAL,
  [ServiceRole.SUPPORT_COORDINATOR]: UPCOMING_BOOKINGS_FOR_SUPPORT_COORDINATOR,
};
