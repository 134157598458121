import gql from "graphql-tag";

export interface NewData {
  updateAccount: {
    id: string;
    acceptedTerms: boolean;
  };
}

export interface Variables {
  id: string;
  acceptedTerms: boolean;
}

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($id: ID!, $acceptedTerms: Boolean!) {
    updateAccount(id: $id, acceptedTerms: $acceptedTerms) {
      id
      acceptedTerms
    }
  }
`;
