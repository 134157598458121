import React from "react";
import { Link } from "@reach/router";

import { ServiceRole, IUserContext } from "../../types/profile";
import { VirtualBookingAlert } from "./VirtualBookingAlert";
import { useDownloadBookingHistory } from "../../hooks/useDownloadBookingHistory";
import { Button } from "../layout/Button";

export const BookingNav = ({
  user,
  pathname,
}: {
  user: Pick<IUserContext, "id" | "name" | "__typename">;
  pathname: string;
}) => {
  const [downloadFile] = useDownloadBookingHistory(user);

  const history = pathname.includes("history");

  const NavLink = (props: any) => (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => ({
        className: `${
          isCurrent || isPartiallyCurrent
            ? "font-bold border-b-2 border-gray-800"
            : "opacity-75"
        } ${props.className}`,
      })}
    />
  );
  return (
    <>
      <VirtualBookingAlert user={user} />
      <h1 className="text-3xl font-bold mb-4">Bookings</h1>
      <div className="md:flex justify-between items-center mb-6 md:mb-0">
        <nav className="mb-5 mx-1 ">
          <ul className="">
            <li className="inline-block mr-6">
              <NavLink
                className="text-gray-800 inline-block pb-2"
                to={
                  user.__typename === ServiceRole.SUPPORT_COORDINATOR
                    ? `/bookings/upcoming`
                    : `/bookings/date`
                }
              >
                Your Bookings
              </NavLink>
            </li>
            <li className="inline-block mr-6">
              <NavLink className="text-gray-800 inline-block pb-2" to="history">
                History
              </NavLink>
            </li>
          </ul>
        </nav>
        {user.__typename === ServiceRole.SUPPORT_WORKER && history && (
          <Button secondary onClick={downloadFile} size="small" icon="download">
            Download booking history
          </Button>
        )}
      </div>
    </>
  );
};
