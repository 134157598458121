import React, { useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";

import { SupportProfessionalSummaryCardGroup } from "./SupportProfessionalSummaryCardGroup";
import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import { LoadingDots } from "../../layout/LoadingDots";
import { SupportPrompt } from "../../pages/discover/SupportPrompt";
import { IUserContext } from "../../../types/profile";
import { Button } from "../../layout/Button";
import { EndUser } from "../../../types/endUser";
import { Data } from "../../../graphql/queries/paginatedSupportProfessionalsData";
import { Variables as RecommendedVariables } from "../../../graphql/queries/recommendedSupportProfessionals/paginatedRecommendedSupportProfessionals";
import { Variables as ByServiceVariables } from "../../../graphql/queries/paginatedSupportProfessionalsByServiceType";

export interface SupportProfessionalListProps {
  // Must be passed a query that returns data in the shape of paginated support professionals:
  // ie: {
  //    paginatedSupportProfessionals: {
  //      supportProfessionals: SupportProfessional[]
  //      total: number;
  //    }
  // }
  query: DocumentNode;
  queryVariables: RecommendedVariables | ByServiceVariables;
  user: Pick<IUserContext, "id" | "name">;
  margins: string;
  endUser?: EndUser;
  displayPrompt?: boolean;
  rows?: number;
}

export const SupportProfessionalList = ({
  query,
  queryVariables,
  user,
  margins,
  endUser,
  displayPrompt,
  rows = 2,
}: SupportProfessionalListProps) => {
  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);

  const [randomSeed] = useState(Math.random());

  const containerRef = useCallback((node) => {
    if (node !== null) {
      setContainerWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const itemsInRow = Math.floor(containerWidth / 250);
  const limit = itemsInRow * rows;

  const variables = {
    ...queryVariables,
    options: {
      offset: 0,
      randomSeed,
      limit,
    },
  };

  const { data, error, loading, fetchMore } = useQuery<
    Data,
    RecommendedVariables | ByServiceVariables
  >(query, {
    variables,
    fetchPolicy: "network-only",
  });

  const fetchMoreSupportProfessionals = async () => {
    setLoadingMore(true);
    await fetchMore({
      variables: {
        ...variables,
        options: { ...variables.options, offset: offset + limit },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newSupportProfessionals = fetchMoreResult
          ? fetchMoreResult.paginatedSupportProfessionals.supportProfessionals
          : [];
        return {
          paginatedSupportProfessionals: {
            ...prev.paginatedSupportProfessionals,
            supportProfessionals: [
              ...prev.paginatedSupportProfessionals.supportProfessionals,
              ...newSupportProfessionals,
            ],
          },
        };
      },
    });
    setLoadingMore(false);
    setOffset(offset + limit);
  };

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  const { supportProfessionals, total } = data.paginatedSupportProfessionals;

  return (
    <>
      <div ref={containerRef} className={`mb-12 ${margins}`}>
        <SupportProfessionalSummaryCardGroup
          supportWorkers={supportProfessionals}
          endUser={endUser}
        />
      </div>
      <div className={`flex items-center justify-center py-10 ${margins}`}>
        {supportProfessionals.length < total ? (
          <Button
            secondary
            onClick={fetchMoreSupportProfessionals}
            type="button"
            size="xl"
            disabled={loadingMore}
          >
            {loadingMore ? <LoadingDots height="21" /> : "Load More"}
          </Button>
        ) : (
          <>
            {displayPrompt && (
              <SupportPrompt
                text={"Can't find what you're looking for?"}
                className=""
                user={user}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
