import React, { useState } from "react";
import {
  RemoteVideoTrack as RemoteVideo,
  RemoteAudioTrack as RemoteAudio,
  LocalVideoTrack,
  LocalAudioTrack,
} from "twilio-video";
import { ApolloQueryResult } from "@apollo/client";

import { Participant } from "./Participant";
import { Booking } from "../../../types/booking";
import { addPartiesToBooking } from "../addPartiesToBooking";
import { Controls } from "./Controls";
import { LoadingDots } from "../../layout/LoadingDots";
import { useRoom } from "./hooks/useRoom";
import { navigate } from "@reach/router";
import { WaitingRoom } from "./WaitingRoom";
import { EndCallConfirmationModal } from "./EndCallConfirmationModal";
import { IUserContext } from "../../../types/profile";
import { useLogger, useLockBody } from "../../../hooks";

// Explicitly setting a null disabled attribute in order to type check tracks later
export interface RemoteVideoTrack extends RemoteVideo {
  disable?: undefined;
  enable?: undefined;
}
export interface RemoteAudioTrack extends RemoteAudio {
  disable?: undefined;
  enable?: undefined;
}

export type Track =
  | RemoteVideoTrack
  | RemoteAudioTrack
  | LocalVideoTrack
  | LocalAudioTrack
  | null;

export const VideoRoom = ({
  accessToken,
  booking,
  refetchBooking,
  user,
}: {
  accessToken: string;
  booking: Booking;
  refetchBooking: (
    variables?: { bookingId: string } | undefined
  ) => Promise<ApolloQueryResult<any>>;
  user: Pick<IUserContext, "id" | "__typename">;
}) => {
  useLockBody();
  const { log } = useLogger();
  const { room, participants, disconnected } = useRoom(accessToken);
  const [endCallConfirmation, setEndCallConfirmation] = useState(false);

  if (!room) {
    return <LoadingDots className="m-auto mb-2 mt-5" />;
  }

  const bookingWithParties = addPartiesToBooking<
    Pick<
      Booking,
      | "id"
      | "endUser"
      | "supportProfessional"
      | "state"
      | "startTime"
      | "scheduleId"
    >
  >(booking, user);

  if (disconnected) {
    refetchBooking({ bookingId: booking.id })
      .then((res) => {
        navigate(`/bookings/${booking.id}`, { state: { disconnected: true } });
      })
      .catch((err) => log("error", err));
  }

  return (
    <>
      <div className="w-full bg-gray-200 grid grid-cols-3 m-auto mt-8">
        <div className="fixed top-0 left-0 z-20 w-full h-full">
          {participants[0] ? (
            <div className="remote-participant fixed w-full left-0 top-0 h-screen m-0 md:col-span-2 bg-black flex z-30">
              <Participant
                participant={participants[0]}
                serviceRole={bookingWithParties.otherParty}
                className="z-30"
              />
              <Controls
                room={room}
                setEndCallConfirmation={setEndCallConfirmation}
              />
            </div>
          ) : (
            <WaitingRoom
              booking={bookingWithParties}
              room={room}
              setEndCallConfirmation={setEndCallConfirmation}
            />
          )}
        </div>
        <Participant
          participant={room.localParticipant}
          serviceRole={bookingWithParties.party}
          isLocal
          className="z-30"
        />
      </div>
      {endCallConfirmation && (
        <EndCallConfirmationModal
          setEndCallConfirmation={setEndCallConfirmation}
          booking={booking}
          user={user}
        />
      )}
    </>
  );
};
