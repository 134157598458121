import React from "react";

import { Icon } from "../../../layout/Icons";

export const PendingVerification = () => {
  return (
    <div className="rounded-md bg-blue-100 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon name="pending" className="w-6 h-6 text-blue-700" />
        </div>
        <div className="ml-3">
          <h3 className="text-base leading-5 font-bold text-blue-800">
            Verification Pending
          </h3>
          <div className="mt-2 text-base leading-5 text-blue-700">
            <p>
              Some information on your account is pending verification. As soon
              as it is approved your account will be activated.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
