import React from "react";
import { RouteComponentProps } from "@reach/router";
import { ToastConsumer, AddToast } from "react-toast-notifications";
import { useQuery } from "@apollo/client";

import { EndUser, Permissions } from "../../../../types/endUser";
import { FundingTypeForm } from "./FundingTypeForm";
import {
  END_USER_PERMISSIONS_QUERY,
  Data,
  Variables,
} from "../../../../graphql/queries/endUserPermissions";
import { useLogger } from "../../../../hooks";
import { Loading } from "../../../layout/Loading";
import { Icon } from "../../../layout/Icons";

export type EndUserT = Pick<
  EndUser,
  "fundingType" | "id" | "ndisParticipantNumber" | "otherFundingType"
>;

export interface FundingSettingsProps extends RouteComponentProps {
  endUser: EndUserT;
}

export const FundingSettings = ({ endUser }: FundingSettingsProps) => {
  const { log } = useLogger();
  const { data, error, loading } = useQuery<Data, Variables>(
    END_USER_PERMISSIONS_QUERY,
    {
      variables: { endUserId: endUser.id },
    }
  );

  if (error) {
    log("error", error);
  }

  if (loading || !data) {
    return <Loading contentOnly />;
  }

  return (
    <ToastConsumer>
      {({ add }: { add: AddToast }) => {
        return (
          <div className="border-container mx-1 xm:ml-12 lg:mx-12">
            <h3 className="mb-0">Funding Details</h3>
            <FundingSettingsContent
              endUser={endUser}
              add={add}
              permissions={data.endUser.permissions}
            />
          </div>
        );
      }}
    </ToastConsumer>
  );
};

export const FundingSettingsContent = ({
  endUser,
  add,
  permissions,
}: {
  endUser: EndUserT;
  add: AddToast;
  permissions?: Permissions;
}) => {
  return (
    <>
      <div className="mt-8 border-t border-gray-200 sm:mt-5 ">
        <FundingTypeForm endUser={endUser} add={add} />
      </div>
      {endUser.fundingType && permissions && !permissions.invoice && (
        <div className="rounded-md bg-blue-100 p-4 mb-6 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <Icon name="info" className="w-6 h-6 text-blue-700" />
            </div>
            <div className="ml-3">
              <h3 className="text-base leading-5 font-bold text-blue-800">
                Invoice Booking Requests
              </h3>
              <div className="mt-2 text-base leading-5 text-blue-700">
                <p>
                  if you want to request an invoice at the time of booking
                  please email{" "}
                  <a
                    href="mailto:support@tappon.co"
                    className="font-bold text-blue-700"
                  >
                    support@tappon.co
                  </a>{" "}
                  to add this feature to your account
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
