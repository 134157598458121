import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { Loading } from "../../../layout/Loading";
import { ErrorPage } from "../../../layout/ErrorPage";
import { LocationMarker } from "./LocationMarker";
import { ContactButton } from "./Contactbutton";
import { Button } from "../../../layout/Button";
import { About } from "./About";
import {
  SUPPORT_PROFESSIONAL_PROFILE,
  Data,
  Variables,
} from "../../../../graphql/queries/supportProfessionalProfile";
import { EmploymentHistory } from "./employmentHistory/EmploymentHistory";
import { WorkingWithChildrenCheck } from "./WorkingWithChildrenCheck";
import { Icon } from "../../../layout/Icons";
import { NotFoundPage } from "../../../pages/NotFoundPage";
import { IUserContext, ServiceRole } from "../../../../types/profile";
import defaultAvatar from "../../../../assets/images/default-avatar-image.png";

interface SupportProfessionalProfileProps extends RouteComponentProps {
  profileId?: string;
  user: Pick<IUserContext, "id" | "__typename">;
}

export const SupportProfessionalProfilePage = ({
  profileId,
  user,
}: SupportProfessionalProfileProps) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    SUPPORT_PROFESSIONAL_PROFILE,
    {
      variables: {
        profileId: profileId || "",
        endUserId: user.id,
      },
    }
  );

  if (error) {
    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { supportProfessional, endUser } = data;

  if (!supportProfessional) {
    return <NotFoundPage />;
  }

  const firstName = supportProfessional.name.split(" ")[0];

  const {
    name,
    title,
    addressCity,
    id,
    employmentExperience,
    profileImage,
    availabilities,
  } = supportProfessional;

  const over18 =
    !endUser ||
    moment(endUser.dateOfBirth).isBefore(
      moment(Date.now()).subtract(18, "years")
    );

  const wwcc = supportProfessional.wwcc.find(
    (w) =>
      w.verificationDate && moment(w.expiryDate).isAfter(moment(Date.now()))
  );

  const bookable = !!over18 || !!wwcc;
  const hasAvailability =
    availabilities.filter((a) => !a.available).length !== 7;

  return (
    <div className="flex flex-wrap md:flex-no-wrap mx-6 my-8 md:m-16 md:ml-24 lg:ml-40">
      <div className="w-full md:w-1/4">
        <div
          className="w-32 rounded-full m-auto md:rounded mb-6 square md:w-full bg-cover bg-center"
          title={`${name}'s Profile Image`}
          style={{
            backgroundImage: `url(${(profileImage && profileImage.large) || defaultAvatar})`,
          }}
        />

        {wwcc && (
          <WorkingWithChildrenCheck
            wwcc={wwcc}
            className="hidden md:inline-block"
          />
        )}
      </div>
      <div
        data-testid="sp-profile-container"
        className="md:relative w-full md:w-3/4 xl:w-3/5 xl:ml-16 md:pl-16 py-2"
      >
        {user.id === supportProfessional.id && (
          <Button
            className="absolute top-0 right-0 mt-32 md:mt-8 mr-8"
            icon="edit"
            to={`/worker/profile/${user.id}/edit`}
          >
            Edit
          </Button>
        )}
        <div className="text-center md:text-left">
          <h1 className="leading-none mb-2">{name}</h1>
          <div className="md:flex items-center">
            <p className="text-gray-600 font-normal text-2xl mr-4 mb-4 md:mb-0">
              {title}
            </p>
            {addressCity && (
              <LocationMarker
                location={addressCity}
                className="justify-center md:justify-start"
              />
            )}
          </div>
        </div>
        <div data-testid="book-now-container" className="relative">
          {user.id !== supportProfessional.id && (
            <div className="mb-3 mt-10">
              {[ServiceRole.END_USER, ServiceRole.SUPPORT_COORDINATOR].includes(
                user.__typename
              ) && (
                <>
                  {user.__typename === ServiceRole.SUPPORT_COORDINATOR ||
                  endUser?.permissions.message ? (
                    <div className="mx-5 flex items-center justify-between">
                      <ContactButton id={id} name={firstName} />
                      {bookable && hasAvailability && (
                        <Button to={`/worker/profile/${id}/new-booking`}>
                          Book Now
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div
                      className="w-full bg-teal-200 border border-teal-400 text-teal-700 p-4 mb-3 flex items-center mb-4"
                      role="alert"
                    >
                      <Icon className="h-8 w-8 mr-6 flex-none" name="alert" />
                      <div>
                        <p className="mb-2">
                          Lets complete your profile set up so you can start
                          reaching out to support professionals
                        </p>
                        <Button size="small" to="/settings/personal">
                          Settings
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <About
          supportProfessional={supportProfessional}
          wwcc={wwcc}
          bookable={bookable}
        />
        {employmentExperience.length > 0 && (
          <EmploymentHistory
            supportProfessional={supportProfessional}
            history={employmentExperience.slice().sort((x, y) => {
              return moment(x.startDate).isAfter(moment(y.startDate)) ? -1 : 1;
            })}
          />
        )}
      </div>
    </div>
  );
};
