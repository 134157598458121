import React from "react";
import { Form } from "formik-semantic-ui";
import { useMutation } from "@apollo/client";

import { PaymentFlow } from "../../../../types/booking";
import { paymentTypeMessages } from "../../../bookings/BookingHelpers";
import { ServiceRole } from "../../../../types/profile";
import { SupportProfessional } from "../../../../types/supportProfessional";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import { SUPPORT_PROFESSIONAL_SETTINGS } from "../../../../graphql/queries/settings/supportProfessionalSettings";
import { Checkbox } from "../../../layout/forms";
import { Icon } from "../../../layout/Icons";
import { useLogger } from "../../../../hooks/useLogger";
import { Button } from "../../../layout/Button";
import { FormikHelpers } from "formik";

interface FormValues {
  acceptsTransferBookings: boolean;
  acceptsFastBookings: boolean;
}

export const EditPaymentOptions = ({
  supportProfessional,
}: {
  supportProfessional: SupportProfessional;
}) => {
  const { log } = useLogger();
  const [paymentMutationFn] = useMutation<NewData, Variables>(
    UPDATE_SUPPORT_PROFESSIONAL
  );

  const handleSubmit = (
    values: FormValues,
    formikApi: FormikHelpers<FormValues>
  ) => {
    const { setTouched } = formikApi;
    const variables = {
      ...values,
      id: supportProfessional.id,
    };
    paymentMutationFn({
      variables,
      refetchQueries: [
        {
          query: SUPPORT_PROFESSIONAL_SETTINGS,
          variables: { profileId: supportProfessional.id },
        },
      ],
    })
      .then((res) => {
        formikApi.setSubmitting(false);
        setTouched({});
      })
      .catch((err) => {
        log("error", err);
      });
  };

  const initialValues: FormValues = {
    acceptsTransferBookings: supportProfessional.acceptsTransferBookings,
    acceptsFastBookings: supportProfessional.acceptsFastBookings,
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {(props) => {
        const { touched } = props;
        const displayButton =
          touched.acceptsTransferBookings || touched.acceptsFastBookings;
        return (
          <>
            <div className="flex items-center mb-3">
              <Checkbox
                label="I want to accept transfer bookings"
                ariaLabel="I want to accept transfer bookings"
                name="acceptsTransferBookings"
                className="flex items-center"
              />
            </div>
            <div className="bg-gray-100 border border-gray-200 text-gray-800 p-3 mb-10 flex text-sm">
              <Icon
                name="question"
                className="w-4 h-4 mr-4 text-gray-600 flex-none mt-2"
              />
              {
                paymentTypeMessages[PaymentFlow.INVOICE][
                  ServiceRole.SUPPORT_WORKER
                ]
              }
            </div>
            <div className="flex items-center mb-2">
              <Checkbox
                label="I want to accept fast bookings"
                ariaLabel="I want to accept fast bookings"
                name="acceptsFastBookings"
                className="flex items-center"
              />
            </div>
            <div className="bg-gray-100 border border-gray-200 text-gray-800 p-3 mb-6 flex items-center text-sm">
              <Icon name="question" className="w-4 h-4 mr-4 text-gray-600" />
              {
                paymentTypeMessages[PaymentFlow.CREDIT_CARD][
                  ServiceRole.SUPPORT_WORKER
                ]
              }
            </div>
            {!supportProfessional.acceptsFastBookings &&
              !supportProfessional.acceptsTransferBookings && (
                <div className="bg-tangerine-200 border border-tangerine-400 text-tangerine-700 p-4 mb-4 flex items-center mb-6">
                  <svg
                    className="fill-current h-6 w-6 mr-4 opacity-75"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" />
                  </svg>
                  You currently have no payment options enabled and won't be
                  able to receive any new bookings
                </div>
              )}

            {displayButton && (
              <div className="w-full flex justify-end">
                <Button type="submit">Save</Button>
              </div>
            )}
          </>
        );
      }}
    </Form>
  );
};
