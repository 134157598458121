import { useEffect } from "react";

export const useLockBody = () => {
  // Locks the body element and removes necessary transform properties,
  // in order to use position: fixed (ie. in VideoRoom)

  useEffect(() => {
    const transformEl = document.getElementById("transform-element");
    const prevTransformProp = transformEl ? transformEl.style.transform : "";

    function lockBodyScroll() {
      if (transformEl) {
        transformEl.style.transform = "none";
      }
      document.body.classList.add("locked");
    }
    window.addEventListener("resize", lockBodyScroll);

    lockBodyScroll();

    return function cleanup() {
      window.removeEventListener("resize", lockBodyScroll);
      if (transformEl) transformEl.style.transform = prevTransformProp;
      document.body.classList.remove("locked");
    };
  });
};
