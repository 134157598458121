import React, { ChangeEvent } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useMutation, ApolloQueryResult } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import { useLogger } from "../../../../hooks/useLogger";
import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import {
  ServiceOffering,
  SupportProfessional,
} from "../../../../types/supportProfessional";
import { ToggleField as Toggle } from "../../../layout/forms";

export interface EditEnabledFormProps {
  supportProfessional: SupportProfessional;
  serviceOffering: ServiceOffering;
  add?: AddToast;
  refetch: (
    variables?: { profileId: string } | undefined
  ) => Promise<ApolloQueryResult<any>>;
  isMeetAndGreet?: boolean;
}

interface FormValues {
  enabled: boolean;
}

export const EditEnabledForm = ({
  supportProfessional,
  serviceOffering,
  add,
  refetch,
  isMeetAndGreet,
}: EditEnabledFormProps) => {
  const { log } = useLogger();
  const [updateSupportProfessional] = useMutation<NewData, Variables>(
    UPDATE_SUPPORT_PROFESSIONAL
  );

  const handleSubmit = (
    values: FormValues,
    formikApi: FormikHelpers<FormValues>
  ) => {
    const { enabled } = values;
    const serviceOfferings = supportProfessional.serviceOfferings.map(
      (s: ServiceOffering) => {
        return s.id === serviceOffering.id
          ? {
              ...s,
              id: s.id,
              serviceId: s.service.id,
              enabled,
            }
          : { ...s, serviceId: s.service.id, id: s.id };
      }
    );

    const variables = {
      id: supportProfessional.id,
      serviceOfferings,
    };

    updateSupportProfessional({
      variables,
    })
      .then((res) => res)
      .catch((err) => {
        log("error", err);
        refetch().then((res) => {
          formikApi.resetForm();
        });

        if (add)
          add(`There was an error updating your service offering`, {
            appearance: "error",
            autoDismiss: true,
          });
      });
  };

  return (
    <Formik
      initialValues={{
        enabled: serviceOffering.enabled,
      }}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { submitForm, isSubmitting } = props;
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
          submitForm();
        };
        return (
          <Form>
            <li className="py-5 sm:py-0 sm:pb-5 pr-5 sm:pr-12 pl-6 xm:pl-8 flex justify-between items-center hover:text-gray-700 hover:bg-gray-100">
              <div className="">
                <fieldset>
                  <div className="flex sm:items-baseline">
                    <div className="sm:col-span-2">
                      <div className="max-w-lg ml-4">
                        <div className="mt-4">
                          <div className={`text-gray-800 mr-8`}>
                            <Toggle
                              name="enabled"
                              label={
                                isMeetAndGreet
                                  ? "Turn on if you wish to offer new clients a free 15 minute session"
                                  : "Enabled"
                              }
                              ariaLabel="Enabled"
                              onChange={handleChange}
                              className=""
                              disabled={isSubmitting}
                              labelSize="small"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </li>
          </Form>
        );
      }}
    </Formik>
  );
};
