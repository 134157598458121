import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Formik, Form } from "formik";
import { useMutation, ApolloQueryResult } from "@apollo/client";
import { AddToast } from "react-toast-notifications";

import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import {
  SupportProfessional,
  ServiceOffering,
} from "../../../../types/supportProfessional";
import { ToggleField as Toggle } from "../../../layout/forms";
import { useLogger } from "../../../../hooks/useLogger";

export interface EditDeliveryTypesFormProps {
  supportProfessional: SupportProfessional;
  serviceOffering: ServiceOffering;
  add?: AddToast;
  refetch: (
    variables?: { profileId: string } | undefined
  ) => Promise<ApolloQueryResult<any>>;
  setErrorMessage: Dispatch<SetStateAction<boolean>>;
}

export const EditDeliveryTypesForm = ({
  supportProfessional,
  serviceOffering,
  add,
  refetch,
  setErrorMessage,
}: EditDeliveryTypesFormProps) => {
  const { log } = useLogger();
  const [updateSupportProfessional] = useMutation<NewData, Variables>(
    UPDATE_SUPPORT_PROFESSIONAL
  );

  const handleSubmit = (values: any, formikApi: any) => {
    const { virtual, inPerson } = values;
    const serviceOfferings = supportProfessional.serviceOfferings.map((s) => {
      return s.id === serviceOffering.id
        ? {
            id: s.id,
            serviceId: s.service.id,
            virtual,
            inPerson,
          }
        : { id: s.id, serviceId: s.service.id };
    });

    const variables = {
      id: supportProfessional.id,
      serviceOfferings,
    };

    updateSupportProfessional({
      variables,
    })
      .then((res) => res)
      .catch((err) => {
        log("error", err);
        refetch().then((res) => {
          formikApi.resetForm();
        });

        if (add)
          add(`There was an error updating your service offering`, {
            appearance: "error",
            autoDismiss: true,
          });
      });
  };

  return (
    <Formik
      initialValues={{
        virtual: serviceOffering.virtual,
        inPerson: serviceOffering.inPerson,
      }}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { submitForm, isSubmitting } = props;
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
          submitForm();
        };
        return (
          <Form>
            <li className="py-5 sm:py-0 sm:pb-5 pr-5 sm:pr-12 pl-6 xm:pl-8 flex justify-between items-center border-b border-light border-gray-100 hover:text-gray-700 hover:bg-gray-100">
              <div className="">
                <fieldset>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                      <legend className="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700 ml-4">
                        How would you like to provide this service?
                      </legend>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg ml-4">
                        <div className="mt-4">
                          <VirtualToggle
                            serviceOffering={serviceOffering}
                            setErrorMessage={setErrorMessage}
                            handleChange={handleChange}
                            isSubmitting={isSubmitting}
                          />
                          <InPersonToggle
                            serviceOffering={serviceOffering}
                            setErrorMessage={setErrorMessage}
                            handleChange={handleChange}
                            isSubmitting={isSubmitting}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </li>
          </Form>
        );
      }}
    </Formik>
  );
};

const InPersonToggle = ({
  serviceOffering,
  setErrorMessage,
  handleChange,
  isSubmitting,
}: {
  serviceOffering: ServiceOffering;
  setErrorMessage: Dispatch<SetStateAction<boolean>>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSubmitting: boolean;
}) => {
  return (
    <div className={`text-gray-800  mr-8`}>
      <div
        className="flex items-center h-12"
        onClick={() => {
          if (!serviceOffering.virtual) {
            setErrorMessage(true);
          }
        }}
      >
        <Toggle
          name="inPerson"
          label="In Person"
          ariaLabel="In Person"
          onChange={handleChange}
          className=""
          disabled={isSubmitting || !serviceOffering.virtual}
          labelSize="small"
        />
      </div>
    </div>
  );
};

const VirtualToggle = ({
  serviceOffering,
  setErrorMessage,
  handleChange,
  isSubmitting,
}: {
  serviceOffering: ServiceOffering;
  setErrorMessage: Dispatch<SetStateAction<boolean>>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSubmitting: boolean;
}) => {
  return (
    <div className={`text-gray-800 mr-8`}>
      <div
        className="flex items-center h-12"
        onClick={() => {
          if (!serviceOffering.inPerson) {
            setErrorMessage(true);
          }
        }}
      >
        <Toggle
          name="virtual"
          ariaLabel="virtual"
          onChange={handleChange}
          className=""
          disabled={isSubmitting || !serviceOffering.inPerson}
          labelSize="small"
        />
      </div>
    </div>
  );
};
