import React from "react";
import { navigate } from "@reach/router";
import { useQuery, ApolloQueryResult } from "@apollo/client";

import { Loading } from "../../layout/Loading";
import { ErrorPage } from "../../layout/ErrorPage";
import { VideoRoom } from "./VideoRoom";
import { IUserContext } from "../../../types/profile";
import { Booking } from "../../../types/booking";
import {
  BOOKING_VIDEO_ACCESS_TOKEN_QUERY,
  Data,
  Variables,
} from "../../../graphql/queries/bookingVideoAccessToken";

export const VideoRoomContainer = ({
  booking,
  user,
  refetchBooking,
}: {
  booking: Booking;
  user: Pick<IUserContext, "id" | "__typename">;
  refetchBooking: (
    variables?: { bookingId: string | undefined } | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) => {
  const { data, error, loading } = useQuery<Data, Variables>(
    BOOKING_VIDEO_ACCESS_TOKEN_QUERY,
    {
      variables: {
        bookingId: booking.id,
        serviceRoleId: user.id,
      },
      fetchPolicy: "no-cache",
    }
  );

  if (error) {
    const { message } = error;
    const state = { message: "", error: "videoError" };
    const knownErrors = [
      "booking is more than 15 minutes in the future",
      "booking is in the past",
      "booking is not virtual",
      "booking has ended",
      "booking is not in a valid state to start a video call",
    ];

    knownErrors.forEach((e) => {
      if (message.includes(e)) {
        state.message = e;
      }
    });

    if (knownErrors.find((e) => message.includes(e))) {
      navigate(`/bookings/${booking.id}`, { state });

      return <></>;
    }

    return <ErrorPage error={error.message} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const { bookingVideoAccessToken: accessToken } = data;

  return (
    <>
      <VideoRoom
        accessToken={accessToken || ""}
        booking={booking}
        refetchBooking={refetchBooking}
        user={user}
      />
    </>
  );
};
