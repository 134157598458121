import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { ApolloQueryResult } from "@apollo/client";
import { v4 as uuid } from "uuid";

import { SupportProfessional } from "../../../../types/supportProfessional";
import { ServiceRole } from "../../../../types/profile";
import { ServiceOfferingSettings } from "./ServiceOfferingSettings";

export interface ServiceSettingsProps extends RouteComponentProps {
  supportProfessional: SupportProfessional;
  serviceId?: string;
  refetch: (
    variables?: { profileId: string } | undefined
  ) => Promise<ApolloQueryResult<any>>;
}

export const ServiceSettings = ({
  supportProfessional,
  serviceId,
  refetch,
}: ServiceSettingsProps) => {
  if (supportProfessional.__typename === ServiceRole.END_USER) {
    return <Redirect noThrow to="/settings/profile" />;
  }

  return (
    <div
      data-testid="service-settings"
      className="border-container xm:ml-12 lg:mx-12"
    >
      <h2 className="mb-5 ml-3">Services you offer</h2>

      {supportProfessional.virtualOnly && (
        <div className="info mb-4">
          You can currently only offer virtual services
        </div>
      )}

      {supportProfessional.serviceOfferings.map((s) => (
        <ServiceOfferingSettings
          key={uuid()}
          serviceOffering={s}
          supportProfessional={supportProfessional}
          open={s.id === serviceId}
          refetch={refetch}
        />
      ))}
      {supportProfessional.serviceOfferings.length === 0 && (
        <div className="bg-orange-200 border border-orange-400 text-orange-700 p-4 mb-4 flex items-center">
          <svg
            className="fill-current h-6 w-6 mr-4 opacity-75"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" />
          </svg>
          You aren't currently offering any services
        </div>
      )}
      <p className="px-5 my-10">
        If you wish to update which services you offer, please contact{" "}
        <a href="mailto:support@tappon.co">support@tappon.co</a>
      </p>
    </div>
  );
};
