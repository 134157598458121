import gql from "graphql-tag";

export interface Data {
  accountLink: string;
}

export interface Variables {
  supportProfessionalId: string;
}

export const GET_SUPPORT_PROFESSIONAL_ACCOUNT_LINK_URL = gql`
  query AccountLinkQuery($supportProfessionalId: ID!) {
    accountLink(id: $supportProfessionalId)
  }
`;
