import React from "react";
import { Menu } from "semantic-ui-react";
import styled from "styled-components";

import { ProfileImage, ServiceRole } from "../../../types/profile";

import { Avatar } from "../Avatar";

export interface UserSelectOptions {
  userOptions: UserOptionT[];
  switchUser: (id: string) => void;
}

export interface UserOptionT {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  profileImage?: ProfileImage;
  __typename: ServiceRole;
}

export const UserSelect = ({ userOptions, switchUser }: UserSelectOptions) => {
  return (
    <UserMenu
      inverted
      pointing
      secondary
      size="large"
      vertical
      width="very wide"
    >
      {userOptions.map((user: UserOptionT, index: number) => {
        return (
          <UserMenuItem
            key={index}
            onClick={(_event: React.SyntheticEvent) =>
              switchUser(user.id as string)
            }
          >
            <Avatar float="left" className="mr-4" user={user} size="sm" />
            {user.name || `${user.firstName} ${user.lastName}`}
          </UserMenuItem>
        );
      })}
    </UserMenu>
  );
};

const UserMenu = styled(Menu)`
  &&& {
    width: 100% !important;
    border: 0 !important;
  }
`;

const UserMenuItem = styled(Menu.Item)`
  &&& {
    margin: 0 15px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    display: flex !important;
    align-items: center !important;

    &:last-child {
      padding-bottom: 65px !important;
    }
  }
`;
