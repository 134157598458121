import gql from "graphql-tag";
import { SupportCoordinator } from "../../types/supportCoordinator";

import { Viewer } from "../../types/viewer";
import { SUPPORT_COORDINATOR_FIELDS } from "../fragments";

export interface Data {
  viewer: Viewer;
  adminSupportCoordinators: SupportCoordinator[];
}

export const ALL_AVAILABLE_PROFILES = gql`
  ${SUPPORT_COORDINATOR_FIELDS}
  {
    viewer {
      id
      role
      endUsers {
        id
        name
        permissions {
          booking
          message
          invoice
        }
        profileImage {
          id
          small
          med
        }
      }
      supportProfessionals {
        id
        name
        profileImage {
          id
          small
          med
        }
      }
      supportCoordinator {
        ...SupportCoordinatorFields
      }
    }
    adminSupportCoordinators {
      ...SupportCoordinatorFields
    }
  }
`;
